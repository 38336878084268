import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import DatePicker from '../../Components/widgets/DatePicker';
import { startLoading, stopLoading } from '../../Components/Util';
import Select from 'react-select';
import * as service from "../../services";
import moment from 'moment';

function CreateEla(props) {
    const [title, setTitle] = useState("Create Ela Quote");
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [lookup, setLookup] = useState([]);
    const [isNew, setIsNew] = useState(true);
    const [opportunities, setOpportunities] = useState([]);
    const [recipe, setRecipe] = useState({ channel: 'DIRECT' });
    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                setTitle(`Edit ELA Quote #${props.match.params.id}`);
                setIsNew(false);
                service.getElaData("ela-header", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        setRecipe({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }

                });

            } else {
                setIsNew(true);
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            service.getElaLookups(res => {
                setLookup(res)
                resolve("");
            });
        });
        Promise.all([p1, p2])
            .then(values => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.id]);

    const validateSearchTerm = () => {
        setErrors({});
        let isValid = true;

        if (!recipe['opportunity_id'] || recipe['opportunity_id'].length <= 0) {
            setErrors(errors => ({ ...errors, opportunity_id: 'Please enter search term' }));
            isValid = false;
        }

        return isValid;
    }
    const searchOpportunities = () => {
        if (validateSearchTerm()) {
            setLoading(true);
            service.searchOpportunities(recipe.opportunity_id, (data) => {
                console.log(data.searchRecords);
                if (data && data.searchRecords) {
                    setOpportunities(data.searchRecords);
                    setLoading(false);
                }

            });
        }
    }
    const resetOpportunities = () => {
        setErrors({});
        setIsNew(true);
        setOpportunities([]);
        setRecipe({ opportunity_id: undefined });
    }
    const quoteWithoutOpportunity = () => {
        setIsNew(false);
        setRecipe({ opportunity_id: 'NONE' });
    }
    const selectOpportunity = (opp) => {
        setLoading(true);
        service.getOpportunity(opp, (data) => {
            setIsNew(false);
            if (data) {

                service.getContact(data.OwnerId, (user_data) => {

                    let obj = {};
                    obj.ela_name = 'ELA ' + data.Name;

                    obj.channel = data.Channel__c === 'Partner' ? data.Distributor__c ? 'T2' : 'T1' : 'DIRECT';
                    console.log(obj);
                    obj.distributor_name = data.Distributor__c;
                    obj.reseller_name = data.Partner__c;
                    if (data.Quote_Partner_Level__c) {
                        obj.partner_type = data.Quote_Partner_Level__c.replace(' ', '_').toUpperCase();
                    }


                    obj.end_customer_name = data.Account_Name_Text__c;

                    //console.log('data.Opp_Territory__c >'+ data.Opp_Territory__c);
                    if (data.Opp_Territory__c) {
                        const rec = lookup.sfdc_territories.find(item => item.label.replace(/[^a-z0-9]/gi, '').toUpperCase() === data.Opp_Territory__c.replace(/[^a-z0-9]/gi, '').toUpperCase());
                        obj.end_customer_territory_id = rec ? rec.value : undefined;
                    }

                    //console.log('data.Entity__c >'+ data.Entity__c);
                    if (data.Entity__c) {
                        const rec = lookup.claroty_entities.find(item => item.value.replace(/[^a-z0-9]/gi, '').toUpperCase() === data.Entity__c.replace(/[^a-z0-9]/gi, '').toUpperCase());
                        obj.contracting_entity_id = rec ? rec.value : undefined;
                    }

                    obj.currency_code = data.CurrencyIsoCode;
                    //console.log('data.Payment_Terms__c >'+ data.Payment_Terms__c);
                    if (data.Payment_Terms__c) {
                        const rec = lookup.payment_term.find(item => item.value.replace(/[^a-z0-9]/gi, '').toUpperCase() === data.Payment_Terms__c.replace(/[^a-z0-9]/gi, '').toUpperCase());
                        obj.payment_term = rec ? rec.value : undefined;
                    }
                    //console.log('data.SLA_Type__c >'+ data.SLA_Type__c);
                    if (data.SLA_Type__c) {
                        const rec = lookup.sla.find(item => item.value.replace(/[^a-z0-9]/gi, '').toUpperCase() === data.SLA_Type__c.replace(/[^a-z0-9]/gi, '').toUpperCase());
                        obj.sla = rec ? rec.value : undefined;
                    }


                    obj.opportunity_sales_country = data.Billing_country__c;
                    obj.opportunity_is_registered_deal = data.ImpartnerPRM__IsDealRegistration__c ? true : false;
                    obj.sales_director_name = data.Claroty_Sales_Director__c;
                    obj.sales_director_email = user_data.Email
                    obj.sales_director_email = user_data && user_data.Email ? user_data.Email : null;
                    obj.sales_director_phone = user_data && user_data.Phone ? user_data.Phone : (user_data && user_data.MobilePhone ? user_data.MobilePhone : null);
                    obj.opportunity_id = data.Id;
                    obj.opportunity_name = data.Name;
                    console.log(obj)
                    setRecipe(obj);


                });
            }
            setLoading(false);
        });
    }

    const validate = () => {
        setErrors({});
        let isValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let validation = [
            { field: "ela_name", msg: "Please enter ELA Name" },
            { field: "end_customer_name", msg: "Please enter End Customer Name" },
            { field: "end_customer_territory_id", msg: "Please select End Customer Territory" },
            { field: "contract_start_date", msg: "Please select Contract Start Date" },
            { field: "contract_length", msg: "Please select Contract Length" },
            { field: "currency_code", msg: "Please select Currency" },
            { field: "sla", msg: "Please select SLA" },
            { field: "payment_term", msg: "Please select Payment Term" },
            { field: "contracting_entity_id", msg: "Please select Claroty Contracting Entity" }
        ];



        if (recipe.channel === 'T1' || recipe.channel === 'T2') {
            validation.push({ field: 'reseller_name', msg: 'Please enter Reseller Name.' });
            validation.push({ field: 'partner_type', msg: 'Please choose Partner Type.' });

            if (recipe.channel === 'T2') {
                validation.push({ field: 'distributor_name', msg: 'Please enter Distributor Name.' });
            } else {
                recipe.distributor_name = undefined;
            }
        } else {
            recipe.reseller_name = undefined;
            recipe.partner_type = undefined;
            recipe.distributor_name = undefined;
            recipe.opportunity_is_registered_deal = false;
        }

        validation.map(item => {
            console.log(recipe);
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        if (recipe.sales_director_email) {

            if (!pattern.test(recipe.sales_director_email)) {
                isValid = false;
                setErrors(errors => ({ ...errors, sales_director_email: "Please enter valid Sales Director Email" }));
            }
        }
        console.log(errors);
        return isValid;
    };


    const handleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }));
    };
    const handleNext = () => {
        if (validate()) {

            setLoading(true);


            let payload = JSON.parse(JSON.stringify(recipe));

            service.upsertEla('ela-header', payload, res => {
                if (res && res.ela_id) {
                    props.history.push('/configure-ela/' + res.ela_id + '/ref');
                }
            });
        }
    }
    const renderForm = () => {
        return <>
            <div className="col-12" style={{ padding: "0" }}>
                <div className="container-fluid mt-1 pt-2 filter-bar">
                    <div className="container">
                        <div className="row g-12 mt-3">
                            {parseInt(props.match.params.id) === 0 && <div className="col-4">
                                <div className="summary_label" style={{ border: "0" }}>SFDC Opportunity Id</div>
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <input type="text" className="form-control" style={{ width: "normal", padding: "10px" }} name="opportunity_id" placeholder=""
                                        value={recipe.opportunity_id || ''}
                                        onChange={(e) => { handleChange('opportunity_id', (e.target.validity.valid) ? e.target.value : '') }} />
                                    <button type="button" className="btn btn-g btn-outline-warning" style={{ marginLeft: "15px" }} onClick={() => { searchOpportunities() }} ><i className="mdi mdi-magnify " aria-hidden="true" ></i> </button>
                                    <button type="button" className="btn btn-g btn-outline-light" style={{ marginLeft: "15px" }} onClick={() => { resetOpportunities() }} ><i className="mdi mdi-backspace-outline " aria-hidden="true" ></i> </button>
                                </div>

                            </div>}
                            <div className="col-4">
                                <div className="summary_label">SFDC Opportunity Name</div>
                                <div className="summary_data">{recipe.opportunity_name || '-'}</div>
                            </div>
                            <div className="col-4">
                                <div className="summary_label">Billing Country</div>
                                <div className="summary_data">{recipe.opportunity_sales_country || '-'}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                <div className="container pt-1">
                    <div className="row g-12">
                        <div className="col-md-12 col-lg-12">
                            <div className="card  mt-3">

                                <div className="card-body">
                                    <div className="row g-3">

                                        <div className="col-sm-12">
                                            <label htmlFor="ela_name" className="form-label">ELA Name <span className="required">*</span></label>
                                            <input type="text" className="form-control" name="ela_name" placeholder=""
                                                value={recipe.ela_name || ''}
                                                maxLength="100"
                                                onChange={(e) => { handleChange('ela_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.ela_name && <div className="validation-error">{errors.ela_name}</div>}
                                        </div>

                                        <div className="col-sm-12">
                                            <label className="form-label"> Sales Channel <span className="required">*</span></label>
                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                <label className={recipe.channel === 'DIRECT' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="gtm_direct">
                                                    <input type="radio" className="btn-check" name="btnradio" id="gtm_direct" autoComplete="off" checked={recipe.channel === 'DIRECT'}
                                                        onChange={(e) => { handleChange('channel', e.target.checked ? 'DIRECT' : undefined) }}
                                                    />
                                                    Direct
                                                </label>
                                                <label className={recipe.channel === 'T1' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="gtm_T1">
                                                    <input type="radio" className="btn-check" name="btnradio" id="gtm_T1" autoComplete="off" checked={recipe.channel === 'T1'}
                                                        onChange={(e) => { handleChange('channel', e.target.checked ? 'T1' : undefined) }}
                                                    />
                                                    Reseller
                                                </label>
                                                <label className={recipe.channel === 'T2' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="gtm_T2">
                                                    <input type="radio" className="btn-check" name="btnradio" id="gtm_T2" autoComplete="off" checked={recipe.channel === 'T2'}
                                                        onChange={(e) => { handleChange('channel', e.target.checked ? 'T2' : undefined) }}
                                                    />
                                                    Distributor & Reseller
                                                </label>
                                            </div>
                                        </div>
                                       

                                        {recipe.channel === 'T2' && <>

                                            <div className="col-sm-6">
                                                <label htmlFor="distributor_name" className="form-label">Distributor Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" name="distributor_name" placeholder=""
                                                    value={recipe.distributor_name || ''}
                                                    maxLength="100"
                                                    onChange={(e) => { handleChange('distributor_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                                {errors.distributor_name && <div className="validation-error">{errors.distributor_name}</div>}
                                            </div>

                                        </>
                                        }
                                        {(recipe.channel === 'T1' || recipe.channel === 'T2') && <>
                                            <div className="col-sm-6">
                                                <label htmlFor="reseller_name" className="form-label">Reseller Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" name="reseller_name" placeholder=""
                                                    value={recipe.reseller_name || ''}
                                                    maxLength="100"
                                                    onChange={(e) => { handleChange('reseller_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                                {errors.reseller_name && <div className="validation-error">{errors.reseller_name}</div>}
                                            </div>

                                        </>}

                                        {(recipe.channel === 'T1' || recipe.channel === 'T2') && <>
                                            <div className="col-sm-8">
                                                <label className="form-label"> Partner Type <span className="required">*</span></label>
                                                <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                    <label className={recipe.partner_type === 'AUTHORIZED' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="partner_type_authorized">
                                                        <input type="radio" className="btn-check" name="btnradio" id="partner_type_authorized" autoComplete="off" checked={recipe.partner_type === 'AUTHORIZED'}
                                                            onChange={(e) => { handleChange('partner_type', e.target.checked ? 'AUTHORIZED' : undefined) }}
                                                        />
                                                        Authorized
                                                    </label>
                                                    <label className={recipe.partner_type === 'VISIONARY' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="partner_type_visionary">
                                                        <input type="radio" className="btn-check" name="btnradio" id="partner_type_visionary" autoComplete="off" checked={recipe.partner_type === 'VISIONARY'}
                                                            onChange={(e) => { handleChange('partner_type', e.target.checked ? 'VISIONARY' : undefined) }}
                                                        />
                                                        Visionary
                                                    </label>
                                                    <label className={recipe.partner_type === 'ELITE' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="partner_type_elite">
                                                        <input type="radio" className="btn-check" name="btnradio" id="partner_type_elite" autoComplete="off" checked={recipe.partner_type === 'ELITE'}
                                                            onChange={(e) => { handleChange('partner_type', e.target.checked ? 'ELITE' : undefined) }}
                                                        />
                                                        Elite
                                                    </label>
                                                    <label className={recipe.partner_type === 'GLOBAL ELITE' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="partner_type_GLOBAL ELITE">
                                                        <input type="radio" className="btn-check" name="btnradio" id="partner_type_GLOBAL ELITE" autoComplete="off" checked={recipe.partner_type === 'GLOBAL ELITE'}
                                                            onChange={(e) => { handleChange('partner_type', e.target.checked ? 'GLOBAL ELITE' : undefined) }}
                                                        />
                                                        Global Elite
                                                    </label>
                                                </div>
                                                {errors.partner_type && <div className="validation-error">{errors.partner_type}</div>}
                                            </div>
                                            <div className="col-sm-4">

                                            <label className="form-label"> Is Registered Deal? <span className="required">*</span></label>
                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                <label className={!recipe.opportunity_is_registered_deal ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="opportunity_is_registered_deal_no">
                                                    <input type="radio" className="btn-check" name="opportunity_is_registered_deal" id="opportunity_is_registered_deal_no" autoComplete="off" checked={!recipe.opportunity_is_registered_deal}
                                                        onChange={(e) => { handleChange('opportunity_is_registered_deal', e.target.checked ? false : true) }}
                                                    />
                                                    No
                                                </label>
                                                <label className={recipe.opportunity_is_registered_deal ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="opportunity_is_registered_deal_yes">
                                                    <input type="radio" className="btn-check" name="opportunity_is_registered_deal" id="opportunity_is_registered_deal_yes" autoComplete="off" checked={recipe.opportunity_is_registered_deal}
                                                        onChange={(e) => { handleChange('opportunity_is_registered_deal', e.target.checked ? true : false) }}
                                                    />
                                                    Yes
                                                </label>

                                            </div>

                                        </div>
                                        </>
                                        }
                                        <div className="col-sm-6">
                                            <label htmlFor="end_customer_name" className="form-label">End Customer Name <span className="required">*</span></label>
                                            <input type="text" className="form-control" name="end_customer_name" placeholder=""
                                                value={recipe.end_customer_name || ''}
                                                onChange={(e) => { handleChange('end_customer_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.end_customer_name && <div className="validation-error">{errors.end_customer_name}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="end_customer_territory_id" className="form-label">End Customer Territory <span className="required">*</span></label>
                                            <Select id="end_customer_territory_id"
                                                value={(recipe && recipe.end_customer_territory_id && lookup.sfdc_territories.find(item => item.value + '' === recipe.end_customer_territory_id + '')) || null}
                                                options={lookup.sfdc_territories || []}
                                                required
                                                onChange={(e) => { handleChange('end_customer_territory_id', e.value) }}
                                            />
                                            {errors.end_customer_territory_id && <div className="validation-error">{errors.end_customer_territory_id}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="contracting_entity_id" className="form-label">Claroty Contracting Entity <span className="required">*</span></label>
                                            <Select id="contracting_entity_id"
                                                value={(recipe && recipe.contracting_entity_id && lookup.claroty_entities.find(item => item.value + '' === recipe.contracting_entity_id + '')) || null}
                                                options={lookup.claroty_entities || []}
                                                required
                                                onChange={(e) => { handleChange('contracting_entity_id', e.value) }}
                                            />
                                            {errors.contracting_entity_id && <div className="validation-error">{errors.contracting_entity_id}</div>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="contract_start_date" className="form-label">Contract Start Date <span className="required">*</span></label>
                                            <DatePicker
                                                value={recipe.contract_start_date || undefined}
                                                className="form-control"
                                                excludeSunday={false}
                                                excludeSaturday={false}
                                                minConstraint={new Date()}
                                                onChange={(_date) => {
                                                    handleChange('contract_start_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                                }}
                                                dateFormat="dd-MMM-yyyy"

                                                isClearable={true}
                                            />
                                            {errors.contract_start_date && <div className="validation-error">{errors.contract_start_date}</div>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="contract_length" className="form-label">Contract Length<span className="required">*</span></label>
                                            <Select id="contract_length"
                                                value={(recipe && recipe.contract_length && lookup.contract_length.find(item => item.value === recipe.contract_length + '')) || null}
                                                options={lookup.contract_length || []}
                                                required
                                                onChange={(e) => { handleChange('contract_length', e.value) }}
                                            />
                                            {errors.contract_length && <div className="validation-error">{errors.contract_length}</div>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="currency_code" className="form-label">Billing Currency <span className="required">*</span></label>
                                            <Select id="currency_code"
                                                value={(recipe && recipe.currency_code && lookup.sfdc_currencies.find(item => item.value === recipe.currency_code)) || null}
                                                options={lookup.sfdc_currencies || []}
                                                required
                                                isDisabled={recipe.opportunity_id!=='NONE'}
                                                onChange={(e) => { handleChange('currency_code', e.value) }}
                                            />
                                            {errors.currency_code && <div className="validation-error">{errors.currency_code}</div>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="payment_term" className="form-label">Payment Term <span className="required">*</span></label>
                                            <Select id="payment_term"
                                                value={(recipe && recipe.payment_term && lookup.payment_term.find(item => item.value === recipe.payment_term)) || null}
                                                options={lookup.payment_term || []}
                                                required
                                                onChange={(e) => { handleChange('payment_term', e.value) }}
                                            />
                                            {errors.payment_term && <div className="validation-error">{errors.payment_term}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="sla" className="form-label">SLA <span className="required">*</span></label>
                                            <Select id="sla"
                                                value={(recipe && recipe.sla && lookup.sla.find(item => item.value === recipe.sla)) || null}
                                                options={lookup.sla || []}
                                                required
                                                onChange={(e) => { handleChange('sla', e.value) }}
                                            />
                                            {errors.sla && <div className="validation-error">{errors.sla}</div>}
                                        </div>


                                        <div className="col-sm-6">
                                            <label htmlFor="sales_director_name" className="form-label">Sales Director Name </label>
                                            <input type="text" className="form-control" name="sales_director_name" placeholder=""
                                                value={recipe.sales_director_name || ''}
                                                onChange={(e) => { handleChange('sales_director_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.sales_director_name && <div className="validation-error">{errors.sales_director_name}</div>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="sales_director_email" className="form-label">Sales Director Email</label>
                                            <input type="text" className="form-control" name="sales_director_email" placeholder=""
                                                value={recipe.sales_director_email || ''}
                                                onChange={(e) => { handleChange('sales_director_email', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.sales_director_email && <div className="validation-error">{errors.sales_director_email}</div>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="sales_director_phone" className="form-label">Sales Director Contact Phone </label>
                                            <input type="text" className="form-control" name="sales_director_phone" placeholder=""
                                                value={recipe.sales_director_phone || ''}
                                                onChange={(e) => { handleChange('sales_director_phone', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.sales_director_phone && <div className="validation-error">{errors.sales_director_phone}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix" style={{ padding: '10px 0' }}>
                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                        <button type="button" className="btn btn-g btn-outline-secondary float-end" onClick={() => props.history.push('/ela-home')} ><i className="mdi mdi-cancel " aria-hidden="true" ></i> Cancel </button>
                    </div>
                </div>

            </div>
        </>
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }}>
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>{title}</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {isNew && <> <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container" >
                                        <div className="row g-12 mt-3">
                                            <div className="col-12">
                                                <div className="summary_label" style={{ border: "0" }}>Search SFDC Opportunity</div>
                                                <div style={{ display: "flex", alignItems: "center" }} >
                                                    <input type="text" className="form-control" style={{ width: "normal", padding: "10px" }} name="opportunity_id" placeholder=""
                                                        value={recipe.opportunity_id || ''}
                                                        onChange={(e) => { handleChange('opportunity_id', (e.target.validity.valid) ? e.target.value : '') }} />
                                                    <button type="button" className="btn btn-g btn-outline-warning" style={{ marginLeft: "15px" }} onClick={() => { searchOpportunities() }} ><i className="mdi mdi-magnify " aria-hidden="true" ></i> </button>
                                                    <button type="button" className="btn btn-g btn-outline-light" style={{ marginLeft: "15px" }} onClick={() => { resetOpportunities() }} ><i className="mdi mdi-backspace-outline " aria-hidden="true" ></i> </button>

                                                    <button type="button" className="btn btn-g btn-outline-warning " onClick={() => quoteWithoutOpportunity()} style={{ marginLeft: "15px", width: "400px" }}><i className="mdi mdi-plus " aria-hidden="true" ></i>Quote without Opportunity</button>
                                                </div>
                                                {errors.opportunity_id && <div className="validation-error" style={{ color: "#FFFFFF" }}>{errors.opportunity_id}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                                <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                    <div className="container pt-1">
                                        {opportunities && opportunities.length > 0 ? <div className="row g-12 mt-3">
                                            {opportunities.map((item) => {
                                                return <div key={'opp_' + item.Id} className="flex-table"  >
                                                    <div className="flex-table-row pt-3" style={{ flex: "1 100%" }}>
                                                        <div className="flex-table-col">
                                                            <div className="flex-table-col-content" style={{ flex: "4 1", padding: "0 5px 5px 0" }}  >
                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Opportunity</div>
                                                                <span className="view-link" style={{ fontWeight: "300", fontSize: "1em" }}>{item.Id}</span>

                                                                <div className="view-link" style={{ fontWeight: "600", fontSize: "1em", }}>{item.Name}</div>
                                                                <div className="view-link" style={{ fontWeight: "300", fontSize: "1em", }}><b>Opportunity Close Date:</b> {item.CloseDate}</div>
                                                            </div>

                                                            <div className="flex-table-col-content" style={{ flex: "3 1", padding: "0 5px 5px 0" }}  >
                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Account Name</div>
                                                                <span className="view-link" style={{ fontWeight: "300", fontSize: "1em", }}>{item.Account_Name_Text__c}</span>
                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Claroty Sales Director</div>
                                                                <span className="view-link" style={{ fontWeight: "300", fontSize: "1em", }}>{item.Claroty_Sales_Director__c}</span>
                                                            </div>
                                                            <div className="flex-table-col-content" style={{ flex: "2 1", padding: "0 5px 5px 0" }}  >
                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>{item.Channel__c !== 'Partner' ? 'Channel' : 'Reseller Name'}</div>
                                                                <span className="view-link" style={{ fontWeight: "300", fontSize: "1em", }}>{item.Channel__c !== 'Partner' ? 'Direct' : (item.Partner__c ? item.Partner__c : '-')}</span>
                                                                {item.Distributor__c && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Distributor Name</div>}
                                                                {item.Distributor__c && <span className="view-link" style={{ fontWeight: "300", fontSize: "1em", }}>{item.Distributor__c}</span>}
                                                            </div>
                                                            <div className="flex-table-col-content" style={{ flex: "0.5 1", padding: "0 5px 5px 0" }} >
                                                                <button className='btn btn-primary btn-sm' onClick={() => { selectOpportunity(item.Id) }}>Select</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div> : <div></div>
                                        }
                                    </div>
                                </div>
                            </>
                            }
                            {!isNew && renderForm()}

                        </div>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default CreateEla;


