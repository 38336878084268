
import React, { useState } from 'react';
import Header from '../../Components/layout/Header';
import UserDashboard from './UserDashboard';
import ListPriceDashboard from './ListPriceDashboard';
import PricingVariables from './PricingVariables';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuthState } from '../../Context';

function Dashboard(props) {
    const userDetails = useAuthState();
    let adminTabIdx=localStorage.getItem('adminTabIdx')
    const [tabIndex, setTabIndex] = useState(adminTabIdx?parseInt(adminTabIdx) : 0);

    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";

    const selectTab=(idx)=>{
        localStorage.setItem('adminTabIdx', idx+'');
        setTabIndex(idx);
    }
    const { app } = userDetails;

    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div className="row " style={{ background: "#000000" }}>
                        <div className="col-12" style={{ padding: "0" }}>
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i style={{ color: "#ffffff" }} className='mdi mdi-cog mdi-36px'></i>
                                        <h3 className="col-11" style={{ display: "inline-block", paddingTop: "10px", color: "#ffffff" }}>Administration</h3>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-g btn-outline-warning float-end" onClick={() => props.history.push('/userdetail/-/NEW')} style={{ padding: "8px 10px" }}><i className="mdi mdi-plus " aria-hidden="true" ></i> Create New User</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="container">
                    <Tabs style={{ width: "100%" }} defaultIndex={tabIndex} >
                            <TabList>
                                <Tab onClick={() => { selectTab(0); }}><div className="tab-title" style={{ fontSize: '1.3em' }}>Users</div></Tab>
                                {app==='ELA' && <Tab onClick={() => { selectTab(1); }}><div className="tab-title" style={{ fontSize: '1.3em' }}>ELA List Price</div></Tab> }
                                {app==='ELA' &&<Tab onClick={() => { selectTab(2); }}><div className="tab-title" style={{ fontSize: '1.3em' }}>ELA Pricing Variables</div></Tab>}
                            </TabList>
                            <TabPanel><UserDashboard {...props} /></TabPanel>
                            {app==='ELA' &&<TabPanel><ListPriceDashboard {...props} /></TabPanel>}
                            {app==='ELA' &&<TabPanel><PricingVariables {...props}  /></TabPanel>}
                            </Tabs>
                            </div>
                </div>
            </main>
        </>
    )
}

export default Dashboard;