import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import ProgressArc from '../../../Components/widgets/ProgressArc';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SelectField from './fields/Select';
function OverUtilization(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    let under_utilization_mri = 0;
    let after_utilization_mri = 0;
    let max_capacity_mri = 0;
    let annual_suboptimal_procedures_mri = 0;
    let after_annual_suboptimal_procedures_mri = 0;
    let downtime_cost_mri = 0;
    let after_downtime_cost_mri = 0;
    let maintenance_cost_mri = 0;
    let after_maintenance_cost_mri = 0;
    let gross_saving_mri = 0;
    let gross_saving_percent_mri = 0;
    let revenue_recovered_mri = 0;

    if (parseFloat(data.step1__device_count_mri) > 0) {
        under_utilization_mri = parseFloat(data.step5__utilization_before_mri) < parseFloat(data.step5__optimal_capacity_utilization_mri)
            && parseFloat(data.step5__utilization_before_mri) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_mri) - parseFloat(data.step5__utilization_before_mri)
            : 0;
        after_utilization_mri = parseFloat(data.step5__utilization_after_mri) < parseFloat(data.step5__optimal_capacity_utilization_mri)
            && parseFloat(data.step5__utilization_after_mri) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_mri) - parseFloat(data.step5__utilization_after_mri)
            : 0;
        max_capacity_mri = parseFloat(data.step1__device_count_mri)
            * parseFloat(data.step5__avg_procedure_per_hour_mri)
            * parseFloat(data.step5__operating_hour_per_day_mri)
            * parseFloat(data.step5__operating_day_per_week_mri)
            * 52;
        annual_suboptimal_procedures_mri = Math.round((parseFloat(data.step5__utilization_before_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            (max_capacity_mri * parseFloat(data.step5__utilization_before_mri)) - (max_capacity_mri * parseFloat(data.step5__optimal_capacity_utilization_mri))
            : (max_capacity_mri * parseFloat(data.step5__optimal_capacity_utilization_mri)) - (max_capacity_mri * parseFloat(data.step5__utilization_before_mri))) / 100);

        after_annual_suboptimal_procedures_mri = Math.round((parseFloat(data.step5__utilization_after_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            (max_capacity_mri * parseFloat(data.step5__utilization_after_mri)) - (max_capacity_mri * parseFloat(data.step5__optimal_capacity_utilization_mri))
            : (max_capacity_mri * parseFloat(data.step5__optimal_capacity_utilization_mri)) - (max_capacity_mri * parseFloat(data.step5__utilization_after_mri))) / 100);

        downtime_cost_mri = parseFloat(data.step5__utilization_before_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            parseFloat(data.step5__avg_procedure_revenue_mri) * parseFloat(data.step5__additional_down_time_mri) * annual_suboptimal_procedures_mri / 100
            : 0;

        after_downtime_cost_mri = parseFloat(data.step5__utilization_after_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            parseFloat(data.step5__avg_procedure_revenue_mri) * parseFloat(data.step5__additional_down_time_mri) * after_annual_suboptimal_procedures_mri / 100
            : 0;

        maintenance_cost_mri = parseFloat(data.step5__utilization_before_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            parseFloat(data.step5__avg_procedure_revenue_mri) * parseFloat(data.step5__additional_maintenance_mri) * annual_suboptimal_procedures_mri / 100
            : 0;

        after_maintenance_cost_mri = parseFloat(data.step5__utilization_after_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            parseFloat(data.step5__avg_procedure_revenue_mri) * parseFloat(data.step5__additional_maintenance_mri) * after_annual_suboptimal_procedures_mri / 100
            : 0;

        revenue_recovered_mri = downtime_cost_mri + maintenance_cost_mri - after_downtime_cost_mri - after_maintenance_cost_mri;
        gross_saving_mri = revenue_recovered_mri * parseFloat(data.step1__avg_margin_mri)/100;
        gross_saving_percent_mri = Math.round((downtime_cost_mri + maintenance_cost_mri) > 0 ? revenue_recovered_mri * 100 / (downtime_cost_mri + maintenance_cost_mri) : 0);
    }

    let under_utilization_ct = 0;
    let after_utilization_ct = 0;
    let max_capacity_ct = 0;
    let annual_suboptimal_procedures_ct = 0;
    let after_annual_suboptimal_procedures_ct = 0;
    let downtime_cost_ct = 0;
    let after_downtime_cost_ct = 0;
    let maintenance_cost_ct = 0;
    let after_maintenance_cost_ct = 0;
    let gross_saving_ct = 0;
    let gross_saving_percent_ct = 0;
    let revenue_recovered_ct = 0;

    if (parseFloat(data.step1__device_count_ct) > 0) {
        under_utilization_ct = parseFloat(data.step5__utilization_before_ct) < parseFloat(data.step5__optimal_capacity_utilization_ct)
            && parseFloat(data.step5__utilization_before_ct) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_ct) - parseFloat(data.step5__utilization_before_ct)
            : 0;
        after_utilization_ct = parseFloat(data.step5__utilization_after_ct) < parseFloat(data.step5__optimal_capacity_utilization_ct)
            && parseFloat(data.step5__utilization_after_ct) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_ct) - parseFloat(data.step5__utilization_after_ct)
            : 0;
        max_capacity_ct = parseFloat(data.step1__device_count_ct)
            * parseFloat(data.step5__avg_procedure_per_hour_ct)
            * parseFloat(data.step5__operating_hour_per_day_ct)
            * parseFloat(data.step5__operating_day_per_week_ct)
            * 52;
        annual_suboptimal_procedures_ct = Math.round((parseFloat(data.step5__utilization_before_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            (max_capacity_ct * parseFloat(data.step5__utilization_before_ct)) - (max_capacity_ct * parseFloat(data.step5__optimal_capacity_utilization_ct))
            : (max_capacity_ct * parseFloat(data.step5__optimal_capacity_utilization_ct)) - (max_capacity_ct * parseFloat(data.step5__utilization_before_ct))) / 100);

        after_annual_suboptimal_procedures_ct = Math.round((parseFloat(data.step5__utilization_after_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            (max_capacity_ct * parseFloat(data.step5__utilization_after_ct)) - (max_capacity_ct * parseFloat(data.step5__optimal_capacity_utilization_ct))
            : (max_capacity_ct * parseFloat(data.step5__optimal_capacity_utilization_ct)) - (max_capacity_ct * parseFloat(data.step5__utilization_after_ct))) / 100);

        downtime_cost_ct = parseFloat(data.step5__utilization_before_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            parseFloat(data.step5__avg_procedure_revenue_ct) * parseFloat(data.step5__additional_down_time_ct) * annual_suboptimal_procedures_ct / 100
            : 0;

        after_downtime_cost_ct = parseFloat(data.step5__utilization_after_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            parseFloat(data.step5__avg_procedure_revenue_ct) * parseFloat(data.step5__additional_down_time_ct) * after_annual_suboptimal_procedures_ct / 100
            : 0;

        maintenance_cost_ct = parseFloat(data.step5__utilization_before_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            parseFloat(data.step5__avg_procedure_revenue_ct) * parseFloat(data.step5__additional_maintenance_ct) * annual_suboptimal_procedures_ct / 100
            : 0;

        after_maintenance_cost_ct = parseFloat(data.step5__utilization_after_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            parseFloat(data.step5__avg_procedure_revenue_ct) * parseFloat(data.step5__additional_maintenance_ct) * after_annual_suboptimal_procedures_ct / 100
            : 0;

        revenue_recovered_ct = downtime_cost_ct + maintenance_cost_ct - after_downtime_cost_ct - after_maintenance_cost_ct;
        gross_saving_ct = revenue_recovered_ct * parseFloat(data.step1__avg_margin_ct)/100;
        gross_saving_percent_ct = Math.round((downtime_cost_ct + maintenance_cost_ct) > 0 ? revenue_recovered_ct * 100 / (downtime_cost_ct + maintenance_cost_ct) : 0);
    }
    let under_utilization_cath = 0;
    let after_utilization_cath = 0;
    let max_capacity_cath = 0;
    let annual_suboptimal_procedures_cath = 0;
    let after_annual_suboptimal_procedures_cath = 0;
    let downtime_cost_cath = 0;
    let after_downtime_cost_cath = 0;
    let maintenance_cost_cath = 0;
    let after_maintenance_cost_cath = 0;
    let gross_saving_cath = 0;
    let gross_saving_percent_cath = 0;
    let revenue_recovered_cath = 0;

    if (parseFloat(data.step1__device_count_cath) > 0) {
        under_utilization_cath = parseFloat(data.step5__utilization_before_cath) < parseFloat(data.step5__optimal_capacity_utilization_cath)
            && parseFloat(data.step5__utilization_before_cath) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_cath) - parseFloat(data.step5__utilization_before_cath)
            : 0;
        after_utilization_cath = parseFloat(data.step5__utilization_after_cath) < parseFloat(data.step5__optimal_capacity_utilization_cath)
            && parseFloat(data.step5__utilization_after_cath) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_cath) - parseFloat(data.step5__utilization_after_cath)
            : 0;
        max_capacity_cath = parseFloat(data.step1__device_count_cath)
            * parseFloat(data.step5__avg_procedure_per_hour_cath)
            * parseFloat(data.step5__operating_hour_per_day_cath)
            * parseFloat(data.step5__operating_day_per_week_cath)
            * 52;
        annual_suboptimal_procedures_cath = Math.round((parseFloat(data.step5__utilization_before_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            (max_capacity_cath * parseFloat(data.step5__utilization_before_cath)) - (max_capacity_cath * parseFloat(data.step5__optimal_capacity_utilization_cath))
            : (max_capacity_cath * parseFloat(data.step5__optimal_capacity_utilization_cath)) - (max_capacity_cath * parseFloat(data.step5__utilization_before_cath))) / 100);

        after_annual_suboptimal_procedures_cath = Math.round((parseFloat(data.step5__utilization_after_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            (max_capacity_cath * parseFloat(data.step5__utilization_after_cath)) - (max_capacity_cath * parseFloat(data.step5__optimal_capacity_utilization_cath))
            : (max_capacity_cath * parseFloat(data.step5__optimal_capacity_utilization_cath)) - (max_capacity_cath * parseFloat(data.step5__utilization_after_cath))) / 100);

        downtime_cost_cath = parseFloat(data.step5__utilization_before_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            parseFloat(data.step5__avg_procedure_revenue_cath) * parseFloat(data.step5__additional_down_time_cath) * annual_suboptimal_procedures_cath / 100
            : 0;

        after_downtime_cost_cath = parseFloat(data.step5__utilization_after_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            parseFloat(data.step5__avg_procedure_revenue_cath) * parseFloat(data.step5__additional_down_time_cath) * after_annual_suboptimal_procedures_cath / 100
            : 0;

        maintenance_cost_cath = parseFloat(data.step5__utilization_before_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            parseFloat(data.step5__avg_procedure_revenue_cath) * parseFloat(data.step5__additional_maintenance_cath) * annual_suboptimal_procedures_cath / 100
            : 0;

        after_maintenance_cost_cath = parseFloat(data.step5__utilization_after_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            parseFloat(data.step5__avg_procedure_revenue_cath) * parseFloat(data.step5__additional_maintenance_cath) * after_annual_suboptimal_procedures_cath / 100
            : 0;

        revenue_recovered_cath = downtime_cost_cath + maintenance_cost_cath - after_downtime_cost_cath - after_maintenance_cost_cath;
        gross_saving_cath = revenue_recovered_cath * parseFloat(data.step1__avg_margin_cath)/100;
        gross_saving_percent_cath = Math.round((downtime_cost_cath + maintenance_cost_cath) > 0 ? revenue_recovered_cath * 100 / (downtime_cost_cath + maintenance_cost_cath) : 0);
    }
    let under_utilization_la = 0;
    let after_utilization_la = 0;
    let max_capacity_la = 0;
    let annual_suboptimal_procedures_la = 0;
    let after_annual_suboptimal_procedures_la = 0;
    let downtime_cost_la = 0;
    let after_downtime_cost_la = 0;
    let maintenance_cost_la = 0;
    let after_maintenance_cost_la = 0;
    let gross_saving_la = 0;
    let gross_saving_percent_la = 0;
    let revenue_recovered_la = 0;

    if (parseFloat(data.step1__device_count_la) > 0) {
        under_utilization_la = parseFloat(data.step5__utilization_before_la) < parseFloat(data.step5__optimal_capacity_utilization_la)
            && parseFloat(data.step5__utilization_before_la) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_la) - parseFloat(data.step5__utilization_before_la)
            : 0;
        after_utilization_la = parseFloat(data.step5__utilization_after_la) < parseFloat(data.step5__optimal_capacity_utilization_la)
            && parseFloat(data.step5__utilization_after_la) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_la) - parseFloat(data.step5__utilization_after_la)
            : 0;
        max_capacity_la = parseFloat(data.step1__device_count_la)
            * parseFloat(data.step5__avg_procedure_per_hour_la)
            * parseFloat(data.step5__operating_hour_per_day_la)
            * parseFloat(data.step5__operating_day_per_week_la)
            * 52;
        annual_suboptimal_procedures_la = Math.round((parseFloat(data.step5__utilization_before_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            (max_capacity_la * parseFloat(data.step5__utilization_before_la)) - (max_capacity_la * parseFloat(data.step5__optimal_capacity_utilization_la))
            : (max_capacity_la * parseFloat(data.step5__optimal_capacity_utilization_la)) - (max_capacity_la * parseFloat(data.step5__utilization_before_la))) / 100);

        after_annual_suboptimal_procedures_la = Math.round((parseFloat(data.step5__utilization_after_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            (max_capacity_la * parseFloat(data.step5__utilization_after_la)) - (max_capacity_la * parseFloat(data.step5__optimal_capacity_utilization_la))
            : (max_capacity_la * parseFloat(data.step5__optimal_capacity_utilization_la)) - (max_capacity_la * parseFloat(data.step5__utilization_after_la))) / 100);

        downtime_cost_la = parseFloat(data.step5__utilization_before_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            parseFloat(data.step5__avg_procedure_revenue_la) * parseFloat(data.step5__additional_down_time_la) * annual_suboptimal_procedures_la / 100
            : 0;

        after_downtime_cost_la = parseFloat(data.step5__utilization_after_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            parseFloat(data.step5__avg_procedure_revenue_la) * parseFloat(data.step5__additional_down_time_la) * after_annual_suboptimal_procedures_la / 100
            : 0;

        maintenance_cost_la = parseFloat(data.step5__utilization_before_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            parseFloat(data.step5__avg_procedure_revenue_la) * parseFloat(data.step5__additional_maintenance_la) * annual_suboptimal_procedures_la / 100
            : 0;

        after_maintenance_cost_la = parseFloat(data.step5__utilization_after_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            parseFloat(data.step5__avg_procedure_revenue_la) * parseFloat(data.step5__additional_maintenance_la) * after_annual_suboptimal_procedures_la / 100
            : 0;

        revenue_recovered_la = downtime_cost_la + maintenance_cost_la - after_downtime_cost_la - after_maintenance_cost_la;
        gross_saving_la = revenue_recovered_la * parseFloat(data.step1__avg_margin_la)/100;
        gross_saving_percent_la = Math.round((downtime_cost_la + maintenance_cost_la) > 0 ? revenue_recovered_la * 100 / (downtime_cost_la + maintenance_cost_la) : 0);
    }
    let under_utilization_xr = 0;
    let after_utilization_xr = 0;
    let max_capacity_xr = 0;
    let annual_suboptimal_procedures_xr = 0;
    let after_annual_suboptimal_procedures_xr = 0;
    let downtime_cost_xr = 0;
    let after_downtime_cost_xr = 0;
    let maintenance_cost_xr = 0;
    let after_maintenance_cost_xr = 0;
    let gross_saving_xr = 0;
    let gross_saving_percent_xr = 0;
    let revenue_recovered_xr = 0;

    if (parseFloat(data.step1__device_count_xr) > 0) {
        under_utilization_xr = parseFloat(data.step5__utilization_before_xr) < parseFloat(data.step5__optimal_capacity_utilization_xr)
            && parseFloat(data.step5__utilization_before_xr) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_xr) - parseFloat(data.step5__utilization_before_xr)
            : 0;
        after_utilization_xr = parseFloat(data.step5__utilization_after_xr) < parseFloat(data.step5__optimal_capacity_utilization_xr)
            && parseFloat(data.step5__utilization_after_xr) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_xr) - parseFloat(data.step5__utilization_after_xr)
            : 0;
        max_capacity_xr = parseFloat(data.step1__device_count_xr)
            * parseFloat(data.step5__avg_procedure_per_hour_xr)
            * parseFloat(data.step5__operating_hour_per_day_xr)
            * parseFloat(data.step5__operating_day_per_week_xr)
            * 52;
        annual_suboptimal_procedures_xr = Math.round((parseFloat(data.step5__utilization_before_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            (max_capacity_xr * parseFloat(data.step5__utilization_before_xr)) - (max_capacity_xr * parseFloat(data.step5__optimal_capacity_utilization_xr))
            : (max_capacity_xr * parseFloat(data.step5__optimal_capacity_utilization_xr)) - (max_capacity_xr * parseFloat(data.step5__utilization_before_xr))) / 100);

        after_annual_suboptimal_procedures_xr = Math.round((parseFloat(data.step5__utilization_after_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            (max_capacity_xr * parseFloat(data.step5__utilization_after_xr)) - (max_capacity_xr * parseFloat(data.step5__optimal_capacity_utilization_xr))
            : (max_capacity_xr * parseFloat(data.step5__optimal_capacity_utilization_xr)) - (max_capacity_xr * parseFloat(data.step5__utilization_after_xr))) / 100);

        downtime_cost_xr = parseFloat(data.step5__utilization_before_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            parseFloat(data.step5__avg_procedure_revenue_xr) * parseFloat(data.step5__additional_down_time_xr) * annual_suboptimal_procedures_xr / 100
            : 0;

        after_downtime_cost_xr = parseFloat(data.step5__utilization_after_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            parseFloat(data.step5__avg_procedure_revenue_xr) * parseFloat(data.step5__additional_down_time_xr) * after_annual_suboptimal_procedures_xr / 100
            : 0;

        maintenance_cost_xr = parseFloat(data.step5__utilization_before_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            parseFloat(data.step5__avg_procedure_revenue_xr) * parseFloat(data.step5__additional_maintenance_xr) * annual_suboptimal_procedures_xr / 100
            : 0;

        after_maintenance_cost_xr = parseFloat(data.step5__utilization_after_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            parseFloat(data.step5__avg_procedure_revenue_xr) * parseFloat(data.step5__additional_maintenance_xr) * after_annual_suboptimal_procedures_xr / 100
            : 0;

        revenue_recovered_xr = downtime_cost_xr + maintenance_cost_xr - after_downtime_cost_xr - after_maintenance_cost_xr;
        gross_saving_xr = revenue_recovered_xr * parseFloat(data.step1__avg_margin_xr)/100;
        gross_saving_percent_xr = Math.round((downtime_cost_xr + maintenance_cost_xr) > 0 ? revenue_recovered_xr * 100 / (downtime_cost_xr + maintenance_cost_xr) : 0);
    }

    const total_cost = (downtime_cost_xr + maintenance_cost_xr) * parseFloat(data.step1__avg_margin_xr)/100 + 
    (downtime_cost_la + maintenance_cost_la) * parseFloat(data.step1__avg_margin_la)/100 + 
    (downtime_cost_cath + maintenance_cost_cath) * parseFloat(data.step1__avg_margin_cath)/100 + 
    (downtime_cost_ct + maintenance_cost_ct) * parseFloat(data.step1__avg_margin_ct/100) + 
    (downtime_cost_mri + maintenance_cost_mri) * parseFloat(data.step1__avg_margin_mri)/100 ;
    const after_total_cost = (after_downtime_cost_xr + after_maintenance_cost_xr) * parseFloat(data.step1__avg_margin_xr)/100 + 
    (after_downtime_cost_la + after_maintenance_cost_la) * parseFloat(data.step1__avg_margin_la)/100+ 
    (after_downtime_cost_cath + after_maintenance_cost_cath) * parseFloat(data.step1__avg_margin_cath)/100 +
     (after_downtime_cost_ct + after_maintenance_cost_ct) * parseFloat(data.step1__avg_margin_ct)/100 + 
     (after_downtime_cost_mri + after_maintenance_cost_mri) * parseFloat(data.step1__avg_margin_mri)/100;
     
    const gross_saving = total_cost - after_total_cost;
    const gross_saving_percent = total_cost > 0 ? parseInt((total_cost - after_total_cost) * 100 / total_cost) : 0;

    const next = () => {
        props.next({ step5__total: gross_saving, step5__percent: gross_saving_percent,
            step5__gross_saving_mri: gross_saving_mri,
            step5__gross_saving_ct: gross_saving_ct ,
            step5__gross_saving_cath: gross_saving_cath ,
            step5__gross_saving_la: gross_saving_la ,
            step5__gross_saving_xr: gross_saving_xr  });
    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>

            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Over Utilization</h4>
                </div>
                <div className='col-2' >
                    <SelectField
                        fieldColor="orange"
                        fieldName="step5__mvr"
                        value={(data && data["step5__mvr"] && props.mvrs.find(item => item.value === data["step5__mvr"])) || null}
                        options={props.mvrs}
                        label="Months to Value Realization"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step5__mvr"]}
                    />
                </div>
            </div>
            <div className='row mt-3 gx-5 '>

                <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(gross_saving) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Over Utilization Revenue Recovery</div>
                        <div className='row' >
                            <div className='col-2'>
                                <div className='icon' style={{ width: "50px", height: "50px" }}>
                                    <ProgressArc value={gross_saving_percent} radius={40} arcColor={"#ffffff"} arcBackgroundColor={parseFloat(gross_saving) > 0 ? "#4D9262" : "#333333"} textColor={"#ffffff"} />
                                </div>
                            </div>
                            <div className='col-8'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(gross_saving), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
                <Tabs style={{ width: "100%" }} variant="fullWidth">
                    <TabList>
                        {data.step1__device_count_mri > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_mri} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MRI <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_mri > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_mri, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_ct > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_ct} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>CT SCANNER  <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_ct > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_ct, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_cath > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_cath} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MAMMOGRAPHY <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_cath > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_cath, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_la > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_la} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>C-ARM ACCELERATOR <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_la > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_la, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_xr > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_xr} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>X-RAY RADIOLOGY <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_xr > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_xr, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                    </TabList>
                    {data.step1__device_count_mri > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>

                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_mri"
                                    value={data["step5__utilization_before_mri"] + ""}
                                    label="Actual Utilization BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_mri"
                                    value={data["step5__utilization_after_mri"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Capacity Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_before_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_after_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_utilization_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Max Capacity (Procedures Per Year @ 100%)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Suboptimal Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_suboptimal_procedures_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_annual_suboptimal_procedures_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_cost_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_downtime_cost_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenance_cost_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_maintenance_cost_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_mri,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_mri, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_ct > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>

                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_ct"
                                    value={data["step5__utilization_before_ct"] + ""}
                                    label="Actual Utilization BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_ct"
                                    value={data["step5__utilization_after_ct"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Capacity Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_before_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_after_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_utilization_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Max Capacity (Procedures Per Year @ 100%)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Suboptimal Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_suboptimal_procedures_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_annual_suboptimal_procedures_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_cost_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_downtime_cost_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenance_cost_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_maintenance_cost_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_ct,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_ct, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_cath > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>


                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_cath"
                                    value={data["step5__utilization_before_cath"] + ""}
                                    label="Actual Utilization BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_cath"
                                    value={data["step5__utilization_after_cath"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_cath, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Capacity Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_before_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_after_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_utilization_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Max Capacity (Procedures Per Year @ 100%)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Suboptimal Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_suboptimal_procedures_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_annual_suboptimal_procedures_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_cost_cath, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_downtime_cost_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenance_cost_cath, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_maintenance_cost_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_cath,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_cath, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_la > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>


                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_la"
                                    value={data["step5__utilization_before_la"] + ""}
                                    label="Actual Utilization BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_la"
                                    value={data["step5__utilization_after_la"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_la, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Capacity Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_before_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_after_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_utilization_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Max Capacity (Procedures Per Year @ 100%)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Suboptimal Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_suboptimal_procedures_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_annual_suboptimal_procedures_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_cost_la, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_downtime_cost_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenance_cost_la, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_maintenance_cost_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_la,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_la, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_xr > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>

                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_xr"
                                    value={data["step5__utilization_before_xr"] + ""}
                                    label="Actual Utilization BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_xr"
                                    value={data["step5__utilization_after_xr"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Capacity Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__optimal_capacity_utilization_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_before_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__utilization_after_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_utilization_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_down_time_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (% of Avg. Procedure Revenue)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__additional_maintenance_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Max Capacity (Procedures Per Year @ 100%)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(max_capacity_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Suboptimal Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_suboptimal_procedures_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_annual_suboptimal_procedures_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Downtime Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_cost_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_downtime_cost_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Increased Maintenance Cost (Overutilization Cost)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenance_cost_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_maintenance_cost_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_xr,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_xr, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                </Tabs>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default OverUtilization;