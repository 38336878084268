import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading, formatCurrency } from '../../Components/Util';
import MultySelectDropdown from '../../Components/widgets/MultySelectDropdown';
import * as service from "../../services";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import { CumChart } from './CumChart';
import RefData from './RefData';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
function ShowBack(props) {

    const [loading, setLoading] = useState(true)
    const [summary, setSummary] = useState(undefined);
    const [referenceData, setReferenceData] = useState(undefined);
    const [title, setTitle] = useState("");
    const [sharePop, setSharePop] = useState(false);
    const [shareOptions, setShareOptions] = useState(undefined);
    const [sharedModelUsers, setSharedModelUsers] = useState([]);
    const [sharedModelUsersMsg, setSharedModelUsersMsg] = useState(undefined);
    const [efficiency, setEfficiency] = useState([]);
    const [benefit, setBenefit] = useState([]);
    const [recipe, setRecipe] = useState(undefined);



    useEffect(() => {
        let summaryData = undefined;
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getShowBackData(props.match.params.id, res => {
                    summaryData = res;
                    setSummary({ ...res });
                    setTitle(`Model #${props.match.params.id}`);
                    resolve("");
                });
            } else resolve("");
        });
        let p2 = new Promise((resolve, reject) => {
            service.getLookups(res => {
                setShareOptions(res.users_share);
                resolve("");
            });
        });
        let p3 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getModel(props.match.params.id, res => {
                    setRecipe({ ...res });
                    resolve("");
                });
            } else resolve("");
        });
        let p4 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getSharedModelUsers(props.match.params.id, res => {
                    setSharedModelUsers(res);
                    resolve("");
                });
            } else resolve("");
        });
        let p5 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getRefData(res => {
                    console.log(res);
                    setReferenceData(res);
                    resolve("");
                });
            } else resolve("");
        });

        Promise.all([p1, p2, p3, p4, p5])
            .then(values => {
                let cumEfficiency = [];
                let cumBenefit = [];

                if (summaryData && summaryData['Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)']) {
                    summaryData['Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)']
                        .filter(item => item.name === 'ICS Site OT Engineering' || item.name === 'ICS Site Security' || item.name === 'Security Operations')
                        .forEach((item, index) => {

                            cumEfficiency.push({
                                name: item.name,
                                "Year 1": parseInt(item["Year 1"]),
                                "Year 2": parseInt(item["Year 1"] + item["Year 2"]),
                                "Year 3": parseInt(item["Year 1"] + item["Year 2"] + item["Year 3"])
                            });
                        });
                }
                var x = ["Year 1", "Year 2", "Year 3"].map(item => {
                    return {
                        name: item,
                        "ICS Site OT Engineering": cumEfficiency.find(i => i.name === "ICS Site OT Engineering")[item],
                        "ICS Site Security": cumEfficiency.find(i => i.name === "ICS Site Security")[item],
                        "Security Operations": cumEfficiency.find(i => i.name === "Security Operations")[item],
                    }
                });

                console.log(x);
                setEfficiency(x);
                if (summaryData && summaryData['Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)']) {
                    summaryData['Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)']
                        .filter(item => item.name === 'ICS Site OT Engineering' || item.name === 'ICS Site Security' || item.name === 'Security Operations')
                        .forEach((item, index) => {
                            cumBenefit.push({
                                name: item.name,
                                "Year 1": parseInt(item["Year 1"]),
                                "Year 2": parseInt(item["Year 1"] + item["Year 2"]),
                                "Year 3": parseInt(item["Year 1"] + item["Year 2"] + item["Year 3"])
                            });
                        });
                }
                var y = ["Year 1", "Year 2", "Year 3"].map(item => {
                    return {
                        name: item,
                        "ICS Site OT Engineering": cumBenefit.find(i => i.name === "ICS Site OT Engineering")[item],
                        "ICS Site Security": cumBenefit.find(i => i.name === "ICS Site Security")[item],
                        "Security Operations": cumBenefit.find(i => i.name === "Security Operations")[item],
                    }
                });
                setBenefit(y);

                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.id]);


    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const downloadPPTX = () => {
        service.downloadPPTX(props.match.params.id, res => {
            console.log("download complete");
        })

    }
    const saveSharedUsers = () => {
        if (!sharedModelUsers || sharedModelUsers.length <= 0) {
            setSharedModelUsersMsg('<div class="validation-error">Please select Users.</div>');
        } else {
            setSharedModelUsersMsg(undefined)
            let payload = JSON.parse(JSON.stringify(sharedModelUsers));
            payload = payload.map(item => { return { id: item.value } })
            setLoading(true);
            service.upsertSharedModelUsers(props.match.params.id, payload, res => {
                setLoading(false);
                setSharedModelUsersMsg('<div class="validation-success">Saved successfully.</div>');
                setTimeout(() => {
                    setSharedModelUsersMsg();
                }, 1000)
            })
        }
    }

    const renderStatisticsItem = (item, key) => {
        const maxVal = item["Customer"] > item["Industry Average"] ? item["Customer"] : item["Industry Average"];
        let customer = 0;
        let average = 0;
        if (item.unit === "%") {
            customer = item["Customer"] || 0;
            average = item["Industry Average"] || 0;
        } else {
            if (maxVal > 0) {
                customer = item["Customer"] * 100 / maxVal;
                average = item["Industry Average"] * 100 / maxVal;
            }
        }

        return <span key={key} className="list-group-item list-group-item-action flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
                <div style={{ width: "calc(100% - 300px)" }}>
                    <h6 style={{ margin: "0" }}>{item.name}</h6>
                    <div style={{ fontSize: "0.85em", fontWeight: "300", height: "60px" }}>{item.comments}</div>
                </div>
                <div className="clearfix" style={{ width: "400px" }}>
                    <div className="float-start m-2" >
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", fontWeight: "300", fontSize: "0.65em", textAlign: "right" }}>CUSTOMER</div>
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", textAlign: "right" }}>{item.unit === "$" ? item.unit : ""}{formatCurrency(item["Customer"], 2)}{item.unit === "$" ? "" : item.unit}</div>
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", fontWeight: "300", fontSize: "0.65em", textAlign: "right" }}>INDUSTRY AVERAGE</div>
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", textAlign: "right" }}>{item.unit === "$" ? item.unit : ""}{formatCurrency(item["Industry Average"], 2)}{item.unit === "$" ? "" : item.unit}</div>
                    </div>
                    <div className="float-start m-2" style={{ color: "#aaaaaa" }}>
                        <div className="clrMauve" style={{ height: "40px", width: customer + "px" }}>&nbsp;</div>
                        <div className="clrGray" style={{ height: "40px", width: average + "px" }}>&nbsp;</div>
                    </div>
                </div>
            </div>
        </span>
    }

    const renderPostClarotyItem = (item, key) => {
        const maxVal = item["Customer Today"] > item["Benefit Post Claroty"] ? item["Customer Today"] : item["Benefit Post Claroty"];
        let customer = 0;
        let benefit = 0;
        if (item.unit === "%") {
            customer = item["Customer Today"] || 0;
            benefit = item["Benefit Post Claroty"] || 0;
        } else {
            if (maxVal > 0) {
                customer = item["Customer Today"] * 100 / maxVal;
                benefit = item["Benefit Post Claroty"] * 100 / maxVal;
            }
        }



        let indentClass = "";
        if (item.name.endsWith("(37.5hr/48wk YR)")) {
            indentClass = "ms-5";
        }
        return <span key={key} className="list-group-item list-group-item-action flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
                <div style={{ width: "calc(100% - 300px)" }} className={indentClass}>
                    <h6 style={{ margin: "0" }}>{item.name}</h6>
                    <div style={{ fontSize: "0.85em", fontWeight: "300", height: "60px" }}>{item.comments}</div>
                </div>
                <div className="clearfix" style={{ width: "400px" }}>
                    <div className="float-start m-2" >
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", fontWeight: "300", fontSize: "0.65em", textAlign: "right" }}>CUSTOMER TODAY</div>
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", textAlign: "right" }}>{item.unit === "$" ? item.unit : ""}{formatCurrency(item["Customer Today"], item.unit === "$" || item.unit === "Hours" ? 0 : 1)}{item.unit === "$" ? "" : item.unit}</div>
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", fontWeight: "300", fontSize: "0.65em", textAlign: "right" }}>BENEFIT POST CLAROTY</div>
                        <div style={{ height: "20px", lineHeight: "15px", width: "100%", textAlign: "right" }}>{item.unit === "$" ? item.unit : ""}{formatCurrency(item["Benefit Post Claroty"], item.unit === "$" || item.unit === "Hours" ? 0 : 1)}{item.unit === "$" ? "" : item.unit}</div>
                    </div>
                    <div className="float-start m-2" style={{ color: "#aaaaaa" }}>
                        <div className="clrGray" style={{ height: "40px", width: customer + "px", background: "#cccccc" }}>&nbsp;</div>
                        <div className="clrGreen" style={{ height: "40px", width: benefit + "px" }}>&nbsp;</div>
                    </div>

                </div>
            </div>
        </span>
    }

    const renderSpreadRow = (item, key, unit, dec) => {
        return <tr key={key}>
            <th>{item["name"]}</th>
            <td align="right">{unit === "$" ? unit : ""}{formatCurrency(item["Year 1"], dec)}{unit === "$" ? "" : unit}</td>
            <td align="right">{unit === "$" ? unit : ""}{formatCurrency(item["Year 2"], dec)}{unit === "$" ? "" : unit}</td>
            <td align="right">{unit === "$" ? unit : ""}{formatCurrency(item["Year 3"], dec)}{unit === "$" ? "" : unit}</td>
            <td align="right">{unit === "$" ? unit : ""}{formatCurrency(item["TOTAL"], dec)}{unit === "$" ? "" : unit}</td>
        </tr>
    }
    const renderSummaryPct2 = (item, key, color, isBoxed) => {
        return <div style={{ background: color }}>
            <span key={key} className="list-group-item list-group-item-action flex-column align-items-start">
                <div className="d-flex w-100 justify-content-between">
                    <div style={{ width: "600px" }}>
                        <h6 style={{ margin: "0" }}>{item.name}</h6>
                        <div style={{ fontWeight: "300", height: "60px" }}>{item.comments}</div>
                        <div style={{ marginTop: "25px", padding: "6px" }}>
                            <div style={{ display: "inline-block", width: "33%" }}>
                                <div style={{ fontSize: "0.65em", color: '#C74B9A', fontWeight: "200" }}>CUSTOMER</div>
                                <div style={{ fontSize: "2.0em", color: '#C74B9A', fontWeight: "300" }}>{formatCurrency(item["Customer"])}%</div>
                            </div>
                            <div style={{ display: "inline-block", width: "33%" }}>
                                <div style={{ fontSize: "0.65em", color: '#333333', fontWeight: "200" }}>INDUSTRY AVERAGE<sup>*</sup></div>
                                <div style={{ display: "inline-block", fontSize: "2.0em", color: '#AAAAAA', fontWeight: "300" }}>{formatCurrency(item["Industry Average"])}%</div>
                            </div>
                            <div style={{ display: "inline-block", width: "34%", fontSize: "0.65em", color: '#333333', fontWeight: "300" }}> <sup>*</sup> Industry Average is
                                {
                                    item.name === "Malicious or Criminal Attack (%)" || item.name === "System Glitch (%)" || item.name === "Human Error (%)" || item.name === "Global Geography Data Breach Probability" ?
                                        " based on Geography"
                                        : item.name === "Statistical likelihood based on frequency of breaches compared to other industries" ? " based on Industry Vertical"
                                            : " based on ICS security alert automation"
                                }
                            </div>
                        </div>
                    </div>
                    <div className="clearfix" >
                        <div style={{ height: "150px", width: "150px", margin: "0 auto" }}>

                            <CircularProgressbarWithChildren
                                value={formatCurrency(item["Industry Average"])}
                                strokeWidth={20}

                                styles={buildStyles({
                                    pathColor: "#AAAAAA",
                                    trailColor: "#eee",
                                    // rotation: 0.5,
                                    strokeLinecap: "butt"
                                })}
                            >

                                <div style={{ width: "58%" }}>
                                    <CircularProgressbar
                                        value={formatCurrency(item["Customer"])}
                                        // text={`${formatCurrency(item["Customer"])}%`}
                                        strokeWidth={30}
                                        styles={buildStyles({
                                            pathColor: "#C74B9A",
                                            trailColor: "#eee",
                                            textColor: "#C74B9A",
                                            // rotation: 0.5,
                                            strokeLinecap: "butt"
                                        })}
                                    />

                                </div>


                            </CircularProgressbarWithChildren>



                        </div>


                    </div>
                </div>
            </span>
        </div>
    }

    const renderSummaryPct3 = (item, color, isBoxed) => {
        return <div style={{ background: color, textAlign: "center", marginTop: "5px" }}>
            <div style={{ color: "#333333", fontSize: "1em", padding: "0.5em", height: isBoxed ? "40px" : "60px", fontWeight: isBoxed ? 300 : 300 }}>{item.name}</div>
            {/* <div style={{ fontSize: "3em", fontWeight: "600" }}>{formatCurrency(item["Customer"])}%</div> */}
            {isBoxed && <div style={{ fontSize: "0.75em", fontWeight: "300", height: "60px" }}>{item.comments}</div>}
            <div>
                <div style={{ height: "206px", width: "180px", margin: "0 auto", display: "inline-block" }}>

                    <CircularProgressbarWithChildren
                        value={formatCurrency(item["Industry Average"])}
                        strokeWidth={20}

                        styles={buildStyles({
                            pathColor: "#AAAAAA",
                            trailColor: "#eee",
                            // rotation: 0.5,
                            strokeLinecap: "butt"
                        })}
                    >
                        {/*
                Width here needs to be (100 - 2 * strokeWidth)% 
                in order to fit exactly inside the outer progressbar.
                */}
                        <div style={{ width: "58%" }}>
                            <CircularProgressbar
                                value={formatCurrency(item["Customer"])}
                                // text={`${formatCurrency(item["Customer"])}%`}
                                strokeWidth={30}
                                styles={buildStyles({
                                    pathColor: "#C74B9A",
                                    trailColor: "#eee",
                                    textColor: "#C74B9A",
                                    // rotation: 0.5,
                                    strokeLinecap: "butt"
                                })}
                            />

                        </div>


                    </CircularProgressbarWithChildren>



                </div>
                <div style={{ marginTop: "25px", padding: "6px", display: "inline-block", width: "50%" }}>
                    <div style={{ display: "inline-block", width: "33%", borderRight: "2px dotted #ffffff" }}>
                        <div style={{ fontSize: "0.65em", color: '#C74B9A', fontWeight: "600" }}>CUSTOMER</div>
                        <div style={{ fontSize: "2.0em", color: '#C74B9A', fontWeight: "300" }}>{formatCurrency(item["Customer"])}%</div>
                    </div>
                    <div style={{ display: "inline-block", width: "33%" }}>
                        <div style={{ fontSize: "0.65em", color: '#333333', fontWeight: "600" }}>INDUSTRY AVERAGE<sup>*</sup></div>
                        <div style={{ display: "inline-block", fontSize: "2.0em", color: '#AAAAAA', fontWeight: "300" }}>{formatCurrency(item["Industry Average"])}%</div>
                    </div>
                    <div style={{ display: "inline-block", width: "34%", fontSize: "0.75em", color: '#333333', fontWeight: "400" }}> <sup>*</sup> Industry Average is
                        {
                            item.name === "Malicious or Criminal Attack (%)" || item.name === "System Glitch (%)" || item.name === "Human Error (%)" || item.name === "Global Geography Data Breach Probability" ?
                                " based on Geography"
                                : item.name === "Statistical likelihood based on frequency of breaches compared to other industries" ? " based on Industry Vertical"
                                    : " based on ICS security alert automation"
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    const renderSummaryRow = (item, color, unit, label) => {
        return <React.Fragment>
            <div className="row">
                <div className="col-3 pill-center" style={{ background: color, fontSize: "4em", fontWeight: "200", color: "#FFFFFF" }}>{formatCurrency((item["Customer Today"] - item["Benefit Post Claroty"]))}</div>
                <div className="col-3 pill-center" style={{ background: color, fontSize: "2em", fontWeight: "200", color: "#FFFFFF" }}>{unit}</div>
                <div className="col-6 pill-left" style={{ background: color, fontSize: "1.2em", fontWeight: "300", color: "#FFFFFF" }}>{label}</div>
            </div>

        </React.Fragment>
    }
    let tabIndex = localStorage.getItem('MODEL_TAB_INDEX') || 0;
    loading ? startLoading() : stopLoading();
    return (
        <div >
            <div className="row" style={{ background: "#000000" }}>
            <div className="col-12" style={{ padding: "0" }}>
                <div className="container-fluid mt-3">
                        <div className="row">
                            <div className="col-6">
                                <h3 className="col-11" style={{ paddingTop: "10px", color:"#ffffff" }}>{title} {recipe && " - " +recipe.model_name}</h3>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-g btn-outline-light float-end" style={{ marginLeft: "15px" }} onClick={() => downloadPPTX(props.match.params.id)} ><i className="mdi mdi-download " aria-hidden="true" ></i> Download Slides</button>
                            
                                <button type="button" className="btn btn-g btn-outline-light float-end" style={{ marginLeft: "15px" }} onClick={() => setSharePop(true)} ><i className="mdi mdi-share-variant " aria-hidden="true" ></i> Share Model</button>
                            
                                <button type="button" className="btn btn-g btn-outline-warning float-end" onClick={() => props.history.push('/create-model/' + props.match.params.id + '/ref')} ><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i> Edit Model</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12" style={{ padding: "0" }}>
                    <div className="container-fluid mt-1 pt-2 filter-bar">
                        {recipe && <div className="row g-12 mt-3">
                            {/* <div className="col-3">
                                <div className="summary_label">Model Name</div>
                                <div className="summary_data">{recipe.model_name}</div>
                            </div> */}
                            <div className="col-3">
                                <div className="summary_label">Model Type</div>
                                <div className="summary_data">{recipe.model_type}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Customer Name</div>
                                <div className="summary_data">{recipe.customer_name}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Opportunity Number</div>
                                <div className="summary_data">{recipe.opportunity_number ? recipe.opportunity_number : "-"}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Region</div>
                                <div className="summary_data">{recipe.geography_name}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Industry Vertical</div>
                                <div className="summary_data">{recipe.vertical_name}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Automation Stage</div>
                                <div className="summary_data">{recipe.automation_stage_name}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Number Of Sites</div>
                                <div className="summary_data">{recipe.num_sites}</div>
                            </div>
                            <div className="col-3">
                                <div className="summary_label">Owner</div>
                                <div className="summary_data">{recipe.owner_name}</div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className="col-12 pt-3" style={{background:"#f8f9fa"}}>
                <Tabs style={{ width: "100%" }} defaultIndex={parseInt(tabIndex)}>
                    <div className="clearfix cmm-tabs" style={{ top: 0 }}>
                        <TabList>
                            <Tab ><div style={{ textAlign: "center" }}>Summary</div></Tab>
                            <Tab ><div style={{ textAlign: "center" }}>Statistics &amp; Risk</div></Tab>
                            <Tab ><div style={{ textAlign: "center" }}>OT Asset Owner Per Site Operator Value Monthly</div></Tab>
                            {recipe && recipe.model_type === "ADVANCED" && <Tab ><div style={{ textAlign: "center" }}>Security Operations Costs &amp; Benfits</div></Tab>}
                            <Tab ><div style={{ textAlign: "center" }}>Total Time Savings &amp; Productivity Benefits</div></Tab>
                            <Tab ><div style={{ textAlign: "center" }}>Reference Data</div></Tab>
                        </TabList>
                    </div>
                    <div style={{ marginTop: "4px" }}>
                        <TabPanel>
                            {summary && summary["Statistics"] && summary["Statistics"].length > 0 && <div className="col-12">
                                <div className="row g-12 mt-3" style={{ paddingLeft: "10px" }}>
                                    <div className="col-4">
                                        <h5>ICS OT Engineer Plant Benefit Monthly</h5>
                                        <div style={{ padding: "12px" }}>
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Days spent per month reviewing OT changes Centrally?"), "#429cda", "Days", "Reduction in time spent reviewing OT changes centrally")}
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Walkdown time Days (physical investigation)?"), "#429cda", "Days", "Reduction in walkdown time (physical investigation)")}
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Number of RCAs Days?"), "#429cda", "Days", "Reduction in RCA")}
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Mean Time To Repair HRS?"), "#429cda", "Hours", "Reduction in Mean Time To Repair")}
                                        </div>
                                        <h5 style={{ marginTop: "50px" }}>ICS Site Security Plant Benefit Monthly</h5>
                                        <div style={{ padding: "12px" }}>
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Walkdown time Days (Monthly physical investigation)"), "#7b2ff5", "Days", "Reduction in walkdown time (physical investigation)")}
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Avg Travel Time to Site HRS"), "#7b2ff5", "Hours", "Reduction in travel time to site")}
                                            {renderSummaryRow(summary["OT Asset Owner Per Site Operator Value Montlly"].find((item) => item["name"] === "Vendor Firmware Update Time at Site Hrs / Shadow time"), "#7b2ff5", "Hours", "Reduction in shadow time for vendor firmware update")}
                                        </div>

                                    </div>
                                    <div className="col-4">
                                        <h5>Statistics</h5>

                                        {renderSummaryPct3(summary["Statistics"].find((item) => item["name"] === "Global Geography Data Breach Probability"), "#f2ca51")}
                                        {renderSummaryPct3(summary["Statistics"].find((item) => item["name"] === "Statistical likelihood based on frequency of breaches compared to other industries"), "#f2ca51")}
                                        {renderSummaryPct3(summary["Statistics"].find((item) => item["name"] === "Organisations which are at the same stage of Security Automation versus Some Deployment"), "#f2ca51")}

                                    </div>
                                    <div className="col-4">
                                        <h5>Risk likelihood</h5>
                                        {renderSummaryPct3(summary["Chance of Risk from statistical likelihood compared to Global Average"].find((item) => item["name"] === "Malicious or Criminal Attack (%)"), "#d9d9d9")}
                                        {renderSummaryPct3(summary["Chance of Risk from statistical likelihood compared to Global Average"].find((item) => item["name"] === "System Glitch (%)"), "#d9d9d9")}
                                        {renderSummaryPct3(summary["Chance of Risk from statistical likelihood compared to Global Average"].find((item) => item["name"] === "Human Error (%)"), "#d9d9d9")}
                                    </div>
                                </div>


                            </div>
                            }
                        </TabPanel>
                        <TabPanel>
                            <div className="row g-12 mt-3">
                                <div className="col-6">
                                    {summary && summary["Statistics"] && summary["Statistics"].length > 0 && <div className="list-group">
                                        <h5>Statistics</h5>
                                        {summary["Statistics"].map((item, index) => {
                                            return <div key={"StatisticsDiv" + index}> {renderSummaryPct2(item, "Statistics" + index, "#f2ca51", true)} </div>
                                        })}
                                    </div>}
                                </div>
                                <div className="col-6">
                                    {summary && summary["Chance of Risk from statistical likelihood compared to Global Average"] && summary["Chance of Risk from statistical likelihood compared to Global Average"].length > 0 && <div className="list-group">
                                        <h5>Risk Likelihood</h5>
                                        {summary["Chance of Risk from statistical likelihood compared to Global Average"].map((item, index) => {
                                            return <div key={"Chance of Risk from statistical likelihood compared to Global AverageDiv" + index}> {renderSummaryPct2(item, "Chance of Risk from statistical likelihood compared to Global Average" + index, "#d9d9d9", true)} </div>
                                        })}
                                    </div>}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            {summary && summary["OT Asset Owner Per Site Operator Value Montlly"] && summary["OT Asset Owner Per Site Operator Value Montlly"].length > 0 && <div>

                                <div className="row mt-4">
                                    <div className="col-6">
                                        <h5 style={{ fontWeight: "300" }}>ICS Site OT Engineering</h5>
                                        {summary["OT Asset Owner Per Site Operator Value Montlly"].filter(item => item["subcategory"] === "ICS Site OT Engineering").map((item, index) => {
                                            return renderPostClarotyItem(item, "ICS Site OT Engineering" + index)
                                        })}
                                    </div>
                                    <div className="col-6">
                                        <h5 style={{ fontWeight: "300" }}>ICS Site Security</h5>
                                        {summary["OT Asset Owner Per Site Operator Value Montlly"].filter(item => item["subcategory"] === "ICS Site Security").map((item, index) => {
                                            return renderPostClarotyItem(item, "ICS Site Security" + index)
                                        })}
                                    </div>
                                </div>
                            </div>}
                        </TabPanel>
                        {recipe && recipe.model_type === "ADVANCED" && <TabPanel>
                            <div className="row mt-4">
                                <div className="col-6">
                                    {summary && summary["Security Operations Costs"] && summary["Security Operations Costs"].length > 0 && <div className="list-group">

                                        {summary["Security Operations Costs"].map((item, index) => {
                                            return renderStatisticsItem(item, "Security Operations Costs" + index)
                                        })}
                                    </div>}
                                </div>
                                <div className="col-6">
                                    {summary && summary["Security Operations Benefits"] && summary["Security Operations Benefits"].length > 0 && <div className="list-group">

                                        {summary["Security Operations Benefits"].filter(item => item.name !== 'Time associated with Attack MDR' && item.name !== 'Time associated with Breach Containment' && item.name !== 'Equivalent SOC Resource Annualised (37.5hr/48wk YR)').map((item, index) => {
                                            return renderPostClarotyItem(item, "Security Operations Benefits" + index);
                                        })}
                                    </div>}
                                </div>
                            </div>
                        </TabPanel>
                        }
                        <TabPanel>
                            <div className="row  mt-4">
                                <div className="col-6">
                                    {recipe && recipe.model_type && summary && summary["Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)"] && summary["Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)"].length > 0 && <div>
                                        <h5>Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)</h5>

                                        {efficiency && efficiency.length > 0 && <div style={{ width: "100%", height: "300px" }}>

                                            <CumChart
                                                data={efficiency.map(item => {
                                                    if (recipe.model_type === 'ADVANCED') {
                                                        return item;
                                                    } else {
                                                        let _item = item;
                                                        delete _item["Security Operations"];
                                                        console.log(_item);
                                                        return _item;
                                                    }
                                                })}
                                                modelType={recipe.model_type}
                                            />

                                        </div>
                                        }
                                        <table className="table table-striped">
                                            <colgroup>
                                                <col width="40%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "right" }} scope="col">&nbsp;</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Year 1</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Year 2</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Year 3</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recipe && recipe.model_type && summary["Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)"].filter(item => item["name"] !== 'Security Operations' || (item["name"] === 'Security Operations' && recipe.model_type === 'ADVANCED')).map((item, index) => {
                                                    return renderSpreadRow(item, "Total Time Efficiency $ Savings (Static View / Not Linked to Deployment)" + index, "$", 0)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>

                                <div className="col-6">
                                    {recipe && recipe.model_type && summary && summary["Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)"] && summary["Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)"].length > 0 && <div>
                                        <h5>Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)</h5>
                                        {benefit && benefit.length > 0 && <div style={{ width: "100%", height: "300px" }}>



                                            <CumChart
                                                data={benefit.map(item => {
                                                    if (recipe.model_type === 'ADVANCED') {
                                                        return item;
                                                    } else {
                                                        let _item = item;
                                                        delete _item["Security Operations"];
                                                        console.log(_item);
                                                        return _item;
                                                    }
                                                })}
                                                modelType={recipe.model_type}
                                            />

                                        </div>
                                        }
                                        <table className="table table-striped">
                                            <colgroup>
                                                <col width="40%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "right" }} scope="col">&nbsp;</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Year 1</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Year 2</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Year 3</th>
                                                    <th style={{ textAlign: "right" }} scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {summary["Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)"].filter(item => item["name"] !== 'Security Operations' || (item["name"] === 'Security Operations' && recipe.model_type === 'ADVANCED')).map((item, index) => {
                                                    return renderSpreadRow(item, "Total Time Productivity in Hours benefit (Static View / Avg 8Hr Day)" + index, "", 0)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="row  mt-4">
                                <div className="col-12">
                                    {summary && referenceData && <div>

                                        <RefData referenceData={referenceData} recipe={recipe} />
                                    </div>
                                    }
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
                </div>
            </div>
            {
                shareOptions && shareOptions.length > 0 && sharePop &&
                <div className='popup'>
                    <div className='popup_content'>
                        <div className="popup-title">
                            Share Model
                        </div>
                        <div style={{ maxHeight: "200px", fontSize: "13px" }}>
                            <label style={{ fontSize: "13px" }}>USERS</label>
                            <MultySelectDropdown
                                placeholder="Select"
                                closeMenuOnSelect={false}
                                value={sharedModelUsers}
                                options={shareOptions}
                                onChange={(_selectedValues) => {
                                    setSharedModelUsers(_selectedValues)
                                }}
                            />
                            {sharedModelUsersMsg && <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: sharedModelUsersMsg }} />}
                        </div>
                        <div className="popup-btn-panel">
                            <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveSharedUsers()} >Save</button>
                            <button type="button" className="btn btn-g btn-secondary float-end" onClick={() => setSharePop(false)} >Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ShowBack;


