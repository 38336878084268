import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading } from '../../Components/Util';
import * as service from "../../services";
import ReactTooltip from 'react-tooltip';
import { useAuthState } from '../../Context';

function ConfigureEla(props) {
    const userDetails = useAuthState()
    let { userType } = userDetails.user;

    const [title, setTitle] = useState("Configure Ela Quote");
    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);
    const [products, setProducts] = useState(undefined);
    const [services, setServices] = useState(undefined);
    const [shipping, setShipping] = useState(undefined);
    const [hardware, setHardware] = useState(undefined);
    const [xdome, setXdome] = useState(undefined);
    const [xdomeSites, setXdomeSites] = useState(undefined);
    const [showDeleteSiteConfirmation, setShowDeleteSiteConfirmation] = useState(false);
    const [activeSite, setActiveSite] = useState({});
    const [activeService, setActiveService] = useState({});
    const [activeShipping, setActiveShipping] = useState({});
    const [activeHardware, setActiveHardware] = useState({});
    const [activeXdomeSite, setActiveXdomeSite] = useState({});
    const [showDeleteProductConfirmation, setShowDeleteProductConfirmation] = useState(false);
    const [showDeleteXdomeConfirmation, setShowDeleteXdomeConfirmation] = useState(false);
    const [showDeleteXdomeSiteConfirmation, setShowDeleteXdomeSiteConfirmation] = useState(false);
    const [showDeleteServiceConfirmation, setShowDeleteServiceConfirmation] = useState(false);
    const [showDeleteShippingConfirmation, setShowDeleteShippingConfirmation] = useState(false);
    const [showDeleteHardwareConfirmation, setShowDeleteHardwareConfirmation] = useState(false);
    const [showAddDropDown, setShowAddDropDown] = useState(false);


    const [quoteHeader, setQuoteHeader] = useState({});
    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                setTitle(`Edit ELA Quote #${props.match.params.id}`);
                service.getElaData("ela-header", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }

                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            loadSites(() => {
                resolve("");
            });

        });
        let p3 = new Promise((resolve, reject) => {
            loadProducts(() => {
                resolve("");
            });

        });
        let p4 = new Promise((resolve, reject) => {
            loadServices(() => {
                resolve("");
            });

        });
        let p5 = new Promise((resolve, reject) => {
            loadShipping(() => {
                resolve("");
            });

        });
        let p6 = new Promise((resolve, reject) => {
            loadHardware(() => {
                resolve("");
            });

        });
        let p7 = new Promise((resolve, reject) => {
            loadXdome(() => {
                resolve("");
            });

        });
        let p8 = new Promise((resolve, reject) => {
            loadXdomeSites(() => {
                resolve("");
            });

        });
        Promise.all([p1, p2, p3, p4, p5, p6, p7, p8])
            .then(values => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.id]);

    const loadSites = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_site_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setSites(res);
                    fn();
                } else {
                    setSites([]);
                    fn();
                }
            });
        } else {
            setSites([]);
            fn();
        }
    }
    const loadProducts = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_products_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setProducts(res);
                    fn();
                } else {
                    setProducts(undefined);
                    fn();
                }
            });
        } else {
            setProducts([]);
            fn();
        }
    }
    const loadXdomeSites = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_xdome_sites_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setXdomeSites(res);
                    fn();
                } else {
                    setXdomeSites(undefined);
                    fn();
                }
            });
        } else {
            setXdomeSites([]);
            fn();
        }
    }
    const loadXdome = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_xdome_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setXdome(res);
                    fn();
                } else {
                    setXdome(undefined);
                    fn();
                }
            });
        } else {
            setXdome([]);
            fn();
        }
    }
    const loadServices = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_services_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setServices(res);
                    fn();
                } else {
                    setServices(undefined);
                    fn();
                }
            });
        } else {
            setServices([]);
            fn();
        }
    }
    const loadShipping = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_shipping_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setShipping(res);
                    fn();
                } else {
                    setShipping(undefined);
                    fn();
                }
            });
        } else {
            setShipping([]);
            fn();
        }
    }
    const loadHardware = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_hardware_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setHardware(res);
                    fn();
                } else {
                    setHardware(undefined);
                    fn();
                }
            });
        } else {
            setHardware([]);
            fn();
        }
    }
    const validate = () => {
        return true;
    };
    const deleteSite = () => {

        service.upsertEla('ela-site-delete', { id: activeSite.id, quote_id: props.match.params.id }, res => {
            loadSites(() => {
                service.getElaData("ela-header", { id: props.match.params.id }, res => {
                    if (res && res.length === 1) {
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        setShowDeleteSiteConfirmation(false);
                    }
                });
            })
        });

    };
    const deleteProduct = () => {

        service.upsertEla('ela-products-delete', { id: getProductId(), quote_id: props.match.params.id }, res => {
            loadProducts(() => {
                console.log(`deleted ${res.products_id}`);
                setShowDeleteProductConfirmation(false);
            })
        });
    };

    const deleteXdome = () => {

        service.upsertEla('ela-xdome-delete', { id: getXdomeId(), quote_id: props.match.params.id }, res => {
            loadXdome(() => {
                console.log(`deleted ${res.products_id}`);
                setShowDeleteXdomeConfirmation(false);
            })
        });
    };

    const deleteXdomeSite = () => {

        service.upsertEla('ela-xdome-site-delete', { id: activeXdomeSite.id, quote_id: props.match.params.id }, res => {
            loadXdomeSites(() => {
                service.getElaData("ela-header", { id: props.match.params.id }, res => {
                    if (res && res.length === 1) {
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        setShowDeleteXdomeSiteConfirmation(false);
                    }
                });
            })
        });

    };

    const deleteService = () => {

        service.upsertEla('ela-services-delete', { id: activeService.id, quote_id: props.match.params.id }, res => {
            loadServices(() => {
                console.log(`deleted ${res.services_id}`);
                setShowDeleteServiceConfirmation(false);
            })
        });
    };
    const deleteHardware = () => {

        service.upsertEla('ela-hardware-delete', { id: activeHardware.id, quote_id: props.match.params.id }, res => {
            loadHardware(() => {
                console.log(`deleted ${res.hardware_id}`);
                setShowDeleteHardwareConfirmation(false);
            })
        });
    };
    const deleteShipping = () => {

        service.upsertEla('ela-shipping-delete', { id: activeShipping.id, quote_id: props.match.params.id }, res => {
            loadShipping(() => {
                console.log(`deleted ${res.shipping_id}`);
                setShowDeleteShippingConfirmation(false);
            })
        });
    };
    const handleNext = () => {
        if (validate()) {
            setLoading(true);
            service.upsertEla('ela-price-complete', { id: props.match.params.id }, res => {
                console.log(`completed ${res.ela_id}`);
                props.history.push('/summary-ela/' + props.match.params.id + '/ref');
            });


        }
    }
    const getProductId = () => {
        if (products && products.length > 0) {
            return products[0].id;
        }
        return 0;
    }
    const getXdomeId = () => {
        if (xdome && xdome.length > 0) {
            return xdome[0].id;
        }
        return 0;
    }
    const modifyCount = (year, part_type, part_id, add_count) => {
        startLoading();
        service.upsertEla('ela-modify-count', { quote_id: props.match.params.id, id: part_id, part_type: part_type, add_count: add_count, year: year }, res => {
            service.getElaData("ela-header", { id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length === 1) {
                    setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                    loadProducts(() => {
                        if (part_type === 'SITE') {
                            loadSites(() => {
                                stopLoading();
                            });
                        }
                        if (part_type === 'SERVICE') {
                            loadServices(() => {
                                stopLoading();
                            });
                        }
                        if (part_type === 'SHIP') {
                            loadShipping(() => {
                                stopLoading();
                            });
                        }
                        if (part_type === 'HW') {
                            loadHardware(() => {
                                stopLoading();
                            });
                        }
                    });
                }

            });

        });
    }
    const renderDeployment = (year, count, part_type, part_id, showControls) => {
        let can_decr=true;
        if(part_type==='SITE' && !userType.isElaDDUser){
            const item = sites.find(item => item.id===part_id);
            if(item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count<=20){
                can_decr=false;
            }
        }
        return <li className="list-group-item" style={{ padding: "0.2rem 0rem", width:"75px", background: count > 0 ? "#ffffff" : "#e5e5e5" }}>
            {year > 0 && <div style={{ padding: "0rem 0rem", textAlign:"center" }}>
                <div style={{ fontWeight: "400", fontSize: "0.65em", color: count > 0 ? "#222222" : "#CCCCCC" }}>Year {year}</div>
                <div style={{ fontWeight: "600", fontSize: "1.0em", textAlign: "center", color: count > 0 ? "#222222" : "#CCCCCC" }}>{count}</div>
            </div>}
            {year === 0 && <div style={{ padding: "0rem 0rem", textAlign:"center" }}>
                <div style={{ fontWeight: "400", fontSize: "0.65em", color: count > 0 ? "#222222" : "#CCCCCC" }}>Total</div>
                <div style={{ fontWeight: "600", fontSize: "1.0em", textAlign: "center", color: count > 0 ? "#222222" : "#CCCCCC" }}>{count}</div>
            </div>}
            {showControls && <div style={{ padding: "0rem 0.2rem" }}>
                <i className='mdi mdi-plus-box-outline float-start incr' style={{ cursor: "pointer" }} data-tip data-for={"incr-" + part_type + '-' + part_id + '-' + year} onClick={() => { modifyCount(year, part_type, part_id, 1) }}>
                    <ReactTooltip type="dark" id={"incr-" + part_type + '-' + part_id + '-' + year} place="bottom" effect="solid" className="sidenav-tooltip">+ Increase</ReactTooltip>
                </i>
                {can_decr && (count > 0) && <i className='mdi mdi-minus-box-outline float-end decr' style={{ cursor: "pointer" }} data-tip data-for={"decr-" + part_type + '-' + part_id + '-' + year} onClick={() => { modifyCount(year, part_type, part_id, -1) }}>
                    <ReactTooltip type="dark" id={"decr-" + part_type + '-' + part_id + '-' + year} place="bottom" effect="solid" className="sidenav-tooltip">- Decrease</ReactTooltip>
                </i>}
            </div>
            }
        </li>
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>{title}</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.partner_type + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal ? 'Yes' : 'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email ? ' [EMAIL: ' + quoteHeader.sales_director_email + ']' : '') + (quoteHeader.sales_director_phone ? ' [CONTACT #: ' + quoteHeader.sales_director_phone + ']' : '')}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                {/* <h4 className="card-header">Quote Header</h4> */}
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-6">
                                                            <h4>ELA Configuration</h4>

                                                        </div>
                                                        <div className="col-6">

                                                            <div className="dropdown float-end" >
                                                                <button type="button" className="btn  btn-outline-primary dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { setShowAddDropDown(!showAddDropDown) }} style={{ padding: "4px 15px", marginLeft: "5px", height: "45px" }}><i className="mdi mdi-plus " aria-hidden="true" ></i> Add </button>
                                                                {showAddDropDown && <ul className="download-dropdown-menu" aria-labelledby="dropdownMenuButton" onMouseLeave={() => { setShowAddDropDown(false) }}>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-site/${props.match.params.id}/0/ref`)} >Add OnPrem Site Configuration</li>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-xdome/${props.match.params.id}/${getXdomeId()}/ref`)}>{getXdomeId() === 0 ? "Add" : "Edit"} xDome Asset Based Configuration</li>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-xdome-site/${props.match.params.id}/0/ref`)}>Add xDome Site Based Configuration</li>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-hardware/${props.match.params.id}/0/ref`)}>Add Hardware Configuration</li>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-products/${props.match.params.id}/${getProductId()}/ref`)}>{getProductId() === 0 ? "Add" : "Edit"} Quote Wide Products</li>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-services/${props.match.params.id}/0/ref`)}>Add Professional Service</li>
                                                                    <li style={{ cursor: "pointer", fontWeight: "300" }} className="dropdown-item" onClick={() => props.history.push(`/configure-shipping/${props.match.params.id}/0/ref`)}>Add Shipping</li>
                                                                </ul>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row g-3" style={{ margin: "0px 0px" }}>
                                                        <div className="flex-table" style={{ border: "0px solid #e5e5e5" }}>
                                                            <div className="flex-table-row" style={{ flex: "1 55%" }}>
                                                                <div className="flex-table-col" style={{ height: "100%" }}>
                                                                    <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                        <div style={{ fontSize: "1.5em", fontWeight: "100", height: "100%", display: "flex", alignItems: "center" }}>OnPrem Site Configurations</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                <div className="flex-table-col">
                                                                    <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                        <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                        <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {quoteHeader.split_total_sites.reduce((partialSum, a) => partialSum + a, 0)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                <div className="flex-table-col">
                                                                    <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                        <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                        <ul className="list-group list-group-horizontal">
                                                                            {renderDeployment(1, quoteHeader.num_year1_sites, 'QUOTE', quoteHeader.id, false)}
                                                                            {renderDeployment(2, quoteHeader.num_year2_sites, 'QUOTE', quoteHeader.id, false)}
                                                                            {renderDeployment(3, quoteHeader.num_year3_sites, 'QUOTE', quoteHeader.id, false)}
                                                                            {quoteHeader.contract_length > 3 && renderDeployment(4, quoteHeader.num_year4_sites, 'QUOTE', quoteHeader.id, false)}
                                                                            {quoteHeader.contract_length > 4 && renderDeployment(5, quoteHeader.num_year5_sites, 'QUOTE', quoteHeader.id, false)}

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                <div className="flex-table-col">
                                                                    <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {sites && sites.length > 0 ?
                                                            sites.map((item, key) => {
                                                                return <div key={key + '_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>


                                                                    <div className="flex-table-row" style={{ flex: "1 10%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Site Size</div>
                                                                                <div style={{ fontWeight: "600", fontSize: "2.0em", color: "#aaaaaa" }}> {item.site_size}</div>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop: "10px" }}>Site Name</div>
                                                                                <div style={{ fontWeight: "300", fontSize: "1em" }}>{item.site_description}</div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-table-row" style={{ flex: "1 45%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Configuration</div>
                                                                                {item.include_ctd && <button type="button" className="btn  btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"ctd" + item.id}> CTD


                                                                                    {item.include_ao_app_db && <span style={{ margin: "2px 3px", fontWeight: "400", color: "#cccccc" }}><i className="mdi mdi-tag-plus"></i> AppDB </span>}
                                                                                    {item.include_ao_active && <span style={{ margin: "2px 3px", fontWeight: "400", color: "#cccccc" }}><i className="mdi mdi-tag-plus"></i> Active  </span>}
                                                                                    {item.include_ao_factory_talk_asset_centre && <span style={{ margin: "2px 3px", fontWeight: "400", color: "#cccccc" }}><i className="mdi mdi-tag-plus"></i> FactoryTalk AssetCentre  </span>}
                                                                                    {item.include_ao_edge && <span style={{ margin: "2px 3px", fontWeight: "400", color: "#cccccc" }}><i className="mdi mdi-tag-plus"></i> Edge (with CTD)  </span>}
                                                                                    <ReactTooltip type="light" border={true} id={"ctd" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                        <div style={{ textAlign: "left" }}>
                                                                                            <h6>CTD</h6>
                                                                                            <div>
                                                                                                {item.include_ao_app_db && <div><i className="mdi mdi-tag-plus"></i> AppDB Add-On</div>}
                                                                                                {item.include_ao_active && <div><i className="mdi mdi-tag-plus"></i> Active Add-On</div>}
                                                                                                {item.include_ao_factory_talk_asset_centre && <div><i className="mdi mdi-tag-plus"></i> FactoryTalk AssetCentre Add-On</div>}
                                                                                                {item.include_ao_edge && <div><i className="mdi mdi-tag-plus"></i> Edge Add-On</div>}

                                                                                            </div>
                                                                                        </div>
                                                                                    </ReactTooltip>
                                                                                </button>}


                                                                                {item.include_sensors_small && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"ss" + item.id}> Small Sensors

                                                                                    <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}> <i style={{ color: "#00cc00" }} className="mdi mdi-signal-variant"></i>
                                                                                        <span className="badge  bg-danger" >
                                                                                            {item.small_sensor_count}
                                                                                            <span className="visually-hidden">number of small sensors</span>
                                                                                        </span>
                                                                                    </span>
                                                                                    <ReactTooltip type="light" border={true} id={"ss" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                        <div style={{ textAlign: "left" }}>
                                                                                            <h6>Small Sensors</h6>
                                                                                            <div>
                                                                                                <div><i style={{ color: "#00cc00" }} className="mdi mdi-signal-variant"></i> {item.small_sensor_count} Sensors</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ReactTooltip>
                                                                                </button>}
                                                                                {item.include_sensors_large && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"ls" + item.id}> Large Sensors

                                                                                    <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}> <i style={{ color: "#00cc00" }} className="mdi mdi-signal-variant"></i>
                                                                                        <span className="badge  bg-danger" >
                                                                                            {item.large_sensor_count}
                                                                                            <span className="visually-hidden">number of large sensors</span>
                                                                                        </span>
                                                                                    </span>
                                                                                    <ReactTooltip type="light" border={true} id={"ls" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                        <div style={{ textAlign: "left" }}>
                                                                                            <h6>Large Sensors</h6>
                                                                                            <div>
                                                                                                <div><i style={{ color: "#00cc00" }} className="mdi mdi-signal-variant"></i> {item.large_sensor_count} Sensors</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ReactTooltip>
                                                                                </button>}

                                                                                {item.include_sra_site && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"sra" + item.id}> SRA Site


                                                                                    <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}> <i style={{ color: "#00cc00" }} className="mdi mdi-server"></i>
                                                                                        <span className="badge  bg-danger" >
                                                                                            {item.sra_site_server_count}
                                                                                            <span className="visually-hidden">number of SRA Site servers</span>
                                                                                        </span>
                                                                                    </span>
                                                                                    {item.include_sra_site_ha && <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}><i style={{ color: "#00cc00" }} className="mdi mdi-lifebuoy"></i></span>}
                                                                                    <ReactTooltip type="light" border={true} id={"sra" + item.id} place="top" effect="solid" className="sidenav-tooltip" style={{ alignContent: "left" }}>
                                                                                        <div style={{ textAlign: "left" }}>
                                                                                            <h6>SRA Site</h6>
                                                                                            <div>
                                                                                                <div><i style={{ color: "#00cc00" }} className="mdi mdi-server"></i> {item.sra_site_server_count} Servers</div>
                                                                                                {item.include_sra_site_ha && <div><i style={{ color: "#00cc00" }} className="mdi mdi-lifebuoy"></i> High Availability</div>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </ReactTooltip>
                                                                                </button>}

                                                                                {item.include_edge && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.65em" }} data-tip data-for={"edge" + item.id}> Edge
                                                                                    <ReactTooltip type="light" border={true} id={"edge" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                        <div style={{ textAlign: "left" }}><h6>Edge</h6></div>

                                                                                    </ReactTooltip>
                                                                                </button>}



                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                                <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}</div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                                <ul className="list-group list-group-horizontal">
                                                                                    {renderDeployment(1, item.year1_count, 'SITE', item.id, true)}
                                                                                    {renderDeployment(2, item.year2_count, 'SITE', item.id, true)}
                                                                                    {renderDeployment(3, item.year3_count, 'SITE', item.id, true)}
                                                                                    {quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'SITE', item.id, true)}
                                                                                    {quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'SITE', item.id, true)}

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-site/${props.match.params.id}/${item.id}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                    <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                                </button>
                                                                                <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {
                                                                                    setActiveSite(item);
                                                                                    setShowDeleteSiteConfirmation(true);
                                                                                }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                    <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                            : <div className="blank-div" style={{ margin: "30px 0px" }}>No Sites Found.</div>}
                                                    </div>
                                                    {xdomeSites && xdomeSites.length > 0 && <div className="row g-3" style={{ margin: "30px 0px" }}>
                                                        <div style={{ fontSize: "1.5em", fontWeight: "100" }}>xDome Site Based Configurations </div> 
                                                        {xdomeSites.map((item, key) => {
                                                            return <div key={key + '_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>
                                                                    <div className="flex-table-row" style={{ flex: "1 10%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Site Size</div>
                                                                                <div style={{ fontWeight: "600", fontSize: "2.0em", color: "#aaaaaa" }}> {item.site_size}</div>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop: "10px" }}>Site Name</div>
                                                                                <div style={{ fontWeight: "300", fontSize: "1em" }}>{item.site_description}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex-table-row" style={{ flex: "1 45%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Bundle Type</div>
                                                                                <div style={{ fontWeight: "300", fontSize: "1em" }}>{item.include_essentials?"With Hardware":"Without Hardware"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                                <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}</div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                                <ul className="list-group list-group-horizontal">
                                                                                    {renderDeployment(1, item.year1_count, 'XDOMESITE', item.id, false)}
                                                                                    {renderDeployment(2, item.year2_count, 'XDOMESITE', item.id, false)}
                                                                                    {renderDeployment(3, item.year3_count, 'SIXDOMESITETE', item.id, false)}
                                                                                    {quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'XDOMESITE', item.id, false)}
                                                                                    {quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'XDOMESITE', item.id, false)}

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                        <div className="flex-table-col">
                                                                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                                <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-xdome-site/${props.match.params.id}/${item.id}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                    <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                                </button>
                                                                                <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {
                                                                                    setActiveXdomeSite(item);
                                                                                    setShowDeleteXdomeSiteConfirmation(true);
                                                                                }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                    <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                
                                                            </div>
                                                        })}
                                                    </div>}
                                                    {xdome && xdome.length > 0 && <div className="row g-3" style={{ margin: "30px 0px" }}>

                                                        <div style={{ fontSize: "1.5em", fontWeight: "100" }}>xDome Asset Based Configurations

                                                        </div>


                                                        {xdome.map((item, key) => {
                                                        
                                                            return <div key={key + '_ xdome_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>


                                                                <div className="flex-table-row" style={{ flex: "1 10%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "2.0em", color: "#aaaaaa" }}>{item.number_of_sites}</div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 45%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Configuration</div>

                                                                            {item.include_essentials && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"include_essentials" + item.id}> xDome Essentials
                                                                                <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}><i style={{ color: "#00cc00" }} className="mdi mdi-server"></i></span>
                                                                                <ReactTooltip type="light" border={true} id={"include_essentials" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                    <div style={{ textAlign: "left" }}><h6>xDome Essentials</h6></div>

                                                                                    <div>

                                                                                        <div><i style={{ color: "#00cc00" }} className="mdi mdi-server"></i> Includes Hardware</div>
                                                                                    </div>
                                                                                </ReactTooltip>
                                                                            </button>}
                                                                            {item.exclude_hw && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"exclude_hw" + item.id}> xDome Essentials
                                                                                <ReactTooltip type="light" border={true} id={"exclude_hw" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                    <div style={{ textAlign: "left" }}><h6>xDome Essentials</h6></div>
                                                                                    <div>

                                                                                        <div> No Hardware</div>
                                                                                    </div>
                                                                                </ReactTooltip>
                                                                            </button>}
                                                                            {item.include_advanced_detection && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"include_advanced_detection" + item.id}> xDome Advanced Anomaly &amp; Threat Detection
                                                                                <ReactTooltip type="light" border={true} id={"include_advanced_detection" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                    <div style={{ textAlign: "left" }}><h6>xDome Advanced Detection</h6></div>

                                                                                </ReactTooltip>
                                                                            </button>}
                                                                            {item.include_cyber_hygine && <button type="button" className="btn btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"include_cyber_hygine" + item.id}> xDome Advanced Vulnerability &amp; Risk Management
                                                                                <ReactTooltip type="light" border={true} id={"include_cyber_hygine" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                    <div style={{ textAlign: "left" }}><h6>xDome Cyber Hygiene</h6></div>

                                                                                </ReactTooltip>
                                                                            </button>}
                                                                            {item.include_npm && <button type="button" className="btn btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"include_npm" + item.id}> xDome Network Security Management
                                                                                <ReactTooltip type="light" border={true} id={"include_npm" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                    <div style={{ textAlign: "left" }}><h6>Dome Network Protect (NPM)</h6></div>

                                                                                </ReactTooltip>
                                                                            </button>}




                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Assets</div>
                                                                            <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                            <ul className="list-group list-group-horizontal">
                                                                                {renderDeployment(1, item.year1_count, 'XDOME', item.id, false)}
                                                                                {renderDeployment(2, item.year2_count, 'XDOME', item.id, false)}
                                                                                {renderDeployment(3, item.year3_count, 'XDOME', item.id, false)}
                                                                                {quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'XDOME', item.id, false)}
                                                                                {quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'XDOME', item.id, false)}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-xdome/${props.match.params.id}/${getXdomeId()}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {

                                                                                setShowDeleteXdomeConfirmation(true);
                                                                            }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {(Math.floor((item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count)/1000)) < item.number_of_sites && <div className="alert alert-warning d-flex align-items-top" role="alert">
                                                                    <i className='mdi mdi-warning-outline'></i>
                                                                    <div style={{ fontSize: "1em", padding: "0 10px" }}>
                                                                    At least 1 Collection Server is required per site. 
                                                                    You will need {item.number_of_sites} Collection Servers per quote configuration. 
                                                                    Please add an additional {item.number_of_sites - (Math.floor((item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count)/1000))}  Collection Servers 
                                                                    and also add the shipping cost for the same amount.
                                                                    </div>
                                                                </div>}
                                                                {(Math.floor((item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count)))/ item.number_of_sites> 1000 && <div className="alert alert-warning d-flex align-items-top" role="alert">
                                                                    <i className='mdi mdi-warning-outline'></i>
                                                                    <div style={{ fontSize: "1em", padding: "0 10px" }}>
                                                                         1 Collection Server supports upto 1000 assets. 
                                                                         The average number of assets per site is more than 1000. 
                                                                         Please add additional Collection Servers to cover all assets
                                                                         and also add the shipping cost for the same amount
                                                                         
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        })
                                                        }
                                                    </div>
                                                    }
                                                    {products && products.length > 0 && <div className="row g-3" style={{ margin: "30px 0px" }}>

                                                        <div style={{ fontSize: "1.5em", fontWeight: "100" }}>Quote Wide Product Configurations

                                                        </div>


                                                        {products.map((item, key) => {
                                                            console.log(item);
                                                            return <div key={key + '_products_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>


                                                                <div className="flex-table-row" style={{ flex: "1 10%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Site</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "2.0em", color: "#aaaaaa" }}>All Sites</div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 45%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Configuration</div>

                                                                            {item.include_emc && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"emc" + item.id}> CTD EMC
                                                                                <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}> <i style={{ color: "#00cc00" }} className="mdi mdi-laptop"></i>
                                                                                    <span className="badge bg-danger" >
                                                                                        {item.emc_console_count}
                                                                                        <span className="visually-hidden">number of Consoles</span>
                                                                                    </span>
                                                                                </span>
                                                                                {item.include_emc_ha && <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}><i style={{ color: "#00cc00" }} className="mdi mdi-lifebuoy"></i></span>}

                                                                                <ReactTooltip type="light" border={true} id={"emc" + item.id} place="top" effect="solid" className="sidenav-tooltip" style={{ alignContent: "left" }}>
                                                                                    <div style={{ textAlign: "left" }}>
                                                                                        <h6>EMC</h6>
                                                                                        <div>
                                                                                            <div><i style={{ color: "#00cc00" }} className="mdi mdi-laptop"></i> {item.emc_console_count} Consoles</div>
                                                                                            <div><i style={{ color: "#00cc00" }} className="mdi mdi-signal-variant"></i> {item.emc_server_count} Servers</div>
                                                                                            {item.include_emc_ha && <div><i style={{ color: "#00cc00" }} className="mdi mdi-lifebuoy"></i> High Availability</div>}
                                                                                        </div>
                                                                                    </div>
                                                                                </ReactTooltip>
                                                                            </button>}
                                                                            {item.include_ctd_live && <button type="button" className="btn btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"ctd-live" + item.id}> CTD Live
                                                                                <ReactTooltip type="light" border={true} id={"ctd-live" + item.id} place="top" effect="solid" className="sidenav-tooltip">
                                                                                    <div style={{ textAlign: "left" }}><h6>CTD Live</h6></div>

                                                                                </ReactTooltip>
                                                                            </button>}
                                                                            {item.include_sra_sac && <button type="button" className="btn btn-sm btn-dark position-relative" style={{ margin: "2px 3px", height: "50px", fontWeight: "400", fontSize: "0.75em" }} data-tip data-for={"sra-sac" + item.id}> SRA SAC

                                                                                {item.include_sra_sac_ha && <span style={{ marginLeft: "10px", color: "#00cc00", fontSize: "0.85em" }}><i style={{ color: "#00cc00" }} className="mdi mdi-lifebuoy"></i></span>}

                                                                                <ReactTooltip type="light" border={true} id={"sra-sac" + item.id} place="top" effect="solid" className="sidenav-tooltip" style={{ alignContent: "left" }}>
                                                                                    <div style={{ textAlign: "left" }}>
                                                                                        <h6>SRA SAC</h6>
                                                                                        <div>

                                                                                            {item.include_sra_sac_ha && <div><i style={{ color: "#00cc00" }} className="mdi mdi-lifebuoy"></i> High Availability</div>}
                                                                                        </div>
                                                                                    </div>
                                                                                </ReactTooltip>
                                                                            </button>}




                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                            <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                            <ul className="list-group list-group-horizontal">
                                                                                {renderDeployment(1, item.year1_count, 'PRODUCT', item.id, false)}
                                                                                {renderDeployment(2, item.year2_count, 'PRODUCT', item.id, false)}
                                                                                {renderDeployment(3, item.year3_count, 'PRODUCT', item.id, false)}
                                                                                {quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'PRODUCT', item.id, false)}
                                                                                {quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'PRODUCT', item.id, false)}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-products/${props.match.params.id}/${getProductId()}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {

                                                                                setShowDeleteProductConfirmation(true);
                                                                            }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        })
                                                        }
                                                    </div>
                                                    }
                                                    {hardware && hardware.length > 0 && <div className="row g-3" style={{ margin: "30px 0px" }}>

                                                        <div style={{ fontSize: "1.5em", fontWeight: "100" }}>Hardware Configurations

                                                        </div>


                                                        {hardware.map((item, key) => {
                                                            console.log(item);
                                                            return <div key={key + '_hardware_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>



                                                                <div className="flex-table-row" style={{ flex: "1 55%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Part</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "1.0em", }}>{item.part_name}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Units</div>
                                                                            <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}>
                                                                                {item.total_count + item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                            <ul className="list-group list-group-horizontal">
                                                                                {item.split_by !== 'SITE' && renderDeployment(0, item.total_count, 'HW', item.id, true)}
                                                                                {item.split_by === 'SITE' && renderDeployment(1, item.year1_count, 'HW', item.id, true)}
                                                                                {item.split_by === 'SITE' && renderDeployment(2, item.year2_count, 'HW', item.id, true)}
                                                                                {item.split_by === 'SITE' && renderDeployment(3, item.year3_count, 'HW', item.id, true)}
                                                                                {item.split_by === 'SITE' && quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'HW', item.id, true)}
                                                                                {item.split_by === 'SITE' && quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'HW', item.id, true)}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-hardware/${props.match.params.id}/${item.id}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {
                                                                                setActiveHardware(item);
                                                                                setShowDeleteHardwareConfirmation(true);
                                                                            }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        })
                                                        }

                                                    </div>
                                                    }
                                                    {services && services.length > 0 && <div className="row g-3" style={{ margin: "30px 0px" }}>

                                                        <div style={{ fontSize: "1.5em", fontWeight: "100" }}>Services Configurations

                                                        </div>


                                                        {services.map((item, key) => {
                                                            console.log(item);
                                                            return <div key={key + '_services_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>


                                                                <div className="flex-table-row" style={{ flex: "1 10%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Installation Type</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "2.0em", color: "#aaaaaa" }}>{item.service}</div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 45%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Configuration</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "1.0em", }}>{item.part_name}</div>
                                                                            <div style={{ fontWeight: "300", fontSize: "0.65em", color: "#aaaaaa" }}>{item.notes}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                            <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                            <ul className="list-group list-group-horizontal">
                                                                                {renderDeployment(1, item.year1_count, 'SERVICE', item.id, true)}
                                                                                {renderDeployment(2, item.year2_count, 'SERVICE', item.id, true)}
                                                                                {renderDeployment(3, item.year3_count, 'SERVICE', item.id, true)}
                                                                                {quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'SERVICE', item.id, true)}
                                                                                {quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'SERVICE', item.id, true)}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-services/${props.match.params.id}/${item.id}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {
                                                                                setActiveService(item);
                                                                                setShowDeleteServiceConfirmation(true);
                                                                            }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        })
                                                        }
                                                    </div>
                                                    }
                                                    {shipping && shipping.length > 0 && <div className="row g-3" style={{ margin: "30px 0px" }}>

                                                        <div style={{ fontSize: "1.5em", fontWeight: "100" }}>Shipping Configurations

                                                        </div>


                                                        {shipping.map((item, key) => {
                                                            console.log(item);
                                                            return <div key={key + '_shipping_' + item.id} className="flex-table" style={{ border: "1px solid #e5e5e5" }}>


                                                                <div className="flex-table-row" style={{ flex: "1 10%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>City</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "1.0em", color: "#aaaaaa" }}>{item.city}</div>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Country</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "1.0em", color: "#aaaaaa" }}>{item.country}</div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 45%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Incoterm</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "1.0em", }}>{item.shipping_type === 'DAP' ? 'Delivery-at-Place (DAP)' : item.shipping_type === 'DDP' ? 'Delivery Duty & Tax Paid (DDP)' : item.shipping_type === 'DDP+IOR' ? 'Delivery Duty & Tax Paid (DDP) with Importer of Record (IoR)' : ''}</div>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Region</div>
                                                                            <div style={{ fontWeight: "600", fontSize: "1.0em" }}>{item.region}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 8%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Sites</div>
                                                                            <div style={{ fontWeight: "400", fontSize: "2.0em", color: "#333333" }}> {item.year1_count + item.year2_count + item.year3_count + item.year4_count + item.year5_count}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 32%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Deployment</div>
                                                                            <ul className="list-group list-group-horizontal">
                                                                                {renderDeployment(1, item.year1_count, 'SHIP', item.id, true)}
                                                                                {renderDeployment(2, item.year2_count, 'SHIP', item.id, true)}
                                                                                {renderDeployment(3, item.year3_count, 'SHIP', item.id, true)}
                                                                                {quoteHeader.contract_length > 3 && renderDeployment(4, item.year4_count, 'SHIP', item.id, true)}
                                                                                {quoteHeader.contract_length > 4 && renderDeployment(5, item.year5_count, 'SHIP', item.id, true)}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-table-row" style={{ flex: "1 5%" }}>
                                                                    <div className="flex-table-col">
                                                                        <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                                                            <button type="button" className="btn btn-sm btn-outline-primary" style={{ margin: "2px" }} data-tip data-for="edit-site" onClick={() => { props.history.push(`/configure-shipping/${props.match.params.id}/${item.id}/ref`) }}><i className="mdi mdi-square-edit-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="edit-site" place="left" effect="solid" className="sidenav-tooltip">Edit Configuration</ReactTooltip>
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm btn-outline-danger" style={{ margin: "2px" }} onClick={() => {
                                                                                setActiveShipping(item);
                                                                                setShowDeleteShippingConfirmation(true);
                                                                            }} data-tip data-for="delete-site" ><i className="mdi mdi-delete-outline " aria-hidden="true" ></i>
                                                                                <ReactTooltip type="dark" id="delete-site" place="left" effect="solid" className="sidenav-tooltip">Delete Configuration</ReactTooltip>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        })
                                                        }

                                                    </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} disabled={!(sites && sites.length) && !(xdome && xdome.length)  && !(xdomeSites && xdomeSites.length)} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                                        <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/create-ela/' + props.match.params.id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
                {showDeleteSiteConfirmation && <div className='popup'>
                    <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)" }}>

                        <div style={{ fontSize: "1em" }}>

                            <div className='container-fluid' style={{ textAlign: "center" }}>
                                <i style={{ color: "#dc3545" }} className='mdi mdi-48px mdi-delete-circle'></i>
                                <div style={{ fontSize: "1.5em" }}>Are you sure?</div>
                                <div style={{ fontSize: "0.95em" }}>Do you really want to delete the configuration {activeSite.site_description}?</div>
                            </div>
                        </div>

                        <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                            <button type="button" className="btn btn-g btn-danger " style={{ marginLeft: "5px" }} onClick={() => deleteSite()} ><i className='mdi mdi-delete'></i> Delete</button>
                            <button type="button" className="btn btn-g btn-secondary" style={{ marginLeft: "5px" }} onClick={() => setShowDeleteSiteConfirmation(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                            {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                        </div>
                    </div>
                </div>}
                {showDeleteProductConfirmation && <div className='popup'>
                    <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)" }}>

                        <div style={{ fontSize: "1em" }}>

                            <div className='container-fluid' style={{ textAlign: "center" }}>
                                <i style={{ color: "#dc3545" }} className='mdi mdi-48px mdi-delete-circle'></i>
                                <div style={{ fontSize: "1.5em" }}>Are you sure?</div>
                                <div style={{ fontSize: "0.95em" }}>Do you really want to delete Quote Wide Product configuration?</div>
                            </div>
                        </div>

                        <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                            <button type="button" className="btn btn-g btn-danger " style={{ marginLeft: "5px" }} onClick={() => deleteProduct()} ><i className='mdi mdi-delete'></i> Delete</button>
                            <button type="button" className="btn btn-g btn-secondary" style={{ marginLeft: "5px" }} onClick={() => setShowDeleteProductConfirmation(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                            {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                        </div>
                    </div>
                </div>}
                {showDeleteXdomeConfirmation && <div className='popup'>
                    <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)" }}>

                        <div style={{ fontSize: "1em" }}>

                            <div className='container-fluid' style={{ textAlign: "center" }}>
                                <i style={{ color: "#dc3545" }} className='mdi mdi-48px mdi-delete-circle'></i>
                                <div style={{ fontSize: "1.5em" }}>Are you sure?</div>
                                <div style={{ fontSize: "0.95em" }}>Do you really want to delete xDome configuration?</div>
                            </div>
                        </div>

                        <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                            <button type="button" className="btn btn-g btn-danger " style={{ marginLeft: "5px" }} onClick={() => deleteXdome()} ><i className='mdi mdi-delete'></i> Delete</button>
                            <button type="button" className="btn btn-g btn-secondary" style={{ marginLeft: "5px" }} onClick={() => setShowDeleteXdomeConfirmation(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                            {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                        </div>
                    </div>
                </div>}
                {showDeleteServiceConfirmation && <div className='popup'>
                    <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)" }}>

                        <div style={{ fontSize: "1em" }}>

                            <div className='container-fluid' style={{ textAlign: "center" }}>
                                <i style={{ color: "#dc3545" }} className='mdi mdi-48px mdi-delete-circle'></i>
                                <div style={{ fontSize: "1.5em" }}>Are you sure?</div>
                                <div style={{ fontSize: "0.95em" }}>Do you really want to delete Professional Services configuration {activeService.part_name}?</div>
                            </div>
                        </div>

                        <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                            <button type="button" className="btn btn-g btn-danger " style={{ marginLeft: "5px" }} onClick={() => deleteService()} ><i className='mdi mdi-delete'></i> Delete</button>
                            <button type="button" className="btn btn-g btn-secondary" style={{ marginLeft: "5px" }} onClick={() => setShowDeleteServiceConfirmation(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                            {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                        </div>
                    </div>
                </div>}
                {showDeleteShippingConfirmation && <div className='popup'>
                    <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)" }}>

                        <div style={{ fontSize: "1em" }}>

                            <div className='container-fluid' style={{ textAlign: "center" }}>
                                <i style={{ color: "#dc3545" }} className='mdi mdi-48px mdi-delete-circle'></i>
                                <div style={{ fontSize: "1.5em" }}>Are you sure?</div>
                                <div style={{ fontSize: "0.95em" }}>Do you really want to delete Shipping Configuration {activeShipping.region + ' ' + (activeShipping.shipping_type === 'DAP' ? 'Delivery-at-Place (DAP)' : activeShipping.shipping_type === 'DDP' ? 'Delivery Duty & Tax Paid (DDP)' : activeShipping.shipping_type === 'DDP+IOR' ? 'Delivery Duty & Tax Paid (DDP) with Importer of Record (IoR)' : '')}?</div>
                            </div>
                        </div>

                        <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                            <button type="button" className="btn btn-g btn-danger " style={{ marginLeft: "5px" }} onClick={() => deleteShipping()} ><i className='mdi mdi-delete'></i> Delete</button>
                            <button type="button" className="btn btn-g btn-secondary" style={{ marginLeft: "5px" }} onClick={() => setShowDeleteShippingConfirmation(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                            {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                        </div>
                    </div>
                </div>}
                {showDeleteHardwareConfirmation && <div className='popup'>
                    <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)" }}>

                        <div style={{ fontSize: "1em" }}>

                            <div className='container-fluid' style={{ textAlign: "center" }}>
                                <i style={{ color: "#dc3545" }} className='mdi mdi-48px mdi-delete-circle'></i>
                                <div style={{ fontSize: "1.5em" }}>Are you sure?</div>
                                <div style={{ fontSize: "0.95em" }}>Do you really want to delete Hardware Configuration {activeHardware.part_name}?</div>
                            </div>
                        </div>

                        <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                            <button type="button" className="btn btn-g btn-danger " style={{ marginLeft: "5px" }} onClick={() => deleteHardware()} ><i className='mdi mdi-delete'></i> Delete</button>
                            <button type="button" className="btn btn-g btn-secondary" style={{ marginLeft: "5px" }} onClick={() => setShowDeleteHardwareConfirmation(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                            {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                        </div>
                    </div>
                </div>}
            </main>
        </>
    )
}

export default ConfigureEla;


