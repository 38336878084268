import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/bootstrap.min.css';

// localStorage.removeItem('user');
// localStorage.removeItem('token');
// localStorage.removeItem('app');

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
reportWebVitals();
