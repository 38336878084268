import React from 'react';
import Text from './fields/Text';
import SelectField from './fields/Select';


function ModelDetail(props) {

    const data = props.data;
    const errors = props.errors;
    const countries = [
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'United States of America', label: 'United States of America' }
    ];
   
    const templates=props.templates;
    const handleChange = props.handleChange;

    const next=() =>{
        props.next();
    }
    const previous=() =>{
        props.previous();
    }

    return (<div className='container'>
        <div className='row mt-3'>
            <h4>Model Details</h4>
        </div>
        <div className='row mt-3 gx-5 '>
            <div className='col-6' style={{ borderRight: "1px solid #e5e5e5" }}>

                <h4 style={{ fontSize: "1.2rem" }}>Model Details</h4>
                <Text
                    fieldColor="blue"
                    fieldName="step1__meta_model_name"
                    value={data["step1__meta_model_name"] || ""}
                    label="Model Name"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__meta_model_name"]}
                />
                <Text
                    fieldColor="blue"
                    fieldName="step1__meta_customer_name"
                    value={data["step1__meta_customer_name"] || ""}
                    label="Customer Name"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__meta_customer_name"]}
                />
                <Text
                    fieldColor="blue"
                    fieldName="step1__meta_opportunity_number"
                    value={data["step1__meta_opportunity_number"] || ""}
                    label="Opportunity Number"
                    required={false}
                    handleChange={handleChange}
                    error={errors["step1__meta_opportunity_number"]}
                />
                <Text
                    fieldColor="blue"
                    fieldName="step1__meta_owner"
                    value={data["step1__meta_owner"] || ""}
                    label="Owner"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__meta_owner"]}
                />
                <SelectField
                    fieldColor="blue"
                    fieldName="step1__meta_template"
                    value={(data && data["step1__meta_template"] && templates.find(item => item.value === data["step1__meta_template"])) || null}
                    options={templates}
                    label="Template"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__meta_template"]}
                />
                <SelectField
                    fieldColor="blue"
                    fieldName="step1__meta_country"
                    value={(data && data["step1__meta_country"] && countries.find(item => item.value === data["step1__meta_country"])) || null}
                    options={countries}
                    label="Country"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__meta_country"]}
                />

                <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Operations</h4>
                <div className='row'>
                    <div className='col-lg-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__ops_hours_per_day"
                            value={data["step1__ops_hours_per_day"]+""}
                            label="Location Operating Hours Per Day"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__ops_hours_per_day"]}

                            rightMarker="hours"
                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                        />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__ops_days_per_week"
                            value={data["step1__ops_days_per_week"]+""}
                            label="Location Operating Days Per Week"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__ops_days_per_week"]}

                            rightMarker="days"
                            pattern="[0-9]*"
                        />
                    </div>
                </div>

                <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Number of Devices</h4>
                <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_mri"
                            value={data["step1__device_count_mri"]+""}
                            label="MRI"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_mri"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_ct"
                            value={data["step1__device_count_ct"]+""}
                            label="CT Scanner"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_ct"]}

                            pattern="[0-9]*"
                        />
                    </div>

                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_cath"
                            value={data["step1__device_count_cath"]+""}
                            label="Mammography"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_cath"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_la"
                            value={data["step1__device_count_la"]+""}
                            label="C-Arm"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_la"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_xr"
                            value={data["step1__device_count_xr"]+""}
                            label="X-Ray"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_xr"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    {/* <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_ir"
                            value={data["step1__device_count_ir"]+""}
                            label="Interventional Radiology"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_ir"]}

                            pattern="[0-9]*"
                        />
                    </div> */}
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_ip"
                            value={data["step1__device_count_ip"]+""}
                            label="Infusion Pumps"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_ip"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_pm"
                            value={data["step1__device_count_pm"]+""}
                            label="Patient Monitoring"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_pm"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <Text
                            fieldColor="blue"
                            fieldName="step1__device_count_am"
                            value={data["step1__device_count_am"]+""}
                            label="Ultrasound"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__device_count_am"]}

                            pattern="[0-9]*"
                        />
                    </div>
                    
                </div>


            </div>
            <div className='col-6'>
                <h4 style={{ fontSize: "1.2rem" }}>Security</h4>

                
                <Text
                    fieldColor="blue"
                    fieldName="step1__sec_hospital_count"
                    value={data["step1__sec_hospital_count"] +""}
                    label="Total number of Hospitals for sensor deployment"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__sec_hospital_count"]}
                    width="100px"
                    pattern="[0-9]*"
                />
                <Text
                    fieldColor="blue"
                    fieldName="step1__sec_ambulatory_count"
                    value={data["step1__sec_ambulatory_count"] +""}
                    label="Total Number of Ambulatory Care locations for sensor deployment (for Surgery & Imaging Centers)"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__sec_ambulatory_count"]}
                    width="100px"
                    pattern="[0-9]*"
                />
                <Text
                    fieldColor="blue"
                    fieldName="step1__sec_datacenter_count"
                    value={data["step1__sec_datacenter_count"] +""}
                    label="Total number of Data Centers for sensor deployment"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__sec_datacenter_count"]}
                    width="100px"
                    pattern="[0-9]*"
                />
                <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Financials (Annual Values)</h4>
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_patient_gross_revenue"
                    value={data["step1__fin_patient_gross_revenue"]+""}
                    label="Patient Gross Revenue"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_patient_gross_revenue"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_medicare_reimbursements"
                    value={data["step1__fin_medicare_reimbursements"]+""}
                    label="Medicare Reimbursements"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_medicare_reimbursements"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_cyberinsurance_premium"
                    value={data["step1__fin_cyberinsurance_premium"]+""}
                    label="Cyber Insurance Annual Premium"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_cyberinsurance_premium"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_parts_budget"
                    value={data["step1__fin_parts_budget"]+""}
                    label="Average Annual Replacement Parts Budget"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_parts_budget"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_maint_supply_budget"
                    value={data["step1__fin_maint_supply_budget"]+""}
                    label="Average Annual Maintenance Supplies Budget"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_maint_supply_budget"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_time_maint_budget"
                    value={data["step1__fin_time_maint_budget"]+""}
                    label="Average Annual Time & Material Budget"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_time_maint_budget"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <Text
                    fieldColor="orange"
                    fieldName="step1__fin_consulting_fee_budget"
                    value={data["step1__fin_consulting_fee_budget"]+""}
                    label="Average Annual Consulting Fee Budget"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step1__fin_consulting_fee_budget"]}
                    leftMarker="$"

                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                />
                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>
                        <Text
                            fieldColor="orange"
                            fieldName="step1__fin_system_costs_increase"
                            value={data["step1__fin_system_costs_increase"]+""}
                            label="Annual System Costs Increase"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step1__fin_system_costs_increase"]}
                            rightMarker="%"
                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                        />
                    </div>
                    
                </div>
            </div>
            <div className='col-12'>
            {data.step1__device_count_mri > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>MRI Questions</h6>
                    </div>
                    <div className='col-6'>
                        <Text
                            fieldColor="orange"
                            fieldName="step5__avg_procedure_per_hour_mri"
                            value={data["step5__avg_procedure_per_hour_mri"] + ""}
                            label="Average Procedures per hour"
                            required={true}
                            handleChange={handleChange}
                            error={errors["step5__avg_procedure_per_hour_mri"]}
                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                        />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_hour_per_day_mri"
                                    value={data["step5__operating_hour_per_day_mri"] + ""}
                                    label="Operating hour per day"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_hour_per_day_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_day_per_week_mri"
                                    value={data["step5__operating_day_per_week_mri"] + ""}
                                    label="Operating days per week"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_day_per_week_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_revenue_mri"
                                    value={data["step5__avg_procedure_revenue_mri"] + ""}
                                    label="Average procedure revenue"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_revenue_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__optimal_capacity_utilization_mri"
                                    value={data["step5__optimal_capacity_utilization_mri"] + ""}
                                    label="Optimal Capacity Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__optimal_capacity_utilization_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_mri"
                                    value={data["step1__avg_margin_mri"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                  
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_down_time_mri"
                                    value={data["step5__additional_down_time_mri"] + ""}
                                    label="There is additional down-time associated with over-utilization.  
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_down_time_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_maintenance_mri"
                                    value={data["step5__additional_maintenance_mri"] + ""}
                                    label="There is additional maintenance associated with over-utilization. 
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_maintenance_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="blue"
                                    fieldName="step7_number_of_ftes_mri"
                                    value={data["step7_number_of_ftes_mri"] + ""}
                                    label="Number of Technicians (FTE)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_number_of_ftes_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                /> 
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="blue"
                                    fieldName="step7_avg_procedures_per_fte_mri"
                                    value={data["step7_avg_procedures_per_fte_mri"] + ""}
                                    label="Average Hourly Procedures Per Technician"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_avg_procedures_per_fte_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                /> 
                    </div>
                  
                </div>
            }
            {data.step1__device_count_ct > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>CT Scanner Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_per_hour_ct"
                                    value={data["step5__avg_procedure_per_hour_ct"] + ""}
                                    label="Average Procedures per hour"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_per_hour_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_hour_per_day_ct"
                                    value={data["step5__operating_hour_per_day_ct"] + ""}
                                    label="Operating hour per day"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_hour_per_day_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_day_per_week_ct"
                                    value={data["step5__operating_day_per_week_ct"] + ""}
                                    label="Operating days per week"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_day_per_week_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_revenue_ct"
                                    value={data["step5__avg_procedure_revenue_ct"] + ""}
                                    label="Average procedure revenue"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_revenue_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__optimal_capacity_utilization_ct"
                                    value={data["step5__optimal_capacity_utilization_ct"] + ""}
                                    label="Optimal Capacity Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__optimal_capacity_utilization_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_ct"
                                    value={data["step1__avg_margin_ct"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
              
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_down_time_ct"
                                    value={data["step5__additional_down_time_ct"] + ""}
                                    label="There is additional down-time associated with over-utilization.  
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_down_time_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_maintenance_ct"
                                    value={data["step5__additional_maintenance_ct"] + ""}
                                    label="There is additional maintenance associated with over-utilization. 
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_maintenance_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="blue"
                                    fieldName="step7_number_of_ftes_ct"
                                    value={data["step7_number_of_ftes_ct"] + ""}
                                    label="Number of Technicians (FTE)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_number_of_ftes_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                /> 
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="blue"
                                    fieldName="step7_avg_procedures_per_fte_ct"
                                    value={data["step7_avg_procedures_per_fte_ct"] + ""}
                                    label="Average Hourly Procedures Per Technician"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_avg_procedures_per_fte_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                /> 
                    </div>
                </div>
            }
            {data.step1__device_count_cath > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>Mammography Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_per_hour_cath"
                                    value={data["step5__avg_procedure_per_hour_cath"] + ""}
                                    label="Average Procedures per hour"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_per_hour_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_hour_per_day_cath"
                                    value={data["step5__operating_hour_per_day_cath"] + ""}
                                    label="Operating hour per day"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_hour_per_day_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_day_per_week_cath"
                                    value={data["step5__operating_day_per_week_cath"] + ""}
                                    label="Operating days per week"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_day_per_week_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_revenue_cath"
                                    value={data["step5__avg_procedure_revenue_cath"] + ""}
                                    label="Average procedure revenue"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_revenue_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__optimal_capacity_utilization_cath"
                                    value={data["step5__optimal_capacity_utilization_cath"] + ""}
                                    label="Optimal Capacity Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__optimal_capacity_utilization_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_cath"
                                    value={data["step1__avg_margin_cath"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_down_time_cath"
                                    value={data["step5__additional_down_time_cath"] + ""}
                                    label="There is additional down-time associated with over-utilization.  
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_down_time_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_maintenance_cath"
                                    value={data["step5__additional_maintenance_cath"] + ""}
                                    label="There is additional maintenance associated with over-utilization. 
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_maintenance_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                </div>
            }
            {data.step1__device_count_la > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>C-Arm Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_per_hour_la"
                                    value={data["step5__avg_procedure_per_hour_la"] + ""}
                                    label="Average Procedures per hour"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_per_hour_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_hour_per_day_la"
                                    value={data["step5__operating_hour_per_day_la"] + ""}
                                    label="Operating hour per day"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_hour_per_day_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_day_per_week_la"
                                    value={data["step5__operating_day_per_week_la"] + ""}
                                    label="Operating days per week"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_day_per_week_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_revenue_la"
                                    value={data["step5__avg_procedure_revenue_la"] + ""}
                                    label="Average procedure revenue"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_revenue_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__optimal_capacity_utilization_la"
                                    value={data["step5__optimal_capacity_utilization_la"] + ""}
                                    label="Optimal Capacity Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__optimal_capacity_utilization_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
         
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_down_time_la"
                                    value={data["step5__additional_down_time_la"] + ""}
                                    label="There is additional down-time associated with over-utilization.  
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_down_time_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                fieldColor="orange"
                                fieldName="step5__additional_maintenance_la"
                                value={data["step5__additional_maintenance_la"] + ""}
                                label="There is additional maintenance associated with over-utilization. 
                                Estimate that impact as a % of Procedure Revenue reduction for 
                                procedures perfromed above the optimal utilization level."
                                required={true}
                                handleChange={handleChange}
                                error={errors["step5__additional_maintenance_la"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                            />
                    </div>
                </div>
            }
            {/* {data.step1__device_count_ir > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>Interventional Radiology Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_per_hour_ir"
                                    value={data["step5__avg_procedure_per_hour_ir"] + ""}
                                    label="Average Procedures per hour"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_per_hour_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_hour_per_day_ir"
                                    value={data["step5__operating_hour_per_day_ir"] + ""}
                                    label="Operating hour per day"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_hour_per_day_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_day_per_week_ir"
                                    value={data["step5__operating_day_per_week_ir"] + ""}
                                    label="Operating days per week"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_day_per_week_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_revenue_ir"
                                    value={data["step5__avg_procedure_revenue_ir"] + ""}
                                    label="Average procedure revenue"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_revenue_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__optimal_capacity_utilization_ir"
                                    value={data["step5__optimal_capacity_utilization_ir"] + ""}
                                    label="Optimal Capacity Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__optimal_capacity_utilization_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
           
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_down_time_ir"
                                    value={data["step5__additional_down_time_ir"] + ""}
                                    label="There is additional down-time associated with over-utilization.  
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_down_time_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_maintenance_ir"
                                    value={data["step5__additional_maintenance_ir"] + ""}
                                    label="There is additional maintenance associated with over-utilization. 
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_maintenance_ir"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                </div>
            } */}
            {data.step1__device_count_xr > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>X-Ray Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_per_hour_xr"
                                    value={data["step5__avg_procedure_per_hour_xr"] + ""}
                                    label="Average Procedures per hour"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_per_hour_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_hour_per_day_xr"
                                    value={data["step5__operating_hour_per_day_xr"] + ""}
                                    label="Operating hour per day"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_hour_per_day_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__operating_day_per_week_xr"
                                    value={data["step5__operating_day_per_week_xr"] + ""}
                                    label="Operating days per week"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__operating_day_per_week_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__avg_procedure_revenue_xr"
                                    value={data["step5__avg_procedure_revenue_xr"] + ""}
                                    label="Average procedure revenue"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__avg_procedure_revenue_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__optimal_capacity_utilization_xr"
                                    value={data["step5__optimal_capacity_utilization_xr"] + ""}
                                    label="Optimal Capacity Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__optimal_capacity_utilization_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_xr"
                                    value={data["step1__avg_margin_xr"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
              
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_down_time_xr"
                                    value={data["step5__additional_down_time_xr"] + ""}
                                    label="There is additional down-time associated with over-utilization.  
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_down_time_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step5__additional_maintenance_xr"
                                    value={data["step5__additional_maintenance_xr"] + ""}
                                    label="There is additional maintenance associated with over-utilization. 
                                    Estimate that impact as a % of Procedure Revenue reduction for 
                                    procedures perfromed above the optimal utilization level."
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__additional_maintenance_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="blue"
                                    fieldName="step7_number_of_ftes_xr"
                                    value={data["step7_number_of_ftes_xr"] + ""}
                                    label="Number of Technicians (FTE)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_number_of_ftes_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                /> 
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="blue"
                                    fieldName="step7_avg_procedures_per_fte_xr"
                                    value={data["step7_avg_procedures_per_fte_xr"] + ""}
                                    label="Average Hourly Procedures Per Technician"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_avg_procedures_per_fte_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                /> 
                    </div>
                </div>
            }
            {data.step1__device_count_ip > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>Infusion Pump Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__usage_hours_ip"
                                    value={data["step3__usage_hours_ip"] + ""}
                                    label="Average Hours of Usage (Weekly)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__usage_hours_ip"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__optimal_utilization_ip"
                                    value={data["step3__optimal_utilization_ip"] + ""}
                                    label="Optimal Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__optimal_utilization_ip"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    {/* <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_ip"
                                    value={data["step1__avg_margin_ip"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_ip"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div> */}
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__avg_device_cost_ip"
                                    value={data["step3__avg_device_cost_ip"] + ""}
                                    label="Average Purchase Cost Per Device"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__avg_device_cost_ip"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__avg_device_maintenance_ip"
                                    value={data["step3__avg_device_maintenance_ip"] + ""}
                                    label="Annual Maintenance Cost as a Percentage
                                of Purchase Cost"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__avg_device_maintenance_ip"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                </div>
            }
            {data.step1__device_count_pm > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>Patient Monitoring Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__usage_hours_pm"
                                    value={data["step3__usage_hours_pm"] + ""}
                                    label="Average Hours of Usage (Weekly)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__usage_hours_pm"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__optimal_utilization_pm"
                                    value={data["step3__optimal_utilization_pm"] + ""}
                                    label="Optimal Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__optimal_utilization_pm"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    {/* <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_pm"
                                    value={data["step1__avg_margin_pm"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_pm"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div> */}
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__avg_device_cost_pm"
                                    value={data["step3__avg_device_cost_pm"] + ""}
                                    label="Average Purchase Cost Per Device"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__avg_device_cost_pm"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__avg_device_maintenance_pm"
                                    value={data["step3__avg_device_maintenance_pm"] + ""}
                                    label="Annual Maintenance Cost as a Percentage
                                of Purchase Cost"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__avg_device_maintenance_pm"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                </div>
            }
            {data.step1__device_count_am > 0 && 
                <div className='row mt-3 gx-5 ' style={{border:"1px solid #e5e5e5", padding:"10px 0 20px 0", background:"#ffffff", borderRadius:"5px"}}>
                    <div className='col-12'>
                        <h6 style={{ fontSize: "1.0rem" }}>Ultrasound Questions</h6>
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__usage_hours_am"
                                    value={data["step3__usage_hours_am"] + ""}
                                    label="Average Hours of Usage (Weekly)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__usage_hours_am"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__optimal_utilization_am"
                                    value={data["step3__optimal_utilization_am"] + ""}
                                    label="Optimal Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__optimal_utilization_am"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                    {/* <div className='col-3'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step1__avg_margin_am"
                                    value={data["step1__avg_margin_am"] + ""}
                                    label="Average Margin per procedure"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step1__avg_margin_am"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div> */}
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__avg_device_cost_am"
                                    value={data["step3__avg_device_cost_am"] + ""}
                                    label="Average Purchase Cost Per Device"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__avg_device_cost_am"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    leftMarker="$"
                                />
                    </div>
                    <div className='col-6'>
                    <Text
                                    fieldColor="orange"
                                    fieldName="step3__avg_device_maintenance_am"
                                    value={data["step3__avg_device_maintenance_am"] + ""}
                                    label="Annual Maintenance Cost as a Percentage
                                of Purchase Cost"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step3__avg_device_maintenance_am"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                    </div>
                </div>
            }
            </div>
        </div>
        <div className='mt-3'>
            <div className="clearfix">
                <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
            </div>
        </div>
    </div>)
}

export default ModelDetail;