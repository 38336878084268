import { get, post } from "./Service";
import axios from "axios";
import {saveAs} from "file-saver";
import {startLoading, stopLoading} from '../Components/Util'

export const getModelsDashboard = (payload, onDone) => {
    post("/api/get-model-dashboard", payload, (res) => {
        onDone(res);
    });
};

export const createModel = (payload, onDone) => {
    post("/api/create-model", payload, (res) => {
        onDone(res);
    });
};

export const getModel = (model_id, onDone) => {
    get("/api/get-model/" + model_id, (res) => {
        onDone(res);
    });
};

export const getShowBackData = (model_id, onDone) => {
    get("/api/get-show-back-data/" + model_id, (res) => {
        onDone(res);
    });
};

export const downloadPPTX = (model_id,  onDone) => {
    startLoading();
    axios({
        url: encodeURI("/api/get-model-pptx/"+model_id),
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        } 
      }).then((response) => {
        saveAs(response.data, "bva_"+model_id+".pptx");
        stopLoading();
      });
};

export const getSharedModelUsers = (model_id, onDone) => {
    get("/api/shared-model-users/" + model_id, (res) => {
        onDone(res);
    });
};

export const upsertSharedModelUsers = (modelId, payload, onDone) => {
    post("/api/shared-model-users/" + modelId, payload, (res) => {
        onDone(res);
    });
};

export const getRefData = (onDone) => {
    get("/api/get-ref-data", (res) => {
        onDone(res);
    });
};

export const getSocRefData = (gId,vId,aId,onDone) => {
    get("/api/get-soc-ref-data/" + gId +"/" + vId+"/" + aId, (res) => {
        onDone(res);
    });
};
