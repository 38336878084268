import React, { useState } from 'react';
import { useAuthDispatch, useAuthState } from '../../Context';
import * as service  from '../../services';


function Header(props) {
    const [showAppDrawer, setShowAppDrawer] = useState(false);
    const userDetails = useAuthState()
    const dispatch = useAuthDispatch()

    const logOut = () => {
        service.addActivity('PLAT', 'LOGOUT', 'NONE');
        dispatch({ type: 'LOGOUT' });
        props.history.push('/login');
    }

    const setRunningApp = (appName) => {
        localStorage.setItem('app', JSON.stringify(appName));
        dispatch({ type: 'SET_APP', payload: appName });
        service.addActivity('PLAT', 'OPEN APP', appName);
        if (appName === 'BVA')
            props.history.push("/bva-home");
        if (appName === 'BVA HC')
            props.history.push("/bvahc-home");
        if (appName === 'ELA')
            props.history.push("/ela-home");
    }

    let { user, userType } = userDetails.user;
    if (!userType) {
        userType = {};
    }
    const { app } = userDetails;

    return (
        <header id="page-topbar">
            <nav className="navbar navbar-expand-lg p-0">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <div className="header-logo" />

                    <div className="collapse navbar-collapse pull-right">

                        <ul id="upper-menu" className="navbar-nav ml-auto ml-5 justify-content-right">
                            {app === 'BVA' && <span style={{ fontWeight: "100", fontSize: "1.4rem" }}>Business Value Assessment (Industrial) <span style={{ fontWeight: "300", fontSize: "0.5em", color: "#cccccc", paddingLeft: "10px" }}> v1.0.1</span></span>}
                            {app === 'BVA HC' && <span style={{ fontWeight: "100", fontSize: "1.4rem" }}>Business Value Assessment (Healthcare) <span style={{ fontWeight: "300", fontSize: "0.5em", color: "#cccccc", paddingLeft: "10px" }}> v0.0.1</span></span>}
                            {app === 'ELA' && <span style={{ fontWeight: "100", fontSize: "1.4rem" }}>Enterprise Licensing Agreement<span style={{ fontWeight: "300", fontSize: "0.5em", color: "#cccccc", paddingLeft: "10px" }}> v0.0.1</span></span>}
                            {userType.isMultiApp && <li >
                                <span onClick={() => setShowAppDrawer(!showAppDrawer)} style={{ cursor: "pointer" }}><i className="mdi mdi-view-grid"></i></span>
                                {showAppDrawer === true && <div>
                                    <div className="drawer" style={{ position: "absolute", top: "30px", right: "0", height: (userType.appCount > 6 ? "300px" : (userType.appCount > 2 ? '260px' : "130px")) }}>
                                        <ul className="menu">
                                            {userType.isBvaUser && <li className="menu-icon" onClick={() => setRunningApp('BVA')} style={{ display: "inline-block" }}>
                                                <div className="app-icon">
                                                    <span><i className="mdi mdi-48px mdi-cube-outline" aria-hidden="true" style={{ color: '#E217B7' }}></i></span>
                                                </div>
                                                <div className="dr-app-name">Business Value Assessment (Industrial)</div>
                                            </li>}
                                            {userType.isBvaHcUser && <li className="menu-icon" onClick={() => setRunningApp('BVA HC')} style={{ display: "inline-block" }}>
                                                <div className="app-icon">
                                                    <span><i className="mdi mdi-48px mdi-medical-bag" aria-hidden="true" style={{ color: '#E217B7' }}></i></span>
                                                </div>
                                                <div className="dr-app-name">Business Value Assessment (Healthcare)</div>
                                            </li>}
                                            {userType.isElaUser && <li className="menu-icon" onClick={() => setRunningApp('ELA')} style={{ display: "inline-block" }}>
                                                <div className="app-icon">
                                                    <span><i className="mdi mdi-48px mdi-license" aria-hidden="true" style={{ color: '#FA0000' }}></i></span>
                                                </div>
                                                <div className="dr-app-name">Enterprise Licensing Agreement</div>
                                            </li>}
                                            
                                        </ul>
                                    </div>
                                </div>}
                            </li>}
                            <li>
                                <span style={{ fontWeight: "300" }}>{user.firstName + ' ' + user.lastName}</span>
                            </li>
                            <li>
                                <span onClick={() => logOut()} ><i className="mdi mdi-logout"></i></span>
                            </li>

                        </ul>
                    </div>
                    {/* <a href="javascript:void(0)" className="sh search-trigger" id="OpenSearch"><i className="icon-search"></i></a> */}
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light second-nav">
                <div className="fluid">
                    <div className="menu-main-menu-container collapse navbar-collapse second-nav" id="navbarNavDropdown" style={{ fontWeight: "300" }}>
                        <ul id="main-menu" className="menu navbar-nav ml-auto ml-5 justify-content-right lower">
                            {userType.isBvaUser && app === 'BVA' && <li><span onClick={() => {
                                service.addActivity('BVA', 'OPEN PAGE', "HOME");
                                props.history.push('/bva-home');
                                }} style={{ cursor: "pointer" }}><i className="mdi mdi-cube-outline"></i> Models</span></li>}
                            {userType.isBvaHcUser && app === 'BVA HC' && <li><span onClick={() => {
                                service.addActivity('BVA HC', 'OPEN PAGE', "HOME");
                                props.history.push('/bvahc-home');
                                }} style={{ cursor: "pointer" }}><i className="mdi mdi-cube-outline"></i> Models</span></li>}
                            {userType.isElaUser && app === 'ELA' && <li><span onClick={() => {
                                service.addActivity('ELA', 'OPEN PAGE', "HOME");
                                props.history.push('/ela-home');
                                }} style={{ cursor: "pointer" }}><i className="mdi mdi-license"></i> ELA Quotes</span></li>}
                            {userType.isElaDDUser && app === 'ELA' && <li><span onClick={() =>{ 
                                service.addActivity('ELA', 'OPEN PAGE', "APPROVAL");
                                props.history.push('/ela-approvals'); 
                                }} style={{ cursor: "pointer" }}><i className="mdi mdi-check-decagram"></i> ELA Approvals</span></li>}
                            {(userType.isBvaSuperUser || userType.isElaAdmin || userType.isBvaHcAdmin) && <li><span onClick={() => { 
                                service.addActivity('PLAT', 'OPEN PAGE', "ADMIN");
                                props.history.push('/admin'); 
                            }} style={{ cursor: "pointer" }}><i className="mdi mdi-cog-outline"></i> Admin</span></li>}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;


