import React, { useState } from 'react';
import * as service from "../services";
import { startLoading, stopLoading, isEmpty } from '../Components/Util.js';

function Login(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "centered-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "centered-body";

    const [username, setUserName] = useState('')
    const [errors, setError] = useState({})
    const [succMessage, setMessage] = useState();
    const [warning, setWarning] = useState();

    const validate = () => {
        let errors = {}
        if (!username || username.length <= 0) errors.username = 'Please enter Email address.';
        setError(errors)
        return isEmpty(errors)
    }

    const handleSubmit = () => {
        if (validate()) {
            startLoading();
            service.forgotPassword({ email: username }, res => {
                stopLoading();
                if (res.status === 'SUCCESS') setMessage(res.msg)
                if (res.status === 'WARNING') setWarning(res.msg)
            });
        }
    }

    return (
        <div className="text-center auto-height">
            <main className="form-signin auto-height">
                <form className="auto-height">
                    <div className="brand-logo"></div>
                    {/* <h1 className="h3 mb-3 fw-normal" style={{ fontSize: "1.1rem" }}>Business Value Assessment</h1> */}
                    {!succMessage && !warning && <>
                        <div className="form-floating">
                            <input type="email" id="floatingInput" className="form-control" placeholder="Email address" value={username || ''} onChange={(e) => { setUserName(e.target.value) }} required autoFocus />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        {errors.username && <p className="help is-danger">{errors.username}</p>}
                        <div className="mt-2 col-md-12 row">
                            <div className="col-md-6 ">
                                <button className="w-100 btn-secondary  btn btn-lg" style={{ color: "#ffffff" }} type="button" onClick={() => props.history.push('/login')}>Cancel</button>
                            </div>
                            <div className="col-md-6 ">
                                <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => handleSubmit()}>Submit</button>
                            </div>
                        </div>
                    </>}

                    {succMessage &&
                        <>
                            {succMessage && <p className="help is-danger"><i className="mdi mdi-check" style={{ color: 'green' }}></i> {succMessage}</p>}
                            <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => props.history.push('/login')}>OK</button>
                        </>
                    }
                    {warning &&
                        <>
                            {warning && <p className="help is-danger"><i className="mdi mdi-account-lock-outline" style={{ color: 'red' }}></i> {warning}</p>}
                            <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => props.history.push('/login')}>OK</button>
                        </>
                    }
                    <p className="mt-5 mb-3 text-muted">Claroty &copy; 2022</p>
                </form>
            </main>
        </div>
    )
}

export default Login;
