import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from '../Context';

const AppRoutes = ({ path, component: Component, isPrivate, ...rest }) => {
    const userDetails = useAuthState()
    return (
        <Route path={path}
            render={props =>
                isPrivate && !Boolean(userDetails.user) ? <Redirect to={{ pathname: "/login" }} /> : <Component {...props} />
            }
            {...rest}
        />
    )
}

export default AppRoutes