import React from 'react';
import { formatCurrency } from '../../Components/Util';

function RefData(props) {
    return <div><h5>GEOGRAPHY</h5><table className="table">
        <colgroup>
            <col width="10%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
        </colgroup>
        <thead>
            <tr>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} rowSpan="2">COUNTRY</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="5">GENERAL SECURITY LANDSCAPE</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >INCIDENTS</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="3">ROOT CAUSE</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="4">ORGANIZATIONAL COST</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="2">PER CAPITA COST</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="2">MTTI/MTTC	</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} rowSpan="2">PER CAPITA COST</th>
            </tr>
            <tr>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Security Automation Fully Delpoyed</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Security Automation Partially Delpoyed</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Security Automation Not Delpoyed</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Average Data Breach Probability</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >2018 Data Breach Probability</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Average Number Of Breached Record</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Malicious or Criminal Attack</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >System Glitch</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Human Error </th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Detection &amp; Escalation</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Notification Cost</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Post data breach response</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Lost Business</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Direct Per Capita Cost</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Indirect Per Capita Cost</th>

                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >MTTI (days)</th>
                <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >MTTC (days)</th>
            </tr>
            {props.referenceData && props.referenceData.geography && props.referenceData.geography.length > 0 && props.referenceData.geography.map(item => {
                return <tr key={item.factor_name} style={{ background: item.factor_name === props.recipe.geography_name ? "#D4E687" : "" }}>
                    <td style={{ textAlign: "right" }} >{item.factor_name}</td>
                    <td style={{ textAlign: "right" }}  >{formatCurrency(item["Security Automation Fully Delpoyed (%)"], 0)}%</td>
                    <td style={{ textAlign: "right" }}  >{formatCurrency(item["Security Automation Partially Delpoyed (%)"], 0)}%</td>
                    <td style={{ textAlign: "right" }}  >{formatCurrency(item["Security Automation Not Delpoyed (%)"], 0)}%</td>
                    <td style={{ textAlign: "right" }}  >{formatCurrency(item["AVG Data Breach Probability"], 3)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["2018 Data Breach Probability"], 3)}</td>

                    <td style={{ textAlign: "right" }} >{formatCurrency(item["AVG NUMBER OF  BREACHED RECORD (#)"], 0)}</td>

                    <td style={{ textAlign: "right" }} >{formatCurrency(item["Malicious or Criminal Attack (%)"], 0)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["System Glitch (%)"], 0)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["Human Error (%)"], 0)}%</td>

                    <td style={{ textAlign: "right" }} >{formatCurrency(item["Detection & Escalation (%)"] * 100, 2)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["Notification Cost (%)"] * 100, 2)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["Post data breach response (%)"] * 100, 2)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["Lost Business (%)"] * 100, 2)}%</td>

                    <td style={{ textAlign: "right" }} >{formatCurrency(item["DIRECT PER CAPITA COST (%)"] * 100, 2)}%</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["INDIRECT PER CAPITA COST (%)"] * 100, 2)}%</td>

                    <td style={{ textAlign: "right" }} >{formatCurrency(item["MTTI (days)"], 0)}</td>
                    <td style={{ textAlign: "right" }} >{formatCurrency(item["MTTC (days)"], 0)}</td>

                    <td style={{ textAlign: "right" }} >${formatCurrency(item["PER CAPITA COST ($)"], 0)}</td>
                </tr>
            })}
        </thead>
        <tbody>

        </tbody>
    </table>
        <div className="row g-12 mt-3">
            <div className="col-6">
                <h5>VERTICAL</h5><table className="table">
                    <colgroup>
                        <col width="20%" />
                        <col width="13%" />
                        <col width="13%" />
                        <col width="13%" />
                        <col width="13%" />
                        <col width="13%" />
                        <col width="14%" />
                    </colgroup>
                    <thead>

                        <tr>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Vertical</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Frequency</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Chance Of Breach</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Abnormal Churn Rate</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >MTTI (days)</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >MTTC (days)</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="2">PER CAPITA COST</th>


                        </tr>
                        {props.referenceData && props.referenceData.vertical && props.referenceData.vertical.length > 0 && props.referenceData.vertical.map(item => {
                            return <tr key={item.factor_name} style={{ background: item.factor_name === props.recipe.vertical_name ? "#D4E687" : "" }}>
                                <td style={{ textAlign: "right" }} >{item.factor_name}</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["FREQUENCY"], 0)}</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["Chance of Breach"] * 100, 0)}%</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["ABNORMAL CHURN RATE (%)"], 1)}%</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["MTTI (days)"], 0)}</td>
                                <td style={{ textAlign: "right" }} >{formatCurrency(item["MTTC (days)"], 0)}</td>
                                <td style={{ textAlign: "right" }} >${formatCurrency(item["PER CAPITA COST"], 0)}</td>

                            </tr>
                        })}
                    </thead>
                    <tbody>

                    </tbody>
                </table>
                <h5>ROOT CAUSE</h5><table className="table">
                    <colgroup>
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                    </colgroup>
                    <thead>

                        <tr>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Root Cause</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Average Distribution</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >MTTI (days)</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >MTTC (days)</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} colSpan="2">PER CAPITA COST</th>


                        </tr>
                        {props.referenceData && props.referenceData.rootcause && props.referenceData.rootcause.length > 0 && props.referenceData.rootcause.map(item => {
                            return <tr key={item.factor_name} style={{ background: item.factor_name === props.recipe.vertical_name ? "#D4E687" : "" }}>
                                <td style={{ textAlign: "right" }} >{item.factor_name}</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["AVG Distribution (%)"], 0)}%</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["MTTI (days)"], 0)}</td>
                                <td style={{ textAlign: "right" }} >{formatCurrency(item["MTTC (days)"], 0)}</td>
                                <td style={{ textAlign: "right" }} >${formatCurrency(item["Per Capita Cost ($)"], 0)}</td>

                            </tr>
                        })}
                    </thead>
                    <tbody>

                    </tbody>
                </table>
                <h5>SECURITY AUTOMATION</h5><table className="table">
                    <colgroup>
                        <col width="40%" />
                        <col width="30%" />
                        <col width="30%" />
                    </colgroup>
                    <thead>

                        <tr>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Automation Level</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Average Cost of Breach</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }} >Orgs (%)</th>


                        </tr>
                        {props.referenceData && props.referenceData.maturity && props.referenceData.maturity.length > 0 && props.referenceData.maturity.map(item => {
                            return <tr key={item.factor_name} style={{ background: item.factor_name === props.recipe.automation_stage_name ? "#D4E687" : "" }}>
                                <td style={{ textAlign: "right" }} >{item.factor_name}</td>
                                <td style={{ textAlign: "right" }}  >${formatCurrency(item["Avg Cost of Breach ($)"], 0)}</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["Orgs (%)"] * 100, 0)}</td>

                            </tr>
                        })}
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
            <div className="col-6">
            <h5>FACTORS</h5><table className="table">
                    <colgroup>
                        <col width="40%" />
                        <col width="60%" />
                    </colgroup>
                    <thead>

                        <tr>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Factor</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Per Capita Impact</th>


                        </tr>
                        {props.referenceData && props.referenceData.factors && props.referenceData.factors.length > 0 && props.referenceData.factors.map(item => {
                            return <tr key={item.factor_name} style={{ background: item.factor_name === props.recipe.vertical_name ? "#D4E687" : "" }}>
                                <td style={{ textAlign: "right" }} >{item.factor_name}</td>
                                <td style={{ textAlign: "right" }}  >{formatCurrency(item["factor_value_numeric"], 1)}</td>

                            </tr>
                        })}
                    </thead>
                    <tbody>

                    </tbody>
                </table>

                <h5>SOC PERSONNEL SIZE</h5><table className="table">
                    <colgroup>
                        <col width="40%" />
                        <col width="60%" />
                    </colgroup>
                    <thead>

                        <tr>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >SOC Personnel Size</th>
                            <th style={{ textAlign: "center", border: "1px solid #CCCCCC" }}  >Blended Analyst Cost</th>


                        </tr>
                        {props.referenceData && props.referenceData.size && props.referenceData.size.length > 0 && props.referenceData.size.map(item => {
                            return <tr key={item.factor_name} style={{ background: item.factor_name === props.recipe.vertical_name ? "#D4E687" : "" }}>
                                <td style={{ textAlign: "right" }} >{item.factor_name}</td>
                                <td style={{ textAlign: "right" }}  >${formatCurrency(item["blended_analysis_cost"], 0)}</td>

                            </tr>
                        })}
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
export default RefData;