import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import ProgressArc from '../../../Components/widgets/ProgressArc';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SelectField from './fields/Select';
function UnderUtilization(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    let under_utilization_mri = 0;
    let max_capacity_mri = 0;
    let annual_suboptimal_procedures_mri = 0;
    let annual_capacity_unbilled_mri = 0;
    let annual_capacity_unbilled_fulfilled_mri = 0;
    let annual_revenue_unbilled_fulfilled_mri = 0;
    let gross_saving_mri = 0;
    let revenue_recovered_mri = 0;

    if (parseFloat(data.step1__device_count_mri) > 0) {
        under_utilization_mri = parseFloat(data.step5__utilization_before_mri) < parseFloat(data.step5__optimal_capacity_utilization_mri)
            && parseFloat(data.step5__utilization_before_mri) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_mri) - parseFloat(data.step5__utilization_before_mri)
            : 0;

        max_capacity_mri = parseFloat(data.step1__device_count_mri)
            * parseFloat(data.step5__avg_procedure_per_hour_mri)
            * parseFloat(data.step5__operating_hour_per_day_mri)
            * parseFloat(data.step5__operating_day_per_week_mri)
            * 52;
        
        annual_suboptimal_procedures_mri = Math.round((parseFloat(data.step5__utilization_before_mri) > parseFloat(data.step5__optimal_capacity_utilization_mri) ?
            (max_capacity_mri * parseFloat(data.step5__utilization_before_mri)) - (max_capacity_mri * parseFloat(data.step5__optimal_capacity_utilization_mri))
            : (max_capacity_mri * parseFloat(data.step5__optimal_capacity_utilization_mri)) - (max_capacity_mri * parseFloat(data.step5__utilization_before_mri))) / 100);
        annual_capacity_unbilled_mri = under_utilization_mri > 0 ? annual_suboptimal_procedures_mri : 0;
        annual_capacity_unbilled_fulfilled_mri = Math.round(annual_capacity_unbilled_mri * parseFloat(data.step6_expected_unbilled_capacity_mri) / 100);
        annual_revenue_unbilled_fulfilled_mri = annual_capacity_unbilled_fulfilled_mri * parseFloat(data.step5__avg_procedure_revenue_mri);
        revenue_recovered_mri = annual_revenue_unbilled_fulfilled_mri;
        gross_saving_mri = revenue_recovered_mri * parseFloat(data.step1__avg_margin_mri)/100;
    }
    let under_utilization_ct = 0;
    let max_capacity_ct = 0;
    let annual_suboptimal_procedures_ct = 0;
    let annual_capacity_unbilled_ct = 0;
    let annual_capacity_unbilled_fulfilled_ct = 0;
    let annual_revenue_unbilled_fulfilled_ct = 0;
    let gross_saving_ct = 0;
    let revenue_recovered_ct = 0;

    if (parseFloat(data.step1__device_count_ct) > 0) {
        under_utilization_ct = parseFloat(data.step5__utilization_before_ct) < parseFloat(data.step5__optimal_capacity_utilization_ct)
            && parseFloat(data.step5__utilization_before_ct) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_ct) - parseFloat(data.step5__utilization_before_ct)
            : 0;

        max_capacity_ct = parseFloat(data.step1__device_count_ct)
            * parseFloat(data.step5__avg_procedure_per_hour_ct)
            * parseFloat(data.step5__operating_hour_per_day_ct)
            * parseFloat(data.step5__operating_day_per_week_ct)
            * 52;
        console.log(max_capacity_ct);
        annual_suboptimal_procedures_ct = Math.round((parseFloat(data.step5__utilization_before_ct) > parseFloat(data.step5__optimal_capacity_utilization_ct) ?
            (max_capacity_ct * parseFloat(data.step5__utilization_before_ct)) - (max_capacity_ct * parseFloat(data.step5__optimal_capacity_utilization_ct))
            : (max_capacity_ct * parseFloat(data.step5__optimal_capacity_utilization_ct)) - (max_capacity_ct * parseFloat(data.step5__utilization_before_ct))) / 100);
        annual_capacity_unbilled_ct = under_utilization_ct > 0 ? annual_suboptimal_procedures_ct : 0;
        annual_capacity_unbilled_fulfilled_ct = Math.round(annual_capacity_unbilled_ct * parseFloat(data.step6_expected_unbilled_capacity_ct) / 100);
        annual_revenue_unbilled_fulfilled_ct = annual_capacity_unbilled_fulfilled_ct * parseFloat(data.step5__avg_procedure_revenue_ct);
        revenue_recovered_ct = annual_revenue_unbilled_fulfilled_ct;
        gross_saving_ct = revenue_recovered_ct * parseFloat(data.step1__avg_margin_ct)/100;
    }
    let under_utilization_cath = 0;
    let max_capacity_cath = 0;
    let annual_suboptimal_procedures_cath = 0;
    let annual_capacity_unbilled_cath = 0;
    let annual_capacity_unbilled_fulfilled_cath = 0;
    let annual_revenue_unbilled_fulfilled_cath = 0;
    let gross_saving_cath = 0;
    let revenue_recovered_cath = 0;

    if (parseFloat(data.step1__device_count_cath) > 0) {
        under_utilization_cath = parseFloat(data.step5__utilization_before_cath) < parseFloat(data.step5__optimal_capacity_utilization_cath)
            && parseFloat(data.step5__utilization_before_cath) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_cath) - parseFloat(data.step5__utilization_before_cath)
            : 0;

        max_capacity_cath = parseFloat(data.step1__device_count_cath)
            * parseFloat(data.step5__avg_procedure_per_hour_cath)
            * parseFloat(data.step5__operating_hour_per_day_cath)
            * parseFloat(data.step5__operating_day_per_week_cath)
            * 52;
        console.log(max_capacity_cath);
        annual_suboptimal_procedures_cath = Math.round((parseFloat(data.step5__utilization_before_cath) > parseFloat(data.step5__optimal_capacity_utilization_cath) ?
            (max_capacity_cath * parseFloat(data.step5__utilization_before_cath)) - (max_capacity_cath * parseFloat(data.step5__optimal_capacity_utilization_cath))
            : (max_capacity_cath * parseFloat(data.step5__optimal_capacity_utilization_cath)) - (max_capacity_cath * parseFloat(data.step5__utilization_before_cath))) / 100);
        annual_capacity_unbilled_cath = under_utilization_cath > 0 ? annual_suboptimal_procedures_cath : 0;
        annual_capacity_unbilled_fulfilled_cath = Math.round(annual_capacity_unbilled_cath * parseFloat(data.step6_expected_unbilled_capacity_cath) / 100);
        annual_revenue_unbilled_fulfilled_cath = annual_capacity_unbilled_fulfilled_cath * parseFloat(data.step5__avg_procedure_revenue_cath);
        revenue_recovered_cath = annual_revenue_unbilled_fulfilled_cath;
        gross_saving_cath = revenue_recovered_cath * parseFloat(data.step1__avg_margin_cath)/100;
    }
    let under_utilization_la = 0;
    let max_capacity_la = 0;
    let annual_suboptimal_procedures_la = 0;
    let annual_capacity_unbilled_la = 0;
    let annual_capacity_unbilled_fulfilled_la = 0;
    let annual_revenue_unbilled_fulfilled_la = 0;
    let gross_saving_la = 0;
    let revenue_recovered_la = 0;

    if (parseFloat(data.step1__device_count_la) > 0) {
        under_utilization_la = parseFloat(data.step5__utilization_before_la) < parseFloat(data.step5__optimal_capacity_utilization_la)
            && parseFloat(data.step5__utilization_before_la) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_la) - parseFloat(data.step5__utilization_before_la)
            : 0;

        max_capacity_la = parseFloat(data.step1__device_count_la)
            * parseFloat(data.step5__avg_procedure_per_hour_la)
            * parseFloat(data.step5__operating_hour_per_day_la)
            * parseFloat(data.step5__operating_day_per_week_la)
            * 52;
        console.log(max_capacity_la);
        annual_suboptimal_procedures_la = Math.round((parseFloat(data.step5__utilization_before_la) > parseFloat(data.step5__optimal_capacity_utilization_la) ?
            (max_capacity_la * parseFloat(data.step5__utilization_before_la)) - (max_capacity_la * parseFloat(data.step5__optimal_capacity_utilization_la))
            : (max_capacity_la * parseFloat(data.step5__optimal_capacity_utilization_la)) - (max_capacity_la * parseFloat(data.step5__utilization_before_la))) / 100);
        annual_capacity_unbilled_la = under_utilization_la > 0 ? annual_suboptimal_procedures_la : 0;
        annual_capacity_unbilled_fulfilled_la = Math.round(annual_capacity_unbilled_la * parseFloat(data.step6_expected_unbilled_capacity_la) / 100);
        annual_revenue_unbilled_fulfilled_la = annual_capacity_unbilled_fulfilled_la * parseFloat(data.step5__avg_procedure_revenue_la);
        revenue_recovered_la = annual_revenue_unbilled_fulfilled_la;
        gross_saving_la = revenue_recovered_la * parseFloat(data.step1__avg_margin_la)/100;
    }

    let under_utilization_xr = 0;
    let max_capacity_xr = 0;
    let annual_suboptimal_procedures_xr = 0;
    let annual_capacity_unbilled_xr = 0;
    let annual_capacity_unbilled_fulfilled_xr = 0;
    let annual_revenue_unbilled_fulfilled_xr = 0;
    let gross_saving_xr = 0;
    let revenue_recovered_xr = 0;

    if (parseFloat(data.step1__device_count_xr) > 0) {
        under_utilization_xr = parseFloat(data.step5__utilization_before_xr) < parseFloat(data.step5__optimal_capacity_utilization_xr)
            && parseFloat(data.step5__utilization_before_xr) > 0 ?
            parseFloat(data.step5__optimal_capacity_utilization_xr) - parseFloat(data.step5__utilization_before_xr)
            : 0;

        max_capacity_xr = parseFloat(data.step1__device_count_xr)
            * parseFloat(data.step5__avg_procedure_per_hour_xr)
            * parseFloat(data.step5__operating_hour_per_day_xr)
            * parseFloat(data.step5__operating_day_per_week_xr)
            * 52;
        console.log(max_capacity_xr);
        annual_suboptimal_procedures_xr = Math.round((parseFloat(data.step5__utilization_before_xr) > parseFloat(data.step5__optimal_capacity_utilization_xr) ?
            (max_capacity_xr * parseFloat(data.step5__utilization_before_xr)) - (max_capacity_xr * parseFloat(data.step5__optimal_capacity_utilization_xr))
            : (max_capacity_xr * parseFloat(data.step5__optimal_capacity_utilization_xr)) - (max_capacity_xr * parseFloat(data.step5__utilization_before_xr))) / 100);
        annual_capacity_unbilled_xr = under_utilization_xr > 0 ? annual_suboptimal_procedures_xr : 0;
        annual_capacity_unbilled_fulfilled_xr = Math.round(annual_capacity_unbilled_xr * parseFloat(data.step6_expected_unbilled_capacity_xr) / 100);
        annual_revenue_unbilled_fulfilled_xr = annual_capacity_unbilled_fulfilled_xr * parseFloat(data.step5__avg_procedure_revenue_xr);
        revenue_recovered_xr = annual_revenue_unbilled_fulfilled_xr;
        gross_saving_xr = revenue_recovered_xr * parseFloat(data.step1__avg_margin_xr)/100;
    }

    const gross_saving = gross_saving_mri + gross_saving_ct + gross_saving_cath + gross_saving_la + gross_saving_xr;

    const next = () => {
        props.next({ step6__total: gross_saving,
            step6__gross_saving_mri: gross_saving_mri,
            step6__gross_saving_ct: gross_saving_ct ,
            step6__gross_saving_cath: gross_saving_cath ,
            step6__gross_saving_la: gross_saving_la ,
            step6__gross_saving_xr: gross_saving_xr  });
    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>

            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Under Utilization</h4>
                </div>
                <div className='col-2' >
                    <SelectField
                        fieldColor="orange"
                        fieldName="step6__mvr"
                        value={(data && data["step6__mvr"] && props.mvrs.find(item => item.value === data["step6__mvr"])) || null}
                        options={props.mvrs}
                        label="Months to Value Realization"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step6__mvr"]}
                    />
                </div>
            </div>
            <div className='row mt-3 gx-5 '>

                <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(gross_saving) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Under Utilization Revenue Recovery</div>
                        <div className='row' >

                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(gross_saving), 0)}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
                <Tabs style={{ width: "100%" }} variant="fullWidth">
                    <TabList>
                        {data.step1__device_count_mri > 0 && <Tab >
                            <div className='row'>

                                <div className='col-12'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MRI <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_mri > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_mri, 0)}</div>
                                </div>

                            </div>
                        </Tab>}
                        {data.step1__device_count_ct > 0 && <Tab >
                            <div className='row'>

                                <div className='col-12'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>CT SCANNER  <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_ct > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_ct, 0)}</div>
                                </div>

                            </div>
                        </Tab>}
                        {data.step1__device_count_cath > 0 && <Tab >
                            <div className='row'>

                                <div className='col-12'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MAMMOGRAPHY <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_cath > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_cath, 0)}</div>
                                </div>

                            </div>
                        </Tab>}
                        {data.step1__device_count_la > 0 && <Tab >
                            <div className='row'>

                                <div className='col-12'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>C-ARM ACCELERATOR <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_la > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_la, 0)}</div>
                                </div>

                            </div>
                        </Tab>}
                        {data.step1__device_count_xr > 0 && <Tab >
                            <div className='row'>

                                <div className='col-12'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>X-RAY RADIOLOGY <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_xr > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_xr, 0)}</div>
                                </div>

                            </div>
                        </Tab>}
                    </TabList>
                    {data.step1__device_count_mri > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                            <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_mri"
                                    value={data["step5__utilization_before_mri"] + ""}
                                    label="Actual Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                {/* <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_mri"
                                    value={data["step5__utilization_after_mri"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> */}
                                <Text
                                    fieldColor="sky"
                                    fieldName="step6_expected_unbilled_capacity_mri"
                                    value={data["step6_expected_unbilled_capacity_mri"] + ""}
                                    label="Expected Percentage of Unbilled Procedure Capacity to
                                    be Fulfilled"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step6_expected_unbilled_capacity_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Expected Percentage of Unbilled Procedure Capacity to be Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step6_expected_unbilled_capacity_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_fulfilled_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Generated By Fulfilling Unbilled Procedures</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_unbilled_fulfilled_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_mri,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_mri, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_ct > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                            <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_ct"
                                    value={data["step5__utilization_before_ct"] + ""}
                                    label="Actual Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                {/* <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_ct"
                                    value={data["step5__utilization_after_ct"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> */}
                                <Text
                                    fieldColor="sky"
                                    fieldName="step6_expected_unbilled_capacity_ct"
                                    value={data["step6_expected_unbilled_capacity_ct"] + ""}
                                    label="Expected Percentage of Unbilled Procedure Capacity to
                                    be Fulfilled"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step6_expected_unbilled_capacity_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Expected Percentage of Unbilled Procedure Capacity to be Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step6_expected_unbilled_capacity_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_fulfilled_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Generated By Fulfilling Unbilled Procedures</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_unbilled_fulfilled_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_ct,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_ct, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_cath > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                            <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_cath"
                                    value={data["step5__utilization_before_cath"] + ""}
                                    label="Actual Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                {/* <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_cath"
                                    value={data["step5__utilization_after_cath"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> */}
                                <Text
                                    fieldColor="sky"
                                    fieldName="step6_expected_unbilled_capacity_cath"
                                    value={data["step6_expected_unbilled_capacity_cath"] + ""}
                                    label="Expected Percentage of Unbilled Procedure Capacity to
                                    be Fulfilled"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step6_expected_unbilled_capacity_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Expected Percentage of Unbilled Procedure Capacity to be Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step6_expected_unbilled_capacity_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_fulfilled_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Generated By Fulfilling Unbilled Procedures</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_unbilled_fulfilled_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_cath,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_cath, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_la > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                            <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_la"
                                    value={data["step5__utilization_before_la"] + ""}
                                    label="Actual Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                {/* <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_la"
                                    value={data["step5__utilization_after_la"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> */}
                                <Text
                                    fieldColor="sky"
                                    fieldName="step6_expected_unbilled_capacity_la"
                                    value={data["step6_expected_unbilled_capacity_la"] + ""}
                                    label="Expected Percentage of Unbilled Procedure Capacity to
                                    be Fulfilled"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step6_expected_unbilled_capacity_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Expected Percentage of Unbilled Procedure Capacity to be Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step6_expected_unbilled_capacity_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_fulfilled_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Generated By Fulfilling Unbilled Procedures</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_unbilled_fulfilled_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_la,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_la, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_xr > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                            <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_before_xr"
                                    value={data["step5__utilization_before_xr"] + ""}
                                    label="Actual Utilization"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_before_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                {/* <Text
                                    fieldColor="sky"
                                    fieldName="step5__utilization_after_xr"
                                    value={data["step5__utilization_after_xr"] + ""}
                                    label="Actual Utilization AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step5__utilization_after_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> */}
                                <Text
                                    fieldColor="sky"
                                    fieldName="step6_expected_unbilled_capacity_xr"
                                    value={data["step6_expected_unbilled_capacity_xr"] + ""}
                                    label="Expected Percentage of Unbilled Procedure Capacity to
                                    be Fulfilled"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step6_expected_unbilled_capacity_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Underutilization Percentage Points</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(under_utilization_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Expected Percentage of Unbilled Procedure Capacity to be Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step6_expected_unbilled_capacity_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Unbilled Procedure Capacity Fulfilled</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_capacity_unbilled_fulfilled_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Generated By Fulfilling Unbilled Procedures</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_unbilled_fulfilled_xr, 2)}</td>
                                            </tr>
                                            <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_xr,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_xr, 2)}</td>
                                        </tr>
                                      

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}

                </Tabs>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default UnderUtilization;