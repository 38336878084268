import { get, post, loginpost } from "./Service";

export const getUsersDashboard = (payload, onDone) => {
    post("/api/get-user-dashboard", payload, (res) => {
        onDone(res);
    });
};

export const upsertUser = (payload, onDone) => {
    post("/api/upsert-user", payload, (res) => {
        onDone(res);
    });
};

export const getUserDetail = (email, onDone) => {
    get("/api/user-detail/"  + email ,(res) => {
        onDone(res);
    });
};

export const sendPassword = (email, onDone) => {
    get("/api/send-password/" + email, (res) => {
        onDone(res);
    });
};

export const updatePassword = (payload, onDone) => {
    post("/api/update-password", payload, (res) => {
        onDone(res);
    });
};

export const forgotPassword = (payload, onDone) => {
    loginpost("/api/forgot-password", payload, (res) => {
        onDone(res);
    });
};
