import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import SelectField from './fields/Select';

function PartsManagement(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    const estimated_reduction_parts = (1-parseFloat(data.step10_estimated_reduction_parts)/100) * parseFloat(data.step1__fin_parts_budget);
    const estimated_reduction_maint_supp= (1-parseFloat(data.step10_estimated_reduction_maint_supp)/100) * parseFloat(data.step1__fin_maint_supply_budget);
    const estimated_reduction_tnm = (1-parseFloat(data.step10_estimated_reduction_tnm)/100) * parseFloat(data.step1__fin_time_maint_budget);
    const estimated_reduction_consulting = (1-parseFloat(data.step10_estimated_reduction_consulting)/100) * parseFloat(data.step1__fin_consulting_fee_budget);
    
    const before_total=parseFloat(data.step1__fin_parts_budget)+parseFloat(data.step1__fin_maint_supply_budget)+parseFloat(data.step1__fin_time_maint_budget)+parseFloat(data.step1__fin_consulting_fee_budget);
    const after_total=estimated_reduction_parts+estimated_reduction_maint_supp+estimated_reduction_tnm+estimated_reduction_consulting;
    const step10__total = before_total-after_total;

    const next = () => {
        props.next({ step10__total: step10__total });

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
            <div className='col-10' >
                <h4>Parts Management</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step10__mvr"
                    value={(data && data["step10__mvr"] && props.mvrs.find(item => item.value === data["step10__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step10__mvr"]}
                />
                </div>
            </div>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step10__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Parts Management Streamlined Maintenance</div>
                        <div className='row' >
                            
                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(step10__total), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-4'>
                                <Text
                                    fieldColor="sky"
                                    fieldName="step10_estimated_reduction_parts"
                                    value={data["step10_estimated_reduction_parts"] + ""}
                                    label="Estimated reduction in Parts"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step10_estimated_reduction_parts"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> 
                                <Text
                                    fieldColor="sky"
                                    fieldName="step10_estimated_reduction_maint_supp"
                                    value={data["step10_estimated_reduction_maint_supp"] + ""}
                                    label="Estimated reduction in Maintenance Supplies"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step10_estimated_reduction_maint_supp"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> 
                                <Text
                                    fieldColor="sky"
                                    fieldName="step10_estimated_reduction_tnm"
                                    value={data["step10_estimated_reduction_tnm"] + ""}
                                    label="Estimated reduction in Time and Material Services"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step10_estimated_reduction_tnm"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> 
                                <Text
                                    fieldColor="sky"
                                    fieldName="step10_estimated_reduction_consulting"
                                    value={data["step10_estimated_reduction_consulting"] + ""}
                                    label="Estimated reduction in Consulting fees"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step10_estimated_reduction_consulting"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                /> 
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Average annual Replacement Parts budget</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_parts_budget, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(estimated_reduction_parts, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average annual Maintenance Supplies budget</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_maint_supply_budget, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(estimated_reduction_maint_supp, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average annual Time and Materials budget</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_time_maint_budget, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(estimated_reduction_tnm, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average annual Consulting Fees budget</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_consulting_fee_budget, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(estimated_reduction_consulting, 2)}</td>
                                        </tr>
                                       
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>               


                </div>

            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default PartsManagement;