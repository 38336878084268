import React from 'react';
import Select from 'react-select';

function SelectField(props) {
    const fieldClass=props.fieldColor+"-field";
    const fieldName=props.fieldName;
    const value=props.value;
    const options=props.options;
    const label=props.label;
    const required=props.required;
    const handleChange=props.handleChange;
    const error=props.error;
    const width=props.width || "100%";
    return (
        <div className={fieldClass+" mt-2"} >
            <label htmlFor={fieldName} className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>{label} {required && <span className="required">*</span>}</label>
            <div style={{width:width}}>
                <Select id={fieldName} 
                    value={value || ''}
                    options={options || []}
                    required={required}
                    onChange={(e) => { handleChange(fieldName, e.value) }}
                     />
            </div>
            {error && <div className="validation-error">{error}</div>}
        </div>
    )
}
export default SelectField;
