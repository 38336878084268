import { get, post } from "./Service";

import axios from "axios";
import {saveAs} from "file-saver";
import {startLoading, stopLoading} from '../Components/Util'

export const getUniqueId = (onDone) => {
    get("/api/get-uuid", (res) => {
        console.log(res);
        onDone(res);
    });
};
export const uploadFile = (file, fileName, guid, contextId, context, onDone) => {
    post(encodeURI("/api/upload-files/" + fileName + "/" + guid + "/" + contextId + "/" + context), file, (res) => {
        onDone(res);
    });
};

export const getUploadedFile = (payload, onDone) => {
    post("/api/get-files", payload, (res) => {
        onDone(res);
    });
};

export const deleteUploadedFile = (payload, onDone) => {
    post("/api/delete-file", payload, (res) => {
        onDone(res);
    });
};

export const serveFilefromS3 = (fileName, filePath, onDone) => {
    startLoading();
    axios({
        url: encodeURI('/api/serve-files/' + fileName + '/' + filePath),
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        } // important
      }).then((response) => {
        
        saveAs(response.data, fileName);
        stopLoading();
      });
};