import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading } from '../../Components/Util';
import Select from 'react-select';
import * as service from "../../services";
import { useAuthState } from "../../Context";
import ReactTooltip from 'react-tooltip';

function Form(props) {
    const userDetails = useAuthState();
    const { user } = userDetails.user;
    console.log(user);
    const [loading, setLoading] = useState(true);
    const [lookup, setLookup] = useState([]);
    const [recipe, setRecipe] = useState({ automation_stage_id: '4', owner_id: user.id + '' });
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState("Create Model");
    const [step, setStep] = useState(1);
    const [stepsStatus, setStepsStatus] = useState([0, 0, 0]);
    const [socRef, setSocRef] = useState(undefined);

    useEffect(() => {
        startLoading();

        let p1 = new Promise((resolve, reject) => {
            service.getLookups(res => {
                setLookup(res)
                resolve("");
            });
        });

        let p2 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getModel(props.match.params.id, res => {
                    setRecipe({ ...res });
                    setTitle(`Edit Model #${props.match.params.id}`);
                    setStepsStatus([1, 1, 1]);
                    resolve("");
                });
            } else {
                resolve("");
            }
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.id]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    useEffect(() => {
        if (step === 3) {
            if (!socRef) {
                service.getSocRefData(recipe['geography_id'], recipe['vertical_id'], recipe['automation_stage_id'], res => {

                    if (res.data) setSocRef(res.data);

                    setRecipe({ ...recipe, size_range_id: 3 });
                    const num_so_stolen_records = res.data && res.data.find(item => item.factor_name === 'G52').factor_value_numeric;
                    const cost_so_legal_per_record = res.data && res.data.find(item => item.factor_name === 'G54').factor_value_numeric;
                    const size_range_id = 3;
                    const pct_resources_breach_containment = 25;
                    const pct_resources_breach_investigation = 15;
                    setRecipe({ ...recipe, num_so_stolen_records, cost_so_legal_per_record, size_range_id, pct_resources_breach_containment, pct_resources_breach_investigation });
                });
            }
        } else {
            setSocRef(undefined);
        }
    }, [step, recipe]);


    const handleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }));
    };

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [];
        if (step === 1) {
            validation.push({ field: 'model_name', msg: 'Please enter Model Name.' });
            validation.push({ field: 'owner_id', msg: 'Please select Owner.' });
            validation.push({ field: 'customer_name', msg: 'Please enter Customer Name.' });
            validation.push({ field: 'geography_id', msg: 'Please select Region.' });
            validation.push({ field: 'vertical_id', msg: 'Please select Industry Vertical.' });
            validation.push({ field: 'num_sites', msg: 'Please enter Number of Sites.' });
        } else if (step === 2) {
            validation.push({ field: 'num_ot_engineers', msg: 'Please enter Average Number of OT Engineers per site.' });
            validation.push({ field: 'days_ot_changes', msg: 'Please enter Days spent per month reviewing OT changes centrally.' });
            validation.push({ field: 'days_ot_walkdown', msg: 'Please enter Walkdown time Days (physical investigation).' });
            validation.push({ field: 'days_ot_rca', msg: 'Please enter Number of RCAs Days.' });
            validation.push({ field: 'hours_ot_repair', msg: 'Please enter Mean Time To Respond.' });
            validation.push({ field: 'cost_ot_engineer', msg: 'Please enter Average Cost of OT Engineer.' });
            // } else if(step===3){
            validation.push({ field: 'num_sec_engineers', msg: 'Please enter Average Number of Cybersecurity Engineers per site.' });
            validation.push({ field: 'num_sec_systems', msg: 'Please enter Number of disparate systems.' });
            validation.push({ field: 'days_sec_walkdown', msg: 'Please enter Walkdown time Days (Monthly physical investigation).' });
            validation.push({ field: 'cost_sec_engineer', msg: 'Please enter Average Cost of Security Engineer.' });
            // validation.push({ field: 'num_ot_freq_human_error', msg: 'Please enter Humar Error.' });
            // validation.push({ field: 'num_ot_freq_security_event', msg: 'Please enter Cybersecurity Event.' });
            // validation.push({ field: 'num_ot_freq_equipment_failure', msg: 'Please enter Equipment Failure.' });
            // } else if(step===4){
            validation.push({ field: 'hours_site_travel_time', msg: 'Please enter Average Travel Time to Site.' });
            validation.push({ field: 'num_site_vendor_changes', msg: 'Please enter Number of Third party vendor changes requiring a shadow per month.' });
            validation.push({ field: 'num_site_vendor_firmware', msg: 'Please enter Vendor Firmware Update Time at Site / Shadow time.' });
            validation.push({ field: 'num_site_escorts', msg: 'Please enter Shadow resource / Escorts.' });
        } else if (step === 3) {
            validation.push({ field: 'num_so_attack_attempts', msg: 'Please enter Total attack attempts last year.' });
            validation.push({ field: 'num_so_breaches', msg: 'Please enter Breach last year.' });
            // validation.push({ field: 'num_so_stolen_records', msg: 'Please enter Records stolen or lost.' });
            // validation.push({ field: 'cost_so_legal_per_record', msg: 'Please enter Legal Cost per breach.' });
            validation.push({ field: 'hours_meantime_attack_mdr', msg: 'Please enter Mean Time for Attach MDR.' });
            validation.push({ field: 'days_meantime_breach_containment', msg: 'Please enter Mean Time to contain breach.' });
            validation.push({ field: 'days_meantime_breach_investigation', msg: 'Please enter Mean Time to investigate breach.' });
            // validation.push({ field: 'size_range_id', msg: 'Please enter SOC Personnel Size.' });
            validation.push({ field: 'pct_resources_breach_containment', msg: 'Please enter % of SOC resources associated with Breach Containment.' });
            validation.push({ field: 'pct_resources_breach_investigation', msg: 'Please enter % of SOC resources associated with Breach Investigation.' });
        }
        validation.map(item => {
            console.log(recipe);
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };

    const handleSave = (version) => {
        if (validate()) {
            setLoading(true);
            if (version === "basic") {
                recipe.model_type = "BASIC"
            } else {
                recipe.model_type = "ADVANCED"
            }

            let payload = JSON.parse(JSON.stringify(recipe));
            service.createModel(payload, res => {
                props.history.push('/summary/' + res + '/ref');
            });
        }
    }
    const handleNext = () => {
        if (validate()) {
            const newStepStatus = stepsStatus;
            newStepStatus[step - 1] = 1;
            setStep(step + 1);
            setStepsStatus(newStepStatus);
        }
    }
    const handleBack = () => {
        setStep(step - 1);
    }
    // console.log(recipe);
    loading ? startLoading() : stopLoading();
    return (
        !loading && <div className="row " style={{ background: "#000000" }}>

            <div className="col-12" style={{ padding: "0" }}>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="col-12" style={{ paddingTop: "10px", color: "#ffffff" }}>{title} {parseInt(props.match.params.id) > 0 && recipe && recipe.model_name && " - " + recipe.model_name}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 " style={{ padding: "0" }}>
                <div className="container-fluid mt-1 filter-bar pb-1">
                <div className="container pt-3">
                      
                            
                    <ul className="progress-indicator">
                        <li className={step === 1 ? "active" : stepsStatus[0] === 1 ? "completed" : ""}>
                            <i className={step === 1 ? "mdi mdi-circle-edit-outline" : stepsStatus[0] === 1 ? "mdi mdi-check" : ""} aria-hidden="true"></i>
                            <span className="bubble"></span>
                            <span style={{color:"#ffffff"}}>Customer Details</span>
                        </li>

                        <li className={step === 2 ? "active" : stepsStatus[1] === 1 ? "completed" : ""}>
                            <i className={step === 2 ? "mdi mdi-circle-edit-outline" : stepsStatus[1] === 1 ? "mdi mdi-check" : ""} aria-hidden="true"></i>
                            <span className="bubble"></span>
                            <span style={{color:"#ffffff"}}>ASSET OWNER/OPERATOR</span>
                        </li>
                        <li className={step === 3 ? "active" : stepsStatus[2] === 1 ? "completed" : ""}>
                            <i className={step === 3 ? "mdi mdi-circle-edit-outline" : stepsStatus[2] === 1 ? "mdi mdi-check" : ""} aria-hidden="true"></i>
                            <span className="bubble"></span>
                            <span style={{color:"#ffffff"}}>SECURITY OPERATIONS</span>
                        </li>
                    </ul>
                            
                        
                </div>
                </div>
            <div className="col-12 "   style={{background:"#f8f9fa"}}>
            <div className="container-fluid pt-1">
                <div className="row g-12">
                    
                        <div className="col-md-12 col-lg-12">
                            {step === 1 && <div className="card  mt-3">
                                <h4 className="card-header">Customer Details</h4>
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-sm-12">
                                            <label htmlFor="customer_name" className="form-label">Model Name <span className="required">*</span></label>
                                            <input type="text" className="form-control" name="model_name" placeholder=""
                                                value={recipe.model_name || ''}
                                                onChange={(e) => { handleChange('model_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.model_name && <div className="validation-error">{errors.model_name}</div>}
                                        </div>

                                        <div className="col-sm-12">
                                            <label htmlFor="customer_name" className="form-label">Customer Name <span className="required">*</span></label>
                                            <input type="text" className="form-control" name="customer_name" placeholder=""
                                                value={recipe.customer_name || ''}
                                                onChange={(e) => { handleChange('customer_name', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.customer_name && <div className="validation-error">{errors.customer_name}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="customer_name" className="form-label">Opportunity Number</label>
                                            <input type="text" className="form-control" name="opportunity_number" placeholder=""
                                                value={recipe.opportunity_number || ''}
                                                onChange={(e) => { handleChange('opportunity_number', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.opportunity_number && <div className="validation-error">{errors.opportunity_number}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="owner_name" className="form-label">Owner <span className="required">*</span></label>
                                            <Select id="owner_id"
                                                value={(recipe && recipe.owner_id && lookup.users_share.find(item => item.value === recipe.owner_id + '')) || null}
                                                options={lookup.users_share || []}
                                                required
                                                onChange={(e) => { handleChange('owner_id', e.value) }}
                                            />
                                            {errors.owner_id && <div className="validation-error">{errors.owner_id}</div>}
                                        </div>

                                        <div className="col-sm-6">
                                            <label htmlFor="geography_id" className="form-label">Region <span className="required">*</span></label>
                                            <Select id="geography_id"
                                                value={(recipe && recipe.geography_id && lookup.bva_geographies.find(item => item.value === recipe.geography_id + '')) || null}
                                                options={lookup.bva_geographies || []}
                                                required
                                                onChange={(e) => { handleChange('geography_id', e.value) }}
                                            />
                                            {errors.geography_id && <div className="validation-error">{errors.geography_id}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="vertical_id" className="form-label">Industry Vertical <span className="required">*</span></label>
                                            <Select id="vertical_id"
                                                value={(recipe && recipe.vertical_id && lookup.bva_verticals.find(item => item.value === recipe.vertical_id + '')) || null}
                                                options={lookup.bva_verticals || []}
                                                required
                                                onChange={(e) => { handleChange('vertical_id', e.value) }}
                                            />
                                            {errors.vertical_id && <div className="validation-error">{errors.vertical_id}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="num_sites" className="form-label">Number of Sites <span className="required">*</span></label>
                                            <input type="text" className="form-control" id="num_sites" placeholder=""
                                                value={recipe.num_sites || ''}
                                                pattern="[0-9]*"
                                                onChange={(e) => { handleChange('num_sites', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.num_sites && <div className="validation-error">{errors.num_sites}</div>}
                                        </div>
                                        <div className="col-sm-12">
                                            <label htmlFor="automation_stage" className="form-label"> Are Industrial Control Systems (ICS) security alerts automated to SOC? <span className="required">*</span> <i className="mdi mdi-help" aria-hidden="true" data-tip data-for="stage"></i></label>
                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                {lookup && lookup.bva_automation_stages && lookup.bva_automation_stages.sort(function (a, b) {
                                                    return b.value - a.value;
                                                }).map(item => {
                                                    let checked = (recipe.automation_stage_id + '' === item.value + '');



                                                    return <React.Fragment key={item.value}>

                                                        <label className={checked ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor={'ott_' + item.value}>
                                                            <input type="radio" className="btn-check" name="btnradio" id={'ott_' + item.value} autoComplete="off" checked={checked}
                                                                onChange={(e) => { handleChange('automation_stage_id', e.target.checked ? item.value : 0) }}
                                                            />
                                                            {item.label}
                                                        </label>
                                                    </React.Fragment>
                                                })}
                                            </div>
                                            <ReactTooltip id="stage" place="top" effect="solid">
                                                Can include competitor solutions
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {step === 2 && <div className="card  mt-3">
                                <h4 className="card-header">Asset Owner/Operator</h4>
                                <div className="card-body">
                                    <h5 className="card-title">ICS Site OT Engineering</h5>
                                    <div className="row g-3">
                                        <div className="col-sm-6">
                                            <label htmlFor="num_ot_engineers" className="form-label">Average Number of OT Engineers per site <span className="required">*</span></label>
                                            <input type="text" className="form-control" id="num_ot_engineers" placeholder=""
                                                value={recipe.num_ot_engineers || ''}
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                onChange={(e) => { handleChange('num_ot_engineers', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.num_ot_engineers && <div className="validation-error">{errors.num_ot_engineers}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="days_ot_changes" className="form-label">Days spent per month reviewing OT changes centrally <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="days_ot_changes" placeholder=""
                                                    value={recipe.days_ot_changes || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('days_ot_changes', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">days</div>
                                            </div>
                                            {errors.days_ot_changes && <div className="validation-error">{errors.days_ot_changes}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="days_ot_walkdown" className="form-label">Physical Investigation time days per month <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="days_ot_walkdown" placeholder=""
                                                    value={recipe.days_ot_walkdown || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('days_ot_walkdown', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">days</div>
                                            </div>
                                            {errors.days_ot_walkdown && <div className="validation-error">{errors.days_ot_walkdown}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="days_ot_rca" className="form-label">Number of Root Cause Analysis (RCA) days per month <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="days_ot_rca" placeholder=""
                                                    value={recipe.days_ot_rca || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('days_ot_rca', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">days</div>
                                            </div>
                                            {errors.days_ot_rca && <div className="validation-error">{errors.days_ot_rca}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="hours_ot_repair" className="form-label">Mean Time To Respond  <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="hours_ot_repair" placeholder=""
                                                    value={recipe.hours_ot_repair || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('hours_ot_repair', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">hours</div>
                                            </div>
                                            {errors.hours_ot_repair && <div className="validation-error">{errors.hours_ot_repair}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="cost_ot_engineer" className="form-label">Average Cost of OT Engineer  <span className="required">*</span></label>
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <input type="text" className="form-control" id="cost_ot_engineer" placeholder=""
                                                    value={recipe.cost_ot_engineer || ''}
                                                    pattern="^[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('cost_ot_engineer', (e.target.validity.valid) ? e.target.value : '') }} />
                                            </div>
                                            {errors.cost_ot_engineer && <div className="validation-error">{errors.cost_ot_engineer}</div>}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h5 className="card-title ">ICS Site IT Network/Security</h5>
                                    <div className="row g-3">
                                        <div className="col-sm-6">
                                            <label htmlFor="num_sec_engineers" className="form-label">Average Number of IT/Cybersecurity Engineers per site <span className="required">*</span></label>
                                            <input type="text" className="form-control" id="num_sec_engineers" placeholder=""
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                value={recipe.num_sec_engineers || ''}
                                                onChange={(e) => { handleChange('num_sec_engineers', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.num_sec_engineers && <div className="validation-error">{errors.num_sec_engineers}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="num_sec_systems" className="form-label">Number of disparate systems <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="num_sec_systems" placeholder=""
                                                    value={recipe.num_sec_systems || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('num_sec_systems', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">systems</div>
                                            </div>
                                            {errors.num_sec_systems && <div className="validation-error">{errors.num_sec_systems} <span className="required">*</span></div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="days_sec_walkdown" className="form-label">Physical Investigation time days per month  <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="days_sec_walkdown" placeholder=""
                                                    value={recipe.days_sec_walkdown || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('days_sec_walkdown', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">days</div>
                                            </div>
                                            {errors.days_sec_walkdown && <div className="validation-error">{errors.days_sec_walkdown}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="cost_sec_engineer" className="form-label">Average Cost of Security Engineer <span className="required">*</span></label>
                                            <div className="input-group">
                                                <div className="input-group-text">$</div>
                                                <input type="text" className="form-control" id="cost_sec_engineer" placeholder=""
                                                    value={recipe.cost_sec_engineer || ''}
                                                    pattern="^[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('cost_sec_engineer', (e.target.validity.valid) ? e.target.value : '') }} />
                                            </div>
                                            {errors.cost_sec_engineer && <div className="validation-error">{errors.cost_sec_engineer}</div>}
                                        </div>

                                        {/* <div className="col-sm-12">
                                        <div className="card text-dark bg-light mb-3" >
                                            <div className="card-body">
                                                <h5 className="card-title">Monthly Unplanned Outage Frequencies</h5>
                                                <div className="row g-3">
                                                    <div className="col-sm-4">
                                                        <label htmlFor="num_ot_freq_human_error" className="form-label">Humar Error</label>
                                                        <input type="text" className="form-control" id="num_ot_freq_human_error" placeholder=""
                                                            value={recipe.num_ot_freq_human_error || ''}
                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                            onChange={(e) => { handleChange('num_ot_freq_human_error', (e.target.validity.valid) ? e.target.value : '') }} />
                                                        {errors.num_ot_freq_human_error && <div className="validation-error">{errors.num_ot_freq_human_error}</div>}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label htmlFor="num_ot_freq_security_event" className="form-label">Cybersecurity Event</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" id="num_ot_freq_security_event" placeholder=""
                                                                value={recipe.num_ot_freq_security_event || ''}
                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                                onChange={(e) => { handleChange('num_ot_freq_security_event', (e.target.validity.valid) ? e.target.value : '') }} />
                                                        </div>
                                                        {errors.num_ot_freq_security_event && <div className="validation-error">{errors.num_ot_freq_security_event}</div>}
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label htmlFor="num_ot_freq_equipment_failure" className="form-label">Equipment Failure </label>
                                                        <input type="text" className="form-control" id="num_ot_freq_equipment_failure" placeholder=""
                                                            value={recipe.num_ot_freq_equipment_failure || ''}
                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                            onChange={(e) => { handleChange('num_ot_freq_equipment_failure', (e.target.validity.valid) ? e.target.value : '') }} />
                                                        {errors.num_ot_freq_equipment_failure && <div className="validation-error">{errors.num_ot_freq_equipment_failure}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                    <hr className="my-4" />
                                    <h5 className="card-title">Site Access</h5>
                                    <div className="row g-3">
                                        <div className="col-sm-6">
                                            <label htmlFor="hours_site_travel_time" className="form-label">Average Travel Time to Site <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="hours_site_travel_time" placeholder=""
                                                    value={recipe.hours_site_travel_time || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('hours_site_travel_time', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">hours</div>
                                            </div>
                                            {errors.hours_site_travel_time && <div className="validation-error">{errors.hours_site_travel_time}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="num_site_vendor_changes" className="form-label">Number of Third party vendor changes requiring a shadow / escort per month <span className="required">*</span></label>
                                            <input type="text" className="form-control" id="num_site_vendor_changes" placeholder=""
                                                value={recipe.num_site_vendor_changes || ''}
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                onChange={(e) => { handleChange('num_site_vendor_changes', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {errors.num_site_vendor_changes && <div className="validation-error">{errors.num_site_vendor_changes}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="num_site_vendor_firmware" className="form-label">Vendor Firmware Update Time at Site - Shadow / Escort time <span className="required">*</span> </label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="num_site_vendor_firmware" placeholder=""
                                                    value={recipe.num_site_vendor_firmware || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('num_site_vendor_firmware', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">hours</div>
                                            </div>
                                            {errors.num_site_vendor_firmware && <div className="validation-error">{errors.num_site_vendor_firmware}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="num_site_escorts" className="form-label">Shadow resource / Escorts <span className="required">*</span></label>
                                            <div className="input-group">

                                                <input type="text" className="form-control" id="num_site_escorts" placeholder=""
                                                    value={recipe.num_site_escorts || ''}
                                                    pattern="^[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('num_site_escorts', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">resources</div>
                                            </div>
                                            {errors.num_site_escorts && <div className="validation-error">{errors.num_site_escorts}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {step === 3 && <div className="card mt-3">
                                <h4 className="card-header">Security Operations</h4>
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-sm-6">
                                            <label htmlFor="num_so_attack_attempts" className="form-label">Total attack attempts last year <span className="required">*</span></label>
                                            <input type="text" className="form-control" id="num_so_attack_attempts" placeholder=""
                                                value={recipe.num_so_attack_attempts || ''}
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                onChange={(e) => { handleChange('num_so_attack_attempts', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {socRef && socRef.filter(item => item.factor_name === 'G50').map(item => {
                                                return <div key={item.factor_name} className="text-muted">Frequency of attacks in a year based on vertical industry:
                                                    <b style={{ cursor: "pointer", color: "#0969da" }} onClick={() => { handleChange('num_so_attack_attempts', item.factor_value_numeric) }}>{item.factor_value_numeric}</b>
                                                </div>
                                            })}

                                            {errors.num_so_attack_attempts && <div className="validation-error">{errors.num_so_attack_attempts}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="num_so_breaches" className="form-label">Breach last year <span className="required">*</span></label>
                                            <input type="text" className="form-control" id="num_so_breaches" placeholder=""
                                                value={recipe.num_so_breaches || ''}
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                onChange={(e) => { handleChange('num_so_breaches', (e.target.validity.valid) ? e.target.value : '') }} />
                                            {socRef && socRef.filter(item => item.factor_name === 'G51').map(item => {
                                                return <div key={item.factor_name} className="text-muted">Volume successful based on Probability factors:
                                                    <b style={{ cursor: "pointer", color: "#0969da" }} onClick={() => { handleChange('num_so_breaches', item.factor_value_numeric) }}>{item.factor_value_numeric}</b>
                                                </div>
                                            })}
                                            {errors.num_so_breaches && <div className="validation-error">{errors.num_so_breaches}</div>}
                                        </div>
                                        {/* <div className="col-sm-6">
                                        <label htmlFor="num_so_stolen_records" className="form-label">Records stolen or lost <span className="required">*</span></label>
                                        <input type="text" className="form-control" id="num_so_stolen_records" placeholder=""
                                            value={recipe.num_so_stolen_records || ''}
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                            onChange={(e) => { handleChange('num_so_stolen_records', (e.target.validity.valid) ? e.target.value : '') }} />
                                        {socRef && socRef.filter( item => item.factor_name==='G52').map( item =>{
                                                return <div key={item.factor_name} className="text-muted">Average number records based on Region: 
                                                <b style={{cursor:"pointer", color:"#0969da"}} onClick={()=>{ handleChange('num_so_stolen_records', item.factor_value_numeric)}}>{item.factor_value_numeric}</b>
                                                </div>
                                            })}
                                        {errors.num_so_stolen_records && <div className="validation-error">{errors.num_so_stolen_records}</div>}
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="cost_so_legal_per_record" className="form-label">Legal Cost per breach <span className="required">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-text">$</div>
                                            <input type="text" className="form-control" id="cost_so_legal_per_record" placeholder=""
                                                value={recipe.cost_so_legal_per_record || ''}
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                onChange={(e) => { handleChange('cost_so_legal_per_record', (e.target.validity.valid) ? e.target.value : '') }} />
                                        </div>
                                        {socRef && socRef.filter( item => item.factor_name==='G54').map( item =>{
                                                return <div key={item.factor_name} className="text-muted">Average cost based on Geography and Vertical: 
                                                <b style={{cursor:"pointer", color:"#0969da"}} onClick={()=>{ handleChange('cost_so_legal_per_record', item.factor_value_numeric)}}>${item.factor_value_numeric}</b>
                                                </div>
                                            })}
                                        {errors.cost_so_legal_per_record && <div className="validation-error">{errors.cost_so_legal_per_record}</div>}
                                    </div> */}
                                        <div className="col-sm-6">
                                            <label htmlFor="hours_meantime_attack_mdr" className="form-label">Mean Time for Manage Detect and Respond (MDR) <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="hours_meantime_attack_mdr" placeholder=""
                                                    value={recipe.hours_meantime_attack_mdr || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('hours_meantime_attack_mdr', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">hours</div>
                                            </div>
                                            {errors.hours_meantime_attack_mdr && <div className="validation-error">{errors.hours_meantime_attack_mdr}</div>}
                                        </div>
                                        <div className="col-sm-6">&nbsp;</div>
                                        <div className="col-sm-6">
                                            <label htmlFor="days_meantime_breach_investigation" className="form-label">Mean Time To Investigate (MTTI) breach <span className="required">*</span> <i className="mdi mdi-help" aria-hidden="true" data-tip data-for="mtti"></i></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="days_meantime_breach_investigation" placeholder=""
                                                    value={recipe.days_meantime_breach_investigation || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('days_meantime_breach_investigation', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">days</div>
                                            </div>
                                            <ReactTooltip id="mtti" place="top" effect="solid">
                                                Mean time to investigate (MTTI): The average time between <br />
                                                the detection of an IT incident and when the organization <br />
                                                begins to investigate its cause and solution.
                                            </ReactTooltip>
                                            {socRef && socRef.filter(item => item.factor_name === 'G61').map(item => {
                                                return <div key={item.factor_name} className="text-muted">Average number of days MTTI based on vertical industry:
                                                    <b style={{ cursor: "pointer", color: "#0969da" }} onClick={() => { handleChange('days_meantime_breach_investigation', item.factor_value_numeric) }}>{item.factor_value_numeric} days</b>
                                                </div>
                                            })}
                                            {errors.days_meantime_breach_containment && <div className="validation-error">{errors.days_meantime_breach_investigation}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="days_meantime_breach_containment" className="form-label">Mean Time To Contain (MTTC) breach <span className="required">*</span> <i className="mdi mdi-help" aria-hidden="true" data-tip data-for="mttc"></i></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="days_meantime_breach_containment" placeholder=""
                                                    value={recipe.days_meantime_breach_containment || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('days_meantime_breach_containment', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">days</div>
                                            </div>
                                            <ReactTooltip id="mttc" place="top" effect="solid" >
                                                Mean time to contain (MTTC) MTTC focuses on how long your <br />
                                                incident response team takes to detect an incident, acknowledge <br />
                                                the incident, and effectively prevent a cybercriminal from doing more harm.
                                            </ReactTooltip>
                                            {socRef && socRef.filter(item => item.factor_name === 'G60').map(item => {
                                                return <div key={item.factor_name} className="text-muted">Average number of days MTTC based on vertical industry:
                                                    <b style={{ cursor: "pointer", color: "#0969da" }} onClick={() => { handleChange('days_meantime_breach_containment', item.factor_value_numeric) }}>{item.factor_value_numeric} days</b>

                                                </div>
                                            })}
                                            {errors.days_meantime_breach_investigation && <div className="validation-error">{errors.days_meantime_breach_containment}</div>}
                                        </div>
                                        {/* <div className="col-sm-6">
                                        <label htmlFor="size_range_id" className="form-label">SOC Personnel Size <span className="required">*</span></label>
                                        <Select id="size_range_id"
                                            value={(recipe && recipe.size_range_id && lookup.bva_size_ranges.find(item => item.value === recipe.size_range_id + '')) || null}
                                            options={lookup.bva_size_ranges || []}
                                            required
                                            onChange={(e) => { handleChange('size_range_id', e.value) }}
                                        />
                                        {errors.size_range_id && <div className="validation-error">{errors.size_range_id}</div>}
                                    </div> */}

                                        <div className="col-sm-6">
                                            <label htmlFor="pct_resources_breach_investigation" className="form-label">% of SOC resources associated with Breach Investigation <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="pct_resources_breach_investigation" placeholder=""
                                                    value={recipe.pct_resources_breach_investigation || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('pct_resources_breach_investigation', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">%</div>
                                            </div>
                                            {errors.pct_resources_breach_investigation && <div className="validation-error">{errors.pct_resources_breach_investigation}</div>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="pct_resources_breach_containment" className="form-label">% of SOC resources associated with Breach Containment <span className="required">*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="pct_resources_breach_containment" placeholder=""
                                                    value={recipe.pct_resources_breach_containment || ''}
                                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                    onChange={(e) => { handleChange('pct_resources_breach_containment', (e.target.validity.valid) ? e.target.value : '') }} />
                                                <div className="input-group-text">%</div>
                                            </div>
                                            {errors.pct_resources_breach_containment && <div className="validation-error">{errors.pct_resources_breach_containment}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="clearfix" style={{ padding: '10px 0' }}>
                                {step > 1 &&
                                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => handleBack()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                                }
                                {step === 3 &&
                                    <button type="button" className="btn btn-g btn-outline-success float-end" style={{ marginLeft: '10px' }} onClick={() => handleSave('advanced')} ><i className="mdi mdi-creation " aria-hidden="true" ></i> Generate Model</button>
                                }

                                {step < 3 &&
                                    <React.Fragment>

                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} >{step === 2 && <i className="mdi mdi-security" aria-hidden="true" ></i>} {step === 2 ? "Advanced Configuration" : "Next"} <i className="mdi mdi-skip-next" aria-hidden="true" ></i></button>
                                    </React.Fragment>
                                }
                                {step === 2 &&
                                    <button type="button" className="btn btn-g btn-outline-success float-end" style={{ marginLeft: '10px' }} onClick={() => handleSave('basic')} ><i className="mdi mdi-creation " aria-hidden="true" ></i> Generate Basic Model</button>
                                }
                                <button type="button" className="btn btn-g btn-outline-secondary float-end" onClick={() => props.history.push('/bva-home')} ><i className="mdi mdi-cancel " aria-hidden="true" ></i> Cancel</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form;


