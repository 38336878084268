import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import routes from './Config/routes.js';
import { AuthProvider } from "./Context";
import AppRoute from './Components/AppRoutes';
import "./assets/styles/icons.css";
import "./assets/styles/common.css";
import "./assets/styles/style.css";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          {routes.map(route => <AppRoute key={route.path} path={route.path} component={route.component} isPrivate={route.isPrivate} />)}
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}


export default App;
