import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading } from '../../Components/Util';
import * as service from "../../services";
import Select from 'react-select';

function ConfigureHardware(props) {

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [recipe, setRecipe] = useState({});
    const [hardwareLookup, setHardwareLookup] = useState([]);
    const [quoteHeader, setQuoteHeader] = useState({});

    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.quote_id) > 0) {

                service.getElaData("ela-header", { id: props.match.params.quote_id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }
                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getElaData("get_hardware_by_id", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setRecipe({ ...res[0] });
                        resolve("");
                    }
                });
            } else {
                setRecipe({ quote_id: props.match.params.quote_id });
                resolve("");
            }
        });
        let p3 = new Promise((resolve, reject) => {
       
            service.getElaData("get_hardware_lookup", { id: props.match.params.id }, res => {
                    setHardwareLookup(res);
                    resolve("");
            });
            
        });

        Promise.all([p1, p2, p3])
            .then(values => {


                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.quote_id,  props.match.params.id]);

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "sku_id", msg: "Please select Hardware" },
        ];
        

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        if ((recipe.total_count || 0) +(recipe.year1_count || 0) + (recipe.year2_count || 0) + (recipe.year3_count || 0) + (recipe.year4_count || 0) + (recipe.year4_count || 0) < 1) {
            if(recipe.split_by && recipe.split_by==='APPORTION'){
                setErrors(errors => ({ ...errors, year_counts: "Please enter number of units" }));
            } else{
                setErrors(errors => ({ ...errors, year_counts: "Please enter deployment schedule" }));
            }
            
            isValid = false;
        }
  

        return isValid;
    };


    const handleChange = (prop, value) => {
        let split_by=recipe.split_by;
        if(prop==='sku_id'){

            let sku=hardwareLookup.find(item => item.value===value);
            split_by=sku.family;
        }
        setRecipe(recipe => ({ ...recipe, split_by, [prop]: value}));
        
    };
    const handleNext = () => {
        if (validate()) {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(recipe));
            let sku =hardwareLookup.find(item => item.value===payload.sku_id);
            payload.split_by=sku.family;
            if(sku.family==='SITE'){
                payload.total_count=0;
            }else{
                payload.year1_count=0;
                payload.year2_count=0;
                payload.year3_count=0;
                payload.year4_count=0;
                payload.year5_count=0;
            }
            payload.year1_count=payload.year1_count || 0;
            payload.year2_count=payload.year2_count || 0;
            payload.year3_count=payload.year3_count || 0;
            payload.year4_count=payload.year4_count || 0;
            payload.year5_count=payload.year5_count || 0;

            service.upsertEla('ela-hardware', payload, res => {
                console.log(res);
                props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref');
            });
        }
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>Configure Ela Quote</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.shipping_type + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal? 'Yes':'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email?' [EMAIL: '+quoteHeader.sales_director_email+']':'') + (quoteHeader.sales_director_phone?' [CONTACT #: '+quoteHeader.sales_director_phone+']':'')}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                {/* <h4 className="card-header">Quote Header</h4> */}
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <h4>Hardware</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">


                                                        <div className="col-sm-12">
                                                            <div className="row g-3">
                                                                <div className="col-sm-12 ">

                                                                    <div className="list-group">

                                                                        <div className="list-group-item p-3">
                                                                           
                                                                            <div className="col-sm-12">
                                                                                <label htmlFor="sku_id" className="form-label">Hardware <span className="required">*</span></label>
                                                                                <Select id="sku_id"
                                                                                    value={(recipe && recipe.sku_id && hardwareLookup.find(item => item.value === recipe.sku_id)) || null}
                                                                                    options={hardwareLookup || []}
                                                                                    required
                                                                                    onChange={(e) => { handleChange('sku_id', e.value) }}
                                                                                />
                                                                                {errors.sku_id && <div className="validation-error">{errors.sku_id}</div>}
                                                                            </div>
                                                                            
                                                                            <div className="col-sm-12 mt-3">
                                                                                {recipe.split_by==='SITE' && <>
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="year1_count" className="form-label">Units deployed during year 1 </label>
                                                                                    
                                                                                    <input type="text" className="form-control" name="year1_count" placeholder=""
                                                                                        value={recipe.year1_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('year1_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.year1_count && <div className="validation-error">{errors.year1_count}</div>}
                                                                                </div>
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="year2_count" className="form-label">Units deployed during year 2 </label>
                                                                                    <input type="text" className="form-control" name="year2_count" placeholder=""
                                                                                        value={recipe.year2_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('year2_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.year2_count && <div className="validation-error">{errors.year2_count}</div>}
                                                                                </div>
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="year3_count" className="form-label">Units deployed during year 3 </label>
                                                                                    <input type="text" className="form-control" name="year3_count" placeholder=""
                                                                                        value={recipe.year3_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('year3_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.year3_count && <div className="validation-error">{errors.year3_count}</div>}
                                                                                </div>
                                                                                {quoteHeader.contract_length > 3 &&
                                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                        <label htmlFor="year4_count" className="form-label">Units deployed during year 4 </label>
                                                                                        <input type="text" className="form-control" name="year4_count" placeholder=""
                                                                                            value={recipe.year4_count || ''}
                                                                                            maxLength="100"
                                                                                            pattern="^\d+$"

                                                                                            onChange={(e) => { handleChange('year4_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                        {errors.year4_count && <div className="validation-error">{errors.year4_count}</div>}
                                                                                    </div>
                                                                                }
                                                                                {quoteHeader.contract_length > 4 &&
                                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                        <label htmlFor="year5_count" className="form-label">Units deployed during year 5 </label>
                                                                                        <input type="text" className="form-control" name="year5_count" placeholder=""
                                                                                            value={recipe.year5_count || ''}
                                                                                            maxLength="100"
                                                                                            pattern="^\d+$"

                                                                                            onChange={(e) => { handleChange('year5_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                        {errors.year5_count && <div className="validation-error">{errors.year5_count}</div>}
                                                                                    </div>
                                                                                }
                                                                                </>}
                                                                                {recipe.split_by==='APPORTION' && <>
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="total_count" className="form-label">Total Units deployed </label>
                                                                                    
                                                                                    <input type="text" className="form-control" name="total_count" placeholder=""
                                                                                        value={recipe.total_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('total_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.total_count && <div className="validation-error">{errors.total_count}</div>}
                                                                                </div>
                                                                                </>}

                                                                                {errors.deployment && <div className="validation-error">{errors.deployment}</div>}
                                                                            </div>
                                                                            {errors.year_counts && <div className="validation-error">{errors.year_counts}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                                        <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default ConfigureHardware;


