import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

export default class DateInput extends Component {
    constructor(props) {
        super();
        this.inputField = React.createRef();
    }
    state = {
        startDate: null
    };

    handleChange = startDate => {
        this.setState(
            { startDate }
        );
        if (this.props.onChange) this.props.onChange(startDate);
    };

    excludeDay = (startDate) => {
        const day = startDate.getDay();
        let _hsaturday = this.props.excludeSaturday || false;
        let _hsunday = this.props.excludeSunday || false;
        if (_hsaturday && _hsunday) {
            return day !== 0 && day !== 6;
        }
        else if (_hsaturday && !_hsunday) {
            return day !== 6;
        }
        else if (!_hsaturday && _hsunday) {
            return day !== 0;
        }
        else if (!_hsaturday && !_hsunday) {
            return day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6;
        }
    }

    render() {
        let _selected = null;
        if (this.props.value && this.props.value !== null) {
            _selected = moment(this.props.value, 'DD-MMM-YYYY').toDate();
        }
        return (
            <div>
                <DatePicker
                    selected={_selected}
                    className={this.props.className}
                    placeholderText={this.props.placeholderText}
                    style={this.props.style}
                    onChange={this.handleChange}
                    minDate={this.props && this.props.minConstraint ? this.props.minConstraint : null}
                    maxDate={this.props && this.props.maxConstraint ? this.props.maxConstraint : null}
                    filterDate={this.excludeDay}
                    excludeDates={this.props.excludedDates}
                    dateFormat={this.props.dateFormat ? this.props.dateFormat : "dd-MMM-yyyy"}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    required={this.props.isRequired}
                    isClearable={this.props.isClearable}
                    readOnly={this.props.isDisabled}
                />
            </div>
        );
    }
}