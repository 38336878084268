
import { 
    Login, 
    Forgot, 
    Route, 
    BvaHome, 
    PageNotFound, 
    CreateModel, 
    Summary, 
    AdminDashboard, 
    UserDetail,
    ElaHome,
    CreateEla,
    ConfigureEla,
    ConfigureSite,
    ConfigureXdome,
    ConfigureXdomeSite,
    ConfigureProducts,
    ConfigureServices,
    ConfigureShipping,
    ConfigureHardware,
    SummaryEla,
    ApprovalDashboard,
BvaHcDashboard,
BvaHcCreateModel} from './../Pages'

const routes = [
    {
        path: '/login',
        component: Login,
        isPrivate: false
    }, {
        path: '/forgot',
        component: Forgot,
        isPrivate: false
    }, {
        path: '/router',
        component: Route,
        isPrivate: true
    }, {
        path: '/bva-home',
        component: BvaHome,
        isPrivate: true
    }, {
        path: '/create-model/:id/:ref',
        component: CreateModel,
        isPrivate: true
    }, {
        path: '/summary/:id/:ref',
        component: Summary,
        isPrivate: true
    }, {
        path: '/admin',
        component: AdminDashboard,
        isPrivate: true
    }, {
        path: '/userdetail/:email/:context',
        component: UserDetail,
        isPrivate: true
    }, {
        path: '/bvahc-home',
        component: BvaHcDashboard,
        isPrivate: true
    },
    {
        path: '/create-hc-model/:id/:ref',
        component: BvaHcCreateModel,
        isPrivate: true
    },
    {
        path: '/ela-home',
        component: ElaHome,
        isPrivate: true
    }, {
        path: '/create-ela/:id/:ref',
        component: CreateEla,
        isPrivate: true
    }, {
        path: '/configure-ela/:id/:ref',
        component: ConfigureEla,
        isPrivate: true
    }, {
        path: '/configure-site/:quote_id/:id/:ref',
        component: ConfigureSite,
        isPrivate: true
    }, {
        path: '/configure-xdome/:quote_id/:id/:ref',
        component: ConfigureXdome,
        isPrivate: true
    },{
        path: '/configure-xdome-site/:quote_id/:id/:ref',
        component: ConfigureXdomeSite,
        isPrivate: true
    },{
        path: '/configure-products/:quote_id/:id/:ref',
        component: ConfigureProducts,
        isPrivate: true
    }, {
        path: '/configure-services/:quote_id/:id/:ref',
        component: ConfigureServices,
        isPrivate: true
    }, {
        path: '/configure-shipping/:quote_id/:id/:ref',
        component: ConfigureShipping,
        isPrivate: true
    }, {
        path: '/configure-hardware/:quote_id/:id/:ref',
        component: ConfigureHardware,
        isPrivate: true
    }, {
        path: '/summary-ela/:id/:ref',
        component: SummaryEla,
        isPrivate: true
    }, {
        path: '/ela-approvals',
        component: ApprovalDashboard,
        isPrivate: true
    }, {
        path: '',
        component: Login,
        isPrivate: true
    }, {
        path: '/*',
        component: PageNotFound,
        isPrivate: true
    }
];



export default routes;