import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import ProgressArc from '../../../Components/widgets/ProgressArc';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SelectField from './fields/Select';
function Uptime(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    let operating_days_per_year_mri = 0;
    let downtime_before_mri = 0;
    let downtime_after_mri = 0;
    let uptime_before_mri = 0;
    let uptime_after_mri = 0;
    let max_capacity_mri = 0;
    let annual_procedures_before_mri = 0;
    let annual_procedures_after_mri = 0;
    let annual_revenue_before_mri = 0;
    let annual_revenue_after_mri = 0;
    let gross_saving_mri = 0;
    let revenue_recovered_mri = 0;


    if (parseFloat(data.step1__device_count_mri) > 0) {
        operating_days_per_year_mri = parseFloat(data.step5__operating_day_per_week_mri) * 52;
        downtime_before_mri = parseFloat(data.step8__downtime_before_mri) * 100 / operating_days_per_year_mri;
        downtime_after_mri = parseFloat(data.step8__downtime_after_mri) * 100 / operating_days_per_year_mri;
        uptime_before_mri = 100 - downtime_before_mri;
        uptime_after_mri = 100 - downtime_after_mri;
        max_capacity_mri = parseFloat(data.step1__device_count_mri)
            * parseFloat(data.step5__avg_procedure_per_hour_mri)
            * parseFloat(data.step5__operating_hour_per_day_mri)
            * 52;
        annual_procedures_before_mri = max_capacity_mri * uptime_before_mri / 100;
        annual_procedures_after_mri = max_capacity_mri * uptime_after_mri / 100;
        annual_revenue_before_mri = annual_procedures_before_mri * parseFloat(data.step5__avg_procedure_revenue_mri);
        annual_revenue_after_mri = annual_procedures_after_mri * parseFloat(data.step5__avg_procedure_revenue_mri);

        revenue_recovered_mri = annual_revenue_after_mri - annual_revenue_before_mri;
        gross_saving_mri = revenue_recovered_mri * parseFloat(data.step1__avg_margin_mri)/100;
        
    }

    let operating_days_per_year_ct = 0;
    let downtime_before_ct = 0;
    let downtime_after_ct = 0;
    let uptime_before_ct = 0;
    let uptime_after_ct = 0;
    let max_capacity_ct = 0;
    let annual_procedures_before_ct = 0;
    let annual_procedures_after_ct = 0;
    let annual_revenue_before_ct = 0;
    let annual_revenue_after_ct = 0;
    let gross_saving_ct = 0;
    let revenue_recovered_ct = 0;


    if (parseFloat(data.step1__device_count_ct) > 0) {
        operating_days_per_year_ct = parseFloat(data.step5__operating_day_per_week_ct) * 52;
        downtime_before_ct = parseFloat(data.step8__downtime_before_ct) * 100 / operating_days_per_year_ct;
        downtime_after_ct = parseFloat(data.step8__downtime_after_ct) * 100 / operating_days_per_year_ct;
        uptime_before_ct = 100 - downtime_before_ct;
        uptime_after_ct = 100 - downtime_after_ct;
        max_capacity_ct = parseFloat(data.step1__device_count_ct)
            * parseFloat(data.step5__avg_procedure_per_hour_ct)
            * parseFloat(data.step5__operating_hour_per_day_ct)
            * 52;
        annual_procedures_before_ct = max_capacity_ct * uptime_before_ct / 100;
        annual_procedures_after_ct = max_capacity_ct * uptime_after_ct / 100;
        annual_revenue_before_ct = annual_procedures_before_ct * parseFloat(data.step5__avg_procedure_revenue_ct);
        annual_revenue_after_ct = annual_procedures_after_ct * parseFloat(data.step5__avg_procedure_revenue_ct);

        revenue_recovered_ct = annual_revenue_after_ct - annual_revenue_before_ct;
        gross_saving_ct = revenue_recovered_ct * parseFloat(data.step1__avg_margin_ct)/100;
    }

    let operating_days_per_year_cath = 0;
    let downtime_before_cath = 0;
    let downtime_after_cath = 0;
    let uptime_before_cath = 0;
    let uptime_after_cath = 0;
    let max_capacity_cath = 0;
    let annual_procedures_before_cath = 0;
    let annual_procedures_after_cath = 0;
    let annual_revenue_before_cath = 0;
    let annual_revenue_after_cath = 0;
    let gross_saving_cath = 0;
    let revenue_recovered_cath = 0;


    if (parseFloat(data.step1__device_count_cath) > 0) {
        operating_days_per_year_cath = parseFloat(data.step5__operating_day_per_week_cath) * 52;
        downtime_before_cath = parseFloat(data.step8__downtime_before_cath) * 100 / operating_days_per_year_cath;
        downtime_after_cath = parseFloat(data.step8__downtime_after_cath) * 100 / operating_days_per_year_cath;
        uptime_before_cath = 100 - downtime_before_cath;
        uptime_after_cath = 100 - downtime_after_cath;
        max_capacity_cath = parseFloat(data.step1__device_count_cath)
            * parseFloat(data.step5__avg_procedure_per_hour_cath)
            * parseFloat(data.step5__operating_hour_per_day_cath)
            * 52;
        annual_procedures_before_cath = max_capacity_cath * uptime_before_cath / 100;
        annual_procedures_after_cath = max_capacity_cath * uptime_after_cath / 100;
        annual_revenue_before_cath = annual_procedures_before_cath * parseFloat(data.step5__avg_procedure_revenue_cath);
        annual_revenue_after_cath = annual_procedures_after_cath * parseFloat(data.step5__avg_procedure_revenue_cath);

        revenue_recovered_cath = annual_revenue_after_cath - annual_revenue_before_cath;
        gross_saving_cath = revenue_recovered_cath * parseFloat(data.step1__avg_margin_cath)/100;
    }

    let operating_days_per_year_la = 0;
    let downtime_before_la = 0;
    let downtime_after_la = 0;
    let uptime_before_la = 0;
    let uptime_after_la = 0;
    let max_capacity_la = 0;
    let annual_procedures_before_la = 0;
    let annual_procedures_after_la = 0;
    let annual_revenue_before_la = 0;
    let annual_revenue_after_la = 0;
    let gross_saving_la = 0;
    let revenue_recovered_la = 0;


    if (parseFloat(data.step1__device_count_la) > 0) {
        operating_days_per_year_la = parseFloat(data.step5__operating_day_per_week_la) * 52;
        downtime_before_la = parseFloat(data.step8__downtime_before_la) * 100 / operating_days_per_year_la;
        downtime_after_la = parseFloat(data.step8__downtime_after_la) * 100 / operating_days_per_year_la;
        uptime_before_la = 100 - downtime_before_la;
        uptime_after_la = 100 - downtime_after_la;
        max_capacity_la = parseFloat(data.step1__device_count_la)
            * parseFloat(data.step5__avg_procedure_per_hour_la)
            * parseFloat(data.step5__operating_hour_per_day_la)
            * 52;
        annual_procedures_before_la = max_capacity_la * uptime_before_la / 100;
        annual_procedures_after_la = max_capacity_la * uptime_after_la / 100;
        annual_revenue_before_la = annual_procedures_before_la * parseFloat(data.step5__avg_procedure_revenue_la);
        annual_revenue_after_la = annual_procedures_after_la * parseFloat(data.step5__avg_procedure_revenue_la);

        revenue_recovered_la = annual_revenue_after_la - annual_revenue_before_la;
        gross_saving_la = revenue_recovered_la * parseFloat(data.step1__avg_margin_la)/100;
    }
    let operating_days_per_year_xr = 0;
    let downtime_before_xr = 0;
    let downtime_after_xr = 0;
    let uptime_before_xr = 0;
    let uptime_after_xr = 0;
    let max_capacity_xr = 0;
    let annual_procedures_before_xr = 0;
    let annual_procedures_after_xr = 0;
    let annual_revenue_before_xr = 0;
    let annual_revenue_after_xr = 0;
    let gross_saving_xr = 0;
    let revenue_recovered_xr = 0;


    if (parseFloat(data.step1__device_count_xr) > 0) {
        operating_days_per_year_xr = parseFloat(data.step5__operating_day_per_week_xr) * 52;
        downtime_before_xr = parseFloat(data.step8__downtime_before_xr) * 100 / operating_days_per_year_xr;
        downtime_after_xr = parseFloat(data.step8__downtime_after_xr) * 100 / operating_days_per_year_xr;
        uptime_before_xr = 100 - downtime_before_xr;
        uptime_after_xr = 100 - downtime_after_xr;
        max_capacity_xr = parseFloat(data.step1__device_count_xr)
            * parseFloat(data.step5__avg_procedure_per_hour_xr)
            * parseFloat(data.step5__operating_hour_per_day_xr)
            * 52;
        annual_procedures_before_xr = max_capacity_xr * uptime_before_xr / 100;
        annual_procedures_after_xr = max_capacity_xr * uptime_after_xr / 100;
        annual_revenue_before_xr = annual_procedures_before_xr * parseFloat(data.step5__avg_procedure_revenue_xr);
        annual_revenue_after_xr = annual_procedures_after_xr * parseFloat(data.step5__avg_procedure_revenue_xr);

        revenue_recovered_xr = annual_revenue_after_xr - annual_revenue_before_xr;
        gross_saving_xr = revenue_recovered_xr * parseFloat(data.step1__avg_margin_xr)/100;
    }
    const hcahps_bonus = parseFloat(data.step8__hcahps_score_improvement) * parseFloat(data.step1__fin_medicare_reimbursements) / 100;
    const hcahps_subtotal = hcahps_bonus + parseFloat(data.step8__jacho_cost_avoidance);

    const gross_saving = gross_saving_mri + gross_saving_ct + gross_saving_cath + gross_saving_la + gross_saving_xr + hcahps_subtotal;

    const additional_operating_days = Math.ceil(parseFloat(data.step8__downtime_before_mri) - parseFloat(data.step8__downtime_after_mri)
        + parseFloat(data.step8__downtime_before_ct) - parseFloat(data.step8__downtime_after_ct)
        + parseFloat(data.step8__downtime_before_cath) - parseFloat(data.step8__downtime_after_cath)
        + parseFloat(data.step8__downtime_before_la) - parseFloat(data.step8__downtime_after_la)
        + parseFloat(data.step8__downtime_before_xr) - parseFloat(data.step8__downtime_after_xr));

    const additional_procedures = Math.ceil(-annual_procedures_before_mri + annual_procedures_after_mri
        - annual_procedures_before_ct + annual_procedures_after_ct
        - annual_procedures_before_cath + annual_procedures_after_cath
        - annual_procedures_before_la + annual_procedures_after_la
        - annual_procedures_before_xr + annual_procedures_after_xr);
    const next = () => {
        props.next({ step8__total: gross_saving, step8_additional_operating_days: additional_operating_days, step8_additional_procedures: additional_procedures,
            step8__gross_saving_mri: gross_saving_mri,
            step8__gross_saving_ct: gross_saving_ct ,
            step8__gross_saving_cath: gross_saving_cath ,
            step8__gross_saving_la: gross_saving_la ,
            step8__gross_saving_xr: gross_saving_xr  });
    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>

            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Uptime &amp; Patient Satisfaction</h4>
                </div>
                <div className='col-2' >
                    <SelectField
                        fieldColor="orange"
                        fieldName="step8__mvr"
                        value={(data && data["step8__mvr"] && props.mvrs.find(item => item.value === data["step8__mvr"])) || null}
                        options={props.mvrs}
                        label="Months to Value Realization"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step8__mvr"]}
                    />
                </div>
            </div>
            <div className='row mt-3 gx-5 '>

                <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(gross_saving) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Uptime &amp; Patient Satisfaction Streamlined Maintenance</div>
                        <div className='row' >

                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(gross_saving), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-4 mt-3 gx-5 '>
                    <Text
                        fieldColor="sky"
                        fieldName="step8__hcahps_score_improvement"
                        value={data["step8__hcahps_score_improvement"] + ""}
                        label="HCAHPS Score Improvement Bonus Percentage"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step8__hcahps_score_improvement"]}
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                        rightMarker="%"
                    />
                    <Text
                        fieldColor="sky"
                        fieldName="step8__jacho_cost_avoidance"
                        value={data["step8__jacho_cost_avoidance"] + ""}
                        label="TJC Environment of Care Compliance Cost Avoided"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step8__jacho_cost_avoidance"]}
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                        leftMarker="$"
                    />
                </div>
                <div className='col-8  mt-3 gx-5'>
                    <table className='table'>

                        <tbody>

                            <tr>
                                <td>Medicare Reimbursement </td>
                                <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_medicare_reimbursements, 2)}</td>
                            </tr>
                            <tr>
                                <td>HCAHPS Score Reimbursement Bonus</td>
                                <td style={{ textAlign: "right" }}>${formatCurrency(hcahps_bonus, 2)}</td>
                            </tr>
                            <tr>
                                <td>TJC Environment of Care Compliance Cost Avoided</td>
                                <td style={{ textAlign: "right" }}>${formatCurrency(data.step8__jacho_cost_avoidance, 2)}</td>
                            </tr>
                            <tr style={{ fontWeight: "600" }}>
                                <td>Total</td>
                                <td style={{ textAlign: "right" }}>${formatCurrency(hcahps_subtotal, 2)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
                <Tabs style={{ width: "100%" }} variant="fullWidth">
                    <TabList>
                        {data.step1__device_count_mri > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MRI <br /> STREAMLINED <br />  MAINTENANCE</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_mri > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_mri, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_ct > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>CT SCANNER  <br /> STREAMLINED <br />  MAINTENANCE</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_ct > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_ct, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_cath > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MAMMOGRAPHY <br /> STREAMLINED <br /> MAINTENANCE</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_cath > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_cath, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_la > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>C-ARM ACCELERATOR <br /> STREAMLINED <br /> MAINTENANCE</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_la > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_la, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_xr > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>X-RAY RADIOLOGY <br /> STREAMLINED <br /> MAINTENANCE</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_xr > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_xr, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                    </TabList>
                    {data.step1__device_count_mri > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_before_mri"
                                    value={data["step8__downtime_before_mri"] + ""}
                                    label="Estimated days of unshceduled downtime BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_before_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_after_mri"
                                    value={data["step8__downtime_after_mri"] + ""}
                                    label="Estimated days of unshceduled downtime AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_after_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated operating days per year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated unscheduled downtime</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_before_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_after_mri, -2)}%</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated uptime</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_before_mri, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_after_mri, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of annual procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_before_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_after_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual average revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_before_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_after_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_mri,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_mri, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_ct > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_before_ct"
                                    value={data["step8__downtime_before_ct"] + ""}
                                    label="Estimated days of unshceduled downtime BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_before_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_after_ct"
                                    value={data["step8__downtime_after_ct"] + ""}
                                    label="Estimated days of unshceduled downtime AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_after_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated operating days per year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated unscheduled downtime</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_before_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_after_ct, -2)}%</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated uptime</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_before_ct, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_after_ct, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of annual procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_before_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_after_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual average revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_before_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_after_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_ct,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_ct, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </TabPanel>}
                    {data.step1__device_count_cath > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_before_cath"
                                    value={data["step8__downtime_before_cath"] + ""}
                                    label="Estimated days of unshceduled downtime BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_before_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_after_cath"
                                    value={data["step8__downtime_after_cath"] + ""}
                                    label="Estimated days of unshceduled downtime AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_after_cath"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_cath, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated operating days per year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated unscheduled downtime</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_before_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_after_cath, -2)}%</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated uptime</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_before_cath, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_after_cath, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of annual procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_before_cath, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_after_cath, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual average revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_before_cath, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_after_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_cath, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_cath,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_cath, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_la > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_before_la"
                                    value={data["step8__downtime_before_la"] + ""}
                                    label="Estimated days of unshceduled downtime BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_before_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_after_la"
                                    value={data["step8__downtime_after_la"] + ""}
                                    label="Estimated days of unshceduled downtime AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_after_la"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_la, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated operating days per year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated unscheduled downtime</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_before_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_after_la, -2)}%</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated uptime</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_before_la, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_after_la, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of annual procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_before_la, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_after_la, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual average revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_before_la, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_after_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_la, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_la,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_la, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_xr > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_before_xr"
                                    value={data["step8__downtime_before_xr"] + ""}
                                    label="Estimated days of unshceduled downtime BEFORE"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_before_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step8__downtime_after_xr"
                                    value={data["step8__downtime_after_xr"] + ""}
                                    label="Estimated days of unshceduled downtime AFTER"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step8__downtime_after_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Procedures per Hour</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__avg_procedure_per_hour_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Facility Operating Days per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Revenue Per Procedure</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated operating days per year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated unscheduled downtime</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_before_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(downtime_after_xr, -2)}%</td>
                                        </tr>

                                        <tr>
                                            <td>Estimated uptime</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_before_xr, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(uptime_after_xr, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of annual procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_before_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(annual_procedures_after_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual average revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_before_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_revenue_after_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_xr,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_xr, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}

                </Tabs>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default Uptime;