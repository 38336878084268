import React, { useEffect } from 'react';
import { stopLoading } from '../../Components/Util.js';
import Header from '../../Components/layout/Header';
import Grid from './Grid';

function Dashboard(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";

    useEffect(() => {
        stopLoading();
    });

    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <Grid {...props} />
                </div>
            </main>
        </>
    )
}

export default Dashboard;