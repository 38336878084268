import axios from "axios";

const get = (url, onDone) => {
    axios({
        method: "get",
        url: url,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const archive = (url, onDone) => {
    axios({
        method: "delete",
        url: url,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const post = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const loginpost = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const patch = (url, payload, onDone) => {
    axios({
        method: "patch",
        url: url,
        data: payload,
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 409) {
                onDone({ status: 'ERROR', data: error.response.data.detail });
            }
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                window.location.href = "/login";
            }
        }
    });
};

const download = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload,
        responseType: 'arraybuffer',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        }
    }).then(res => {
        if (res.data && res.data.code && res.data.code === 400) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else if (res.data && res.data.code && res.data.code === 500) {
            console.log("ERROR: " + res.data.msg)
        } else {
            onDone(res.data);
        }
    }).catch(error => {
        if (error.response.status === 401) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        } else console.log(error);
    });
};


export { get, post, patch, download, archive, loginpost };
