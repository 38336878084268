import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import SelectField from './fields/Select';

function SupportLabour(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    // const level1_saving = (parseFloat(data.step9__level1_tech_count)
    // * parseFloat(data.step9__level1_tech_cost)
    // * (parseFloat(data.step9__level1_volume)/100)
    // * (parseFloat(data.step9__level1_reduction)/100)) || 0;

    // const level2_saving = (parseFloat(data.step9__level2_tech_count)
    // * parseFloat(data.step9__level2_tech_cost)
    // * (parseFloat(data.step9__level2_volume)/100)
    // * (parseFloat(data.step9__level2_reduction)/100)) || 0;

    // const level3_saving = (parseFloat(data.step9__level3_tech_count)
    // * parseFloat(data.step9__level3_tech_cost)
    // * (parseFloat(data.step9__level3_volume)/100)
    // * (parseFloat(data.step9__level3_reduction)/100)) || 0;
    
   

    const external_support_revenue=parseFloat(data.step9__total_expense)*parseFloat(data.step9__estimated_reduction)/100;
    const step9__total =  external_support_revenue;
    const next = () => {
        props.next({ step9__total: step9__total });

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
            <div className='col-10' >
                <h4>Maintenance Contract Expenses</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step9__mvr"
                    value={(data && data["step9__mvr"] && props.mvrs.find(item => item.value === data["step9__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step9__mvr"]}
                />
                </div>
            </div>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step9__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Streamlined Maintenance Contract Expenses</div>
                        <div className='row' >
                            
                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(step9__total), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                    <table className='table'>
                        <tbody>

                            {/* <tr>
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level1_volume"
                                        value={data["step9__level1_volume"] + ""}
                                        label="% of support cases requiring level 1 support"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level1_volume"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level1_tech_cost"
                                        value={data["step9__level1_tech_cost"] + ""}
                                        label="Costs for level 1 support tech per year"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level1_tech_cost"]}
                                        leftMarker="$"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                </td>
                          
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level1_tech_count"
                                        value={data["step9__level1_tech_count"] + ""}
                                        label="Number of level 1 support techs"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level1_tech_count"]}
                                      
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    <Text
                                        fieldColor="sky"
                                        fieldName="step9__level1_reduction"
                                        value={data["step9__level1_reduction"] + ""}
                                        label="% Reduction in costs for level 1 support"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level1_reduction"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                </td>
                             
                                <td style={{ textAlign: "right" }}>
                                    <div>Annual cost savings for level 1 support</div>
                                    <div style={{fontSize:"2.0em"}}>${formatCurrency(level1_saving, 2)}</div>
                                </td>
                              
                            </tr>
                            <tr>
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level2_volume"
                                        value={data["step9__level2_volume"] + ""}
                                        label="% of support cases requiring level 2 support"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level2_volume"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level2_tech_cost"
                                        value={data["step9__level2_tech_cost"] + ""}
                                        label="Costs for level 2 support tech per year"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level2_tech_cost"]}
                                        leftMarker="$"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                </td>
                          
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level2_tech_count"
                                        value={data["step9__level2_tech_count"] + ""}
                                        label="Number of level 2 support techs"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level2_tech_count"]}
                                       
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    <Text
                                        fieldColor="sky"
                                        fieldName="step9__level2_reduction"
                                        value={data["step9__level2_reduction"] + ""}
                                        label="% Reduction in costs for level 2 support"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level2_reduction"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                </td>
                             
                                <td style={{ textAlign: "right" }}>
                                    <div>Annual cost savings for level 2 support</div>
                                    <div style={{fontSize:"2.0em"}}>${formatCurrency(level2_saving, 2)}</div>
                                </td>
                              
                            </tr>
                            <tr>
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level3_volume"
                                        value={data["step9__level3_volume"] + ""}
                                        label="% of support cases requiring level 3 support"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level3_volume"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level3_tech_cost"
                                        value={data["step9__level3_tech_cost"] + ""}
                                        label="Costs for level 3 support tech per year"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level3_tech_cost"]}
                                        leftMarker="$"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                </td>
                          
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__level3_tech_count"
                                        value={data["step9__level3_tech_count"] + ""}
                                        label="Number of level 3 support techs"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level3_tech_count"]}
                                      
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    <Text
                                        fieldColor="sky"
                                        fieldName="step9__level3_reduction"
                                        value={data["step9__level3_reduction"] + ""}
                                        label="% Reduction in costs for level 3 support"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__level3_reduction"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                </td>
                             
                                <td style={{ textAlign: "right" }}>
                                    <div>Annual cost savings for level 3 support</div>
                                    <div style={{fontSize:"2.0em"}}>${formatCurrency(level3_saving, 2)}</div>
                                </td>
                              
                            </tr> */}

                            <tr>
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__total_expense"
                                        value={data["step9__total_expense"] + ""}
                                        label="What is total annual expense in Top Tier/24 Hour service contracts?"
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__total_expense"]}
                                        leftMarker="$"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                   
                                </td>
                          
                                <td>
                                    <Text
                                        fieldColor="blue"
                                        fieldName="step9__estimated_reduction"
                                        value={data["step9__estimated_reduction"] + ""}
                                        label="What is the estimated reduction due to utilization management solution?  "
                                        required={true}
                                        handleChange={handleChange}
                                        error={errors["step9__estimated_reduction"]}
                                        rightMarker="%"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    />
                                    
                                </td>
                             
                                <td style={{ textAlign: "right" }}>
                                    <div>External Support Recovered Revenue</div>
                                    <div style={{fontSize:"2.0em"}}>${formatCurrency(external_support_revenue, 2)}</div>
{/* 
                                    <div>Total Support Recovered Revenue</div>
                                    <div style={{fontSize:"2.0em"}}>${formatCurrency(step9__total, 2)}</div> */}
                                </td>
                              
                            </tr>
                        </tbody>
                    </table>


                </div>

            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default SupportLabour;