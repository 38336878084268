import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading, getStatusColor } from '../../Components/Util.js';
import Header from '../../Components/layout/Header';
import FlagIcon from '../../Components/widgets/FlagIcon';
import * as service from "../../services";
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function ApprovalDashboard(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";

    const initialstate = { sort_by: { value: 'created_at', label: "Created On" }, record_count: 30, sort_direction: 'desc', status:'ALL', view:'APPROVALS' }
    const [loading, setLoading] = useState(true)
    const [store, setStore] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [payload, setPayload] = useState(initialstate);
    const [tabIndex, setTabIndex] = useState(0);
 

    const sort_direction_options = [
        { value: 'id', label: "Ela Quote Id" },
        { value: 'opportunity_id', label: "Opportunity Id" },
        { value: 'opportunity_name', label: "Opportunity Name" },
        { value: 'ela_name', label: "Ela Name" },
        { value: 'end_customer_name', label: "End Customer Name" },
        { value: 'reseller_name', label: "Reseller Name" },
        { value: 'distributor_name', label: "Distributor Name" },
        { value: 'contract_start_date', label: "Contract Start Date" },
        { value: 'created_by_name', label: "Created By" },
        { value: 'created_at', label: "Created On" }

    ];
    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const handleChange = (prop, value) => {
        const _payload = JSON.parse(JSON.stringify(payload));
        _payload[prop] = value;
        console.log(_payload);
        setPayload(_payload);
    };

    const handleLoadMore = () => {
        setShowMore(false);
        let obj = store[store.length - 1];

        const _payload = JSON.parse(JSON.stringify(payload));
        _payload.sort_by = _payload.sort_by.value;
        _payload.lastKey = obj[_payload.sort_by + '_str'];
        service.getElaData("ela-header", _payload, res => {
            setStore([...store, ...res]);
            if (res.length > 29) setShowMore(true)
        });
    };

    const sortClickHandler = val => {
        setPayload({ ...payload, sort_by: val, lastKey: null });
    }

    const sort_directionClickHandler = () => {
        setPayload({ ...payload, sort_direction: payload.sort_direction === "asc" ? "desc" : "asc", lastKey: null });
    }

    const handleReset = () => {
        setPayload(initialstate);
    }
    const selectTab = (current_status, tab_index) =>{
        handleChange('status', current_status)
        setTabIndex(tab_index);
    }
    useEffect(() => {
        startLoading()

        let p1 = new Promise((resolve, reject) => {
            const _payload = JSON.parse(JSON.stringify(payload));
            _payload.sort_by = _payload.sort_by.value;

            service.getElaData("ela-header", _payload, res => {
                setStore(res)
                if (res.length > 29) setShowMore(true)
                resolve("");
            });
        });

        Promise.all([p1])
            .then(values => {
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [payload]);

    const renderSites = (arr) => {
        if (arr && arr.length === 6) {
            const total = arr.reduce((a, b) => a + b, 0);
            //if (total > 0) {
            let ret_arr = []
            arr.forEach((item, index) => {
                ret_arr.push(<div style={{ display: "inline-block", width: "22px" }}>
                    <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", textAlign: "center" }}>{index === 0 ? 'O' : index === 1 ? 'XS' : index === 2 ? 'S' : index === 3 ? 'M' : index === 4 ? 'L' :'XL'}</div>
                    <div style={{ fontWeight: "600", fontSize: "0.65em", lineHeight: "20px", width: "20px", height: "20px", background: item === 0 ? "#cccccc" : "#444444", color: item === 0 ? "#e5e5e5" : "#ffffff", textAlign: "center", borderRadius: "4px" }}>{item}</div>
                </div>)
            });
            ret_arr.push(<div style={{ display: "inline-block", width: "26px", textAlign: "center" }}>
                <div style={{ fontWeight: "600", fontSize: "1em", lineHeight: "26px", width: "26px", height: "26px", background: "#ffffff", color: "#444444", textAlign: "center" }}>{total}</div>
            </div>)
            return ret_arr;
            // }
        }
        return <div style={{ display: "inline-block", width: "26px", textAlign: "center" }}>-</div>;
    }
    const renderGrid = () => {

        return <>
            {store && store.length > 0 ?
                store.map((item, key) => {
                    let link = '';
                    if (item.wizard_step === 1) {
                        link = "/configure-ela/" + item.id + "/ref";
                    } else if (item.wizard_step === 2) {
                        link = "/summary-ela/" + item.id + "/ref";
                    }
                    return <div key={key + '_' + item.id} className="flex-table" >
                    <div className="flex-table-row pt-3" style={{ flex: "1 40%" }}>
                        <div className="flex-table-col">
                            <div className="flex-table-col-content" style={{ flex: "1 1", cursor: "pointer" }} onClick={() => {
                                service.addActivity('ELA', 'OPEN QUOTE APPROVAL', item.id+"");
                                props.history.push(link);
                                }} >
                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>ELA QUOTE</div>
                                <span className="view-link" style={{ fontWeight: "600", fontSize: "1em", textDecoration:"underline" }}>{item.ela_number}</span>
                                <div style={{ fontWeight: "400", fontSize: "0.85em", fontColor: "#222222" }}> {item.ela_name}</div>
                                <div style={{ textAlign:"center", fontSize: "0.95em",marginRight:"30px", color: "#FFFFFF", padding:"2px 10px",background: getStatusColor(item.ela_status) }}>
                                    {item.ela_status}
                                </div>    
                            </div>
                            <div className="flex-table-col-content" style={{ flex: "1 1" , cursor: "pointer" }} onClick={() => props.history.push(link)}>
                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:"5px" }}>Created By</div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em", fontColor: "#222222" }}> {item.created_by_name}</div>
                                <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#222222" }}> {item.created_at_string}</div>
                                {item.approved_at && <><div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "5px" }}>{item.ela_status} By</div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em", fontColor: "#222222" }}> {item.approved_by_name}</div>
                                <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#222222" }}> {item.approved_at_string}</div>
                                </>}
                            </div>
                            <div className="flex-table-col-content" style={{ flex: "1 1" , cursor: "pointer" }} onClick={() => props.history.push(link)}>
                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>End Customer</div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em" }}>{item.end_customer_name}</div>
                                <div>
                                    {item.end_customer_country_code && <FlagIcon code={item.end_customer_country_code.toLowerCase()} />}
                                    <span style={{ paddingLeft: "10px", fontWeight: "400", fontSize: "0.65em", textTransform: "uppercase", wordWrap: "break-word" }}>{item.end_customer_country_name}</span>
                                </div>
                            </div>
                            <div className="flex-table-col-content" style={{ flex: "1 1" , cursor: "pointer" }} onClick={() => props.history.push(link)}>
                            {!item.reseller_name &&  <><div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Channel</div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em" }}>Direct</div>
                                    </>}
                            {item.reseller_name ? <><div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Reseller</div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em" }}>{item.reseller_name}</div>
                                    </> : ''}
                                {item.distributor_name ? <><div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}>Distributor</div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em" }}>{item.distributor_name}</div>
                                 </> : ''}
                            </div>
                            <div className="flex-table-col-content" style={{ flex: "1 1" , cursor: "pointer" }} onClick={() => props.history.push(link)}>
                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", wordWrap: "break-word" }}>
                                    SFDC Opportunity
                                </div>
                                <div style={{ fontWeight: "400", fontSize: "0.85em", wordWrap: "break-word" }}>{item.opportunity_id}</div>
                                <div style={{ fontSize: "0.75em", wordWrap: "break-word" }}>{item.opportunity_name ? item.opportunity_name : "-"}</div>

                            </div>

                        </div>

                    </div>
                    <div className="flex-table-row  pt-3" style={{ flex: "1 60%" }}>
                        <div className="flex-table-col">
                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Contract Term</div>
                                <div style={{ fontWeight: "300", fontSize: "0.75em" }}><i className="mdi mdi-calendar-check" style={{ color: "#808080" }}></i> {item.contract_start_date_string}</div>
                                <div style={{ fontWeight: "300", fontSize: "0.75em" }}><i className="mdi mdi-calendar-remove" style={{ color: "#808080" }}></i> {item.contract_end_date_string}</div>
                                <div style={{ fontWeight: "300", fontSize: "0.75em" }}><i className="mdi mdi-calendar-clock" style={{ color: "#808080" }}></i> {item.contract_length} years</div>
                            </div>
                            <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                    
                                    {item.has_sites && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}><b>Year 1</b> Site Deployment</div>}
                                    {item.has_sites && renderSites(item.split_year1_sites)}
                                    {item.has_xdome && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:item.has_sites?'15px':'0' }}><b>Year 1</b> Assets Deployment</div>}
                                    {item.has_xdome &&  <div style={{ fontWeight: "600", fontSize: "1.1em", fontColor: "#808080" }}>{item.num_year1_assets}</div>}
                                    <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}><b>Year 1</b> value</div>
                                    {item.value_year1 ? <><div style={{ fontWeight: "600", fontSize: "0.85em" }}>
                                        {item.currency_code} {item.value_year1.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </div>
                                        <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                            USD {item.usd_value_year1.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </div>
                                    </>
                                        : <div>-</div>}
                                </div>
                                <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                    
                                {item.has_sites && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}><b>Year 2</b> Site Deployment</div>}
                                    {item.has_sites &&renderSites(item.split_year2_sites)}
                                    {item.has_xdome && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:item.has_sites?'15px':'0' }}><b>Year 2</b> Assets Deployment</div>}
                                    {item.has_xdome &&  <div style={{ fontWeight: "600", fontSize: "1.1em", fontColor: "#808080" }}>{item.num_year2_assets}</div>}
                                    <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}><b>Year 2</b> value</div>
                                    {item.value_year2 ? <><div style={{ fontWeight: "600", fontSize: "0.85em" }}>
                                        {item.currency_code} {item.value_year2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </div>
                                        <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                            USD {item.usd_value_year2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </div>
                                    </>
                                        : <div>-</div>}
                                </div>
                                <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                   
                                {item.has_sites && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}><b>Year 3</b> Site Deployment</div>}
                                    {item.has_sites && renderSites(item.split_year3_sites)}
                                    {item.has_xdome && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:item.has_sites?'15px':'0' }}><b>Year 3</b> Assets Deployment</div>}
                                    {item.has_xdome &&  <div style={{ fontWeight: "600", fontSize: "1.1em", fontColor: "#808080" }}>{item.num_year3_assets}</div>}
                                    <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}><b>Year 3</b> Value</div>
                                    {item.value_year3 ? <><div style={{ fontWeight: "600", fontSize: "0.85em" }}>
                                        {item.currency_code} {item.value_year3.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </div>
                                        <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                            USD {item.usd_value_year3.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </div>
                                    </>
                                        : <div>-</div>}
                                </div>
                                <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                    {item.contract_length > 3 && <>
                                        {item.has_sites && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}><b>Year 4</b> Site Deployment</div>}
                                        {item.has_sites && renderSites(item.split_year4_sites)}
                                        {item.has_xdome && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:item.has_sites?'15px':'0' }}><b>Year 4</b> Assets Deployment</div>}
                                        {item.has_xdome &&  <div style={{ fontWeight: "600", fontSize: "1.1em", fontColor: "#808080" }}>{item.num_year4_assets}</div>}
                                        <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}><b>Year 4</b> Value</div>
                                        {item.value_year4 ? <><div style={{ fontWeight: "600", fontSize: "0.85em" }}>
                                            {item.currency_code} {item.value_year4.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {item.usd_value_year4.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </>
                                            : <div>-</div>}
                                    </>}
                                </div>
                                <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                    {item.contract_length > 4 && <>
                                        {item.has_sites && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}><b>Year 5</b> Site Deployment</div>}
                                        {item.has_sites &&renderSites(item.split_year5_sites)}
                                        {item.has_xdome && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:item.has_sites?'15px':'0' }}><b>Year 5</b> Assets Deployment</div>}
                                    {item.has_xdome &&  <div style={{ fontWeight: "600", fontSize: "1.1em", fontColor: "#808080" }}>{item.num_year5_assets}</div>}
                                        <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}><b>Year 5</b> value</div>
                                        {item.value_year5 ? <><div style={{ fontWeight: "600", fontSize: "0.85em" }}>
                                            {item.currency_code} {item.value_year5.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {item.usd_value_year5.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </>
                                            : <div>-</div>}
                                    </>}
                                </div>

                                <div className="flex-table-col-content" style={{ flex: "1 1" }}>
                                {item.has_sites && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}><b>Total</b> Site Deployment</div>}
                                    {item.has_sites && renderSites(item.split_total_sites)}
                                    {item.has_xdome && <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", marginTop:item.has_sites?'15px':'0' }}><b>Total</b> Assets Deployment</div>}
                                    {item.has_xdome &&  <div style={{ fontWeight: "600", fontSize: "1.1em", fontColor: "#808080" }}>{(item.num_year1_assets+item.num_year2_assets+item.num_year3_assets+item.num_year4_assets+item.num_year5_assets)}</div>}
                                    <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase", paddingTop: "10px" }}><b>Total</b> Value</div>
                                    {item.tcv ? <><div style={{ fontWeight: "600", fontSize: "0.85em" }}>
                                        {item.currency_code} {item.tcv.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </div>
                                        <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                            USD {item.usd_tcv.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </div>
                                    </>
                                        : <div>-</div>}
                                </div>
                        </div>
                    </div>
                </div>
                })
                : <div className="blank-div" style={{ margin: "0 -12px" }}>No Data Found.</div>}
            <div style={{ "textAlign": "center" }}> {store && store.length > 29 && showMore ? <button className="btn btn-outline-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ... </button> : undefined}</div>
        </>
    }
   
    loading ? startLoading() : stopLoading();
    return (
        !loading && <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div className="row" style={{ background: "#000000" }}>
                        <div className="col-12" style={{ padding: "0" }}>
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>ELA Approvals</h3>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                        <div className="col-12 " style={{ padding: "0" }}>
                            <div className="container-fluid mt-1 filter-bar" >
                                <div className="row">
                                    <div style={{ width: "60%", paddingTop: "10px" }}>
                                        <label style={{ fontSize: "12px", color: "#ffffff" }}>SEARCH</label>
                                        <input placeholder='' maxLength="100" style={{ display: "block", padding: "8px", height: "38px", width: "100%", border: "1px solid rgb(204, 204, 204)", borderRadius: "4px" }}
                                            value={payload.search_string || ''}
                                            onChange={(e) => { handleChange('search_string', e.target.value) }} />
                                    </div>
                                    <div style={{ width: "20%", paddingTop: "10px" }}>
                                        <label style={{ fontSize: "12px", color: "#ffffff" }}>SORT BY</label>
                                        <Select
                                            value={payload.sort_by || sort_direction_options[0]}
                                            options={sort_direction_options || []}
                                            required
                                            placeholder="Sort By"
                                            onChange={e => {
                                                sortClickHandler(e);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "10%", paddingTop: "10px" }}>
                                        <label style={{ fontSize: "12px", color: "#ffffff" }}>&nbsp;</label>

                                        <div style={{ cursor: "pointer", padding: "0", width: "100%" }} onClick={sort_directionClickHandler}>{payload.sort_direction === "desc" ?
                                            <button type="button" className="btn btn-outline-light" style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-sort-variant " aria-hidden="true" ></i> Z-A</button> :
                                            <button type="button" className="btn btn-outline-light" style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-sort-reverse-variant" aria-hidden="true" ></i> A-Z</button>}</div>

                                    </div>
                                    <div style={{ width: "10%", paddingTop: "15px" }}>
                                        <label style={{ fontSize: "12px", color: "#ffffff", display: "block" }}>&nbsp;</label>
                                        <button type="button" className="btn btn-outline-light" onClick={() => handleReset()} style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-filter-off " aria-hidden="true" ></i> Reset</button>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="row" style={{ background: "#ffffff" }}>
                        <Tabs style={{ width: "100%" }} defaultIndex={tabIndex} >
                            <TabList>
                                <Tab onClick={() => {selectTab('ALL',0);}}><div className="tab-title" style={{ fontSize: '1.3em' }}>All</div></Tab>
                                <Tab onClick={() => {selectTab('SUBMITTED',1);}}><div className="tab-title" style={{ fontSize: '1.3em' }}>Submitted</div></Tab>
                                <Tab onClick={() => {selectTab('APPROVED',2);}}><div className="tab-title" style={{ fontSize: '1.3em' }}>Approved</div></Tab>
                                <Tab onClick={() => {selectTab('DECLINED',3);}}><div className="tab-title" style={{ fontSize: '1.3em' }}>Declined</div></Tab>
                            </TabList>
                            <TabPanel>
                            {renderGrid()}
                            </TabPanel>
                            <TabPanel>
                            {renderGrid()}
                            </TabPanel>
                            <TabPanel>
                            {renderGrid()}
                            </TabPanel>
                            <TabPanel>
                            {renderGrid()}
                            </TabPanel>
                        </Tabs>
                        
                    </div>

                </div>
            </main>
        </>
    )
}

export default ApprovalDashboard;