import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';
import { formatCurrency } from '../../../../Components/Util';

const RADIAN = Math.PI / 180;
const renderInnerShape = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={-10} textAnchor="middle" fill={fill} width={30}>
        {payload.name}
      </text>
      <text  x={cx} y={cy}  dy={8} textAnchor="middle" fill="#333">{`$${formatCurrency(value,2)}`}</text>
      <text x={cx} y={cy}  dy={26} textAnchor="middle" fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius - 13}
        outerRadius={innerRadius - 9}
        fill={fill}
      />
    
  
      
    </g>
  );
};
const renderActiveShape = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey}  dy={18} textAnchor={textAnchor} fill="#333">{`$${formatCurrency(value,2)}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={36} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
//https://www.htmlcsscolor.com/hex/680038
const COLORS2 = ['#00C49F', '#680038',  '#003868', '#380068' ];
const COLORS = ['#5CD9C1', '#9E5C80', '#B17D99', '#C197AD', '#336086', '#5C809E', '#7D99B1',  '#97ADC1', '#ACBDCD', '#603386','#805C9E','#997DB1'];
const renderLegend = (props) => {
  const { payload } = props;

  return (
    <div className='row'>
      {
        payload.map((entry, index) => {

          return <div className="col-4" key={`item-${index}`} style={{fontSize:"0.75em"}}>
            <div style={{width:"10px", height:"10px", background:entry.color, display:"inline-block", marginRight:"2px"}}></div>
            {entry.value}
          </div>;
})
      }
    </div>
  );
}
export default class Donut extends PureComponent {
  state = {
    activeIndex: 0,
    activeInnerIndex:0
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };
  onInnerPieEnter = (_, index) => {
    this.setState({
      activeInnerIndex: index,
    });
  };
  render() {
    return (
<ResponsiveContainer width="100%" height="100%">
        <PieChart >
          <Pie data={this.props.inner} dataKey="value" outerRadius={145} innerRadius={110} fill="#8884d8" activeIndex={this.state.activeInnerIndex}
            activeShape={renderInnerShape} onMouseEnter={this.onInnerPieEnter} >
          {this.props.inner.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}

          </Pie>
          <Pie data={this.props.outer} dataKey="value" innerRadius={150} outerRadius={185} fill="#82ca9d" activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape} onMouseEnter={this.onPieEnter}>
          {this.props.outer.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
            ))}
          </Pie>
          <Legend content={renderLegend} />
        </PieChart>
        </ResponsiveContainer>
    );
  }
}