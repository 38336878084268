import React, { useEffect, useState } from 'react';
import { startLoading, stopLoading } from '../../Components/Util.js';
import * as service from "../../services";


function UserDashboard(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";

    const initialstate = { sortBy: 'id', recordsPerPage: 30, sortOrder: 'desc' }
    const [loading, setLoading] = useState(true)
    const [store, setStore] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [payload, setPayload] = useState(initialstate);

    useEffect(() => {
        startLoading()
        let p1 = new Promise((resolve, reject) => {
            service.getUsersDashboard(payload, res => {
                setStore(res)
                if (res.length > 29) setShowMore(true)
                resolve("");
            });
        });

        Promise.all([p1])
            .then(values => {
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [payload]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const handleChange = (prop, value) => {
        setPayload(payload => ({ ...payload, [prop]: value }));
    };

    const handleLoadMore = () => {
        setShowMore(false);
        let obj = store[store.length - 1];
        payload.lastKey = obj.model_id; 

        const _payload = JSON.parse(JSON.stringify(payload));
        //_payload.sortBy = _payload.sort_by.value;
        _payload.lastKey = obj[_payload.sortBy];

        service.getUsersDashboard(_payload, res => {
            
            setStore([...store, ...res]);
            console.log(store);
            if (res.length > 29) setShowMore(true)
        });

       
    };

    const handleReset = () => {
        setPayload(initialstate);
    }

    loading ? startLoading() : stopLoading();
    return <div className="col-12 mt-3" style={{ background: "#f8f9fa" }}>
        <div className="container  filter-bar">
            <div className="row">
                <div style={{ width: "90%", paddingTop: "10px" }}>
                    <label style={{ fontSize: "13px", color: "#FFFFFF" }}>USER</label>
                    <input placeholder='' maxLength="100" style={{ display: "block", padding: "8px", height: "38px", width: "100%", border: "1px solid rgb(204, 204, 204)", borderRadius: "4px" }}
                        value={payload.searchString || ''}
                        onChange={(e) => { handleChange('searchString', e.target.value) }} />
                </div>

                <div style={{ width: "10%", paddingTop: "15px" }}>
                    <label style={{ fontSize: "12px", color: "#ffffff", display: "block" }}>&nbsp;</label>
                    <button type="button" className="btn btn-outline-light" onClick={() => handleReset()} style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-filter-off " aria-hidden="true" ></i> Reset</button>
                </div>
            </div>
        </div>
        <div className="container" style={{ padding: 0 }}>
            {store && store.length > 0 ?
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Roles</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {store.map((item, index) => {
                            return <tr key={index}>
                                <th scope="row" style={{ cursor: 'pointer' }} onClick={() => props.history.push('/userdetail/' + item.email + '/DETAIL')} >{item.firstName + ' ' + item.lastName}</th>
                                <td>{item.email}</td>
                                <td>{item.roles}</td>
                                <td>{item.status}</td>
                            </tr>
                        })}
                    </tbody>
                </table> :
                <div className="blank-div" style={{ margin: "0 -12px" }}>No Data Found.</div>}
            <div style={{ "textAlign": "center" }}> {store && store.length > 29 && showMore ? <button className="btn btn-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ...</button> : undefined}</div>
        </div>
    </div>
}

export default UserDashboard;