import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading } from '../../Components/Util';
import * as service from "../../services";
import { useAuthState } from '../../Context';

function ConfigureSite(props) {
    const userDetails = useAuthState()
    let { userType } = userDetails.user;

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [recipe, setRecipe] = useState({});
    const [quoteHeader, setQuoteHeader] = useState({});
    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.quote_id) > 0) {

                service.getElaData("ela-header", { id: props.match.params.quote_id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }
                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getElaData("get_site_by_id", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setRecipe({ ...res[0] });
                        resolve("");
                    }
                });
            } else {
                setRecipe({ quote_id: props.match.params.quote_id });
                resolve("");
            }
        });
        Promise.all([p1, p2])
            .then(values => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.quote_id, props.match.params.id]);

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "site_description", msg: "Please enter Site Configuration Name" },
            { field: "site_size", msg: "Please choose Site Size" }
           
        ];
        if (!recipe.include_ctd) {
            recipe.include_ao_active=false;
            recipe.include_ao_app_db=false;
            recipe.include_ao_edge=false;
            recipe.include_ao_factory_talk_asset_centre=false;
            recipe.exclude_ctd_hw=false;
        } else{
            validation.push( { field: "ctd_services", msg: "Please choose Services" });
        }
        if (recipe.include_sensors_small) {
            validation.push( { field: "small_sensor_services", msg: "Please choose Services" });
            validation.push({ field: "small_sensor_count", msg: "Please enter Number of Small Sensors per site" })
        } else {
            recipe.small_sensor_count = 0;
            recipe.exclude_small_sensor_hw=false;
        }
        if (recipe.include_sensors_large) {
            validation.push( { field: "large_sensor_services", msg: "Please choose Services" });
            validation.push({ field: "large_sensor_count", msg: "Please enter Number of Large Sensors per site" })
        } else {
            recipe.large_sensor_count = 0;
            recipe.exclude_large_sensor_hw=false;
        }
        if (recipe.include_emc) {
            validation.push({ field: "emc_console_count", msg: "Please enter Number of EMC Console per sites" })
        } else {
            recipe.emc_console_count = 0;
            recipe.include_emc_ha=false;
        }
        if (recipe.include_edge) {
            validation.push( { field: "edge_services", msg: "Please choose Services" });
        } 

        if (recipe.include_sra_site) {
            validation.push( { field: "sra_site_services", msg: "Please choose Services" });
            validation.push({ field: "sra_site_server_count", msg: "Please enter Number of SRA Site Servers per site" })
        } else {
            recipe.sra_site_server_count = 0;
            recipe.exclude_sra_hw=false;
            recipe.include_sra_site_ha=false;
        }
        if (!recipe.include_ctd && !recipe.include_sensors_small && !recipe.include_sensors_large && !recipe.include_sra_site && !recipe.include_edge) {
            setErrors(errors => ({ ...errors, option: "Please select an option" }));
            isValid = false;
        }
        if ((recipe.year1_count || 0) + (recipe.year2_count || 0) + (recipe.year3_count || 0) + (recipe.year4_count || 0) + (recipe.year4_count || 0) < 1) {
            setErrors(errors => ({ ...errors, deployment: "Please enter deployment schedule" }));
            isValid = false;
        } else {
            
            if (parseInt(recipe.year1_count || 0) + parseInt(recipe.year2_count || 0) + parseInt(recipe.year3_count || 0) + parseInt(recipe.year4_count || 0) + parseInt(recipe.year4_count || 0) < 20) {
                if(!userType.isElaDDUser){
                    setErrors(errors => ({ ...errors, deployment: "Minimum 20 sites are required to quote ELA" }));
                    isValid = false;
                }
            }
        }
        validation.map(item => {
            console.log(recipe);
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };


    const handleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }));
    };
    const handleNext = () => {
        if (validate()) {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(recipe));
            console.log(payload);
            payload.year1_count=payload.year1_count || 0;
            payload.year2_count=payload.year2_count || 0;
            payload.year3_count=payload.year3_count || 0;
            payload.year4_count=payload.year4_count || 0;
            payload.year5_count=payload.year5_count || 0;
            service.upsertEla('ela-site', payload, res => {
                console.log(res);
                props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref');
            });
        }
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>Configure Ela Quote</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.site_size + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal? 'Yes':'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email?' [EMAIL: '+quoteHeader.sales_director_email+']':'') + (quoteHeader.sales_director_phone?' [CONTACT #: '+quoteHeader.sales_director_phone+']':'')}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <h4>OnPrem Site Configuration</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">

                                                        <div className="col-sm-12">
                                                            <label htmlFor="site_description" className="form-label">Site Configuration Name <span className="required">*</span></label>
                                                            <input type="text" className="form-control" name="site_description" placeholder=""
                                                                value={recipe.site_description || ''}
                                                                maxLength="100"
                                                                onChange={(e) => { handleChange('site_description', (e.target.validity.valid) ? e.target.value : '') }} />
                                                            {errors.site_description && <div className="validation-error">{errors.site_description}</div>}
                                                        </div>
                                                        
                                                        <div className="col-sm-12">
                                                            <label className="form-label"> Site Size <span className="required">*</span></label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                <label className={recipe.site_size === 'O' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_O">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_O" autoComplete="off" checked={recipe.site_size === 'O'}
                                                                        onChange={(e) => { handleChange('site_size', e.target.checked ? 'O' : undefined) }}
                                                                    />
                                                                    One Size
                                                                    <div style={{ fontSize: "0.65em" }}>One Size</div>
                                                                </label>
                                                                <label className={recipe.site_size === 'XS' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_XS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_XS" autoComplete="off" checked={recipe.site_size === 'XS'}
                                                                        onChange={(e) => { handleChange('site_size', e.target.checked ? 'XS' : undefined) }}
                                                                    />
                                                                    Extra Small
                                                                    <div style={{ fontSize: "0.65em" }}>Less than 50 assets</div>
                                                                </label>
                                                                <label className={recipe.site_size === 'S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_S">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_S" autoComplete="off" checked={recipe.site_size === 'S'}
                                                                        onChange={(e) => { handleChange('site_size', e.target.checked ? 'S' : undefined) }}
                                                                    />
                                                                    Small
                                                                    <div style={{ fontSize: "0.65em" }}>Less than 300 assets</div>
                                                                </label>
                                                                <label className={recipe.site_size === 'M' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_M">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_M" autoComplete="off" checked={recipe.site_size === 'M'}
                                                                        onChange={(e) => { handleChange('site_size', e.target.checked ? 'M' : undefined) }}
                                                                    />
                                                                    Medium
                                                                    <div style={{ fontSize: "0.65em" }}>Less than 1,500 assets</div>
                                                                </label>
                                                                <label className={recipe.site_size === 'L' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_L">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_L" autoComplete="off" checked={recipe.site_size === 'L'}
                                                                        onChange={(e) => { handleChange('site_size', e.target.checked ? 'L' : undefined) }}
                                                                    />
                                                                    Large
                                                                    <div style={{ fontSize: "0.65em" }}>Less than 5,000 assets</div>
                                                                </label>
                                                                <label className={recipe.site_size === 'XL' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_XL">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_XL" autoComplete="off" checked={recipe.site_size === 'XL'}
                                                                        onChange={(e) => { handleChange('site_size', e.target.checked ? 'XL' : undefined) }}
                                                                    />
                                                                    Extra Large
                                                                    <div style={{ fontSize: "0.65em" }}>Less than 12,000 assets</div>
                                                                </label>
                                                            </div>
                                                            {errors.site_size && <div className="validation-error">{errors.site_size}</div>}
                                                        </div>
                                                        
                                                        <div className="col-sm-12">
                                                        {errors.option && <div className="validation-error">{errors.option}</div>}
                                                            <div className="row g-3">
                                                                <div className="col-sm-12 ">

                                                                    <ul className="list-group">
                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_ctd}
                                                                                        onChange={(e) => { handleChange('include_ctd', e.target.checked) }} />
                                                                                    <span>CTD</span>
                                                                                </div>
                                                                                {recipe.include_ctd && <div className="col-sm-8">
                                                                                    <label className="form-label"> Select CTD Add Ons</label>
                                                                                    <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                                        <label className={recipe.include_ao_app_db ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_ao_app_db">
                                                                                            <input type="checkbox" name="include_ao_app_db" id="include_ao_app_db" autoComplete="off" checked={recipe.include_ao_app_db}
                                                                                                onChange={(e) => { handleChange('include_ao_app_db', e.target.checked) }}
                                                                                            />
                                                                                            <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>AppDB</div>
                                                                                        </label>
                                                                                        <label className={recipe.include_ao_active ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_ao_active">
                                                                                            <input type="checkbox" name="include_ao_active" id="include_ao_active" autoComplete="off" checked={recipe.include_ao_active}
                                                                                                onChange={(e) => { handleChange('include_ao_active', e.target.checked) }}
                                                                                            />
                                                                                            <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>Active</div>
                                                                                        </label>

                                                                                        <label className={recipe.include_ao_edge ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_ao_edge">
                                                                                            <input type="checkbox" name="include_ao_edge" id="include_ao_edge" autoComplete="off" checked={recipe.include_ao_edge}
                                                                                                onChange={(e) => { handleChange('include_ao_edge', e.target.checked) }}
                                                                                            />
                                                                                            <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>Edge (with CTD)</div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="mt-3">
                                                                                        <label className="form-label"> Services<span className="required">*</span></label>
                                                                                        <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                                            <label className={recipe.ctd_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="ctd_services_HW_SW_PS">
                                                                                                <input type="radio" className="btn-check" name="btnradio" id="ctd_services_HW_SW_PS" autoComplete="off" checked={recipe.ctd_services === 'HW+PS+SW'}
                                                                                                    onChange={(e) => { handleChange('ctd_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                                                />
                                                                                                Hardware + Software + Professional Services
                                                                                            </label>
                                                                                            <label className={recipe.ctd_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="ctd_services_SW_PS">
                                                                                                <input type="radio" className="btn-check" name="btnradio" id="ctd_services_SW_PS" autoComplete="off" checked={recipe.ctd_services === 'SW + M&S'}
                                                                                                    onChange={(e) => { handleChange('ctd_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                                                />
                                                                                                Software + Professional Services Only
                                                                                            </label>
                                                                                            
                                                                                        </div>
                                                                                        {errors.ctd_services && <div className="validation-error">{errors.ctd_services}</div>}
                                                                                    </div>
                                                                                </div>
                                                                                }
                                                                               
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_sensors_small}
                                                                                        onChange={(e) => { handleChange('include_sensors_small', e.target.checked) }} />
                                                                                    <span>Small Sensors</span>
                                                                                </div>
                                                                                <div className="col-sm-8 ">
                                                                                    {recipe.include_sensors_small && <>

                                                                                        <div className="col-sm-12">
                                                                                            <label htmlFor="small_sensor_count" className="form-label">Number of Small Sensors per site<span className="required">*</span></label>
                                                                                            <input type="text" className="form-control" name="small_sensor_count" placeholder=""
                                                                                                value={recipe.small_sensor_count || ''}
                                                                                                maxLength="100"
                                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                                                                style={{ width: "auto" }}
                                                                                                onChange={(e) => { handleChange('small_sensor_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                            {errors.small_sensor_count && <div className="validation-error">{errors.small_sensor_count}</div>}
                                                                                        </div>

                                                                                        <div className="mt-3">
                                                            <label className="form-label"> Services<span className="required">*</span></label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                <label className={recipe.small_sensor_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="small_sensor_services_HW_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="small_sensor_services_HW_SW_PS" autoComplete="off" checked={recipe.small_sensor_services === 'HW+PS+SW'}
                                                                        onChange={(e) => { handleChange('small_sensor_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                    />
                                                                    Hardware + Software + Professional Services
                                                                </label>
                                                                <label className={recipe.small_sensor_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="small_sensor_services_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="small_sensor_services_SW_PS" autoComplete="off" checked={recipe.small_sensor_services === 'SW + M&S'}
                                                                        onChange={(e) => { handleChange('small_sensor_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                    />
                                                                    Software + Professional Services Only
                                                                </label>
                                                                
                                                            </div>
                                                            {errors.small_sensor_services && <div className="validation-error">{errors.small_sensor_services}</div>}
                                                        </div>
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_sensors_large}
                                                                                        onChange={(e) => { handleChange('include_sensors_large', e.target.checked) }} />
                                                                                    <span>Large Sensors</span>
                                                                                </div>
                                                                                <div className="col-sm-8 ">
                                                                                    {recipe.include_sensors_large && <>
                                                                                        <div className="col-sm-12">
                                                                                            <label htmlFor="large_sensor_count" className="form-label">Number of Large Sensors per site<span className="required">*</span></label>
                                                                                            <input type="text" className="form-control" name="large_sensor_count" placeholder=""
                                                                                                value={recipe.large_sensor_count || ''}
                                                                                                maxLength="100"
                                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                                                                style={{ width: "auto" }}
                                                                                                onChange={(e) => { handleChange('large_sensor_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                            {errors.large_sensor_count && <div className="validation-error">{errors.large_sensor_count}</div>}
                                                                                        </div>
                                                                                        <div className="mt-3">
                                                            <label className="form-label"> Services<span className="required">*</span></label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                <label className={recipe.large_sensor_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="large_sensor_services_HW_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="large_sensor_services_HW_SW_PS" autoComplete="off" checked={recipe.large_sensor_services === 'HW+PS+SW'}
                                                                        onChange={(e) => { handleChange('large_sensor_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                    />
                                                                    Hardware + Software + Professional Services
                                                                </label>
                                                                <label className={recipe.large_sensor_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="large_sensor_services_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="large_sensor_services_SW_PS" autoComplete="off" checked={recipe.large_sensor_services === 'SW + M&S'}
                                                                        onChange={(e) => { handleChange('large_sensor_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                    />
                                                                    Software + Professional Services Only
                                                                </label>
                                                                
                                                            </div>
                                                            {errors.large_sensor_services && <div className="validation-error">{errors.large_sensor_services}</div>}
                                                        </div>
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                       


                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_sra_site}
                                                                                        onChange={(e) => { handleChange('include_sra_site', e.target.checked) }} />
                                                                                    <span>SRA</span>
                                                                                </div>
                                                                                <div className="col-sm-8 ">
                                                                                    {recipe.include_sra_site && <>
                                                                                        <div >
                                                                                            <label htmlFor="sra_site_server_count" className="form-label">Number of SRA Servers per site<span className="required">*</span></label>
                                                                                            <input type="text" className="form-control" name="sra_site_server_count" placeholder=""
                                                                                                value={recipe.sra_site_server_count || ''}
                                                                                                maxLength="100"
                                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                                                                style={{ width: "auto" }}
                                                                                                onChange={(e) => { handleChange('sra_site_server_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                            {errors.sra_site_server_count && <div className="validation-error">{errors.sra_site_server_count}</div>}
                                                                                        </div>
                                                                                     <div className='mt-3'>
                                                                                        <input
                                                                                            className="form-check-input me-1"
                                                                                            type="checkbox"
                                                                                            disabled={!recipe.include_sra_site}
                                                                                            checked={recipe.include_sra_site && recipe.include_sra_site_ha}
                                                                                            onChange={(e) => { handleChange('include_sra_site_ha', e.target.checked) }} />
                                                                                        <span className="form-label">SRA High Availability</span>
                                                                                    </div>
                                                                                    <div className="mt-3">
                                                            <label className="form-label"> Services<span className="required">*</span></label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                <label className={recipe.sra_site_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="sra_site_services_HW_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="sra_site_services_HW_SW_PS" autoComplete="off" checked={recipe.sra_site_services === 'HW+PS+SW'}
                                                                        onChange={(e) => { handleChange('sra_site_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                    />
                                                                    Hardware + Software + Professional Services
                                                                </label>
                                                                <label className={recipe.sra_site_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="sra_site_services_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="sra_site_services_SW_PS" autoComplete="off" checked={recipe.sra_site_services === 'SW + M&S'}
                                                                        onChange={(e) => { handleChange('sra_site_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                    />
                                                                    Software + Professional Services Only
                                                                </label>
                                                                
                                                            </div>
                                                            {errors.sra_site_services && <div className="validation-error">{errors.sra_site_services}</div>}
                                                        </div>
                                                                                    </>}

                                                                                </div>
                                                                            </div>
                                                                        </li>


                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_edge}
                                                                                        onChange={(e) => { handleChange('include_edge', e.target.checked) }} />
                                                                                    <span>Edge (Not included in CTD)</span>
                                                                                </div>
                                                                                <div className="col-sm-8 ">
                                                                                    {recipe.include_edge && <>
                                                                                        <div >
                                                            <label className="form-label"> Services<span className="required">*</span></label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                <label className={recipe.edge_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="edge_services_HW_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="edge_services_HW_SW_PS" autoComplete="off" checked={recipe.edge_services === 'HW+PS+SW'}
                                                                        onChange={(e) => { handleChange('edge_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                    />
                                                                    Hardware + Software + Professional Services
                                                                </label>
                                                                <label className={recipe.edge_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="edge_services_SW_PS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="edge_services_SW_PS" autoComplete="off" checked={recipe.edge_services === 'SW + M&S'}
                                                                        onChange={(e) => { handleChange('edge_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                    />
                                                                    Software + Professional Services Only
                                                                </label>
                                                                
                                                            </div>
                                                            {errors.edge_services && <div className="validation-error">{errors.edge_services}</div>}
                                                        </div>
                                                                                    </>}
                                                                                    </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className="row mt-3" style={{ border: "1px solid #CCCCCC", margin: "3px", padding: "20px", background: "#EAEAEA" }}>
                                                                <div className="col-sm-12">
                                                                    <h4>Deployment Schedule</h4>
                                                                    <div>Enter the number of sites with this configuration deployed during each year of the contract.</div>
                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                        <label htmlFor="year1_count" className="form-label">Deployed during year 1 <span className="required">*</span></label>
                                                                        <input type="text" className="form-control" name="year1_count" placeholder=""
                                                                            value={recipe.year1_count || ''}
                                                                            maxLength="100"
                                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                            onChange={(e) => { handleChange('year1_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                        {errors.year1_count && <div className="validation-error">{errors.year1_count}</div>}
                                                                    </div>
                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                        <label htmlFor="year2_count" className="form-label">Deployed during year 2 <span className="required">*</span></label>
                                                                        <input type="text" className="form-control" name="year2_count" placeholder=""
                                                                            value={recipe.year2_count || ''}
                                                                            maxLength="100"
                                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                            onChange={(e) => { handleChange('year2_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                        {errors.year2_count && <div className="validation-error">{errors.year2_count}</div>}
                                                                    </div>
                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                        <label htmlFor="year3_count" className="form-label">Deployed during year 3 <span className="required">*</span></label>
                                                                        <input type="text" className="form-control" name="year3_count" placeholder=""
                                                                            value={recipe.year3_count || ''}
                                                                            maxLength="100"
                                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                            onChange={(e) => { handleChange('year3_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                        {errors.year3_count && <div className="validation-error">{errors.year3_count}</div>}
                                                                    </div>
                                                                    {quoteHeader.contract_length > 3 &&
                                                                        <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                            <label htmlFor="year4_count" className="form-label">Deployed during year 4 <span className="required">*</span></label>
                                                                            <input type="text" className="form-control" name="year4_count" placeholder=""
                                                                                value={recipe.year4_count || ''}
                                                                                maxLength="100"
                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                                onChange={(e) => { handleChange('year4_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                            {errors.year4_count && <div className="validation-error">{errors.year4_count}</div>}
                                                                        </div>
                                                                    }
                                                                    {quoteHeader.contract_length > 4 &&
                                                                        <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                            <label htmlFor="year5_count" className="form-label">Deployed during year 5 <span className="required">*</span></label>
                                                                            <input type="text" className="form-control" name="year5_count" placeholder=""
                                                                                value={recipe.year5_count || ''}
                                                                                maxLength="100"
                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                                onChange={(e) => { handleChange('year5_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                            {errors.year4_coyear5_countunt && <div className="validation-error">{errors.year5_count}</div>}
                                                                        </div>
                                                                    }
                                                                    {errors.deployment && <div className="validation-error">{errors.deployment}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                                        <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default ConfigureSite;


