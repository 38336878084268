import React, { useState, useEffect } from 'react';
import * as service from "../services";
import { useAuthDispatch } from '../Context';
import { startLoading, stopLoading, isEmpty } from './../Components/Util.js';
import axios from "axios";

function Login(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "centered-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "centered-body";

    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [password3, setPassword3] = useState('')
    const [warning, setWarning] = useState(undefined);
    const [resetPasswordRequired, setResetPasswordRequired] = useState(false);
    const [resetMFARequired, setResetMFARequired] = useState(false);
    const [qrCode, setQrCode] = useState(undefined);
    const [mfaCode, setMFACode] = useState(undefined);
    const [mfaCodeRequired, setMFACodeRequired] = useState(false);
    
    const dispatch = useAuthDispatch()
    const [errors, setError] = useState({})

    const validate = () => {
        let errors = {}
        if (!username || username.length <= 0) errors.username = 'Please enter Email Address.';
        if (!password || password.length <= 0) errors.password = 'Please enter Password.';
        setError(errors)
        return isEmpty(errors)
    }

    useEffect(() => {
        axios.get("/api/pulse" ).then(pres => {
            let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
            if (user) {
                dispatch({ type: 'LOGIN_SUCCESS', payload: user });
                props.history.push('/router');
            }
        });
    });

    const handleMFADone =() =>{
        service.enableMFA(() =>{
            dispatch({ type: 'LOGOUT' });
            props.history.push('/router');
        });
    }
    const validateMFA = () => {
        let errors = {}
        
        if (!mfaCode || mfaCode.length !== 6) errors.mfaCode = 'Please enter 6 digit MFA Code.';
        console.log(errors);
        setError(errors)
        return isEmpty(errors)
    }
    const handleMFASubmit =() =>{
        
        if(validateMFA()){
            console.log("loginMFA");
            startLoading();
            service.loginMFA({ username: username, password: password, mfaCode:mfaCode }, (data) => {
                if (data && data.token) {
                    localStorage.setItem('token', JSON.stringify(data.token));
                    service.getInstrospect(res => {
                        if (res.user && res.user.statusId === 2) {
                            localStorage.setItem('user', JSON.stringify(res));
                            dispatch({ type: 'LOGIN_SUCCESS', payload: res });
                            props.history.push('/router');
                        } else if (res.user && res.user.statusId === 1) {//New
                            console.log("reset password required.");
                            setResetPasswordRequired(true);
                            stopLoading();
                        } else if (res.user && res.user.statusId === 3) {//Disabled
                            console.log("User Disabled.");
                            setWarning('You are not authorized, Please contact to the system administrator.')
                            stopLoading();
                        }
                    })
                } else {
                    stopLoading();
                    let errors = {};
                    errors.mfaCode = "Incorrect Code";
                    setError(errors)
                    console.log(errors);
                }
            });
        }
    }
    const handleLogin = () => {
        if (validate()) {
            startLoading();
            service.login({ username: username, password: password, mfaCode:"" }, (data) => {
                if (data && data.token) {
                    localStorage.setItem('token', JSON.stringify(data.token));
                    service.getInstrospect(res => {
                        if (res.user && res.user.statusId === 2) {
                            if(res.user.mfaRequired){
                                if(res.user.mfaEnabled){
                                    setMFACodeRequired(true);
                                    stopLoading(); 
                                } else{
                                    setResetMFARequired(true);
                                    setQrCode(res.user.dataUri);
                                    stopLoading(); 
                                }
                            } else{
                                localStorage.setItem('user', JSON.stringify(res));
                                dispatch({ type: 'LOGIN_SUCCESS', payload: res });
                                props.history.push('/router');
                            }
                        } else if (res.user && res.user.statusId === 1) {//New
                            console.log("reset password required.");
                            setResetPasswordRequired(true);
                            stopLoading();
                        } else if (res.user && res.user.statusId === 3) {//Disabled
                            console.log("User Disabled.");
                            setWarning('You are not authorized, Please contact to the system administrator.')
                            stopLoading();
                        }
                    })
                } else {
                    stopLoading();
                    let errors = {};
                    errors.password = data.msg;
                    setError(errors)
                }
            });
        }
    }

    const validatePassword = () => {
        let errors = {}
        if (!password2 || password2.length <= 0) errors.password2 = 'Please enter New Password.';
        if (!password3 || password3.length <= 0) errors.password3 = 'Please enter Confirm New Password.';
        if (password2 && password3 && password2 !== password3) errors.password3 = 'Password mismatch.';
        setError(errors)
        return isEmpty(errors)
    }

    const handleSubmit = () => {
        if (validatePassword()) {
            startLoading();
            service.updatePassword({ email: username, password: password2, statusId: 2 }, res => {
                stopLoading();
                service.getInstrospect(res => {
                    localStorage.setItem('user', JSON.stringify(res));
                    dispatch({ type: 'LOGIN_SUCCESS', payload: res });
                    props.history.push('/router');
                })
            });
        }
    }

    return (
        <div className="text-center auto-height">
            <main className="form-signin auto-height">
                {!resetPasswordRequired && !resetMFARequired && <form className="auto-height">
                    <div className="brand-logo"></div>
                    {/* <h1 className="h3 mb-3 fw-normal" style={{ fontSize: "1.1rem" }}>Business Value Assessment</h1> */}
                    {!warning ? <>
                        <div className="form-floating">
                            <input type="email" id="floatingInput" className="form-control" placeholder="Email address" value={username || ''} onChange={(e) => setUserName(e.target.value)} required autoFocus />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" id="floatingPassword" className="form-control" autoComplete="off" placeholder="Password" value={password || ''} onChange={(e) => setPassword(e.target.value)} required />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <div className="forgot-link" onClick={() => props.history.push('/forgot')}> forgot password? </div>
                        {errors.username && <p className="help is-danger">{errors.username}</p>}
                        {errors.password && <p className="help is-danger">{errors.password}</p>}
                        <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => handleLogin()}>Sign in</button>
                    </> : <p className="help is-danger"><i className="mdi mdi-account-lock-outline" style={{ color: 'red' }}></i> {warning}</p>}
                    <p className="mt-5 mb-3 text-muted">Claroty &copy; 2022</p>
                </form>}
                {resetMFARequired && <div className="auto-height" style={{width:"660px", marginLeft:"-330px"}}>
                <div className="brand-logo" style={{maxWidth:"660px"}}></div>
                    {/* <h1 className="h3 mb-3 fw-normal" style={{ fontSize: "1.1rem" }}>Business Value Assessment</h1> */}
                    <h1 className="mb-3 fw-normal" style={{ fontSize: "1.0rem", fontWeight:"400" }}>Setup Multi Factor Authentication</h1>
                    <ol style={{textAlign:"left", fontWeight:"300"}}>
                        <li>Scan the provided QR code using a authenticator app (like <a href="https://support.google.com/accounts/answer/1066447?hl=en">Google Authenticator</a>, <a href="https://authy.com/">Authy</a>, <a href="https://1password.com/">1Password</a>, <a href="https://www.microsoft.com/en-us/account/authenticator">Microsoft Authenticator</a> or <a href="https://duo.com/">Duo</a> ) on your phone or tablet. This links your device to your application account.
                        <br/>
                        <img src={qrCode} alt="star" width="200" height="200" style={{marginLeft:"230px"}} />
                        </li>
                        <li>Once your account and MFA app are linked, when you log into the application, you will need to open the app and enter the code  to finish logging in.</li>
                        <li>Some authenticator apps have features like backups and syncing to help you restore access to the app if you lose your device. We recommend using these features for added reliability.</li>
                    </ol>
                    <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => handleMFADone()}>Done</button>

                    <p className="mt-5 mb-3 text-muted">Claroty &copy; 2022</p>

                </div>}
                {mfaCodeRequired && <form className="auto-height">
                    <div className="brand-logo"></div>
                    {/* <h1 className="h3 mb-3 fw-normal" style={{ fontSize: "1.1rem" }}>Business Value Assessment</h1> */}
                    <div className="form-floating">
                        <input  id="floatingInput" className="form-control" placeholder="Six digit MFA Code" value={mfaCode || ''} 
                            onChange={(e) => setMFACode(e.target.value)} required autoFocus />
                        <label htmlFor="floatingInput">MFA Code</label>
                    </div>
                    
                    {errors.mfaCode && <p className="help is-danger" style={{color:"#CC0000"}}>{errors.mfaCode}</p>}
                    <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => handleMFASubmit()}>Submit</button>
                    <p className="mt-5 mb-3 text-muted">Claroty &copy; 2022</p>
                </form>}

                {resetPasswordRequired && <form className="auto-height">
                    <div className="brand-logo"></div>
                    {/* <h1 className="h3 mb-3 fw-normal" style={{ fontSize: "1.1rem" }}>Business Value Assessment</h1> */}
                    <div className="form-floating">
                        <input type="email" id="floatingInput" className="form-control" placeholder="Email address" value={username || ''} disabled={true}
                            onChange={(e) => setUserName(e.target.value)} required autoFocus />
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" id="floatingPassword" className="form-control" autoComplete="off" placeholder="Password" value={password2 || ''} onChange={(e) => setPassword2(e.target.value)} required />
                        <label htmlFor="floatingPassword">New Password</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" id="floatingPassword2" className="form-control" autoComplete="off" placeholder="Confirm New Password" value={password3 || ''} onChange={(e) => setPassword3(e.target.value)} required />
                        <label htmlFor="floatingPassword2">Confirm New Password</label>
                    </div>
                    {errors.password2 && <p className="help is-danger">{errors.password2}</p>}
                    {errors.password3 && <p className="help is-danger">{errors.password3}</p>}
                    <button className="w-100 btn btn-lg socially" style={{ color: "#ffffff" }} type="button" onClick={() => handleSubmit()}>Submit</button>
                    <p className="mt-5 mb-3 text-muted">Claroty &copy; 2022</p>
                </form>}

            </main>
        </div>
    )
}

export default Login;
