import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import SelectField from './fields/Select';
function OutdatedOS(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    let device_cost_bm=0.2 * parseFloat(data.step4__number_of_devices_bm) * parseFloat(data.step4__average_device_cost_bm);
    let device_cost_ciot=0.2 * parseFloat(data.step4__number_of_devices_ciot) * parseFloat(data.step4__average_device_cost_ciot);
    let device_cost_cl=0.2 * parseFloat(data.step4__number_of_devices_cl) * parseFloat(data.step4__average_device_cost_cl);
    let device_cost_giot=0.2 * parseFloat(data.step4__number_of_devices_giot) * parseFloat(data.step4__average_device_cost_giot);
    let device_cost_img=0.2 * parseFloat(data.step4__number_of_devices_img) * parseFloat(data.step4__average_device_cost_img);
    let device_cost_pd=0.2 *  parseFloat(data.step4__number_of_devices_pd) * parseFloat(data.step4__average_device_cost_pd);
    let device_cost_sg=0.2 * parseFloat(data.step4__number_of_devices_sg) * parseFloat(data.step4__average_device_cost_sg);

    const total_cost_to_replace = (
        parseFloat(data.step4__number_of_devices_bm) * parseFloat(data.step4__average_device_cost_bm) +
        parseFloat(data.step4__number_of_devices_ciot) * parseFloat(data.step4__average_device_cost_ciot) +
        parseFloat(data.step4__number_of_devices_cl) * parseFloat(data.step4__average_device_cost_cl) +
        parseFloat(data.step4__number_of_devices_giot) * parseFloat(data.step4__average_device_cost_giot) +
        parseFloat(data.step4__number_of_devices_img) * parseFloat(data.step4__average_device_cost_img) +
        parseFloat(data.step4__number_of_devices_pd) * parseFloat(data.step4__average_device_cost_pd) +
        parseFloat(data.step4__number_of_devices_sg) * parseFloat(data.step4__average_device_cost_sg));
    const estimated_depriciation = 0.2 * total_cost_to_replace;
    const estimated_monthly_saving = estimated_depriciation / 12;
    const next=() =>{
        props.next({
            step4__total: estimated_depriciation
        });
    }
    const previous=() =>{
        props.previous();
    }
    return (
        <div className='container'>
            
            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Outdated Operating System Replacement</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step4__mvr"
                    value={(data && data["step4__mvr"] && props.mvrs.find(item => item.value === data["step4__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step4__mvr"]}
                />
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: estimated_depriciation>0?"#4D9262":"#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Outdated Operating System Replacement Annual Depreciation (20% annual)<br /> Revenue Recovery</div>
                        <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                            ${formatCurrency(estimated_depriciation, 0)}
                        </div>
                    </div>
                   
                </div>
                <div className='col-12' >
                <table className='table'>
                    <tbody>
                        <tr>
                        <td valign="bottom">Building Management Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_bm"
                                value={data["step4__number_of_devices_bm"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_bm"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_bm"
                                value={data["step4__average_device_cost_bm"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_bm"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_bm, 0)}</div>
                            </td>
                        </tr>
                        <tr>
                        <td valign="bottom">Clinical IoT  Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_ciot"
                                value={data["step4__number_of_devices_ciot"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_ciot"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_ciot"
                                value={data["step4__average_device_cost_ciot"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_ciot"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_ciot, 0)}</div>
                            </td>
                        </tr>
                        <tr>
                        <td valign="bottom">Clinical Lab Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_cl"
                                value={data["step4__number_of_devices_cl"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_cl"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_cl"
                                value={data["step4__average_device_cost_cl"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_cl"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_cl, 0)}</div>
                            </td>
                        </tr>
                        <tr>
                        <td valign="bottom">General IoT  Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_giot"
                                value={data["step4__number_of_devices_giot"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_giot"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_giot"
                                value={data["step4__average_device_cost_giot"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_giot"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_giot, 0)}</div>
                            </td>
                        </tr>
                        <tr>
                        <td valign="bottom">Imaging Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_img"
                                value={data["step4__number_of_devices_img"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_img"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_img"
                                value={data["step4__average_device_cost_img"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_img"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_img, 0)}</div>
                            </td>
                        </tr>
                        <tr>
                        <td valign="bottom">Patient Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_pd"
                                value={data["step4__number_of_devices_pd"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_pd"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_pd"
                                value={data["step4__average_device_cost_pd"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_pd"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_pd, 0)}</div>
                            </td>
                        </tr>
                        <tr>
                        <td valign="bottom">Surgical Devices</td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__number_of_devices_sg"
                                value={data["step4__number_of_devices_sg"] + ""}
                                label="Number of Devices"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__number_of_devices_sg"]}
                                pattern="[0-9]*"
                            />
                            </td>
                            <td valign="bottom">
                            <Text
                                fieldColor="blue"
                                fieldName="step4__average_device_cost_sg"
                                value={data["step4__average_device_cost_sg"] + ""}
                                label="Average cost of device "
                                required={true}
                                handleChange={handleChange}
                                error={errors["step4__average_device_cost_sg"]}
                                leftMarker="$"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </td>
                            <td valign="bottom">
                                <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Annual Depreciation (20% annual)</div>
                                <div style={{ fontSize: "1.65em"}}>${formatCurrency(device_cost_sg, 0)}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                
                
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default OutdatedOS;