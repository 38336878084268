import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProgressArc from '../../../Components/widgets/ProgressArc';
import SelectField from './fields/Select';
function FleetManagement(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    let total_weekly_demand_ip = 0;
    let total_annual_hours_ip = 0;
    let total_annual_device_capacity_ip = 0;
    let actual_fleet_utilization_ip = 0;
    let excess_device_ip = 0;
    let annual_lease_purchase_cost_ip = 0;
    let annual_maintenance_cost_ip = 0;
    let total_cost_ip = 0;
    let after_device_count_ip = 0;
    let after_total_annual_device_capacity_ip = 0;
    let after_actual_fleet_utilization_ip = 0;
    let after_excess_device_ip = 0;
    let after_annual_lease_purchase_cost_ip = 0;
    let after_annual_maintenance_cost_ip = 0;
    let after_total_cost_ip = 0;
    let gross_saving_ip = 0;
    let gross_saving_percent_ip = 0;
    let resale_price_ip = 0;
    if (parseFloat(data.step1__device_count_ip) > 0) {
        total_weekly_demand_ip = parseFloat(data.step1__device_count_ip) * parseFloat(data.step3__usage_hours_ip);
        total_annual_hours_ip = total_weekly_demand_ip * 52;
        total_annual_device_capacity_ip = parseFloat(data.step1__device_count_ip) * 24 * 7 * 52;
        actual_fleet_utilization_ip = parseFloat(data.step3_override_pct_ip)?parseFloat(data.step3_override_pct_ip):((total_annual_hours_ip / total_annual_device_capacity_ip) * 100);
        excess_device_ip = ((parseFloat(data.step3__optimal_utilization_ip) - actual_fleet_utilization_ip) / parseFloat(data.step3__optimal_utilization_ip)) * parseFloat(data.step1__device_count_ip);
        annual_lease_purchase_cost_ip = parseFloat(data.step3__avg_device_cost_ip) * parseFloat(data.step1__device_count_ip);
        annual_maintenance_cost_ip = annual_lease_purchase_cost_ip * parseFloat(data.step3__avg_device_maintenance_ip / 100);
        total_cost_ip = annual_lease_purchase_cost_ip + 5 * annual_maintenance_cost_ip;
        resale_price_ip=parseFloat(data.step3__avg_device_cost_ip) * excess_device_ip*(parseFloat(data.step3_resale_percent_ip)/100);
        after_device_count_ip = parseFloat(data.step1__device_count_ip) - excess_device_ip;
        after_total_annual_device_capacity_ip = after_device_count_ip * 24 * 7 * 52;
        after_actual_fleet_utilization_ip = parseFloat(data.step3__optimal_utilization_ip);
        after_excess_device_ip = ((parseFloat(data.step3__optimal_utilization_ip) - after_actual_fleet_utilization_ip) / parseFloat(data.step3__optimal_utilization_ip)) * after_device_count_ip;
        after_annual_lease_purchase_cost_ip = parseFloat(data.step3__avg_device_cost_ip) * after_device_count_ip;
        after_annual_maintenance_cost_ip = after_annual_lease_purchase_cost_ip * parseFloat(data.step3__avg_device_maintenance_ip / 100);
        after_total_cost_ip = (total_cost_ip-resale_price_ip) + 5 * after_annual_maintenance_cost_ip;

    }
    const purchase_cost_saving_ip = resale_price_ip;
    const annual_maintenace_cost_saving_ip = annual_maintenance_cost_ip - after_annual_maintenance_cost_ip;
    const maintenace_cost_saving_ip = 5 * annual_maintenace_cost_saving_ip;
    gross_saving_ip = resale_price_ip + maintenace_cost_saving_ip;
    gross_saving_percent_ip = parseInt((resale_price_ip + maintenace_cost_saving_ip) * 100 / total_cost_ip);

    let total_weekly_demand_pm = 0;
    let total_annual_hours_pm = 0;
    let total_annual_device_capacity_pm = 0;
    let actual_fleet_utilization_pm = 0;
    let excess_device_pm = 0;
    let annual_lease_purchase_cost_pm = 0;
    let annual_maintenance_cost_pm = 0;
    let total_cost_pm = 0;
    let after_device_count_pm = 0;
    let after_total_annual_device_capacity_pm = 0;
    let after_actual_fleet_utilization_pm = 0;
    let after_excess_device_pm = 0;
    let after_annual_lease_purchase_cost_pm = 0;
    let after_annual_maintenance_cost_pm = 0;
    let after_total_cost_pm = 0;
    let gross_saving_pm = 0;
    let gross_saving_percent_pm = 0;
    let resale_price_pm = 0;

    if (parseFloat(data.step1__device_count_pm) > 0) {
        total_weekly_demand_pm = parseFloat(data.step1__device_count_pm) * parseFloat(data.step3__usage_hours_pm);
        total_annual_hours_pm = total_weekly_demand_pm * 52;
        total_annual_device_capacity_pm = parseFloat(data.step1__device_count_pm) * 24 * 7 * 52;
        actual_fleet_utilization_pm = parseFloat(data.step3_override_pct_pm)?parseFloat(data.step3_override_pct_pm):((total_annual_hours_pm / total_annual_device_capacity_pm) * 100);;
        excess_device_pm = ((parseFloat(data.step3__optimal_utilization_pm) - actual_fleet_utilization_pm) / parseFloat(data.step3__optimal_utilization_pm)) * parseFloat(data.step1__device_count_pm);
        annual_lease_purchase_cost_pm = parseFloat(data.step3__avg_device_cost_pm) * parseFloat(data.step1__device_count_pm);
        annual_maintenance_cost_pm = annual_lease_purchase_cost_pm * parseFloat(data.step3__avg_device_maintenance_pm / 100);
        total_cost_pm = annual_lease_purchase_cost_pm + 5 * annual_maintenance_cost_pm;
        resale_price_pm=parseFloat(data.step3__avg_device_cost_pm) * excess_device_pm*(parseFloat(data.step3_resale_percent_pm)/100);
        after_device_count_pm = parseFloat(data.step1__device_count_pm) - excess_device_pm;
        after_total_annual_device_capacity_pm = after_device_count_pm * 24 * 7 * 52;
        after_actual_fleet_utilization_pm = parseFloat(data.step3__optimal_utilization_pm);
        after_excess_device_pm = ((parseFloat(data.step3__optimal_utilization_pm) - after_actual_fleet_utilization_pm) / parseFloat(data.step3__optimal_utilization_pm)) * after_device_count_pm;
        after_annual_lease_purchase_cost_pm = parseFloat(data.step3__avg_device_cost_pm) * after_device_count_pm;
        after_annual_maintenance_cost_pm = after_annual_lease_purchase_cost_pm * parseFloat(data.step3__avg_device_maintenance_pm / 100);
        after_total_cost_pm = (total_cost_pm-resale_price_pm) + 5 * after_annual_maintenance_cost_pm;

    }
    const purchase_cost_saving_pm = resale_price_pm;
    const annual_maintenace_cost_saving_pm = annual_maintenance_cost_pm - after_annual_maintenance_cost_pm;
    const maintenace_cost_saving_pm = 5 * annual_maintenace_cost_saving_pm;
    gross_saving_pm = resale_price_pm + maintenace_cost_saving_pm;
    gross_saving_percent_pm = parseInt((resale_price_pm + maintenace_cost_saving_pm) * 100 / total_cost_pm);

    let total_weekly_demand_am = 0;
    let total_annual_hours_am = 0;
    let total_annual_device_capacity_am = 0;
    let actual_fleet_utilization_am = 0;
    let excess_device_am = 0;
    let annual_lease_purchase_cost_am = 0;
    let annual_maintenance_cost_am = 0;
    let total_cost_am = 0;
    let after_device_count_am = 0;
    let after_total_annual_device_capacity_am = 0;
    let after_actual_fleet_utilization_am = 0;
    let after_excess_device_am = 0;
    let after_annual_lease_purchase_cost_am = 0;
    let after_annual_maintenance_cost_am = 0;
    let after_total_cost_am = 0;
    let gross_saving_am = 0;
    let gross_saving_percent_am = 0;
    let resale_price_am = 0;

    if (parseFloat(data.step1__device_count_am) > 0) {
        total_weekly_demand_am = parseFloat(data.step1__device_count_am) * parseFloat(data.step3__usage_hours_am);
        total_annual_hours_am = total_weekly_demand_am * 52;
        total_annual_device_capacity_am = parseFloat(data.step1__device_count_am) * 24 * 7 * 52;
        actual_fleet_utilization_am = parseFloat(data.step3_override_pct_am)?parseFloat(data.step3_override_pct_am):((total_annual_hours_am / total_annual_device_capacity_am) * 100);
        excess_device_am = ((parseFloat(data.step3__optimal_utilization_am) - actual_fleet_utilization_am) / parseFloat(data.step3__optimal_utilization_am)) * parseFloat(data.step1__device_count_am);
        annual_lease_purchase_cost_am = parseFloat(data.step3__avg_device_cost_am) * parseFloat(data.step1__device_count_am);
        annual_maintenance_cost_am = annual_lease_purchase_cost_am * parseFloat(data.step3__avg_device_maintenance_am / 100);
        total_cost_am = annual_lease_purchase_cost_am + 5 * annual_maintenance_cost_am;
        resale_price_am=parseFloat(data.step3__avg_device_cost_am) * excess_device_am*(parseFloat(data.step3_resale_percent_am)/100);
        after_device_count_am = parseFloat(data.step1__device_count_am) - excess_device_am;
        after_total_annual_device_capacity_am = after_device_count_am * 24 * 7 * 52;
        after_actual_fleet_utilization_am = parseFloat(data.step3__optimal_utilization_am);
        after_excess_device_am = ((parseFloat(data.step3__optimal_utilization_am) - after_actual_fleet_utilization_am) / parseFloat(data.step3__optimal_utilization_am)) * after_device_count_am;
        after_annual_lease_purchase_cost_am = parseFloat(data.step3__avg_device_cost_am) * after_device_count_am;
        after_annual_maintenance_cost_am = after_annual_lease_purchase_cost_am * parseFloat(data.step3__avg_device_maintenance_am / 100);
        after_total_cost_am = (total_cost_am-resale_price_am) + 5 * after_annual_maintenance_cost_am;

    }
    const purchase_cost_saving_am = annual_lease_purchase_cost_am - resale_price_am;
    const annual_maintenace_cost_saving_am = annual_maintenance_cost_am - after_annual_maintenance_cost_am;
    const maintenace_cost_saving_am = 5 * annual_maintenace_cost_saving_am;
    gross_saving_am = resale_price_am + maintenace_cost_saving_am;
    gross_saving_percent_am = parseInt((resale_price_am + maintenace_cost_saving_am) * 100 / total_cost_am);
    //after_annual_maintenance_cost_am
    //annual_maintenance_cost_am
    const total_cost = total_cost_ip + total_cost_am + total_cost_pm;
    const after_total_cost = after_total_cost_ip + after_total_cost_am + after_total_cost_pm;
    const gross_saving = gross_saving_ip + gross_saving_pm + gross_saving_am;
    //const purchase_cost_saving = annual_lease_purchase_cost_am + annual_lease_purchase_cost_pm + annual_lease_purchase_cost_ip
    //    - (after_annual_lease_purchase_cost_am + after_annual_lease_purchase_cost_pm + after_annual_lease_purchase_cost_ip);
    const purchase_cost_saving = resale_price_ip + resale_price_pm + resale_price_am;
    const annual_maintenace_cost_saving = annual_maintenance_cost_am + annual_maintenance_cost_pm + annual_maintenance_cost_ip
    - (after_annual_maintenance_cost_am + after_annual_maintenance_cost_pm + after_annual_maintenance_cost_ip);
    const maintenace_cost_saving = 5 * annual_maintenace_cost_saving;
    const gross_saving_percent = total_cost>0?parseInt((gross_saving) * 100 / total_cost):0;

    const next = () => {
        props.next({ step3__total: gross_saving, 
            step3__purchase_cost_saving: purchase_cost_saving, 
            step3__annual_maintenace_cost_saving: annual_maintenace_cost_saving, 
            step3__percent: gross_saving_percent, 
            step3__gross_saving_ip: gross_saving_ip, 
            step3__gross_saving_pm: gross_saving_pm, 
            step3__gross_saving_am: gross_saving_am });
    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Fleet Management</h4>
                </div>
                <div className='col-2' >

                    <SelectField
                        fieldColor="orange"
                        fieldName="step3__mvr"
                        value={(data && data["step3__mvr"] && props.mvrs.find(item => item.value === data["step3__mvr"])) || null}
                        options={props.mvrs}
                        label="Months to Value Realization"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step3__mvr"]}
                    />
                </div>
            </div>

            <div className='row mt-3 gx-5 '>

                <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(purchase_cost_saving+annual_maintenace_cost_saving) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Fleet Managament Revenue Recovery</div>
                        <div className='row' >
                        <div className='col-3'>&nbsp;</div>
                            <div className='col-3' style={{ textTransform: "uppercase", fontSize: "0.85em", fontWeight: "600" }}>
                                Year 1
                                </div>
                                <div className='col-3' style={{ textTransform: "uppercase", fontSize: "0.85em", fontWeight: "600" }}>
                                Following Years
                                </div>
                                <div className='col-3'>&nbsp;</div>
                                <div className='col-3'>&nbsp;</div>
                            <div className='col-3'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(purchase_cost_saving+annual_maintenace_cost_saving), 0)}
                                </div>
                            </div>
                            <div className='col-3'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(annual_maintenace_cost_saving), 0)}
                                </div>
                            </div>
                            <div className='col-3'>&nbsp;</div>
                            
                        </div>

                    </div>
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
                <Tabs style={{ width: "100%" }} variant="fullWidth">
                    <TabList>
                        {data.step1__device_count_ip > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_ip} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>INFUSION PUMPS <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_ip > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_ip, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_pm > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_pm} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>PATIENT MONITORING <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_pm > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_pm, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_am > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_am} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>ULTRASOUND MACHINES <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_am > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_am, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                    </TabList>
                    {data.step1__device_count_ip > 0 && <TabPanel>
                        <div className='row mt-3'>
                        <div className='col-4'>

                            <Text
                                fieldColor="sky"
                                fieldName="step3_resale_percent_ip"
                                value={data["step3_resale_percent_ip"] + ""}
                                label="Average Resale Price Per Device as percentage of Purchase Amount"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step3_resale_percent_ip"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                            />
                            <Text
                                fieldColor="orange"
                                fieldName="step3_override_pct_ip"
                                value={data["step3_override_pct_ip"]?data["step3_override_pct_ip"]:"" + ""}
                                nullable={true}
                                label="Overrride Actual Utilization (After)"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step3_override_pct_ip"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                            />
                        </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_ip, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_device_count_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Weekly Patient Demand (hours per week per device)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__usage_hours_ip, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__usage_hours_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Weekly Patient Demand (hours per week per device)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_weekly_demand_ip, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_weekly_demand_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Fleet Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__optimal_utilization_ip, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__optimal_utilization_ip, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Purchase Cost Per Device</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step3__avg_device_cost_ip, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step3__avg_device_cost_ip, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Maintenance Costs Per Device (% of Purchase Cost)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__avg_device_maintenance_ip, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__avg_device_maintenance_ip, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Total Annual Hours </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_hours_ip, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_hours_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Maximum Annual Hours (Device Capacity) </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_device_capacity_ip, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_total_annual_device_capacity_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Fleet Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_actual_fleet_utilization_ip, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(actual_fleet_utilization_ip, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Excess Devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_excess_device_ip, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(excess_device_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Cost</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_lease_purchase_cost_ip, 2)}</td>
                                            <td style={{ textAlign: "right" }}>-</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Sale Price of devices not utilized (Recovered in Year 1)</td>
                                            <td style={{ textAlign: "right" }}>-</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(resale_price_ip, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Fixed Maintenance Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_maintenance_cost_ip, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_annual_maintenance_cost_ip, 2)}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Total Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_cost_ip, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_total_cost_ip, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Purchase Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(purchase_cost_saving_ip, 2)}</td>
                                        </tr> */}
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Annual Maintenance Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_maintenace_cost_saving_ip, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Maintenance Cost Saving (Over 5 Years)</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenace_cost_saving_ip, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Gross Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_ip, 2)}</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_pm > 0 && <TabPanel>
                        <div className='row mt-3'>
                        <div className='col-4'>

                            <Text
                                fieldColor="sky"
                                fieldName="step3_resale_percent_pm"
                                value={data["step3_resale_percent_pm"] + ""}
                                label="Average Resale Price Per Device as percentage of Purchase Amount"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step3_resale_percent_pm"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                            />

                            <Text
                                fieldColor="orange"
                                fieldName="step3_override_pct_pm"
                                value={data["step3_override_pct_pm"]?data["step3_override_pct_pm"]:"" + ""}
                                label="Overrride Actual Utilization (After)"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step3_override_pct_pm"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                                nullable={true}
                            />
                        </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_device_count_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Weekly Patient Demand (hours per week per device)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__usage_hours_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__usage_hours_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Weekly Patient Demand (hours per week per device)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_weekly_demand_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_weekly_demand_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Fleet Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__optimal_utilization_pm, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__optimal_utilization_pm, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Purchase Cost Per Device</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step3__avg_device_cost_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step3__avg_device_cost_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Maintenance Costs Per Device (% of Purchase Cost)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__avg_device_maintenance_pm, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__avg_device_maintenance_pm, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Total Annual Hours </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_hours_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_hours_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Maximum Annual Hours (Device Capacity) </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_device_capacity_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_total_annual_device_capacity_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Fleet Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_actual_fleet_utilization_pm, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(actual_fleet_utilization_pm, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Excess Devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_excess_device_pm, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(excess_device_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Cost</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_lease_purchase_cost_pm, 2)}</td>
                                            <td style={{ textAlign: "right" }}>-</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Sale Price of devices not utilized (Recovered in Year 1)</td>
                                            <td style={{ textAlign: "right" }}>-</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(resale_price_pm, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Fixed Maintenance Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_maintenance_cost_pm, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_annual_maintenance_cost_pm, 2)}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Total Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_cost_pm, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_total_cost_pm, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Purchase Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(purchase_cost_saving_pm, 2)}</td>
                                        </tr> */}
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Annual Maintenance Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_maintenace_cost_saving_pm, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Maintenance Cost Saving (Over 5 Years)</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenace_cost_saving_pm, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Gross Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_pm, 2)}</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_am > 0 && <TabPanel>
                        <div className='row mt-3'>

                        <div className='col-4'>

                            <Text
                                fieldColor="sky"
                                fieldName="step3_resale_percent_am"
                                value={data["step3_resale_percent_am"] + ""}
                                label="Average Resale Price Per Device as percentage of Purchase Amount"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step3_resale_percent_am"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                            />
                            <Text
                                fieldColor="orange"
                                fieldName="step3_override_pct_am"
                                value={data["step3_override_pct_am"]?data["step3_override_pct_am"]:"" + ""}
                                label="Overrride Actual Utilization (After)"
                                required={true}
                                handleChange={handleChange}
                                error={errors["step3_override_pct_am"]}
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                rightMarker="%"
                                nullable={true}
                            />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__device_count_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_device_count_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Weekly Patient Demand (hours per week per device)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__usage_hours_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__usage_hours_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Weekly Patient Demand (hours per week per device)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_weekly_demand_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_weekly_demand_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Optimal Fleet Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__optimal_utilization_am, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__optimal_utilization_am, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Average Purchase Cost Per Device</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step3__avg_device_cost_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step3__avg_device_cost_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Maintenance Costs Per Device (% of Purchase Cost)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__avg_device_maintenance_am, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step3__avg_device_maintenance_am, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Total Annual Hours </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_hours_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_hours_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Maximum Annual Hours (Device Capacity) </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(total_annual_device_capacity_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_total_annual_device_capacity_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Fleet Utilization</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_actual_fleet_utilization_am, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(actual_fleet_utilization_am, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Excess Devices</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_excess_device_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(excess_device_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Cost</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_lease_purchase_cost_am, -2)}</td>
                                            <td style={{ textAlign: "right" }}>-</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Sale Price of devices not utilized (Recovered in Year 1)</td>
                                            <td style={{ textAlign: "right" }}>-</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(resale_price_am, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Annual Fixed Maintenance Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_maintenance_cost_am, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_annual_maintenance_cost_am, 2)}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Total Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_cost_am, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_total_cost_am, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Purchase Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(purchase_cost_saving_am, 2)}</td>
                                        </tr> */}
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Annual Maintenance Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(annual_maintenace_cost_saving_am, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Maintenance Cost Saving (Over 5 Years)</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(maintenace_cost_saving_am, 2)}</td>
                                        </tr>
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Gross Cost Saving</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_am, 2)}</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                </Tabs>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default FleetManagement;