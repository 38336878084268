import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading } from '../../Components/Util';
import * as service from "../../services";

function ConfigureXdome(props) {

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [recipe, setRecipe] = useState({});
    const [quoteHeader, setQuoteHeader] = useState({});

    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.quote_id) > 0) {

                service.getElaData("ela-header", { id: props.match.params.quote_id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }
                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getElaData("get_xdome_by_id", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setRecipe({ ...res[0] });
                        resolve("");
                    }
                });
            } else {
                setRecipe({ quote_id: props.match.params.quote_id });
                resolve("");
            }
        });


        Promise.all([p1, p2])
            .then(values => {


                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.quote_id, props.match.params.id]);

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "number_of_sites", msg: "Please enter number of sites" }

        ];

        if (recipe.include_essentials ||
            recipe.exclude_hw) {
            console.log('all ok');
        } else {
            setErrors(errors => ({ ...errors, essential: "Please select Essential package(s)" }));
            isValid = false;
        }

        if ((recipe.year1_count || 0) + (recipe.year2_count || 0) + (recipe.year3_count || 0) + (recipe.year4_count || 0) + (recipe.year4_count || 0) < 1) {
            setErrors(errors => ({ ...errors, deployment: "Please enter deployment schedule" }));
            isValid = false;
        } else {
            if (parseInt(recipe.year1_count || 0) + parseInt(recipe.year2_count || 0) + parseInt(recipe.year3_count || 0) + parseInt(recipe.year4_count || 0) + parseInt(recipe.year4_count || 0) < 1000) {
                setErrors(errors => ({ ...errors, deployment: "Minimum 1000 assets are required for xDome" }));
                isValid = false;
            }
        }

        validation.map(item => {
            console.log(recipe);
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    };


    const handleChange = (prop, value) => {
        if (prop === 'include_essentials' && value) {
            setRecipe(recipe => ({ ...recipe, exclude_hw: false }));
        }
        if (prop === 'exclude_hw' && value) {
            setRecipe(recipe => ({ ...recipe, include_essentials: false }));
        }
        setRecipe(recipe => ({ ...recipe, [prop]: value }));

    };
    const handleNext = () => {
        if (validate()) {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(recipe));

            payload.year1_count = payload.year1_count || 0;
            payload.year2_count = payload.year2_count || 0;
            payload.year3_count = payload.year3_count || 0;
            payload.year4_count = payload.year4_count || 0;
            payload.year5_count = payload.year5_count || 0;

            //console.log(payload);
            service.upsertEla('ela-xdome', payload, res => {
                console.log(res);
                props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref');
            });
        }
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>Configure Ela Quote</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.shipping_type + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal ? 'Yes' : 'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email ? ' [EMAIL: ' + quoteHeader.sales_director_email + ']' : '') + (quoteHeader.sales_director_phone ? ' [CONTACT #: ' + quoteHeader.sales_director_phone + ']' : '')}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                {/* <h4 className="card-header">Quote Header</h4> */}
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <h4>xDome Asset Based Configuration</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-sm-12">
                                                            <label htmlFor="number_of_sites" className="form-label">Number of sites <span className="required">*</span></label>
                                                            <input type="text" className="form-control" name="number_of_sites" placeholder=""
                                                                value={recipe.number_of_sites || ''}
                                                                maxLength="100"
                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                                onChange={(e) => { handleChange('number_of_sites', (e.target.validity.valid) ? e.target.value : '') }} />
                                                            {errors.number_of_sites && <div className="validation-error">{errors.number_of_sites}</div>}
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <label className="form-label">Select Essentials <span className="required">*</span></label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                <label className={recipe.include_essentials ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_O">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_O" autoComplete="off" checked={recipe.include_essentials}
                                                                        onChange={(e) => { handleChange('include_essentials', e.target.checked) }}
                                                                    />
                                                                    <div style={{ fontSize: "1.2em" }}>xDome Essentials</div>
                                                                    (with Hardware)

                                                                </label>
                                                                <label className={recipe.exclude_hw ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="site_size_XS">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="site_size_XS" autoComplete="off" checked={recipe.exclude_hw}
                                                                        onChange={(e) => { handleChange('exclude_hw', e.target.checked) }}
                                                                    />
                                                                    <div style={{ fontSize: "1.2em" }}>xDome Essentials</div>
                                                                    (without Hardware)

                                                                </label>
                                                            </div>
                                                            {errors.essential && <div className="validation-error">{errors.essential}</div>}
                                                        </div>
                                                        <div className="col-sm-12">

                                                            <label className="form-label">Select Packages </label>
                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                {/* <label className={recipe.include_essentials ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_essentials">
                                                                                            <input type="checkbox" name="include_essentials" id="include_essentials" autoComplete="off" checked={recipe.include_essentials}
                                                                                                onChange={(e) => { handleChange('include_essentials', e.target.checked) }}
                                                                                            />
                                                                                            <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>xDome Essentials</div>
                                                                                        </label>
                                                                                        <label className={recipe.exclude_hw ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="exclude_hw">
                                                                                            <input type="checkbox" name="exclude_hw" id="exclude_hw" autoComplete="off" checked={recipe.exclude_hw}
                                                                                                onChange={(e) => { handleChange('exclude_hw', e.target.checked) }}
                                                                                            />
                                                                                            <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>xDome Essentials (no Hardware)</div>
                                                                                        </label> */}
                                                                <label className={recipe.include_advanced_detection ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_advanced_detection">
                                                                    <input type="checkbox" name="include_advanced_detection" id="include_advanced_detection" autoComplete="off" checked={recipe.include_advanced_detection}
                                                                        onChange={(e) => { handleChange('include_advanced_detection', e.target.checked) }}
                                                                    />
                                                                    <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>xDome Advanced Anomaly &amp; Threat Detection</div>
                                                                </label>

                                                                <label className={recipe.include_cyber_hygine ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_cyber_hygine">
                                                                    <input type="checkbox" name="include_cyber_hygine" id="include_cyber_hygine" autoComplete="off" checked={recipe.include_cyber_hygine}
                                                                        onChange={(e) => { handleChange('include_cyber_hygine', e.target.checked) }}
                                                                    />
                                                                    <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>xDome Advanced Vulnerability &amp; Risk Management</div>
                                                                </label>

                                                                <label className={recipe.include_npm ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="include_npm">
                                                                    <input type="checkbox" name="include_npm" id="include_npm" autoComplete="off" checked={recipe.include_npm}
                                                                        onChange={(e) => { handleChange('include_npm', e.target.checked) }}
                                                                    />
                                                                    <div style={{ display: "inline-block", paddingLeft: "10px", fontSize: "0.75em" }}>xDome Network Security Management</div>
                                                                </label>

                                                            </div>


                                                            <div className="row mt-3" style={{ border: "1px solid #CCCCCC", margin: "3px", padding: "20px", background: "#EAEAEA" }}>
                                                                <div className="col-sm-12">
                                                                    <h4>Deployment Schedule</h4>
                                                                    <div>Enter the number of assets deployed during each year of the contract.</div>
                                                                    <div className="alert alert-info d-flex align-items-top" role="alert">
                                                                        <i className='mdi mdi-information-outline'></i>
                                                                        <div style={{ fontSize: "1em", padding: "0 10px" }}>
                                                                            Include all Cyber Physical Systems (OT, IoT, IoMT) Assets.
                                                                            Exclude following asset types:
                                                                            <ul>
                                                                                <li style={{ listStyleType: "square" }}>IT assets regardless if they are discovered in the OT network</li>
                                                                                <li style={{ listStyleType: "square" }}>Guest networks</li>
                                                                                <li style={{ listStyleType: "square" }}>Mobile</li>
                                                                                <li style={{ listStyleType: "square" }}>VOIP Phones (these can often be 1:1 with employee count)</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                        <label htmlFor="year1_count" className="form-label">Deployed during year 1 <span className="required">*</span></label>
                                                                        <input type="text" className="form-control" name="year1_count" placeholder=""
                                                                            value={recipe.year1_count || ''}
                                                                            maxLength="100"
                                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                            onChange={(e) => { handleChange('year1_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                        {errors.year1_count && <div className="validation-error">{errors.year1_count}</div>}
                                                                    </div>
                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                        <label htmlFor="year2_count" className="form-label">Deployed during year 2 <span className="required">*</span></label>
                                                                        <input type="text" className="form-control" name="year2_count" placeholder=""
                                                                            value={recipe.year2_count || ''}
                                                                            maxLength="100"
                                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                            onChange={(e) => { handleChange('year2_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                        {errors.year2_count && <div className="validation-error">{errors.year2_count}</div>}
                                                                    </div>
                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                        <label htmlFor="year3_count" className="form-label">Deployed during year 3 <span className="required">*</span></label>
                                                                        <input type="text" className="form-control" name="year3_count" placeholder=""
                                                                            value={recipe.year3_count || ''}
                                                                            maxLength="100"
                                                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                            onChange={(e) => { handleChange('year3_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                        {errors.year3_count && <div className="validation-error">{errors.year3_count}</div>}
                                                                    </div>
                                                                    {quoteHeader.contract_length > 3 &&
                                                                        <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                            <label htmlFor="year4_count" className="form-label">Deployed during year 4 <span className="required">*</span></label>
                                                                            <input type="text" className="form-control" name="year4_count" placeholder=""
                                                                                value={recipe.year4_count || ''}
                                                                                maxLength="100"
                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                                onChange={(e) => { handleChange('year4_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                            {errors.year4_count && <div className="validation-error">{errors.year4_count}</div>}
                                                                        </div>
                                                                    }
                                                                    {quoteHeader.contract_length > 4 &&
                                                                        <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                            <label htmlFor="year5_count" className="form-label">Deployed during year 5 <span className="required">*</span></label>
                                                                            <input type="text" className="form-control" name="year5_count" placeholder=""
                                                                                value={recipe.year5_count || ''}
                                                                                maxLength="100"
                                                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"

                                                                                onChange={(e) => { handleChange('year5_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                            {errors.year4_coyear5_countunt && <div className="validation-error">{errors.year5_count}</div>}
                                                                        </div>
                                                                    }
                                                                    {errors.deployment && <div className="validation-error">{errors.deployment}</div>}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                                        <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default ConfigureXdome;


