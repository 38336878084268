import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';

function SystemCost(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;
    
    const step2__total = (parseFloat(data["step2__software_fee_annual"]))
        + parseFloat(data["step2__sensors_one_time_fee"])
        + parseFloat(data["step2__sensors_maintenance_fee_annual"])
        + parseFloat(data["step2__onsite_training_one_time_fee"])
        + parseFloat(data["step2__deployment_one_time_fee"]);

    const next=() =>{
        props.next({step2__total:step2__total});
        
    }
    const previous=() =>{
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
                <h4>System Cost</h4>
            </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-6' style={{ borderRight: "1px solid #e5e5e5" }}>
                    <h4 style={{ fontSize: "1.2rem" }}>Software</h4>
                    <Text
                        fieldColor="blue"
                        fieldName="step2__software_fee_annual"
                        value={data["step2__software_fee_annual"] + ""}
                        label="Order Software Fee - Annual"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step2__software_fee_annual"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                    <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Hardware</h4>
                    <Text
                        fieldColor="blue"
                        fieldName="step2__sensors_one_time_fee"
                        value={data["step2__sensors_one_time_fee"] + ""}
                        label="Order Sensors - One Time Fee"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step2__sensors_one_time_fee"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step2__sensors_maintenance_fee_annual"
                        value={data["step2__sensors_maintenance_fee_annual"] + ""}
                        label="Sensors Maintenance - Anuual"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step2__sensors_maintenance_fee_annual"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                    <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Implementation & Training</h4>
                    <Text
                        fieldColor="blue"
                        fieldName="step2__onsite_training_one_time_fee"
                        value={data["step2__onsite_training_one_time_fee"] + ""}
                        label="Two Day Onsite Training - One Time Fee"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step2__onsite_training_one_time_fee"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                    <Text
                        fieldColor="blue"
                        fieldName="step2__deployment_one_time_fee"
                        value={data["step2__deployment_one_time_fee"] + ""}
                        label="Deployment of Sensors - One Time Fee"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step2__deployment_one_time_fee"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                </div>
                <div className='col-6' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step2__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Total First Year Fee</div>
                        <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                            ${formatCurrency(
                                parseFloat(step2__total), 2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default SystemCost;