import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading } from '../../Components/Util';
import * as service from "../../services";

function ConfigureProducts(props) {

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [recipe, setRecipe] = useState({});
    const [quoteHeader, setQuoteHeader] = useState({});
    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.quote_id) > 0) {

                service.getElaData("ela-header", { id: props.match.params.quote_id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }
                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getElaData("get_products_by_id", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setRecipe({ ...res[0] });
                        resolve("");
                    }
                });
            } else {
                setRecipe({ quote_id: props.match.params.quote_id });
                resolve("");
            }
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.quote_id, props.match.params.id]);

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [];

        if (recipe.include_emc) {
            validation.push({ field: "emc_services", msg: "Please choose Services" })
            recipe.emc_console_count = 1;
        } else {
            recipe.emc_console_count = 0;
            recipe.include_emc_ha = false;
            recipe.exclude_emc_hw = false;
        }
        if (!recipe.include_sra_sac) {
            recipe.include_sra_sac_ha = false;
            recipe.exclude_sra_sac_hw = false;
        } else {
            validation.push({ field: "sra_sac_services", msg: "Please choose Services" })
        }
        validation.map(item => {
            console.log(recipe);
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        if (!recipe.include_emc && !recipe.include_ctd_live && !recipe.include_sra_sac) {
            setErrors(errors => ({ ...errors, option: "Please select an option" }));
            isValid = false;
        }
        return isValid;
    };


    const handleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }));
    };
    const handleNext = () => {
        if (validate()) {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(recipe));
            if (payload.include_emc) {
                payload.emc_console_count = 1;
            }
            console.log(payload);
            service.upsertEla('ela-products', payload, res => {
                console.log(res);
                props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref');
            });
        }
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>Configure Ela Quote</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.site_size + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal ? 'Yes' : 'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email ? ' [EMAIL: ' + quoteHeader.sales_director_email + ']' : '') + (quoteHeader.sales_director_phone ? ' [CONTACT #: ' + quoteHeader.sales_director_phone + ']' : '')}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                {/* <h4 className="card-header">Quote Header</h4> */}
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <h4>Quote Wide Product Configuration</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">


                                                        <div className="col-sm-12">

                                                            {errors.option && <div className="validation-error">{errors.option}</div>}
                                                            <div className="row g-3">
                                                                <div className="col-sm-12 ">

                                                                    <ul className="list-group">

                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_emc}
                                                                                        onChange={(e) => { handleChange('include_emc', e.target.checked) }} />
                                                                                    <span>CTD EMC</span>
                                                                                </div>
                                                                                <div className="col-sm-8 ">
                                                                                    {recipe.include_emc && <>
                                                                                        

                                                                                        <div className="col-sm-12">
                                                                                            <label className="form-label"> Services<span className="required">*</span></label>
                                                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                                                <label className={recipe.emc_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="emc_HW_SW_PS">
                                                                                                    <input type="radio" className="btn-check" name="emc_service" id="emc_HW_SW_PS" autoComplete="off" checked={recipe.emc_services === 'HW+PS+SW'}
                                                                                                        onChange={(e) => { handleChange('emc_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                                                    />
                                                                                                    Hardware + Software + Professional Services
                                                                                                </label>
                                                                                                <label className={recipe.emc_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="emc_SW_PS">
                                                                                                    <input type="radio" className="btn-check" name="emc_service" id="emc_SW_PS" autoComplete="off" checked={recipe.emc_services === 'SW + M&S'}
                                                                                                        onChange={(e) => { handleChange('emc_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                                                    />
                                                                                                    Software + Professional Services Only
                                                                                                </label>

                                                                                            </div>
                                                                                            {errors.emc_services && <div className="validation-error">{errors.emc_services}</div>}
                                                                                        </div>
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>



                                                                        <li className="list-group-item p-3">
                                                                            <div className="row g-3">
                                                                                <div className="col-sm-4 ">
                                                                                    <input
                                                                                        className="form-check-input me-1"
                                                                                        type="checkbox"
                                                                                        checked={recipe.include_sra_sac}
                                                                                        onChange={(e) => { handleChange('include_sra_sac', e.target.checked) }} />
                                                                                    <span>SRA SAC</span>
                                                                                </div>
                                                                                <div className="col-sm-8 ">
                                                                                    {recipe.include_sra_sac && <>
                                                                                        <div className='mt-3'>
                                                                                            <input
                                                                                                className="form-check-input me-1"
                                                                                                type="checkbox"

                                                                                                checked={recipe.include_sra_sac_ha}
                                                                                                onChange={(e) => { handleChange('include_sra_sac_ha', e.target.checked) }} />
                                                                                            <span className="form-label">SRA SAC High Availability</span>
                                                                                        </div>
                                                                                        <div className="col-sm-12">
                                                                                            <label className="form-label"> Services<span className="required">*</span></label>
                                                                                            <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                                                <label className={recipe.sra_sac_services === 'HW+PS+SW' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="sra_sac_HW_SW_PS">
                                                                                                    <input type="radio" className="btn-check" name="sra_sac_service" id="sra_sac_HW_SW_PS" autoComplete="off" checked={recipe.sra_sac_services === 'HW+PS+SW'}
                                                                                                        onChange={(e) => { handleChange('sra_sac_services', e.target.checked ? 'HW+PS+SW' : undefined) }}
                                                                                                    />
                                                                                                    Hardware + Software + Professional Services
                                                                                                </label>
                                                                                                <label className={recipe.sra_sac_services === 'SW + M&S' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="sra_sac_SW_PS">
                                                                                                    <input type="radio" className="btn-check" name="sra_sac_service" id="sra_sac_SW_PS" autoComplete="off" checked={recipe.sra_sac_services === 'SW + M&S'}
                                                                                                        onChange={(e) => { handleChange('sra_sac_services', e.target.checked ? 'SW + M&S' : undefined) }}
                                                                                                    />
                                                                                                    Software + Professional Services Only
                                                                                                </label>

                                                                                            </div>
                                                                                            {errors.sra_sac_services && <div className="validation-error">{errors.sra_sac_services}</div>}
                                                                                        </div>
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                                        <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default ConfigureProducts;


