import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const renderLegend = (props) => {
    const { payload } = props;
  
    return (
      <div className='row' style={{marginTop:"30px"}}>
        {
          payload.map((entry, index) => {
  
            return <div className="col-12" key={`item-${index}`} style={{fontSize:"0.75em"}}>
              <div style={{width:"10px", height:"10px", background:entry.color, display:"inline-block", marginRight:"2px"}}></div>
              {entry.value}
            </div>;
  })
        }
      </div>
    );
  }
export default class ROI extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend align="right" verticalAlign='middle' layout='vertical' content={renderLegend} />
          <Line type="monotone" dataKey="ROI" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Cumulative Platform Fees" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
