import React, { useEffect, useState } from 'react';
import { startLoading, stopLoading, validateEmail } from '../../Components/Util.js';
import Header from '../../Components/layout/Header';
import * as service from "../../services";
import Select from 'react-select';
import { useAuthState } from '../../Context';

function UserDetail(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";

    const userDetails = useAuthState()
    const [loading, setLoading] = useState(true)
    const [context, setContext] = useState()
    const [recipe, setRecipe] = useState({ user: { statusId: 1 }, roles: [] });
    const [errors, setErrors] = useState([]);
    const [lookup, setLookup] = useState([]);
    const [payload, setPayload] = useState({ email: props.match.params.email });
    const [passMessage, setPassMessage] = useState();
    const [passUpdateMessage, setPassUpdateMessage] = useState();
    const me = userDetails.user.user
    

    useEffect(() => {
        setContext(me.email === props.match.params.email ? 'PROFILE' : props.match.params.context)

        startLoading()
        let p1 = new Promise((resolve, reject) => {
            if (props.match.params.context !== 'NEW') {
                service.getUserDetail(props.match.params.email, res => {
               
                    setRecipe(res);
                    resolve("");
                })
            } else resolve("");
        });

        let p2 = new Promise((resolve, reject) => {
            service.getLookups(res => {
                setLookup(res)
                resolve("");
            });
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [me.email, props.match.params.email, props.match.params.context]);

    const onChangeHandler = (prop, value) => {
        let user = recipe.user || {};
        user[prop] = value;
        setRecipe(recipe => ({ ...recipe, 'user': user }));
    }

    const roleChangeHandler = (roleId, isChecked) => {
        console.log(recipe);
        let roles = recipe.roles || [];
        if (isChecked)
        roles.push({ "roleId": roleId });
        else
        roles = roles.filter(_a => _a.roleId + '' !== roleId + '')
        setRecipe(recipe => ({ ...recipe, 'roles': roles }));
    }

    const validate = () => {
        let { user, roles } = recipe;
        setErrors({});
        let isValid = true;
        let validation = [
            { field: 'email', msg: 'Please enter Email.' },
            { field: 'firstName', msg: 'Please enter First Name.' },
            { field: 'lastName', msg: 'Please enter Last Name.' }
        ];
        if (!roles || roles.length <= 0) validation.push({ field: 'roles', msg: 'Please select Role(s).' })

        validation.map(item => {
            if (!user[item.field] || user[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        if (user.email && user.email.length > 0 && !validateEmail(user.email)) {
            setErrors(errors => ({ ...errors, 'email': 'Please enter valid email address.' }));
            isValid = false;
        }
        return isValid;
    };

    const handleSave = () => {
        if (validate()) {
            setLoading(true)
            service.upsertUser(recipe, res => {
                if (res.status === 'ERROR') {
                    setLoading(false);
                    setErrors(errors => ({
                        ...errors, 'email': res.msg
                    }));
                } else props.history.push('/admin')
            });
        }
    }

    const sendPassword = () => {
        setLoading(true);
        service.sendPassword(props.match.params.email, res => {
            setLoading(false);
            setPassMessage("Password Sent.")
            setTimeout(() => {
                setPassMessage()
            }, 3000);
        })
    }

    const passwordChangeHandler = (prop, value) => {
        setPayload(payload => ({ ...payload, [prop]: value }));
    }

    const validatePassword = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: 'password', msg: 'Please enter Password.' },
            { field: 'rePassword', msg: 'Please enter Confirm New Password.' }
        ];

        validation.map(item => {
            if (!payload[item.field] || payload[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        if (isValid && payload.password !== payload.rePassword) {
            setErrors(errors => ({ ...errors, 'password': 'Password mismatch.' }));
            isValid = false;
        }

        return isValid;
    };

    const updatePassword = () => {
        if (validatePassword()) {
            setLoading(true);
            service.updatePassword(payload, res => {
                setLoading(false);
                setPayload({ email: props.match.params.email });
                setPassUpdateMessage("Password Updated.")
                setTimeout(() => {
                    setPassUpdateMessage()
                }, 3000);
            });
        }
    }

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    loading ? startLoading() : stopLoading();
    let user = recipe.user || {};
    let roles = recipe.roles || [];
    return (
        !loading && <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container">
                    <div className="row auto-height">
                        <div className="col-12">
                            <div className="container-fluid mt-3">
                                <div className="clearfix">
                                    <h3 className="float-start"> {context === 'DETAIL' ? 'User Detail' : context === 'NEW' ? 'Create User' : 'User Profile'}</h3>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="col-md-12 row">
                                    <div className="col-md-6 row">
                                        <div className="col-sm-12">
                                            <label className="form-label">Email </label>
                                            <div className="input-group">
                                                <div className="input-group-text">@</div>
                                                <input type="text" className="form-control" id="email" placeholder="" disabled={context !== 'NEW'}
                                                    value={user.email || ''} onChange={(e) => onChangeHandler('email', e.target.value)}
                                                />
                                            </div>
                                            {errors.email && <div className="validation-error">{errors.email}</div>}
                                        </div>

                                        <div className="col-sm-6">
                                            <label className="form-label">First Name  </label>
                                            <input type="text" className="form-control" value={user.firstName || ''} onChange={(e) => onChangeHandler('firstName', e.target.value)} />
                                            {errors.firstName && <div className="validation-error">{errors.firstName}</div>}
                                        </div>

                                        <div className="col-sm-6">
                                            <label className="form-label">Last Name  </label>
                                            <input type="text" className="form-control" value={user.lastName || ''}
                                                onChange={(e) => onChangeHandler('lastName', e.target.value)}
                                            />
                                            {errors.lastName && <div className="validation-error">{errors.lastName}</div>}
                                        </div>

                                        <div className="col-sm-12">
                                            <label className="form-label">Status </label>
                                            <Select isDisabled={context === 'NEW' || context === 'PROFILE'}
                                                value={(user && user.statusId && lookup.user_statuses.find(item => item.value + '' === user.statusId + '')) || null}
                                                options={lookup.user_statuses || []}
                                                required
                                                onChange={(e) => { onChangeHandler('statusId', e.value) }}
                                            />
                                            {errors.statusId && <div className="validation-error">{errors.statusId}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-6">
                                            <h5>Roles</h5>
                                            <div className="col-md-12 form-row trim">
                                                {lookup.user_roles.map(item => {
                                                    let _disabled = context === 'PROFILE';
                                                    
                                                    console.log(roles);
                                                    let isChecked = roles.filter(_a => _a.roleId + '' === item.value + '').length > 0;
                                                    
                                                    return <div key={item.value} className="col-md-12">
                                                        <input type="checkbox" id={item.roleId} checked={isChecked} disabled={_disabled}
                                                            onChange={(e) => roleChangeHandler(item.value, e.target.checked)}
                                                        />
                                                        <label htmlFor={item.roleId}><span style={{ cursor: (_disabled ? 'not-allowed' : 'pointer') }}></span> &nbsp;{item.label}</label>
                                                    </div>
                                                })}
                                                {errors.roles && <div className="validation-error">{errors.roles}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {passMessage && <div className="col-md-12">
                                    <i className="mdi mdi-check" style={{ color: 'green' }}></i>  {passMessage}
                                </div>}
                                <div className="clearfix" style={{ padding: '10px 0' }}>
                                    <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleSave()} >{context === 'NEW' ? 'Save' : 'Update'}</button>
                                    {context === 'DETAIL' && <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: '10px' }} onClick={() => sendPassword()} >Send Password</button>}
                                    <button type="button" className="btn btn-g btn-secondary float-end" onClick={() => props.history.push('/admin')} >Cancel</button>
                                </div>
                            </div>

                            {context === 'PROFILE' && <>
                                <hr />
                                <div className="container-fluid mt-3">
                                    <div className="clearfix">
                                        <h5 className="float-start"> Update Password</h5>
                                    </div>
                                </div>

                                <div className="container-fluid">
                                    <div className="col-md-12 row">

                                        <div className="col-sm-6">
                                            <label className="form-label">New Password  </label>
                                            <input type="password" className="form-control" value={payload.password || ''}
                                                onChange={(e) => passwordChangeHandler('password', e.target.value)} />
                                            {errors.password && <div className="validation-error">{errors.password}</div>}
                                        </div>

                                        <div className="col-sm-6">
                                            <label className="form-label">Confirm New Password  </label>
                                            <input type="password" className="form-control" value={payload.rePassword || ''}
                                                onChange={(e) => passwordChangeHandler('rePassword', e.target.value)}
                                            />
                                            {errors.rePassword && <div className="validation-error">{errors.rePassword}</div>}
                                        </div>

                                    </div>
                                    {passUpdateMessage && <div className="col-md-12">
                                        <i className="mdi mdi-check" style={{ color: 'green' }}></i>  {passUpdateMessage}
                                    </div>}
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: '10px' }} onClick={() => updatePassword()} >Update</button>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default UserDetail;