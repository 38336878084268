import React, { useEffect } from 'react';
import { useAuthDispatch, useAuthState } from '../Context';

function Route(props) {

    const userDetails = useAuthState()
    const dispatch = useAuthDispatch()

    const setRunningApp = (appName) => {
        dispatch({ type: 'SET_APP', payload: appName });
        if (appName === 'BVA')
            props.history.push("/bva-home");
        if (appName === 'BVA HC')
            props.history.push("/bvahc-home");
        if (appName === 'ELA')
            props.history.push("/ela-home");
    }
    const { userType } = userDetails.user;
    const { app } = userDetails.user;
    useEffect(() => {
        if (userType) {
            if (!app) {
                let appName = undefined;
                if (userType.isBvaUser) appName = 'BVA';
                else if (userType.isBvaHcUser) appName = 'BVA HC';
                else if (userType.isElaUser) appName = 'ELA';
                setRunningApp(appName);
            }
        }
    });
    return (<div style={{ textAlign: "center", marginTop: "40px" }}>
        <h5>Unauthorized Access</h5>
        <div>Your account does not have access to this page.</div>
    </div>)
}

export default Route;


