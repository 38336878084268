import bigDecimal from "js-big-decimal";

export const startLoading = () => {
  document.getElementById("loader").style.display = "block";
}

export const stopLoading = () => {
  document.getElementById("loader").style.display = "none";
};

export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

export const validateEmail = (email) => {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(email)
}

export const arrayToMap = (data, key) => {
  let map = {};
  data.map(item => {
    let _key = item[key];
    if (_key in map) {
      map[_key].push(item);
    } else {
      map[_key] = [item];
    }
    return undefined;
  });
  return map;
}

export const randomString = (length) => {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const myPermissions = (permissions, object_name) => {
  return permissions.filter(item => item.object_name === object_name)[0]
};
export const formatCurrency = (price, dec) => {
  if(!dec) dec=0;
  
  if(dec===-2){
    const num= parseFloat(price);
    return num.toLocaleString('en-US', {maximumFractionDigits:2});
  } else{
    const mult = Math.pow(10, dec);
    const num= Math.round(price*mult)/mult;

    //console.log(num, bigDecimal.getPrettyValue(bigDecimal.round(num, 0)))
    try {
      return bigDecimal.getPrettyValue(bigDecimal.round(num, dec));
    } catch (e) {
      console.warn(e);
    }
  }
};
export const getDistinctValArray = (data, key) => {
  return [...new Map(data.map(item => [item[key], item])).values()];
}
export const round = (value, precision) =>{
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const sortByIntVal = (data, key) => {
  return data.sort((a, b) => a[key] - b[key]);
}

export const getMagicNumber = () => {
  let min = 1;
  let max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const evalExpression = (expression) => {
  try {
    // eslint-disable-next-line
    eval(expression);
    return "SUCCESS"
  } catch (e) {
    console.warn(e);
    return 'ERROR';
  }
}
export const getStatusColor = (statusId) => {
  return statusId === 'N/A' ? '#CCCCCC' :statusId === 'NEW' ? '#3366ff' :statusId === 'INCOMPLETE' ? '#3366ff' : statusId === 'BUDGETARY' ? '#ff9933' : statusId === 'SUBMITTED' ? '#66ccff':statusId === 'APPROVED' ? '#32d082':statusId === 'DECLINED' ? '#cc0000':"#CCCCCC";
}

export const establishUserType = (auths, user) => {
  var retObj = {
    isBvaSuperUser: false,
    isBvaUser: false,
    isElaAdmin: false,
    isElaDDUser: false,
    isElaQuotingUser: false,
    isBvaHcUser: false,
    isBvaHcSuperUser: false,
    isBvaHcAdmin: false,
    isMultiApp: false,
    appCount: 0
  };

  auths && auths.length > 0 && auths.map(item => {
    if (item.roleId === 1)
      retObj.isBvaSuperUser = true;
    if (item.roleId === 1 || item.roleId === 2)
      retObj.isBvaUser = true;
    if (item.roleId === 5)
      retObj.isElaAdmin = true;
    if (item.roleId === 3)
      retObj.isElaDDUser = true;
    if (item.roleId === 4)
      retObj.isElaQuotingUser = true;
    if (item.roleId === 3 || item.roleId === 4 || item.roleId === 5)
      retObj.isElaUser = true;

    if (item.roleId === 8)
      retObj.isBvaHcAdmin = true;
    if (item.roleId === 7)
      retObj.isBvaHcSuperUser = true;
    if (item.roleId === 6 || item.roleId === 7 || item.roleId === 8)
      retObj.isBvaHcUser = true;
    return item;
  });
  let appCount = 0;
  if (retObj.isBvaUser) appCount = appCount + 1;
  if (retObj.isElaUser) appCount = appCount + 1;
  if (retObj.isBvaHcUser) appCount = appCount + 1;
  if (appCount > 1) retObj.isMultiApp = true;
  retObj.appCount = appCount;

  return retObj;
};


export const checkFileSize = (_files) => {
  let filesize = 0;
  let checkIfAllowedFilesize = true;
  for (let i = 0; i < _files.length; i++) {
    if (_files[i].size >= 1024) {
      filesize = Math.ceil(_files[i].size / 1024);
      if (filesize > 10240) {
        checkIfAllowedFilesize = false;
      }
    }
  }
  return checkIfAllowedFilesize;
};

export const checkIfAllowedFiletype = (_files, allowedMIMETypes, allowedFileExtns) => {
  for (let i = 0; i < _files.length; i++) {
    _files[i].isPermitted = false;
    if (allowedMIMETypes && allowedMIMETypes.length > 0) {
      for (let j = 0; j < allowedMIMETypes.length; j++) {
        if (_files[i].type.indexOf(allowedMIMETypes[j]) === 0) {
          _files[i].isPermitted = true;
        }
      }
    }
    if (!_files[i].isPermitted && allowedFileExtns && allowedFileExtns.length > 0) {
      let splits = (_files[i].name).split(".");
      for (let k = 0; k < allowedFileExtns.length; k++) {
        if (splits[splits.length - 1] === allowedFileExtns[k]) {
          _files[i].isPermitted = true;
        }
      }
    }
    if (_files[i].isPermitted) {
      let splits = (_files[i].name).split(".");
      if (splits[splits.length - 1] === 'exe') {
        _files[i].isPermitted = false;
      }
    }
  }
  return _files;
}

export const replaceSpecialChars = (_str) => {
  let fname = "";
  let splits = _str.split(".");
  for (let i = 0; i < splits.length - 1; i++) {
    fname = fname + splits[i];
  }
  let fupname = fname.replace(/[&/#,+()$~%.'":*?<>{}\s+]/g, "_");
  return  fupname + "." + splits[splits.length - 1];

};
