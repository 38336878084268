import { get } from "./Service";
import { arrayToMap } from './../Components/Util';

export const getLookups = (onDone) => {
    get("/api/get-lookups", (res) => {
        res = arrayToMap(res, 'family');
        onDone(res);
    });
};
export const getElaLookups = (onDone) => {
    get("/api/get-ela-lookups", (res) => {
        res.push({value:"3", label:"3 Years", family:"contract_length"});
        res.push({value:"4", label:"4 Years", family:"contract_length"});
        res.push({value:"5", label:"5 Years", family:"contract_length"});
        //res.push({value:"8/5", label:"8/5", family:"sla"});
        res.push({value:"24/7", label:"24/7", family:"sla"});
        res.push({value:"PBS", label:"PBS", family:"sla"});
        res = arrayToMap(res, 'family');
        onDone(res);
    });
};