import React, { useEffect } from 'react';
import Header from '../../Components/layout/Header';
import Form from './Form';

function CreateModel(props) {

    useEffect(() => {

    });

    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <Form {...props} />
                </div>
            </main>
        </>
    )
}

export default CreateModel;


