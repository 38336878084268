import React from 'react';
import Text from './fields/Text';
import { formatCurrency, round } from '../../../Components/Util';
import SelectField from './fields/Select';

function NetworkCreation(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    const job_classes = [
        { value: 'Clinical Staff', label: 'Clinical Staff' },
        { value: 'Clerical Staff', label: 'Clerical Staff' },
        { value: 'Network Engineer', label: 'Network Engineer' },
        { value: 'InfoSec Engineer', label: 'InfoSec Engineer' },
        { value: 'Biomedical Engineer', label: 'Biomedical Engineer' }
    ];
    const ramp ={
        yr1: 0.25,
        yr2: 0.85,
        yr3: 1,
        yr4: 1,
        yr5: 1
    }
    const get_salary = (jc) =>{
        let val =0;
        if (jc==='Clinical Staff') {
            val= parseFloat(data.step12__salary_clinical_staff)/50/40;
        } else if(jc==='Clerical Staff'){
            val=  parseFloat(data.step12__salary_clerical_staff)/50/40;
        } else if(jc==='Network Engineer'){
            val=  parseFloat(data.step12__salary_network_engineer)/50/40;
        } else if(jc==='InfoSec Engineer'){
            val=  parseFloat(data.step12__salary_infosec_engineer)/50/40;
        } else if(jc==='Biomedical Engineer'){
            val=  parseFloat(data.step12__salary_biomedical_engineer)/50/40;
        }
        
        return val;
    }

    const growth_devices_yr1 = parseFloat(data.step12__number_of_devices_across_facilities) * (1 + parseFloat(data.step12__cagr) / 100);
    const growth_owned_devices_yr1 = (growth_devices_yr1  - Math.round(growth_devices_yr1*parseFloat(data.step12__per_rental_devices) / 100));
    const new_device_types_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const new_vlans_yr1 =  Math.ceil(parseFloat(data.step12__number_of_vlans) *(parseFloat(data.step12__cagr) / 100));


    const device_comm_effort_current_yr1 = (round(parseFloat(data.step16__deploy_policy_effort) * new_device_types_devices_yr1 / 60, 1));
    const grouping_effort_current_yr1 = (round(parseFloat(data.step16__deploy_tag_effort) * new_vlans_yr1 / 60, 1));
    const server_comm_effort_current_yr1 = (round(parseFloat(data.step16__perimeter_effort) * new_device_types_devices_yr1 / 60, 1));
    
 const current_yr1 = Math.ceil(device_comm_effort_current_yr1*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_current_yr1* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_current_yr1*get_salary(data.step16__perimeter_job_class));
    
     const device_comm_effort_current_yr2 = round(device_comm_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const grouping_effort_current_yr2 = round(grouping_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const server_comm_effort_current_yr2 = round(server_comm_effort_current_yr1 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    
    const current_yr2 = Math.ceil(device_comm_effort_current_yr2*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_current_yr2* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_current_yr2*get_salary(data.step16__perimeter_job_class));


    const device_comm_effort_current_yr3 = round(device_comm_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const grouping_effort_current_yr3 = round(grouping_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const server_comm_effort_current_yr3 = round(server_comm_effort_current_yr2 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const current_yr3 = Math.ceil(device_comm_effort_current_yr3*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_current_yr3* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_current_yr3*get_salary(data.step16__perimeter_job_class));

    const device_comm_effort_current_yr4 = round(device_comm_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const grouping_effort_current_yr4 = round(grouping_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const server_comm_effort_current_yr4 = round(server_comm_effort_current_yr3 * (1 + parseFloat(data.step12__cagr) / 100), 1);

    const current_yr4 = Math.ceil(device_comm_effort_current_yr4*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_current_yr4* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_current_yr4*get_salary(data.step16__perimeter_job_class));

    const device_comm_effort_current_yr5 = round(device_comm_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const grouping_effort_current_yr5 = round(grouping_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);
    const server_comm_effort_current_yr5 = round(server_comm_effort_current_yr4 * (1 + parseFloat(data.step12__cagr) / 100), 1);

    const current_yr5 = Math.ceil(device_comm_effort_current_yr5*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_current_yr5* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_current_yr5*get_salary(data.step16__perimeter_job_class));
    
    const device_comm_effort_proposed_yr1= round(device_comm_effort_current_yr1-(device_comm_effort_current_yr1*parseFloat(data.step16__deploy_policy_impact)/100*ramp.yr1),4);
    const grouping_effort_proposed_yr1= round(grouping_effort_current_yr1-(grouping_effort_current_yr1*parseFloat(data.step16__deploy_tag_impact)/100*ramp.yr1),4);
    const server_comm_effort_proposed_yr1= round(server_comm_effort_current_yr1-(server_comm_effort_current_yr1*parseFloat(data.step16__perimeter_impact)/100*ramp.yr1),4);
    
  const proposed_yr1 = Math.ceil(device_comm_effort_proposed_yr1*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_proposed_yr1* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_proposed_yr1*get_salary(data.step16__perimeter_job_class));
    
    const device_comm_effort_proposed_yr2= round(device_comm_effort_current_yr2-(device_comm_effort_current_yr2*parseFloat(data.step16__deploy_policy_impact)/100*ramp.yr2),4);
    const grouping_effort_proposed_yr2= round(grouping_effort_current_yr2-(grouping_effort_current_yr2*parseFloat(data.step16__deploy_tag_impact)/100*ramp.yr2),4);
    const server_comm_effort_proposed_yr2= round(server_comm_effort_current_yr2-(server_comm_effort_current_yr2*parseFloat(data.step16__perimeter_impact)/100*ramp.yr2),4);


    const proposed_yr2 = Math.ceil(device_comm_effort_proposed_yr2*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_proposed_yr2* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_proposed_yr2*get_salary(data.step16__perimeter_job_class));

    const device_comm_effort_proposed_yr3= round(device_comm_effort_current_yr3-(device_comm_effort_current_yr3*parseFloat(data.step16__deploy_policy_impact)/100*ramp.yr3),4);
    const grouping_effort_proposed_yr3= round(grouping_effort_current_yr3-(grouping_effort_current_yr3*parseFloat(data.step16__deploy_tag_impact)/100*ramp.yr3),4);
    const server_comm_effort_proposed_yr3= round(server_comm_effort_current_yr3-(server_comm_effort_current_yr3*parseFloat(data.step16__perimeter_impact)/100*ramp.yr3),4);


    const proposed_yr3 = Math.ceil(device_comm_effort_proposed_yr3*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_proposed_yr3* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_proposed_yr3*get_salary(data.step16__perimeter_job_class));

    const device_comm_effort_proposed_yr4= round(device_comm_effort_current_yr4-(device_comm_effort_current_yr4*parseFloat(data.step16__deploy_policy_impact)/100*ramp.yr4),4);
    const grouping_effort_proposed_yr4= round(grouping_effort_current_yr4-(grouping_effort_current_yr4*parseFloat(data.step16__deploy_tag_impact)/100*ramp.yr4),4);
    const server_comm_effort_proposed_yr4= round(server_comm_effort_current_yr4-(server_comm_effort_current_yr4*parseFloat(data.step16__perimeter_impact)/100*ramp.yr4),4);

    const proposed_yr4 = Math.ceil(device_comm_effort_proposed_yr4*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_proposed_yr4* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_proposed_yr4*get_salary(data.step16__perimeter_job_class));

    const device_comm_effort_proposed_yr5= round(device_comm_effort_current_yr5-(device_comm_effort_current_yr5*parseFloat(data.step16__deploy_policy_impact)/100*ramp.yr5),4);
    const grouping_effort_proposed_yr5= round(grouping_effort_current_yr5-(grouping_effort_current_yr5*parseFloat(data.step16__deploy_tag_impact)/100*ramp.yr5),4);
    const server_comm_effort_proposed_yr5= round(server_comm_effort_current_yr5-(server_comm_effort_current_yr5*parseFloat(data.step16__perimeter_impact)/100*ramp.yr5),4);


    const proposed_yr5 = Math.ceil(device_comm_effort_proposed_yr5*get_salary(data.step16__deploy_policy_job_class)
    + grouping_effort_proposed_yr5* get_salary(data.step16__deploy_tag_job_class)
    + server_comm_effort_proposed_yr5*get_salary(data.step16__perimeter_job_class));

    const saving_yr1=current_yr1-proposed_yr1;
    const saving_yr2=current_yr2-proposed_yr2;
    const saving_yr3=current_yr3-proposed_yr3;
    const saving_yr4=current_yr4-proposed_yr4;
    const saving_yr5=current_yr5-proposed_yr5;

    const step16__total = saving_yr1;

    const next = () => {
        props.next({ step16__total: step16__total, step16__yr1:saving_yr1, step16__yr2:saving_yr2 , step16__yr3:saving_yr3 , step16__yr4:saving_yr4 , step16__yr5:saving_yr5 });

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
            <div className='col-10' >
                <h4>Network Policy Creation Labor</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step16__mvr"
                    value={(data && data["step16__mvr"] && props.mvrs.find(item => item.value === data["step16__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step16__mvr"]}
                />
                </div>
            </div>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step16__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Network Policy Creation Expected Savings</div>
                        <div className='row' >
                            
                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(step16__total), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                <div className='col-12'>
                            <table className='table'>
                                 <colgroup>
                                    <col  style={{width:"40%"}} />
                                    <col style={{width:"15%"}} />
                                    <col style={{width:"25%"}} />
                                    <col style={{width:"10%"}} />
                                    <col style={{width:"10%"}} />
                                </colgroup>
                                    <tbody>
                                    <tr>
                                        <td valign="bottom">Deploy device level policy enforcement across each device type and/or groups of the same device type upon connection to the network.</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step16__deploy_policy_effort"
                                                value={data["step16__deploy_policy_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step16__deploy_policy_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step16__deploy_policy_job_class"
                                            value={(data && data["step16__deploy_policy_job_class"] && job_classes.find(item => item.value === data["step16__deploy_policy_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step16__deploy_policy_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step16__deploy_policy_impact"
                                            value={data["step16__deploy_policy_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step16__deploy_policy_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>
                                        
                                    <tr>
                                        <td valign="bottom">Deploy network-level tag-based security policy for each network group</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step16__deploy_tag_effort"
                                                value={data["step16__deploy_tag_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step16__deploy_tag_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step16__deploy_tag_job_class"
                                            value={(data && data["step16__deploy_tag_job_class"] && job_classes.find(item => item.value === data["step16__deploy_tag_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step16__deploy_tag_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_vlans_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step16__deploy_tag_impact"
                                            value={data["step16__deploy_tag_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step16__deploy_tag_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>      

                                    <tr>
                                        <td valign="bottom">Deploy perimeter and data center policies for each device type</td>
                                        <td >
                                            <Text
                                                fieldColor="blue"
                                                fieldName="step16__perimeter_effort"
                                                value={data["step16__perimeter_effort"] + ""}
                                                label="Effort Required (Minutes)"
                                                required={true}
                                                handleChange={handleChange}
                                                error={errors["step16__perimeter_effort"]}
                                                pattern="[0-9]*"
                                            />
                                        </td>
                                        <td>

                                        <SelectField
                                            fieldColor="orange"
                                            fieldName="step16__perimeter_job_class"
                                            value={(data && data["step16__perimeter_job_class"] && job_classes.find(item => item.value === data["step16__perimeter_job_class"])) || null}
                                            options={job_classes}
                                            label="Job Class"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step16__perimeter_job_class"]}
                                        />
                                        </td>
                                        <td  valign="bottom" >
                                            <div className="form-label" style={{marginBottom:0, fontSize:"0.75em"}}>Volume (Year 1)</div>
                                            <div style={{ fontSize: "1.65em"}}>{formatCurrency(new_device_types_devices_yr1, 0)}</div>
                                        </td>
                                        <td >
                                        <Text
                                            fieldColor="orange"
                                            fieldName="step16__perimeter_impact"
                                            value={data["step16__perimeter_impact"] + ""}
                                            label="Medigate Impact"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step16__perimeter_impact"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        />
                                        </td>
                                    </tr>  
                                        
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>               


                </div>

            </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-12'>
                            <table className='table'>  
                            <thead>
                                    <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                   
                                    <tr>
                                     
                                        <td>Current Spending Without Medigate</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr1, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr2, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr3, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr4, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(current_yr5, 2)}</td>
                                    </tr>
                                    
                                    <tr>
                                      
                                        <td>Proposed Spending With Medigate *</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr1, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr2, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr3, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr4, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(proposed_yr5, 2)}</td>
                                    </tr>
                                   
                                    <tr>
                                  
                                        <td>Expected Savings With Medigate</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr1, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr2, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr3, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr4, 2)}</td>
                                        <td style={{ textAlign: "right" }}>${formatCurrency(saving_yr5, 2)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>               

                </div>
            </div>
            <div class="alert alert-warning" role="alert">
                * Proposed Spending with Medigate assumes normal ramped deployment of 25% deployment completed in Year 1, 85% deployment completed in  Year 2 and full deployment completion in Year 3.        
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default NetworkCreation;