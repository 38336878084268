import React, { useEffect, useState } from 'react';
import ProgressArc from '../../Components/widgets/ProgressArc';
import { formatCurrency } from '../../Components/Util.js';

function BreadCrumb(props) {
    const [steps, setSteps] = useState([]);
    console.log(props)
    useEffect(() => {
        const data = [
            {
                label: "Model Details",
                type: "no summary",
                status: props.data.max_step_reached>=1?"complete":"incomplete",
                active: props.step===1,
                step: 1
            },
            {
                label: "System Cost",
                amount: props.data.max_step_reached>=2?props.data.step2__total :null,
                type: props.data.max_step_reached>=2?"first year" :"no summary",
                status: props.data.max_step_reached>=2?"complete":"incomplete",
                active: props.step===2,
                step: 2
            },
            {
                label: "Fleet Management",
                amount: props.data.max_step_reached>=3?(props.data.step3__purchase_cost_saving+props.data.step3__annual_maintenace_cost_saving) :null,
                recurring: props.data.max_step_reached>=3?(props.data.step3__annual_maintenace_cost_saving) :null,
                type: props.data.max_step_reached>=3?"recovery realised" :"no summary",
                fromMonth: props.data.max_step_reached>=3?props.data.step3__mvr :null,
                //percentage: props.data.max_step_reached>=3?props.data.step3__percent :null,
                status: props.data.max_step_reached>=3?"complete":"incomplete",
                active: props.step===3,
                step: 3
            },
            {
                label: "Outdated Operating System Replacement",
                amount: props.data.max_step_reached>=4?props.data.step4__total :null,
                type: props.data.max_step_reached>=4?"recovery realised" :"no summary",
                fromMonth: props.data.max_step_reached>=4?props.data.step4__mvr :null,
                status: props.data.max_step_reached>=4?"complete":"incomplete",
                active: props.step===4,
                step: 4
            },
            // {
            //     label: "Over Utilization",
            //     amount: props.data.max_step_reached>=5?props.data.step5__total :null,
            //     type: props.data.max_step_reached>=5?"recovery realised" :"no summary",
            //     fromMonth: props.data.max_step_reached>=5?props.data.step5__mvr :null,
            //     //percentage: props.data.max_step_reached>=5?props.data.step5__percent :null,
            //     status: props.data.max_step_reached>=5?"complete":"incomplete",
            //     active: props.step===5,
            //     step: 5
            // },
            {
                label: "Under Utilization",
                amount: props.data.max_step_reached>=6?props.data.step6__total :null,
                type: props.data.max_step_reached>=6?"recovery realised" :"no summary",
                fromMonth: props.data.max_step_reached>=6?props.data.step6__mvr :null,
                status: props.data.max_step_reached>=6?"complete":"incomplete",
                active: props.step===6,
                step: 6
            },
            {
                label: "Increased Throughput",
                amount: props.data.max_step_reached>=7?props.data.step7__total :null,
                type: props.data.max_step_reached>=7?"recovery realised" :"no summary",
                fromMonth: props.data.max_step_reached>=7?props.data.step7__mvr :null,
                //percentage: props.data.max_step_reached>=7?props.data.step7__percent :null,
                status: props.data.max_step_reached>=7?"complete":"incomplete",
                active: props.step===7,
                step: 7
            },
            
            // {
            //     label: "Uptime & Patient Satisfaction",
            //     amount: props.data.max_step_reached>=8?props.data.step8__total :null,
            //     type: props.data.max_step_reached>=8?"streamlined maintenance" :"no summary",
            //     fromMonth: props.data.max_step_reached>=8?props.data.step8__mvr :null,
            //     status: props.data.max_step_reached>=8?"complete":"incomplete",
            //     active: props.step===8,
            //     step: 8
            // },    
            {
                label: "Maintenance Contract Expenses",
                amount: props.data.max_step_reached>=9?props.data.step9__total :null,
                type: props.data.max_step_reached>=9?"streamlined maintenance" :"no summary",
                fromMonth: props.data.max_step_reached>=9?props.data.step9__mvr :null,
                status: props.data.max_step_reached>=9?"complete":"incomplete",
                active: props.step===9,
                step: 9
            }, 
            // {
            //     label: "Parts Management",
            //     amount: props.data.max_step_reached>=10?props.data.step10__total :null,
            //     type: props.data.max_step_reached>=10?"streamlined maintenance" :"no summary",
            //     fromMonth: props.data.max_step_reached>=10?props.data.step10__mvr :null,
            //     status: props.data.max_step_reached>=10?"complete":"incomplete",
            //     active: props.step===10,
            //     step: 10
            // }, 
            {
                label: "Breach Fines & Fees",
                amount: props.data.max_step_reached>=11?props.data.step11__total :null,
                type: props.data.max_step_reached>=11?"cybersecurity cost avoidance" :"no summary",
                fromMonth: props.data.max_step_reached>=11?props.data.step11__mvr :null,
                status: props.data.max_step_reached>=11?"complete":"incomplete",
                active: props.step===11,
                step: 11
            }, 
            {
                label: "Device Lifecycle",
                type: "no summary",
                status: props.data.max_step_reached>=12?"complete":"incomplete",
                active: props.step===12,
                step: 12
            },
            {
                label: "Clinical Engineering Labor",
                amount: props.data.max_step_reached>=13?props.data.step13__total :null,
                type: props.data.max_step_reached>=13?"expected savings" :"no summary",
                fromMonth: props.data.max_step_reached>=13?props.data.step13__mvr :null,
                status: props.data.max_step_reached>=13?"complete":"incomplete",
                active: props.step===13,
                step: 13
            }, 
            {
                label: "Cyber Hygiene Labor",
                amount: props.data.max_step_reached>=14?props.data.step14__total :null,
                type: props.data.max_step_reached>=14?"expected savings" :"no summary",
                fromMonth: props.data.max_step_reached>=14?props.data.step14__mvr :null,
                status: props.data.max_step_reached>=14?"complete":"incomplete",
                active: props.step===14,
                step: 14
            }, 
            {
                label: "Network Management Labor",
                amount: props.data.max_step_reached>=15?props.data.step15__total :null,
                type: props.data.max_step_reached>=15?"expected savings" :"no summary",
                fromMonth: props.data.max_step_reached>=15?props.data.step15__mvr :null,
                status: props.data.max_step_reached>=15?"complete":"incomplete",
                active: props.step===15,
                step: 15
            }, 
            {
                label: "Network Policy Creation",
                amount: props.data.max_step_reached>=16?props.data.step16__total :null,
                type: props.data.max_step_reached>=16?"expected savings" :"no summary",
                fromMonth: props.data.max_step_reached>=16?props.data.step16__mvr :null,
                status: props.data.max_step_reached>=16?"complete":"incomplete",
                active: props.step===16,
                step: 16
            }, 
            {
                label: "Summary",
                type: "no summary",
                status: props.data.max_step_reached>=17?"complete":"incomplete",
                active: props.step===17,
                step: 17
            }
        ];
        setSteps(data);
    }, [props.step]);
    const navigate =(step)=>{
        props.navigate(step);
    }
    const renderItem = (item, isLast) => {
        let showMiniChart = false;
        if (item.percentage && (item.type === "recovery realised" || item.type === "streamlined maintenance" || item.type === "cybersecurity cost avoidance" || item.type === "expected savings")) {
            showMiniChart = true;
        }
        return <div key={"nav-"+item.step} className={"bc-item " + item.status} style={{ color: !item.active ? "#808080" : "#222222" }}>
            <div className="bc-item-track">
                <div className='dot'></div>
                {!isLast && <div className='bar'></div>}
            </div>
            <div className="bc-container" >
                <div style={{ width: "100%" }}>
                    
                    {item.status === "complete" ? <div className="bc-label" style={{cursor:"pointer"}} onClick={()=>{navigate(item.step)}}>{item.label}</div>:<div className="bc-label">{item.label}</div>}
                    {/* {!isLast && item.status === "complete" && !item.active && <div className="btn-clipboard float-end">Edit</div>} */}
                    {item.active && <div className="float-end" style={{ position: "absolute", right: "0", top: "0" }}><i className="mdi mdi-pen" style={{ paddingRight: "15px" }}></i></div>}
                </div>
                <div className={showMiniChart ? "bc-description-icon" : "bc-description"}>

                    {item.type === "no summary" && <div className="text">&nbsp;</div>}
                    {!item.percentage && (item.type === "recovery realised" || item.type === "streamlined maintenance" || item.type === "cybersecurity cost avoidance" || item.type === "expected savings")  && <div className="text"><b>${formatCurrency(item.amount, 0)}{item.recurring?' in first year followed by $'+formatCurrency(item.recurring, 0)+' per year':''}</b> <br /> {item.type}  from month {item.fromMonth}</div>}
                    {item.type === "first year" && <div className="text"><b>{formatCurrency(item.amount, 0)}</b> in first year</div>}
                    {showMiniChart && <div className='icon'>
                        <ProgressArc value={item.percentage} radius={40} arcColor={!item.active ? "#808080" : "#222222"} textColor={!item.active ? "#808080" : "#222222"} />
                    </div>}
                    {showMiniChart && <div className="text"><b>${formatCurrency(item.amount, 0)}</b> {item.type} <br /> from month {item.fromMonth}</div>}
                    
                </div>
            </div>
        </div>;
    }

    return (
        <div className="bc" >
            {steps && steps.length > 0 ? <>
                {steps.map((item, index) => renderItem(item, index === steps.length - 1))}
            </> : null}


        </div>
    )
}

export default BreadCrumb;


