import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading, formatCurrency, getStatusColor } from '../../Components/Util';
import Attachments from '../../Components/Attachments';
import * as service from "../../services";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuthState } from '../../Context';
import DatePicker from '../../Components/widgets/DatePicker';
import moment from 'moment';


function SummaryEla(props) {
    const userDetails = useAuthState();
    let { userType } = userDetails.user;
    console.log(userType);
    const [title, setTitle] = useState("Configure Ela Quote");
    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);
    const [parts, setParts] = useState([]);
    const [pricing, setPricing] = useState([]);
    const [pricingTotal, setPricingTotal] = useState([]);
    const [pricingSchedule, setPricingSchedule] = useState([]);
    const [paymentSchedule, setPaymentSchedule] = useState([]);
    const [paymentYears, setPaymentYears] = useState([]);
    const [quoteHeader, setQuoteHeader] = useState({});
    const [showAdjustment, setShowAdjustment] = useState(false);
    const [priceDetail, setPriceDetail] = useState([]);
    const [prevPriceDetail, setPrevPriceDetail] = useState([]);
    const [showQuoteAdjustment, setShowQuoteAdjustment] = useState(false);
    const [showPaymentSchedule, setShowPaymentSchedule] = useState(false);
    const [approval, setApproval] = useState({});
    const [locked, setLocked] = useState(false);
    const [fileProps, setFileProps] = useState({});
    const [approvalComments, setApprovalComments] = useState(undefined);
    const [approvalCommentsDD, setApprovalCommentsDD] = useState(undefined);
    const [showCopyConfirm, setShowCopyConfirm] = useState(false);
    const [copiedQuoteId, setCopiedQuoteId] = useState(0);
    const [showAddApprover, setShowAddApprover] = useState(false);
    const [approvalStep, setApprovalStep] = useState({});
    const [token, setToken] = useState(undefined);
    const [approvalComment, setApprovalComment] = useState({});
    const [showApprovalComment, setShowApprovalComment] = useState(false);

    const [errors, setErrors] = useState({});
    const loadAll = (fn) => {
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                setTitle(`Edit ELA Quote #${props.match.params.id}`);
                console.log(props)
                service.getElaData("ela-header", { id: props.match.params.id }, res => {
                    console.log(1);
                    if (res && res.length === 1) {
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        if (res[0].ela_status === 'APPROVED' || res[0].ela_status === 'DECLINED' || (res[0].ela_status === 'SUBMITTED' && !userType.isElaDDUser)) {
                            setLocked(true);
                        } else {
                            setLocked(false);
                        }
                        resolve("");
                    }

                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            loadSites(() => {
                console.log(2);
                resolve("");
            });

        });
        let p3 = new Promise((resolve, reject) => {
            loadParts(() => {
                console.log(3);
                resolve("");
            });

        });
        let p4 = new Promise((resolve, reject) => {
            loadPricing(() => {
                console.log(4);
                resolve("");
            });

        });
        let p5 = new Promise((resolve, reject) => {
            loadPricingSchedule(() => {
                console.log(5);
                resolve("");
            });

        });
        let p6 = new Promise((resolve, reject) => {
            loadApprovalComments(() => {
                console.log(5);
                resolve("");
            });

        });

        Promise.all([p1, p2, p3, p4, p5, p6])
            .then(values => {
                console.log(values);
                fn();
            })
            .catch(error => {
                console.log(error.message);
            });

    }
    useEffect(() => {
        startLoading();
        loadAll(() => {
            service.getUniqueId((tkn) => {
                setToken(tkn);
                setFileProps({ guid: tkn, allowUpload: true, context: 'APPROVED_QUOTE_DOCUMENT', contextId: props.match.params.id, defaultLoad: true, label: "Final Quote" });
                setLoading(false);
            });

        });

    }, [props.match.params.id]);



    const loadPricingSchedule = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("getpricing_schedule_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.pricing && res.pricing.length > 0) {
                    setPricingSchedule(res.pricing);
                    if (res.dates && res.dates.length === 1) {
                        setPaymentSchedule(res.dates[0]);
                    }
                    if (res.years && res.years.length === 1) {
                        setPaymentYears(res.years[0]);
                    }
                    fn();
                } else {
                    setPricingSchedule([]);
                    setPaymentSchedule([]);
                    setPaymentYears([]);
                    fn();
                }
            });
        } else {
            setSites([]);
            fn();
        }
    }
    const loadPricing = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_pricing_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    let total = 0;
                    res.forEach(item => {
                        total = total + item.tcv;
                    });
                    setPricingTotal(total);
                    setPricing(res);
                    fn();
                } else {
                    setPricing([]);
                    fn();
                }
            });
        } else {
            setSites([]);
            fn();
        }
    }
    const loadSites = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_site_summary_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setSites(res);
                    fn();
                } else {
                    setSites([]);
                    fn();
                }
            });
        } else {
            setSites([]);
            fn();
        }
    }
    const loadParts = (fn) => {
        if (parseInt(props.match.params.id) > 0) {
            service.getElaData("get_parts_by_quote_id", { quote_id: props.match.params.id }, res => {
                console.log(res);
                if (res && res.length > 0) {
                    console.log(res);
                    setParts(res);
                    fn();
                } else {
                    setParts([]);
                    fn();
                }
            });
        } else {
            setSites([]);
            fn();
        }
    }
    const loadPriceLine = (line_id, fn) => {
        service.getElaData("get_price_line_by_id", { id: line_id }, res => {
            console.log(res);
            if (res && res.length > 0) {
                console.log(res);
                setPriceDetail(res[0]);
                setPrevPriceDetail(res[0])
                fn();
            } else {
                setPriceDetail(undefined);
                setPrevPriceDetail(undefined);
                fn();
            }
        });

    }
    const loadApprovalComments = (fn) => {
        service.getElaData("get_approval_comments_by_id", { id: props.match.params.id }, res => {
            console.log(res);
            if (res && res.length > 0) {
                console.log(res);
                setApprovalComments(res);
                setApprovalCommentsDD(res.find(item=> item.approval_seq===1000))

                
                fn();
            } else {
                setApprovalComments([]);
                fn();
            }
        });

    }
    const validateAdjustment = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "adjustment_value", msg: "Please enter Adjustment Value" }
        ];

        validation.map(item => {
            if (!priceDetail[item.field] || priceDetail[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };
    const validateQuoteAdjustment = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "adjustment_value", msg: "Please enter Adjustment Value" }
        ];

        validation.map(item => {
            if (!quoteHeader[item.field] || quoteHeader[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };
    const validateApproval = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "comments", msg: "Please enter Comments" }
        ];

        validation.map(item => {
            if (!approval[item.field] || approval[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };
    const validateApprovalComment = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "comments", msg: "Please enter Comments" }
        ];

        validation.map(item => {
            if (!approvalComment[item.field] || approvalComment[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, ["approval_"+item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        console.log(errors);
        console.log(approvalComment);
        return isValid;
    };
    const validatePaymentSchedule = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "year1_invoice_date", msg: "Please select Year 1 Invoice date" },
            { field: "year1_payment_date", msg: "Please select Year 1 Payment date" },
            { field: "year2_invoice_date", msg: "Please select Year 2 Invoice date" },
            { field: "year2_payment_date", msg: "Please select Year 2 Payment date" },
            { field: "year3_invoice_date", msg: "Please select Year 3 Invoice date" },
            { field: "year3_payment_date", msg: "Please select Year 3 Payment date" }
        ];
        if (quoteHeader.contract_length > 3) {
            validation.push({ field: "year4_invoice_date", msg: "Please select Year 4 Invoice date" });
            validation.push({ field: "year4_payment_date", msg: "Please select Year 4 Payment date" });
        }
        if (quoteHeader.contract_length > 4) {
            validation.push({ field: "year5_invoice_date", msg: "Please select Year 5 Invoice date" });
            validation.push({ field: "year5_payment_date", msg: "Please select Year 5 Payment date" });
        }

        validation.map(item => {
            if (!paymentSchedule[item.field] || paymentSchedule[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };
    const validateApprovalStep = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "approver_name", msg: "Please enter Approver Name" }
        ];

        validation.map(item => {
            if (!approvalStep[item.field] || approvalStep[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    };

    const addApprovalStep = () => {
        if (validateApprovalStep()) {
            let payload = JSON.parse(JSON.stringify(approvalStep));
            payload.quote_id = props.match.params.id;
            payload.status = 'N/A';
            payload.approval_type = 'STEP';

            console.log(payload);

            startLoading();
            service.upsertEla('ela-add-approval-step', payload, res => {
                loadAll(() => {
                    setLoading(false);
                    setShowAddApprover(false);
                });

            });
        }
    }

    const handleApprovalStepChange = (prop, value) => {
        setApprovalStep(approvalStep => ({ ...approvalStep, [prop]: value }));
    }

    const handleAdjustmentChange = (prop, value) => {
        let adjustment_value = priceDetail.adjustment_value;
        if (prop === 'adjustment_type') {
            adjustment_value = 0;
        }
        setPriceDetail(priceDetail => ({ ...priceDetail, adjustment_value, [prop]: value }));
    }
    const handleQuoteAdjustmentChange = (prop, value) => {
        let adjustment_value = quoteHeader.adjustment_value;
        if (prop === 'adjustment_type') {
            adjustment_value = 0;
        }
        setQuoteHeader(quoteHeader => ({ ...quoteHeader, adjustment_value, [prop]: value }));
    }
    const handlePaymentScheduleChange = (prop, value) => {
        setPaymentSchedule(paymentSchedule => ({ ...paymentSchedule, [prop]: value }));
    }
    const handleApprovalChange = (prop, value) => {
        setApproval(approval => ({ ...approval, [prop]: value }));
    }

    const handleApprovalCommentChange = (prop, value) => {
        setApprovalComment(approvalComment => ({ ...approvalComment, [prop]: value }));
    }    
    const saveQuoteAdjustment = (close) => {
        if (quoteHeader.adjustment_type === 'NONE' || validateQuoteAdjustment()) {
            let payload = JSON.parse(JSON.stringify(quoteHeader));
            startLoading();
            service.upsertEla('ela-quote-adjustment', payload, res => {
                loadAll(() => {
                    setLoading(false);
                    if (close) {
                        setShowQuoteAdjustment(false);
                    }
                });
            });
        }
    }
    const saveAdjustment = (close) => {
        if (priceDetail.adjustment_type === 'NONE' || validateAdjustment()) {
            let payload = JSON.parse(JSON.stringify(priceDetail));
            startLoading();
            service.upsertEla('ela-adjustment', payload, res => {
                if (res && res.line_id) {
                    let p1 = new Promise((resolve, reject) => {
                        loadAll(() => {
                            resolve("");
                        });
                    });
                    let p2 = new Promise((resolve, reject) => {
                        loadPriceLine(res.line_id, () => {
                            resolve("");
                        });

                    });
                    Promise.all([p1, p2])
                        .then(values => {
                            setLoading(false);
                            if (close) {
                                setShowAdjustment(false);
                            }
                        })
                        .catch(error => {
                            console.log(error.message);
                        });
                }
            });
        }
    }
    const savePaymentSchedule = () => {
        if (validatePaymentSchedule()) {
            let payload = JSON.parse(JSON.stringify(paymentSchedule));
            payload.quote_id = props.match.params.id;
            startLoading();
            service.upsertEla('ela-payment-schedule', payload, res => {
                loadAll(() => {
                    setLoading(false);
                    setShowPaymentSchedule(false);
                });
            });
        }
    }
    const openAdjustment = (line_id) => {
        loadPriceLine(line_id, () => {
            setShowAdjustment(true);
        });
    }
    const submitForApproval = () => {
        let payload = JSON.parse(JSON.stringify(quoteHeader));
        startLoading();
        service.upsertEla('ela-submit-for-approval', payload, res => {
            service.sendApprovalEmail(quoteHeader.ela_number, (emailRes) => {
                loadAll(() => {
                    setLoading(false);
                });
            });
        });
    }
    const approve = () => {
        if (validateApproval()) {
            let payload = JSON.parse(JSON.stringify(quoteHeader));
            payload.comments = approval.comments;
            payload.guid = fileProps.guid;
            startLoading();
            service.upsertEla('ela-approve', payload, res => {
                startLoading();
                if(quoteHeader.opportunity_id!=='NONE'){
                    service.syncOpportunity(props.match.params.id, (res) => {
                        loadAll(() => {
                            setLoading(false);
                        });
                    });
                } else{
                    loadAll(() => {
                        setLoading(false);
                    });
                }
            });
        }
    }
    const deleteApproverStep =(id) => {
        startLoading();
        service.upsertEla('ela-approval-step-delete', {id: id}, res => {
            loadAll(() => {
                setLoading(false);
            });
        });
    }
    const openApprovalComments = (id, status) => {
        if (status === 'SUBMITTED') {
            updateApprovalStep(id, status);
        } else{
            let obj = approvalComments.find(item => item.id===id);
            if(obj){
                let new_obj = JSON.parse(JSON.stringify(obj));
                new_obj.status=status;
                setApprovalComment(new_obj);
                setShowApprovalComment(true);
            }
        }
    }
    const saveApprovalComments = () => {
        if (validateApprovalComment()) {
            updateApprovalStep(approvalComment.id, approvalComment.status);
        }
    }
    const updateApprovalStep = (id, status) => {
        let payload = { id: id, status: status };
        if (status !== 'SUBMITTED') {
            payload.comments = approvalComment.comments;
        }
        startLoading();
        service.upsertEla('ela-approval-step-update', payload, res => {
            loadAll(() => {
                setLoading(false);
                setShowApprovalComment(false);
                setApprovalComment({});
            });
        });
    }

    const decline = () => {
        if (validateApproval()) {
            let payload = JSON.parse(JSON.stringify(quoteHeader));
            payload.comments = approval.comments;
            payload.guid = fileProps.guid;
            startLoading();
            service.upsertEla('ela-decline', payload, res => {
                loadAll(() => {
                    setLoading(false);
                });
            });
        }
    }
    const copyQuote = () => {
        startLoading();
        service.upsertEla('ela-copy-quote', { id: props.match.params.id }, res => {
            setShowCopyConfirm(true);
            setCopiedQuoteId(res.ela_id);
        });
    }

    const syncOpportunity = () => {
        service.syncOpportunity(props.match.params.id, (res) => {
            console.log(res);
        });
    }
    const downloadElaQuote = () => {
        startLoading();
        service.downloadElaQuote(props.match.params.id, `${quoteHeader.ela_number}`, function () {
            stopLoading();
        });
    }
    const downloadElaPricing = () => {
        startLoading();
        service.downloadElaPricing(props.match.params.id, `${quoteHeader.ela_number}`, function () {
            stopLoading();
        });
    }

    
    const renderPricingScheduleHeaderRow = () => {
        return <tr style={{ fontSize: "0.85em", fontWeight: "600", borderBottom: "1px solid #CCCCCC" }}>
            {quoteHeader.has_sites && <td>Number of Sites to onboard per year</td>}
            { quoteHeader.has_xdome && <td>Number of Assets to onboard per year</td>}
            <td><div style={{ textAlign: "right", width: "100%" }}>{paymentYears.year1_value}</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>{paymentYears.year2_value}</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>{paymentYears.year3_value}</div></td>
            {quoteHeader.contract_length > 3 && <td><div style={{ textAlign: "right", width: "100%" }}>{paymentYears.year4_value}</div></td>}
            {quoteHeader.contract_length > 4 && <td><div style={{ textAlign: "right", width: "100%" }}>{paymentYears.year5_value}</div></td>}
        </tr>
    }
    const renderPricingScheduleRows = (item) => {
        return <tr style={{ fontSize: item.record_type === 'T' ? "0.95em" : "0.85em", fontWeight: item.record_type === 'T' ? "600" : "300" }}>
            {quoteHeader.has_sites && <td>{item.record_type === 'T' ? 'TOTAL' : item.num_sites}</td>}
            {quoteHeader.has_xdome && <td>{item.record_type === 'T' ? (quoteHeader.has_sites?'':'TOTAL') : item.num_assets}</td>}
            <td><div style={{ textAlign: "right", width: "100%" }}>{item.value_year1 ? <><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.value_year1, 2)}</> : "-"}</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>{item.value_year2 ? <><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.value_year2, 2)}</> : "-"}</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>{item.value_year3 ? <><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.value_year3, 2)}</> : "-"}</div></td>
            {quoteHeader.contract_length > 3 && <td><div style={{ textAlign: "right", width: "100%" }}>{item.value_year4 ? <><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.value_year4, 2)}</> : "-"}</div></td>}
            {quoteHeader.contract_length > 4 && <td><div style={{ textAlign: "right", width: "100%" }}>{item.value_year5 ? <><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.value_year5, 2)}</> : "-"}</div></td>}
        </tr>
    }
    const renderPaymentScheduleRows = () => {
        return <>
            <tr style={{ fontSize: "0.95em", fontWeight: "400", borderBottom: "1px solid #CCCCCC" }}>
                <td colSpan={quoteHeader.contract_length + (quoteHeader.has_sites && quoteHeader.has_xdome?2:1)}>
                    <span style={{ fontSize: "1.2em", fontWeight: "300" }}>Payment Schedule</span>
                    {!locked && <button type="button" className="btn btn-sm btn-outline-danger float-end" onClick={() => setShowPaymentSchedule(true)} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-calendar-edit " aria-hidden="true" ></i> Update Payment Schedule</button>}
                </td>
            </tr>
            <tr style={{ fontSize: "0.95em", fontWeight: "400" }}>
                <td colSpan={(quoteHeader.has_sites && quoteHeader.has_xdome?2:1)}>Invoice Date</td>
                <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year1_invoice_date}</div></td>
                <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year2_invoice_date}</div></td>
                <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year3_invoice_date}</div></td>
                {quoteHeader.contract_length > 3 && <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year4_invoice_date}</div></td>}
                {quoteHeader.contract_length > 4 && <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year5_invoice_date}</div></td>}
            </tr>
            <tr style={{ fontSize: "0.95em", fontWeight: "400" }}>
                <td colSpan={(quoteHeader.has_sites && quoteHeader.has_xdome?2:1)}>Payment Date</td>
                <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year1_payment_date}</div></td>
                <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year2_payment_date}</div></td>
                <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year3_payment_date}</div></td>
                {quoteHeader.contract_length > 3 && <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year4_payment_date}</div></td>}
                {quoteHeader.contract_length > 4 && <td><div style={{ textAlign: "right", width: "100%" }}>{paymentSchedule.year5_payment_date}</div></td>}
            </tr>
        </>
    }
    const renderPricingHeaderRow = () => {
        return <tr style={{ fontSize: "0.85em", fontWeight: "600", borderBottom: "1px solid #CCCCCC" }}>
            <td>Product</td>
            <td>SKU</td>
            <td>Quantity</td>
            <td>Duration</td>
            <td>Comments</td>
            <td><div style={{ textAlign: "right", width: "100%" }}>Total Value(Year {quoteHeader.contract_length})</div></td>
        </tr>
    }
    const renderPricingRows = (item) => {
        return <tr style={{ fontSize: "0.85em", fontWeight: "300" }}>
            <td>{item.part_name}</td>
            <td>{item.sku}</td>
            <td>{item.quantity}</td>
            <td>{item.show_term?item.contract_length+ ' years':'-'} </td>
            <td>{item.comments}</td>
            <td><div style={{ textAlign: "right", width: "100%" }}><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.tcv, 2)}</div></td>
        </tr>
    }
    const renderPricingTotalRow = () => {
        return <tr style={{ fontSize: "0.95em", fontWeight: "600" }}>
            <td colSpan="5" >TOTAL</td>
            <td><div style={{ textAlign: "right", width: "100%" }}><span className="qs-currency">{quoteHeader.currency_code}</span> {formatCurrency(pricingTotal, 2)}</div></td>
        </tr>
    }
    const renderSummaryHeaderRow = (term) => {
        return <tr style={{ fontSize: "0.85em", fontWeight: "400" }}>
            <td>Part Name</td>
            <td>Part Code</td>
            {/* <td>Start Date</td>
            <td>End Date</td> */}
            <td>Term</td>
            <td><div style={{ textAlign: "right", width: "100%" }}>Quantity</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>List Price</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>SLA Adjustment</div></td>
            <td><div style={{ textAlign: "right", width: "100%" }}>Partner Discount</div></td>
            <td colSpan="3"><div style={{ textAlign: "center", width: "100%" }}>Adjustment</div></td>

            <td><div style={{ textAlign: "right", width: "100%" }}>Total Value</div></td>
        </tr>
    }
    const renderSummaryLocationName = (loc) => {
        return <span style={{ fontWeight: "400", fontSize: "1.2em", color: "#000000" }}>
            <div style={{ margin: 0 }}>
                {loc.site_description} {loc.site_size !== 'Q' ?
                    <span style={{ fontWeight: "300", fontSize: "0.65em", display: "inline-block", border: "1px solid #333333", padding: "2px 10px" }}>{loc.site_size === 'O' ? 'One Size' : loc.site_size === 'XS' ? 'Extra Small' : loc.site_size === 'S' ? 'Small' : loc.site_size === 'M' ? 'Medium' : loc.site_size === 'L' ? 'Large' : loc.site_size === 'XL' ? 'Extra Large' : ''}</span> : null}
            </div>

        </span>
    }



    const renderSummaryRows = (site_id, quote_section) => {

        return parts.filter(item => item.site_id === site_id && item.quote_section === quote_section).map((item) => {
            let adj_str = '';
            let adj_value = item.adjustment_value;
            let adj_unit_l = '';
            let adj_unit_r = '';

            if (quoteHeader.adjustment_type === 'DISCOUNT' || quoteHeader.adjustment_type === 'UPLIFT') {
                adj_value = quoteHeader.adjustment_value;
            }

            if (item.adjustment_type === 'DISCOUNT' || quoteHeader.adjustment_type === 'DISCOUNT') {
                adj_str = 'Discount';
                adj_unit_r = '%';


            }
            if (item.adjustment_type === 'UPLIFT' || quoteHeader.adjustment_type === 'UPLIFT') {
                adj_str = 'Uplift';
                adj_unit_r = '%';
            }
            if (item.adjustment_type === 'LIST_OVERRIDE') {
                adj_str = 'List Price Override';
                adj_unit_l = 'USD';
            }
            if (item.adjustment_type === 'NET_OVERRIDE') {
                adj_str = 'Net Price Override';
                adj_unit_l = 'USD';
            }

            return <React.Fragment key={item.id}>
                <tr style={{ fontSize: "0.75em", fontWeight: "300" }}>
                    <td>{item.part_name}</td>
                    <td>{item.sku}</td>
                    {/* <td>{item.contract_start_date}</td>
                    <td>{item.contract_end_date}</td> */}
                    <td>{item.show_term?item.contract_length+ ' years':'-'} </td>
                    <td ><div style={{ textAlign: "right", width: "100%" }}>{item.quantity}</div></td>
                    <td ><div style={{ textAlign: "right", width: "100%" }}>USD {formatCurrency(item.list_price, 2)}</div></td>
                    <td ><div style={{ textAlign: "right", width: "100%" }}>{item.support_type_multiplier}%</div></td>
                    <td ><div style={{ textAlign: "right", width: "100%" }}>{item.partner_discount?formatCurrency(item.partner_discount, 2)+'%':'-'}</div></td>
                    <td ><div style={{ textAlign: "right", width: "100%" }}>
                        <div style={{ width: "100%" }}>
                            {adj_str && <><span >{adj_str}</span></>}

                        </div>
                    </div>
                    </td>
                    <td ><div style={{ textAlign: "right", width: "100%" }}>
                        <div style={{ width: "90%" }}>
                            {adj_str && <><span>{adj_unit_l} {formatCurrency(adj_value, 2)} {adj_unit_r}</span></>}

                        </div>
                    </div>
                    </td>
                    <td style={{ padding: "0.15rem 0" }}><div style={{ textAlign: "center", width: "100%" }}>
                        {!locked && <span>
                            <i className="mdi mdi-tune-vertical" aria-hidden="true" onClick={() => openAdjustment(item.id)} style={{ cursor: "pointer", color: "#dc3545" }}></i>
                        </span>}
                    </div>
                    </td>

                    <td><div style={{ textAlign: "right", width: "100%" }}><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.tcv, 2)}</div></td>
                </tr>
            </React.Fragment>
        });
    }

    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-sm-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>{title}</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.partner_type + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal ? 'Yes' : 'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email ? ' [EMAIL: ' + quoteHeader.sales_director_email + ']' : '') + (quoteHeader.sales_director_phone ? ' [CONTACT #: ' + quoteHeader.sales_director_phone + ']' : '')}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                {/* <h4 className="card-header">Quote Header</h4> */}
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-6">
                                                            <h4 style={{ display: "inline-block" }}>ELA Quote Summary</h4>
                                                            <div style={{ display: "inline-block", color: "#ffffff", padding: "2px 10px", marginLeft: "10px", background: getStatusColor(quoteHeader.ela_status) }}>{quoteHeader.ela_status}</div>
                                                        </div>
                                                        <div className="col-6">

                                                            <div className="dropdown float-end" >
                                                                <button type="button" className="btn  btn-outline-primary" onClick={() => { downloadElaQuote() }} style={{ padding: "4px 15px", marginLeft: "5px", height: "45px" }}><i className="mdi mdi-download " aria-hidden="true" ></i> Download </button>
                                                                <button type="button" className="btn  btn-outline-primary" onClick={() => { downloadElaPricing() }} style={{ padding: "4px 15px", marginLeft: "5px", height: "45px" }}><i className="mdi mdi-download " aria-hidden="true" ></i> Download Pricing </button>
                                                                
                                                                {/* <button type="button" className="btn  btn-outline-primary"  onClick={() => { syncOpportunity() }} style={{ padding: "4px 15px", marginLeft: "5px", height: "45px" }}><i className="mdi mdi-download " aria-hidden="true" ></i> Sync </button> */}


                                                            </div>
                                                            {!locked && <button type="button" className="btn btn-g btn-outline-danger float-end" onClick={() => setShowQuoteAdjustment(true)} style={{ padding: "4px 15px", marginLeft: "5px", height: "45px" }}><i className="mdi mdi-tune-vertical " aria-hidden="true" ></i> Quote Discount/Uplift </button>}
                                                            {quoteHeader.adjustment_type && quoteHeader.adjustment_type !== 'NONE' && <button type="button" className="btn btn-g btn-dark float-end" style={{ padding: "4px 15px", marginLeft: "5px", height: "45px" }} disabled="true">
                                                                <div style={{ fontSize: "0.55em" }}>QUOTE {quoteHeader.adjustment_type}</div>
                                                                <div style={{ fontSize: "0.85em" }}>{formatCurrency(quoteHeader.adjustment_value, 2)} %</div>
                                                            </button>}
                                                        </div>
                                                    </div>
                                                    <Tabs style={{ width: "100%" }} >
                                                        <TabList>
                                                            <Tab><div className="tab-title" style={{ fontSize: '1.3em' }}>Pricing</div></Tab>
                                                            <Tab><div className="tab-title" style={{ fontSize: '1.3em' }}>Pricing Schedule</div></Tab>
                                                            <Tab><div className="tab-title" style={{ fontSize: '1.3em' }}>Summary</div></Tab>
                                                        </TabList>

                                                        <TabPanel>
                                                            <div className="col-md-12 trim" style={{ marginTop: "10px" }}>
                                                                <div className="table-responsive" style={{ overflow: "visible" }}>
                                                                    <table className="mb-0 table table-borderless">
                                                                        <tbody>
                                                                            {renderPricingHeaderRow()}
                                                                            {pricing && pricing.length > 0 ?
                                                                                pricing.map((item, key) => {
                                                                                    return <React.Fragment key={'P_' + key}>
                                                                                        {renderPricingRows(item)}

                                                                                    </React.Fragment>
                                                                                }) : null
                                                                            }
                                                                            {renderPricingTotalRow()}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <div className="col-md-12 trim" style={{ marginTop: "10px" }}>
                                                                <div className="table-responsive" style={{ overflow: "visible" }}>
                                                                    <table className="mb-0 table table-borderless">
                                                                        <tbody>
                                                                            {renderPricingScheduleHeaderRow()}
                                                                            {pricingSchedule && pricingSchedule.length > 0 ?
                                                                                pricingSchedule.map((item, key) => {
                                                                                    return <React.Fragment key={'PS_' + key}>
                                                                                        {renderPricingScheduleRows(item)}

                                                                                    </React.Fragment>
                                                                                }) : null
                                                                            }
                                                                            {renderPaymentScheduleRows()}


                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <div className="col-md-12 trim" style={{ marginTop: "10px" }}>
                                                                <div className="table-responsive" style={{ overflow: "visible" }}>
                                                                    <table className="mb-0 table table-borderless">
                                                                        <tbody>
                                                                            {renderSummaryHeaderRow(quoteHeader.contract_length)}
                                                                            {sites && sites.length > 0 ?
                                                                                sites.map((item, key) => {
                                                                                    return <React.Fragment key={key + '_' + item.id}>
                                                                                        <tr style={{ background: "#ffffff", marginTop: "10px", borderBottom: "1px solid #e5e5e5" }}>
                                                                                            <td colSpan="9">{renderSummaryLocationName(item)}</td>
                                                                                            <td><div style={{ fontSize: ".85em", fontWeight: "300", color: "white", width: "100%", textAlign: "right" }}><span className="qs-currency">{item.currency_code}</span> {formatCurrency(item.tcv, 2)}</div></td>
                                                                                        </tr>
                                                                                        {renderSummaryRows(item.id, item.quote_section)}
                                                                                    </React.Fragment>
                                                                                }) : null
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                    </Tabs>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <div className="row g-12">
                                        <div className="col-sm-12  mt-3">
                                            {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-primary float-end" onClick={() => setShowAddApprover(true)} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-account-plus" aria-hidden="true" ></i>Add Approver</button>}

                                        </div>
                                        <div className="col-sm-12 mt-3">
                                            <ul className="list-group">
                                            {approvalComments && approvalComments.length > 0 && approvalComments.filter(item => item.approval_seq < 1000).map(item => {
                                                return  <li key={"approval_step_" +item.id} className="list-group-item p-3">
                                                    <div className="row g-3">
                                                        <div className="col-sm-2 ">
                                                            <h6 style={{ display: "inline-block"}}>{item.approver_name}</h6>
                                                        </div>
                                                        <div className="col-sm-2 ">
                                                            {<div style={{ display: "inline-block", color: "#ffffff", padding: "2px 10px", marginLeft: "10px", background: getStatusColor(item.status) }}>{item.status}</div>}
                                                        </div>
                                                        <div className="col-sm-4 ">
                                                            {item.comments && <>
                                                            <div className="form-label" style={{ fontWeight: 300, fontSize:"0.75em" }}>COMMENTS </div>
                                                            <div style={{ fontWeight: 600 }}>{item.comments || '-'} </div>
                                                            </>}
                                                            
                                                            <Attachments {...{ guid:null, allowUpload: false, context: 'APPROVAL_STEP', contextId: item.id, defaultLoad: true, label: "" }} />
                                                        </div>
                                                        <div className="col-sm-4 ">
                                                        {item.status === 'N/A' && <>
                                                            <button type="button" className="btn btn-g btn-outline-danger float-end" onClick={() => deleteApproverStep(item.id)} style={{ padding: "4px 15px", marginLeft: "10px" }}><i className="mdi mdi-delete " aria-hidden="true" ></i> </button>
                                                            <button type="button" className="btn btn-g btn-outline-info float-end" onClick={() => openApprovalComments(item.id, 'SUBMITTED')} style={{ padding: "4px 15px" }}><i className="mdi mdi-send " aria-hidden="true" ></i> Approval Email Sent</button>
                                                        </>}
                                                        {item.status === 'SUBMITTED' && <>
                                                            {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => openApprovalComments(item.id, 'APPROVED')} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-check " aria-hidden="true" ></i> Approve</button>}
                                                            {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-outline-danger float-end" onClick={() => openApprovalComments(item.id, 'DECLINED')} style={{ padding: "4px 15px" }}><i className="mdi mdi-close " aria-hidden="true" ></i> Decline</button>}
                                                        </>}
                                                        </div>
                                                    </div>
                                                </li>})}
                                               
                                            </ul>
                                        </div>
                                        </div>}
                                    {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <div className="row g-12">
                                    
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                <h6 className="card-header">Deal Desk Approval</h6>
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-md-12">
                                                            <div className="form-label">Comments </div>
                                                            <div >
                                                                <textarea rows="2" cols="50" maxLength="500" style={{ width: "100%", fontSize: "12px" }}
                                                                    value={approval.comments || ''} onChange={(e) => handleApprovalChange('comments', e.target.value)} /></div>
                                                            {errors.comments && <div className="validation-error">{errors.comments}</div>}
                                                        </div>
                                                        <div className="col-md-12">
                                                            <Attachments {...fileProps} />
                                                        </div>
                                                    </div>
                                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                                        {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-primary float-end" onClick={() => approve()} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-check " aria-hidden="true" ></i> Approve</button>}
                                                        {quoteHeader.ela_status === 'SUBMITTED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-danger float-end" onClick={() => decline()} style={{ padding: "4px 15px" }}><i className="mdi mdi-close " aria-hidden="true" ></i> Decline</button>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>}
                                    {(quoteHeader.ela_status === 'APPROVED' || quoteHeader.ela_status === 'DECLINED') && approvalCommentsDD && <div className="row g-12">
                                    {userType.isElaDDUser && <div className="col-sm-12 mt-3">
                                            <ul className="list-group">
                                            {approvalComments && approvalComments.length > 0 && approvalComments.filter(item => item.approval_seq < 1000).map(item => {
                                                return  <li key={"approval_step_" +item.id} className="list-group-item p-3">
                                                    <div className="row g-3">
                                                        <div className="col-sm-3 ">
                                                            <h6 style={{ display: "inline-block"}}>{item.approver_name}</h6>
                                                        </div>
                                                        <div className="col-sm-3 ">
                                                            {<div style={{ display: "inline-block", color: "#ffffff", padding: "2px 10px", marginLeft: "10px", background: getStatusColor(item.status) }}>{item.status}</div>}
                                                        </div>
                                                        <div className="col-sm-6 ">
                                                            {item.comments && <>
                                                            <div className="form-label" style={{ fontWeight: 300, fontSize:"0.75em" }}>COMMENTS </div>
                                                            <div style={{ fontWeight: 600 }}>{item.comments || '-'} </div>
                                                            </>}
                                                            
                                                            <Attachments {...{ guid:null, allowUpload: false, context: 'APPROVAL_STEP', contextId: item.id, defaultLoad: true, label: "" }} />
                                                        </div>
                                                        
                                                    </div>
                                                </li>})}
                                               
                                            </ul>
                                        </div>}
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                <h6 className="card-header">Deal Desk Approval</h6>
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-md-12">
                                                            <div className="form-label">Status </div>
                                                            <div style={{ fontWeight: 600 }}>{approvalCommentsDD.status} </div>
                                                            <div className="form-label mt-3">Comments </div>
                                                            <div style={{ fontWeight: 600 }}>{approvalCommentsDD.comments} </div>
                                                            <div className="form-label mt-3">Actioned By </div>
                                                            <div style={{ fontWeight: 600 }}>{approvalCommentsDD.action_by} </div>
                                                            <div className="form-label mt-3">Actioned at </div>
                                                            <div style={{ fontWeight: 600 }}>{approvalCommentsDD.action_at} </div>

                                                        </div>
                                                        <div className="col-md-12">
                                                            <Attachments {...{ ...fileProps, allowUpload: false, guid: null }} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>}
                                    <div className="clearfix" style={{ padding: '10px 0' }}>

                                        {quoteHeader.ela_status === 'BUDGETARY' && <button type="button" className="btn btn-g btn-primary float-end" onClick={() => submitForApproval()} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-check " aria-hidden="true" ></i> Submit for Approval</button>}
                                        <button type="button" className="btn btn-g btn-secondary float-end" onClick={() => copyQuote()} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-content-copy " aria-hidden="true" ></i> Copy Quote</button>
                                        {!locked && <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/configure-ela/' + props.match.params.id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
            </main>
            {showApprovalComment && <div className='popup'>
                <div className='popup_content' style={{ width: "600px", left: "calc( 50% - 300px)", background: "#fafafa" }}>
                    <div style={{ fontSize: "1em" }}>
                        <div className='container-fluid' >
                            <i style={{ color: "#0d6efd" }} className='mdi mdi-48px mdi-account-cog'></i>
                            <span style={{ fontSize: "1.9em", paddingLeft: "10px" }}>Approval - {approvalComment.approver_name}</span>
                        </div>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <div className="form-label">Comments </div>
                                <div >
                                    <textarea rows="2" cols="50" maxLength="500" style={{ width: "100%", fontSize: "12px" }}
                                        value={approvalComment.comments || ''} onChange={(e) => handleApprovalCommentChange('comments', e.target.value)} /></div>
                                {errors.approval_comments && <div className="validation-error">{errors.approval_comments}</div>}
                            </div>
                            <div className="col-md-12">
                                <Attachments {...{ guid:token, allowUpload: true, context: 'APPROVAL_STEP', contextId: approvalComment.id, defaultLoad: true, label: "Evidence" }} />
                            </div>
                        </div>
                    </div>

                    <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                    {approvalComment.status === 'APPROVED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-primary float-end" onClick={() => saveApprovalComments()} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-check " aria-hidden="true" ></i> Approve</button>}
                    {approvalComment.status === 'DECLINED' && userType.isElaDDUser && <button type="button" className="btn btn-g btn-danger float-end" onClick={() => saveApprovalComments()} style={{ padding: "4px 15px", marginLeft: "5px" }}><i className="mdi mdi-close " aria-hidden="true" ></i> Decline</button>}
                    <button type="button" className="btn btn-g btn-secondary float-end"  style={{ padding: "4px 15px", marginLeft: "5px" }} onClick={() => setShowApprovalComment(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>                        
                        
                    </div>
                </div>
            </div>}
            {showAddApprover && <div className='popup'>
                <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)", background: "#fafafa" }}>
                    <div style={{ fontSize: "1em" }}>
                        <div className='container-fluid' >
                            <i style={{ color: "#0d6efd" }} className='mdi mdi-48px mdi-account-plus'></i>
                            <span style={{ fontSize: "1.9em", paddingLeft: "10px" }}>Add Approver</span>
                        </div>
                        <label htmlFor="approver_name" className="form-label">Approver Name <span className="required">*</span></label>
                        <div className="input-group">
                            <input type="text" className="form-control" name="approver_name" placeholder=""
                                maxLength="100"
                                value={approvalStep.approver_name || ''}
                                onChange={(e) => { handleApprovalStepChange('approver_name', (e.target.validity.valid) ? e.target.value : '') }} />
                        </div>
                        {errors.approver_name && <div className="validation-error">{errors.approver_name}</div>}
                    </div>

                    <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                        <button type="button" className="btn btn-g btn-primary " style={{ marginLeft: "5px" }} onClick={() => addApprovalStep()} ><i className='mdi mdi-plus'></i>Add </button>
                        <button type="button" className="btn btn-g btn-secondary " style={{ marginLeft: "5px" }} onClick={() => setShowAddApprover(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                        {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                    </div>
                </div>
            </div>}

            {showCopyConfirm && <div className='popup'>
                <div className='popup_content' style={{ width: "400px", left: "calc( 50% - 200px)", background: "#fafafa" }}>
                    <div style={{ fontSize: "1em" }}>
                        <div className='container-fluid' style={{ textAlign: "center" }}>
                            <i style={{ color: "#0d6efd" }} className='mdi mdi-48px mdi-content-copy'></i>
                            <div style={{ fontSize: "1.5em" }}>Quote Copied</div>
                            <div style={{ fontSize: "0.95em" }}>The new quote number is {copiedQuoteId}. Do you  want to open the copied quote?</div>
                        </div>
                    </div>

                    <div className="text-center" style={{ alignContent: "center", padding: "15px" }}>
                        <button type="button" className="btn btn-g btn-primary " style={{ marginLeft: "5px" }} onClick={() => props.history.push(`/create-ela/${copiedQuoteId}/ref`)} ><i className='mdi mdi-open-in-app'></i>Open</button>
                        <button type="button" className="btn btn-g btn-secondary " style={{ marginLeft: "5px" }} onClick={() => setShowCopyConfirm(false)} ><i className='mdi mdi-cancel'></i>Cancel</button>
                        {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                    </div>
                </div>
            </div>}
            {showAdjustment && <div className='popup'>
                <div className='popup_content' style={{ width: "900px", left: "calc( 50% - 450px)", background: "#fafafa" }}>
                    <div className="popup-title" style={{ paddingBottom: quoteHeader.adjustment_type !== 'NONE' ? "0px" : "40px" }}>
                        Adjust Price
                        <div style={{ fontSize: "0.65em", fontWeight: "300" }}>{priceDetail.sku} - {priceDetail.part_name}</div>
                        {quoteHeader.adjustment_type !== 'NONE' && <>
                            <div className="alert alert-danger d-flex align-items-center" role="alert">
                                <i className='mdi mdi-alert mdi-48px'></i>
                                <div style={{ fontSize: "0.75em", padding: "0 50px" }}>
                                    Quote level {quoteHeader.adjustment_type === 'DISCOUNT' ? "Discount" : "Uplift"} of {formatCurrency(quoteHeader.adjustment_value, 2)}% is already applied on this quote.
                                    Applying this line level price adjustment will discard the quote level {quoteHeader.adjustment_type === 'DISCOUNT' ? "Discount" : "Uplift"} from this quote.
                                </div>
                            </div></>}
                    </div>
                    <div style={{ fontSize: "13px" }}>
                        <div className='container-fluid'>

                            <div className='row mt-2'>
                                <div className='col-3 form-label'>List Price</div>
                                <div className='col-3' style={{ fontWeight: "600" }}>USD {priceDetail.list_price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                <div className='col-6'>
                                    {prevPriceDetail.adjustment_type === 'LIST_OVERRIDE' && <div style={{ fontWeight: "300", fontSize: "0.85em", display: "inline-block", border: "1px solid #333333", padding: "2px 10px" }}>Adjusted</div>}
                                </div>

                                {prevPriceDetail.channel !== 'DIRECT' && <>
                                    <div className='col-3  form-label'>Partner Discount</div>
                                    <div className='col-3' style={{ fontWeight: "600" }}>{prevPriceDetail.partner_discount * 100}%</div>
                                    <div className='col-6'></div>
                                </>
                                }
                                {prevPriceDetail.support_type_multiplier !== 1 && <>
                                    <div className='col-3 form-label'>Support {prevPriceDetail.support_type_multiplier > 1 ? 'Uplift' : 'Downlift'}</div>
                                    <div className='col-3' style={{ fontWeight: "600" }}>{(prevPriceDetail.support_type_multiplier * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}%</div>
                                    <div className='col-6'></div>
                                </>
                                }
                                {(prevPriceDetail.adjustment_type === 'DISCOUNT' || prevPriceDetail.adjustment_type === 'UPLIFT') && <>
                                    <div className='col-3 form-label'>{prevPriceDetail.adjustment_type === 'DISCOUNT' ? 'Discount' : 'Uplift'}</div>
                                    <div className='col-3' style={{ fontWeight: "600", color: prevPriceDetail.adjustment_type === 'DISCOUNT' ? '#CC0000' : '#00CC00' }}>{

                                        (prevPriceDetail.adjustment_value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} %
                                    </div>
                                    <div className='col-6'>{(prevPriceDetail.adjustment_type === 'DISCOUNT' || prevPriceDetail.adjustment_type === 'UPLIFT') && <div style={{ fontWeight: "300", fontSize: "0.85em", display: "inline-block", border: "1px solid #333333", padding: "2px 10px" }}> Adjusted</div>}</div>
                                </>}

                                <div className='col-3 form-label'>Net Price</div>
                                <div className='col-3' style={{ fontWeight: "600" }}>USD {prevPriceDetail.usd_discounted_price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                <div className='col-6'>
                                    {prevPriceDetail.adjustment_type === 'NET_OVERRIDE' && <div style={{ fontWeight: "300", fontSize: "0.85em", display: "inline-block", border: "1px solid #333333", padding: "2px 10px" }}>Adjusted</div>}
                                </div>
                                <div className="col-sm-12">
                                    <label htmlFor="adjustment_value" className="form-label">Cumulative Prices</label>

                                    <ul className="list-group list-group-horizontal">
                                        <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 1</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {prevPriceDetail.currency_code} {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.year1_onetime_net_price : prevPriceDetail.year1_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.usd_year1_onetime_net_price : prevPriceDetail.usd_year1_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                        <li className="list-group-item flex-fill" >
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 2</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {prevPriceDetail.currency_code} {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.year2_onetime_net_price : prevPriceDetail.year2_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.usd_year2_onetime_net_price : prevPriceDetail.usd_year2_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                        <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 3</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {prevPriceDetail.currency_code} {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.year3_onetime_net_price : prevPriceDetail.year3_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.usd_year3_onetime_net_price : prevPriceDetail.usd_year3_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                        {prevPriceDetail.contract_length > 3 && <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 4</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {prevPriceDetail.currency_code} {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.year4_onetime_net_price : prevPriceDetail.year4_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.usd_year4_onetime_net_price : prevPriceDetail.usd_year4_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>}
                                        {prevPriceDetail.contract_length > 3 && <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 5</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {prevPriceDetail.currency_code} {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.year5_onetime_net_price : prevPriceDetail.year5_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {(prevPriceDetail.charge_type === 'ONETIME' ? prevPriceDetail.usd_year5_onetime_net_price : prevPriceDetail.usd_year5_cum_net_price).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>}
                                        <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Total</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {prevPriceDetail.currency_code} {prevPriceDetail.tcv.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {prevPriceDetail.usd_tcv.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-9 mt-2">
                                    <div className="col-sm-12">
                                        <label htmlFor="adjustment_value" className="form-label">Adjustment Type <span className="required">*</span></label>
                                    </div>

                                    <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                        <label className={priceDetail.adjustment_type === 'NONE' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="NONE">
                                            <input type="radio" className="btn-check" name="btnradio" id="NONE" autoComplete="off" checked={priceDetail.adjustment_type === 'NONE'}
                                                onChange={(e) => { handleAdjustmentChange('adjustment_type', e.target.checked ? 'NONE' : undefined) }}
                                            />
                                            None
                                        </label>
                                        <label className={priceDetail.adjustment_type === 'LIST_OVERRIDE' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="LIST_OVERRIDE">
                                            <input type="radio" className="btn-check" name="btnradio" id="LIST_OVERRIDE" autoComplete="off" checked={priceDetail.adjustment_type === 'LIST_OVERRIDE'}
                                                onChange={(e) => { handleAdjustmentChange('adjustment_type', e.target.checked ? 'LIST_OVERRIDE' : undefined) }}
                                            />
                                            List Price Override
                                        </label>
                                        <label className={priceDetail.adjustment_type === 'DISCOUNT' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="DISCOUNT">
                                            <input type="radio" className="btn-check" name="btnradio" id="DISCOUNT" autoComplete="off" checked={priceDetail.adjustment_type === 'DISCOUNT'}
                                                onChange={(e) => { handleAdjustmentChange('adjustment_type', e.target.checked ? 'DISCOUNT' : undefined) }}
                                            />
                                            Discount
                                        </label>
                                        <label className={priceDetail.adjustment_type === 'UPLIFT' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="UPLIFT">
                                            <input type="radio" className="btn-check" name="btnradio" id="UPLIFT" autoComplete="off" checked={priceDetail.adjustment_type === 'UPLIFT'}
                                                onChange={(e) => { handleAdjustmentChange('adjustment_type', e.target.checked ? 'UPLIFT' : undefined) }}
                                            />
                                            Uplift
                                        </label>
                                        <label className={priceDetail.adjustment_type === 'NET_OVERRIDE' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="NET_OVERRIDE">
                                            <input type="radio" className="btn-check" name="btnradio" id="NET_OVERRIDE" autoComplete="off" checked={priceDetail.adjustment_type === 'NET_OVERRIDE'}
                                                onChange={(e) => { handleAdjustmentChange('adjustment_type', e.target.checked ? 'NET_OVERRIDE' : undefined) }}
                                            />
                                            Net Price Override
                                        </label>
                                    </div>
                                </div>


                                <div className="col-sm-3 mt-2">
                                    <label htmlFor="adjustment_value" className="form-label">Adjustment Value <span className="required">*</span></label>
                                    <div className="input-group">
                                        {(priceDetail.adjustment_type === 'NET_OVERRIDE' || priceDetail.adjustment_type === 'LIST_OVERRIDE') && <div className="input-group-text">USD</div>}
                                        <input type="text" className="form-control" name="adjustment_value" placeholder=""
                                            value={priceDetail.adjustment_value || ''}
                                            disabled={priceDetail.adjustment_type === 'NONE'}
                                            maxLength="100"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                            onChange={(e) => { handleAdjustmentChange('adjustment_value', (e.target.validity.valid) ? e.target.value : '') }} />
                                        {(priceDetail.adjustment_type === 'DISCOUNT' || priceDetail.adjustment_type === 'UPLIFT') && <div className="input-group-text">%</div>}
                                    </div>
                                    {errors.adjustment_value && <div className="validation-error">{errors.adjustment_value}</div>}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="popup-btn-panel">
                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveAdjustment(false)} >Apply</button>
                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveAdjustment(true)} >Apply & Close</button>
                        <button type="button" className="btn btn-g btn-secondary float-end" style={{ marginLeft: "5px" }} onClick={() => setShowAdjustment(false)} >Close</button>
                        {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                    </div>
                </div>
            </div>
            }
            {showQuoteAdjustment && <div className='popup'>
                <div className='popup_content' style={{ width: "900px", left: "calc( 50% - 450px)", background: "#fafafa" }}>
                    <div className="popup-title" style={{ paddingBottom: "40px" }}>
                        Adjust Quote Price
                    </div>
                    <div style={{ fontSize: "13px" }}>
                        <div className='container-fluid'>

                            <div className='row mt-2'>

                                <div className="col-sm-12">
                                    <label htmlFor="adjustment_value" className="form-label">Cumulative Prices</label>

                                    <ul className="list-group list-group-horizontal">
                                        <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 1</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {quoteHeader.currency_code} {quoteHeader.value_year1.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {quoteHeader.usd_value_year1.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                        <li className="list-group-item flex-fill" >
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 2</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {quoteHeader.currency_code} {quoteHeader.value_year2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {quoteHeader.usd_value_year2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                        <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 3</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {quoteHeader.currency_code} {quoteHeader.value_year3.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {quoteHeader.usd_value_year3.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                        {prevPriceDetail.contract_length > 3 && <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 4</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {quoteHeader.currency_code} {quoteHeader.value_year4.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {quoteHeader.usd_value_year4.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>}
                                        {prevPriceDetail.contract_length > 3 && <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Year 5</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {quoteHeader.currency_code} {quoteHeader.value_year5.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {quoteHeader.usd_value_year5.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>}
                                        <li className="list-group-item flex-fill">
                                            <div style={{ fontWeight: "100", fontSize: "0.65em", textTransform: "uppercase" }}>Total</div>
                                            <div style={{ fontWeight: "600", fontSize: "0.95em" }}>
                                                {quoteHeader.currency_code} {quoteHeader.tcv.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                            <div style={{ fontWeight: "300", fontSize: "0.65em", fontColor: "#808080" }}>
                                                USD {quoteHeader.usd_tcv.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-9 mt-2">
                                    <div className="col-sm-12">
                                        <label htmlFor="adjustment_value" className="form-label">Adjustment Type <span className="required">*</span></label>
                                    </div>

                                    <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                        <label className={quoteHeader.adjustment_type === 'NONE' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="NONE">
                                            <input type="radio" className="btn-check" name="btnradio" id="NONE" autoComplete="off" checked={quoteHeader.adjustment_type === 'NONE'}
                                                onChange={(e) => { handleQuoteAdjustmentChange('adjustment_type', e.target.checked ? 'NONE' : undefined) }}
                                            />
                                            None
                                        </label>
                                        <label className={quoteHeader.adjustment_type === 'DISCOUNT' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="DISCOUNT">
                                            <input type="radio" className="btn-check" name="btnradio" id="DISCOUNT" autoComplete="off" checked={quoteHeader.adjustment_type === 'DISCOUNT'}
                                                onChange={(e) => { handleQuoteAdjustmentChange('adjustment_type', e.target.checked ? 'DISCOUNT' : undefined) }}
                                            />
                                            Discount
                                        </label>
                                        <label className={quoteHeader.adjustment_type === 'UPLIFT' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="UPLIFT">
                                            <input type="radio" className="btn-check" name="btnradio" id="UPLIFT" autoComplete="off" checked={quoteHeader.adjustment_type === 'UPLIFT'}
                                                onChange={(e) => { handleQuoteAdjustmentChange('adjustment_type', e.target.checked ? 'UPLIFT' : undefined) }}
                                            />
                                            Uplift
                                        </label>

                                    </div>
                                </div>


                                <div className="col-sm-3 mt-2">
                                    <label htmlFor="adjustment_value" className="form-label">Adjustment Value <span className="required">*</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="adjustment_value" placeholder=""
                                            value={quoteHeader.adjustment_value || ''}
                                            disabled={quoteHeader.adjustment_type === 'NONE'}
                                            maxLength="100"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                            onChange={(e) => { handleQuoteAdjustmentChange('adjustment_value', (e.target.validity.valid) ? e.target.value : '') }} />
                                        {(quoteHeader.adjustment_type === 'DISCOUNT' || quoteHeader.adjustment_type === 'UPLIFT') && <div className="input-group-text">%</div>}
                                    </div>
                                    {errors.adjustment_value && <div className="validation-error">{errors.adjustment_value}</div>}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="popup-btn-panel">
                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveQuoteAdjustment(false)} >Apply</button>
                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveQuoteAdjustment(true)} >Apply & Close</button>
                        <button type="button" className="btn btn-g btn-secondary float-end" style={{ marginLeft: "5px" }} onClick={() => setShowQuoteAdjustment(false)} >Close</button>
                        {/* <button type="button" className="btn btn-g btn-warning float-end" onClick={() => resetAdjustment()} >Reset Adjustment</button> */}
                    </div>
                </div>
            </div>
            }
            {showPaymentSchedule && <div className='popup'>
                <div className='popup_content' style={{ width: "900px", left: "calc( 50% - 450px)", background: "#fafafa" }}>
                    <div className="popup-title" style={{ paddingBottom: "40px" }}>
                        Update Payment Schedule
                    </div>
                    <div style={{ fontSize: "13px" }}>
                        <div className='container-fluid'>

                            <div className='row mt-2'>
                                <div className="col-sm-6">
                                    <label htmlFor="year1_invoice_date" className="form-label">Year 1 Invoice Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year1_invoice_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year1_invoice_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year1_invoice_date && <div className="validation-error">{errors.year1_invoice_date}</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="year1_payment_date" className="form-label">Year 1 Payment Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year1_payment_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year1_payment_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year1_payment_date && <div className="validation-error">{errors.year1_payment_date}</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="year2_invoice_date" className="form-label">Year 2 Invoice Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year2_invoice_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year2_invoice_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year2_invoice_date && <div className="validation-error">{errors.year2_invoice_date}</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="year2_payment_date" className="form-label">Year 2 Payment Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year2_payment_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year2_payment_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year2_payment_date && <div className="validation-error">{errors.year2_payment_date}</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="year3_invoice_date" className="form-label">Year 3 Invoice Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year3_invoice_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year3_invoice_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year3_invoice_date && <div className="validation-error">{errors.year3_invoice_date}</div>}
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="year3_payment_date" className="form-label">Year 3 Payment Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year3_payment_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year3_payment_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year3_payment_date && <div className="validation-error">{errors.year3_payment_date}</div>}
                                </div>
                                {quoteHeader.contract_length > 3 && <div className="col-sm-6">
                                    <label htmlFor="year4_invoice_date" className="form-label">Year 4 Invoice Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year4_invoice_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year4_invoice_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year4_invoice_date && <div className="validation-error">{errors.year4_invoice_date}</div>}
                                </div>}
                                {quoteHeader.contract_length > 3 && <div className="col-sm-6">
                                    <label htmlFor="year4_payment_date" className="form-label">Year 4 Payment Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year4_payment_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year4_payment_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year4_payment_date && <div className="validation-error">{errors.year4_payment_date}</div>}
                                </div>}
                                {quoteHeader.contract_length > 4 && <div className="col-sm-6">
                                    <label htmlFor="year5_invoice_date" className="form-label">Year 5 Invoice Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year5_invoice_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year5_invoice_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year4_invoice_date && <div className="validation-error">{errors.year4_invoice_date}</div>}
                                </div>
                                }
                                {quoteHeader.contract_length > 4 && <div className="col-sm-6">
                                    <label htmlFor="year5_payment_date" className="form-label">Year 5 Payment Date <span className="required">*</span></label>
                                    <DatePicker
                                        value={paymentSchedule.year5_payment_date || undefined}
                                        className="form-control"
                                        excludeSunday={false}
                                        excludeSaturday={false}
                                        minConstraint={new Date()}
                                        onChange={(_date) => {
                                            handlePaymentScheduleChange('year5_payment_date', _date && moment(_date).format("DD-MMM-yyyy"))
                                        }}
                                        dateFormat="dd-MMM-yyyy"

                                        isClearable={true}
                                    />
                                    {errors.year5_payment_date && <div className="validation-error">{errors.year5_payment_date}</div>}
                                </div>
                                }
                            </div>

                        </div>

                        <div className="popup-btn-panel">
                            <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => savePaymentSchedule()} >Save</button>
                            <button type="button" className="btn btn-g btn-secondary float-end" style={{ marginLeft: "5px" }} onClick={() => setShowPaymentSchedule(false)} >Cancel</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default SummaryEla;


