//https://codesandbox.io/s/kkw3l75ok3
import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export  class CumChart extends PureComponent {

  

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height={320}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          
          <Line type="monotone" dataKey="ICS Site OT Engineering" stroke="#8884d8"  activeDot={{ r: 8 }}/>
            <Line type="monotone" dataKey="ICS Site Security" stroke="#CC0000"   activeDot={{ r: 8 }}/>
            {this.props.modelType==='ADVANCED' && <Line type="monotone" dataKey="Security Operations" stroke="#82ca9d"   activeDot={{ r: 8 }}/>}
          <Legend  />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
