
let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
let token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : "";
let app = localStorage.getItem("app") ? JSON.parse(localStorage.getItem("app")) : "";

export const initialState = {
    user, token, app
};

export const AuthReducer = (initialState, action) => {
    console.log(initialState);
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload,
                loading: false
            };
        case "LOGOUT":
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            return {
                ...initialState,
                user: "",
                token: ""
            };
        case "SET_APP":
            return {
                ...initialState,
                app: action.payload
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};