import { post, get } from "./Service";
import axios from "axios";
import {saveAs} from "file-saver";
import {startLoading, stopLoading} from '../Components/Util'

function removeAccents(strAccents){
    strAccents = strAccents.split('');
    let strAccentsOut = [];
    let strAccentsLen = strAccents.length;
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = ['A','A','A','A','A','A','a','a','a','a','a','a','O','O','O','O','O','O','O','o','o','o','o','o','o','E','E','E','E','e','e','e','e','e','C','c','D','I','I','I','I','i','i','i','i','U','U','U','U','u','u','u','u','N','n','S','s','Y','y','y','Z','z'];
    for (let y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) !== -1) {
            strAccentsOut[y] = accentsOut[accents.indexOf(strAccents[y])];
        }
        else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
}

export const upsertEla = (query, payload, onDone) => {
    post("/api/upsert-ela/" + query, payload, (res) => {
        onDone(res);
    });
};
export const getElaData = (query, payload, onDone) => {
    post("/api/get-ela-data/" + query, payload, (res) => {
        onDone(res);
    });
};
export const searchOpportunities = ( payload, onDone) => {
    post("/api/search-opportunities", removeAccents(payload), (res) => {
        onDone(res);
    });
};
export const getOpportunity = ( payload, onDone) => {
    console.log("getOpportunity >>"+payload+"<<")
    get("/api/get-opportunity/"+payload, (res) => {
        onDone(res);
    });
};
export const getContact = ( payload, onDone) => {
    get("/api/get-user/"+payload, (res) => {
        onDone(res);
    });
};
export const refreshPriceBookEntries = ( onDone) => {

    get("/api/refresh-sfdc-price-entries", (res) => {
        onDone(res);
    });
};

export const syncOpportunity = ( quoteId, onDone) => {
    get("/api/sync_opportunity/"+quoteId, (res) => {
        onDone(res);
    });
};

export const sendApprovalEmail = ( quoteId, onDone) => {
    get("/api/send-approval-email/"+quoteId, (res) => {
        onDone(res);
    });
};

const getSuffix =() => {
    function pad2(n) {  // always returns a string
        return (n < 10 ? '0' : '') + n;
    }
    let dt = new Date();
    return dt.getFullYear() +
           pad2(dt.getMonth() + 1) + 
           pad2(dt.getDate()) +
           pad2(dt.getHours()) +
           pad2(dt.getMinutes()) +
           pad2(dt.getSeconds());
}

export const downloadElaQuote = (quoteId, fileName, onDone) => {
    startLoading();
    axios({
        url: encodeURI('/api/download-ela-quote/' + quoteId + '/' + fileName),
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        } // important
      }).then((response) => {
        saveAs(response.data, fileName+"_"+getSuffix()+".docx");
        stopLoading();
      });
};

export const downloadElaPricing = (quoteId, fileName, onDone) => {
    startLoading();
    axios({
        url: encodeURI('/api/download-ela-pricing/' + quoteId + '/' + fileName),
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        } // important
      }).then((response) => {
        saveAs(response.data, fileName+"_"+getSuffix()+".xlsx");
        stopLoading();
      });
};