import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading } from '../../Components/Util.js';

import * as service from "../../services";
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function ListPriceDashboard(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";
    let lpTabIdx = localStorage.getItem('lpTabIdx');

    const initialstate = { sort_by: { value: 'part_name', label: "Part Name" }, record_count: 30, sort_direction: 'asc', part_type: lpTabIdx === 0 ? 'CORE' : lpTabIdx === 1 ? 'PS' : 'SHIP' }
    const [loading, setLoading] = useState(true)
    const [store, setStore] = useState([]);
    const [selectedSku, setSelectedSku] = useState(undefined);
   
    const [showMore, setShowMore] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [payload, setPayload] = useState(initialstate);
    const [tabIndex, setTabIndex] = useState(lpTabIdx ? parseInt(lpTabIdx) : 0);
    const [errors, setErrors] = useState({});



    const sort_direction_options = [
        { value: 'part_name', label: "Part Name" },
        { value: 'sku', label: "SKU" }

    ];
    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const handleChange = (prop, value) => {
        const _payload = JSON.parse(JSON.stringify(payload));
        _payload[prop] = value;
        console.log(_payload);
        setPayload(_payload);
    };
    const validatePrice = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "price1", msg: "Please enter "+ (tabIndex === 2 ? "DAP Price" : "List Price") }
        ];
        if(tabIndex === 2){
            validation.push({ field: "price2", msg: "Please enter DDP Price" });
            validation.push({ field: "price3", msg: "Please enter DDP + IOR Price" });
        }
        validation.map(item => {
            if (!selectedSku[item.field] || selectedSku[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };
    const handlePriceChange = (prop, value) => {
        setSelectedSku(selectedSku => ({ ...selectedSku, [prop]: value }));
    }
    const saveForm =() =>{
        if(validatePrice()){
            
            service.upsertEla('ela-update-price', selectedSku, resx => {
                const _payload = JSON.parse(JSON.stringify(payload));
                _payload.sort_by = _payload.sort_by.value;
                service.getElaData("all-skus", _payload, res => {
                    setStore(res);
                    setShowForm(false);
                    setErrors([]);
                    if (res.length > 29) setShowMore(true)
                });
            });
        }
    }
    const handleLoadMore = () => {
        setShowMore(false);
        let obj = store[store.length - 1];

        const _payload = JSON.parse(JSON.stringify(payload));
        _payload.sort_by = _payload.sort_by.value;
        _payload.last_key = obj[_payload.sort_by];
        service.getElaData("all-skus", _payload, res => {
            setStore([...store, ...res]);
            if (res.length > 29) setShowMore(true)
        });
    };

    const sortClickHandler = val => {
        setPayload({ ...payload, sort_by: val, last_key: null });
    }

    const sort_directionClickHandler = () => {
        setPayload({ ...payload, sort_direction: payload.sort_direction === "asc" ? "desc" : "asc", last_key: null });
    }

    const handleReset = () => {
        setPayload(initialstate);
    }
    const selectTab = (current_part_type, tab_index) => {
        localStorage.setItem('lpTabIdx', tab_index);
        handleChange('part_type', current_part_type);
        setTabIndex(tab_index);
    }
    useEffect(() => {
        startLoading()

        let p1 = new Promise((resolve, reject) => {
            const _payload = JSON.parse(JSON.stringify(payload));
            _payload.sort_by = _payload.sort_by.value;

            service.getElaData("all-skus", _payload, res => {
                setStore(res)
                if (res.length > 29) setShowMore(true)
                resolve("");
            });
        });

        Promise.all([p1])
            .then(values => {
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [payload]);

    const showDetail = (item) => {
        setSelectedSku(item);
        setShowForm(true);
    }

    const renderGrid = () => {
        return <div className="container" style={{ padding: 0 }}>
            {store && store.length > 0 ?
                <table className="table ">
                    <thead>
                        <tr>
                            <th scope="col">SKU</th>
                            <th scope="col">Part Name</th>
                            <th scope="col" style={{ textAlign: "right" }}>{tabIndex === 2 ? "DAP Price" : "List Price"}</th>
                            {tabIndex === 2 && <th scope="col" style={{ textAlign: "right" }}>DDP Price</th>}
                            {tabIndex === 2 && <th scope="col" style={{ textAlign: "right" }}>DDP + IOR Price</th>}
                            <th scope="col"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {store.map((item, index) => {
                            return <><tr key={index} style={{ background: selectedSku && selectedSku.id === item.id ? "#fafafa" : "#fafafa", color: selectedSku && selectedSku.id === item.id ? "#333333" : "#333333" }}>
                                <th scope="row"  >{item.sku}</th>
                                <td>{item.part_name}</td>
                                <td style={{ textAlign: "right" }}>{item.price1 && ("USD " + item.price1.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}</td>
                                {tabIndex === 2 && <td style={{ textAlign: "right" }}>{item.price2 && ("USD " + item.price2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}</td>}
                                {tabIndex === 2 && <td style={{ textAlign: "right" }}> {item.price3 && ("USD " + item.price3.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}</td>}
                                {/* <td>{selectedSku && selectedSku.id !== item.id?null: <button onClick={() => showDetail(item)}>{selectedSku && selectedSku.id === item.id ? "Close" : "Open"}</button>}</td> */}
                                <td><i className="mdi mdi-square-edit-outline " aria-hidden="true" data-tip data-for="edit-site" style={{ cursor: "pointer" }} onClick={() => { showDetail(item) }}></i></td>
                            </tr>
                                {/* {selectedSku && selectedSku.id === item.id && <tr ><td style={{background:"#ffffff", padding:"30px"}} colSpan={tabIndex === 2 ? 6 : 4}>
                                    {history && history.length > 0 ?
                                        <div >
                                            <div style={{fontSize:"1em", fontWeight:"300"}}>
                                                Pricing Records
                                                <button className='float-end'>Add Price Record</button>
                                            </div>
                                            <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase"}}>Valid From</th>
                                                    <th scope="col" style={{fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase"}}>Valid To</th>
                                                    <th scope="col" style={{fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase"}}>Effective Start Date</th>
                                                    <th scope="col" style={{fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase"}}>Effective End Date</th>
                                                    <th scope="col" style={{ textAlign: "right", fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase" }}>{tabIndex === 2 ? "DAP Price" : "List Price"}</th>
                                                    {tabIndex === 2 && <th scope="col" style={{ textAlign: "right", fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase" }}>DDP Price</th>}
                                                    {tabIndex === 2 && <th scope="col" style={{ textAlign: "right", fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase" }}>DDP + IOR Price</th>}
                                                    <th scope="col" style={{ textAlign: "right", fontSize:"0.65em", fontWeight:"300", color:"#aaaaaa", textTransform: "uppercase" }}> &nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {history.map((item, index) => {
                                                    return <tr key={index} style={{ background: item.is_current ? '#d0f0c0' : 'auto' }}>
                                                        <td style={{fontSize:"0.85em"}}>{item.valid_from_string}</td>
                                                        <td style={{fontSize:"0.85em"}}>{item.valid_to_string}</td>
                                                        <td style={{fontSize:"0.85em"}}>{item.range_start_string}</td>
                                                        <td style={{fontSize:"0.85em"}}>{item.range_end_string}</td>
                                                        <td style={{ textAlign: "right", fontSize:"0.85em" }}>{item.price1 && ("USD " + item.price1.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}</td>
                                                        {tabIndex === 2 && <td style={{ textAlign: "right", fontSize:"0.85em" }}>{item.price2 && ("USD " + item.price2.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}</td>}
                                                        {tabIndex === 2 && <td style={{ textAlign: "right", fontSize:"0.85em" }}> {item.price3 && ("USD " + item.price3.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}</td>}
                                                        <td style={{ textAlign: "right", fontSize:"0.85em", padding:0}}>

                                                            <i className="mdi mdi-square-edit-outline " aria-hidden="true" data-tip data-for="edit-site" style={{cursor:"pointer"}} onClick={() => {setShowForm(true)}}>
                                                            </i>
                                                            <i className="mdi mdi-delete-outline " aria-hidden="true" style={{cursor:"pointer"}} onClick={() => {
                                                                // setActiveSite(item);
                                                                // setShowDeleteSiteConfirmation(true);
                                                            }} data-tip data-for="delete-site" >
                                                            </i>

                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table> </div>:
                                        <div className="blank-div" style={{ margin: "0 -12px" }}>No Data Found.</div>}
                                </td></tr>} */}
                            </>
                        })}
                    </tbody>
                </table> :
                <div className="blank-div" style={{ margin: "0 -12px" }}>No Data Found.</div>}
            <div style={{ "textAlign": "center" }}> {store && store.length > 29 && showMore ? <button className="btn btn-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ...</button> : undefined}</div>
        </div>

    }


    loading ? startLoading() : stopLoading();
    return <div className="col-12 mt-3" style={{ background: "#f8f9fa" }}>
        <div className="container-fluid mt-1 filter-bar" >
            <div className="row">
                <div style={{ width: "60%", paddingTop: "10px" }}>
                    <label style={{ fontSize: "12px", color: "#ffffff" }}>SEARCH</label>
                    <input placeholder='' maxLength="100" style={{ display: "block", padding: "8px", height: "38px", width: "100%", border: "1px solid rgb(204, 204, 204)", borderRadius: "4px" }}
                        value={payload.search_string || ''}
                        onChange={(e) => { handleChange('search_string', e.target.value) }} />
                </div>
                <div style={{ width: "20%", paddingTop: "10px" }}>
                    <label style={{ fontSize: "12px", color: "#ffffff" }}>SORT BY</label>
                    <Select
                        value={payload.sort_by || sort_direction_options[0]}
                        options={sort_direction_options || []}
                        required
                        placeholder="Sort By"
                        onChange={e => {
                            sortClickHandler(e);
                        }}
                    />
                </div>
                <div style={{ width: "10%", paddingTop: "10px" }}>
                    <label style={{ fontSize: "12px", color: "#ffffff" }}>&nbsp;</label>

                    <div style={{ cursor: "pointer", padding: "0", width: "100%" }} onClick={sort_directionClickHandler}>{payload.sort_direction === "desc" ?
                        <button type="button" className="btn btn-outline-light" style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-sort-variant " aria-hidden="true" ></i> Z-A</button> :
                        <button type="button" className="btn btn-outline-light" style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-sort-reverse-variant" aria-hidden="true" ></i> A-Z</button>}</div>

                </div>
                <div style={{ width: "10%", paddingTop: "15px" }}>
                    <label style={{ fontSize: "12px", color: "#ffffff", display: "block" }}>&nbsp;</label>
                    <button type="button" className="btn btn-outline-light" onClick={() => handleReset()} style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-filter-off " aria-hidden="true" ></i> Reset</button>
                </div>
            </div>

        </div>
        <Tabs style={{ width: "100%" }} defaultIndex={tabIndex} >
            <TabList>
                <Tab onClick={() => { selectTab('CORE', 0); }}><div className="tab-title" style={{ fontSize: '1em' }}>Products</div></Tab>
                <Tab onClick={() => { selectTab('PS', 1); }}><div className="tab-title" style={{ fontSize: '1em' }}>Professional Services</div></Tab>
                <Tab onClick={() => { selectTab('SHIP', 2); }}><div className="tab-title" style={{ fontSize: '1em' }}>Shipping</div></Tab>
            </TabList>
            <TabPanel>
                {renderGrid()}
            </TabPanel>
            <TabPanel>
                {renderGrid()}
            </TabPanel>
            <TabPanel>
                {renderGrid()}
            </TabPanel>
        </Tabs>
        {showForm && <div className='popup'>
            <div className='popup_content' style={{ width: "900px", left: "calc( 50% - 450px)", background: "#fafafa" }}>
                <div className="popup-title" style={{ paddingBottom: "40px" }}>
                    Update Price
                </div>
                <div style={{ fontSize: "13px" }}>


                    <div className='container-fluid'>
                        <div className="row g-12 mt-2">
                            <div className="col-3">
                                <div className="form-label" >SKU</div>
                                <div className="form-label" style={{ fontWeight: "400" }}>{selectedSku.sku}</div>
                            </div>
                            <div className="col-9">
                                <div className="form-label" >Part Name</div>
                                <div className="form-label" style={{ fontWeight: "400" }}>{selectedSku.part_name}</div>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className="col-sm-12">
                                <label htmlFor="price1" className="form-label">{tabIndex === 2 ? "DAP Price" : "List Price"} <span className="required">*</span></label>
                                <div className="input-group">
                                    <div className="input-group-text">USD</div>
                                    <input type="text" className="form-control" name="price1" placeholder=""
                                        value={selectedSku.price1 || ''}
                                        disabled={selectedSku.price1 === 'NONE'}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handlePriceChange('price1', (e.target.validity.valid) ? e.target.value : '') }} />
                                </div>
                                {errors.price1 && <div className="validation-error">{errors.price1}</div>}
                            </div>
                            {tabIndex === 2 && <div className="col-sm-12 mt-2">
                                <label htmlFor="price2" className="form-label">DDP Price <span className="required">*</span></label>
                                <div className="input-group">
                                    <div className="input-group-text">USD</div>
                                    <input type="text" className="form-control" name="price1" placeholder=""
                                        value={selectedSku.price2 || ''}
                                        disabled={selectedSku.price2 === 'NONE'}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handlePriceChange('price2', (e.target.validity.valid) ? e.target.value : '') }} />
                                </div>
                                {errors.price2 && <div className="validation-error">{errors.price2}</div>}
                            </div>}

                            {tabIndex === 2 && <div className="col-sm-12 mt-2">
                                <label htmlFor="price3" className="form-label">DDP + IOR Price <span className="required">*</span></label>
                                <div className="input-group">
                                    <div className="input-group-text">USD</div>
                                    <input type="text" className="form-control" name="price3" placeholder=""
                                        value={selectedSku.price3 || ''}
                                        disabled={selectedSku.price3 === 'NONE'}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handlePriceChange('price3', (e.target.validity.valid) ? e.target.value : '') }} />
                                </div>
                                {errors.price3 && <div className="validation-error">{errors.price3}</div>}
                            </div>}

                        </div>

                    </div>

                    <div className="popup-btn-panel">
                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveForm()} >Save</button>
                        <button type="button" className="btn btn-g btn-secondary float-end" style={{ marginLeft: "5px" }} onClick={() => setShowForm(false)} >Cancel</button>
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

export default ListPriceDashboard;