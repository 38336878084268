import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading } from '../../Components/Util';
import Select from 'react-select';
import * as service from "../../services";


function Grid(props) {
    const initialstate = { sortBy: { value: 'created_on_srt', label: "Created On" }, recordsPerPage: 30, sortOrder: 'desc' }
    const [loading, setLoading] = useState(true)
    const [store, setStore] = useState([]);
    const [lookup, setLookup] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [payload, setPayload] = useState(initialstate);
    const sortOrderOptions = [
        { value: 'id', label: "Model Id" },
        { value: 'model_name_srt', label: "Model Name" },
        { value: 'opportunity_number_srt', label: "Opportunity Number" },
        { value: 'customer_name_srt', label: "Customer Name" },
        { value: 'owner_name_srt', label: "Owner" },
        { value: 'created_by_name_srt', label: "Created by" },
        { value: 'created_on_srt', label: "Created On" },
        { value: 'updated_by_name_srt', label: "Last Updated By" },
        { value: 'updated_on_srt', label: "Last Updated On" }

    ];

    useEffect(() => {
        startLoading()

        let p1 = new Promise((resolve, reject) => {
            const _payload = JSON.parse(JSON.stringify(payload));
            _payload.sortBy = _payload.sortBy.value;

            service.getModelsDashboard(_payload, res => {
                setStore(res)
                if (res.length > 29) setShowMore(true)
                resolve("");
            });
        });

        let p2 = new Promise((resolve, reject) => {
            service.getLookups(res => {
                setLookup(res)
                resolve("");
            });
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [payload]);

    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);

    const handleChange = (prop, value) => {
        const _payload = JSON.parse(JSON.stringify(payload));
        _payload[prop] = value;
        console.log(_payload);
        setPayload(_payload);
    };

    const handleLoadMore = () => {
        setShowMore(false);
        let obj = store[store.length - 1];

        const _payload = JSON.parse(JSON.stringify(payload));
        _payload.sortBy = _payload.sortBy.value;
        _payload.lastKey = obj[_payload.sortBy];
        service.getModelsDashboard(_payload, res => {
            setStore([...store, ...res]);
            if (res.length > 29) setShowMore(true)
        });
    };

    const sortClickHandler = val => {
        setPayload({ ...payload, sortBy: val, lastKey: null });
    }

    const sortOrderClickHandler = () => {
        setPayload({ ...payload, sortOrder: payload.sortOrder === "asc" ? "desc" : "asc", lastKey: null });
    }

    const handleReset = () => {
        setPayload(initialstate);
    }

    loading ? startLoading() : stopLoading();
    return (
        <div className="row" style={{ background: "#000000" }}>
            <div className="col-12" style={{ padding: "0" }}>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-6">
                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>Models</h3>
                        </div>
                        <div className="col-6">
                            <button type="button" className="btn btn-g btn-outline-warning float-end" onClick={() => {
                                service.addActivity('BVA', 'CREATE NEW MODEL', "NONE");
                                props.history.push('/create-model/0/ref');
                            }} style={{ padding: "8px 10px" }}><i className="mdi mdi-plus " aria-hidden="true" ></i> Create New Model</button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-12 " style={{ padding: "0" }}>
                <div className="container-fluid mt-1 filter-bar" >
                    <div className="row">
                        <div style={{ width: "25%", paddingTop: "10px" }}>
                            <label style={{ fontSize: "12px", color: "#ffffff" }}>CUSTOMER</label>
                            <input placeholder='' maxLength="100" style={{ display: "block", padding: "8px", height: "38px", width: "100%", border: "1px solid rgb(204, 204, 204)", borderRadius: "4px" }}
                                value={payload.searchString || ''}
                                onChange={(e) => { handleChange('searchString', e.target.value) }} />
                        </div>
                        <div style={{ width: "20%", paddingTop: "10px" }}>
                            <label style={{ fontSize: "12px", color: "#ffffff" }}>REGION</label>
                            <Select id="geography_id"
                                value={(payload && payload.geography_id && lookup.bva_geographies.find(item => item.value === payload.geography_id + '')) || null}
                                options={lookup.bva_geographies || []}
                                required
                                onChange={(e) => { handleChange('geography_id', e.value) }}
                            />
                        </div>
                        <div style={{ width: "20%", paddingTop: "10px" }}>
                            <label style={{ fontSize: "12px", color: "#ffffff" }}>VERTICAL INDUSTRY</label>
                            <Select id="vertical_id"
                                value={(payload && payload.vertical_id && lookup.bva_verticals.find(item => item.value === payload.vertical_id + '')) || null}
                                options={lookup.bva_verticals || []}
                                required
                                onChange={(e) => { handleChange('vertical_id', e.value) }}
                            />
                        </div>
                        <div style={{ width: "15%", paddingTop: "10px" }}>
                            <label style={{ fontSize: "12px", color: "#ffffff" }}>SORT BY</label>
                            <Select
                                value={payload.sortBy || sortOrderOptions[0]}
                                options={sortOrderOptions || []}
                                required
                                placeholder="Sort By"
                                onChange={e => {
                                    sortClickHandler(e);
                                }}
                            />
                        </div>
                        <div style={{ width: "10%", paddingTop: "10px" }}>
                            <label style={{ fontSize: "12px", color: "#ffffff" }}>&nbsp;</label>

                            <div style={{ cursor: "pointer", padding: "0", width: "100%" }} onClick={sortOrderClickHandler}>{payload.sortOrder === "desc" ?
                                <button type="button" className="btn btn-outline-light" style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-sort-variant " aria-hidden="true" ></i> Z-A</button> :
                                <button type="button" className="btn btn-outline-light" style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-sort-reverse-variant" aria-hidden="true" ></i> A-Z</button>}</div>

                        </div>
                        <div style={{ width: "10%", paddingTop: "15px" }}>
                            <label style={{ fontSize: "12px", color: "#ffffff", display: "block" }}>&nbsp;</label>
                            <button type="button" className="btn btn-outline-light" onClick={() => handleReset()} style={{ fontSize: "13px", padding: "2px 10px", width: "100%" }}><i className="mdi mdi-filter-off " aria-hidden="true" ></i> Reset</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12" style={{ background: "#f8f9fa" }}>

                <div className="mt-3">
                    {store && store.length > 0 ?
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Model Name</th>
                                    <th scope="col">Model Type</th>
                                    <th scope="col">Opportunity Number</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Region</th>
                                    <th scope="col">Vertical</th>
                                    <th scope="col">Current Security Automation</th>
                                    <th scope="col">Owner</th>
                                    <th scope="col">Created</th>
                                    <th scope="col">Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {store.map((item, index) => {
                                    return <tr key={index}>
                                        <th scope="row" style={{ cursor: 'pointer' }} onClick={() => {
                                            service.addActivity('BVA', 'OPEN MODEL', item.model_id + "");
                                            props.history.push('/summary/' + item.model_id + '/ref');
                                        }} >{item.model_id}</th>
                                        <td>{item.model_name}</td>
                                        <td>{item.model_type}</td>
                                        <td>{item.opportunity_number}</td>
                                        <td>{item.customer_name}</td>
                                        <td>{item.geography_name}</td>
                                        <td>{item.vertical_name}</td>
                                        <td>{item.automation_stage_name}</td>
                                        <td>{item.owner_name}</td>
                                        <td>{item.created_by_name} <div style={{ fontSize: "0.85em" }}>{item.created_on_str}</div></td>
                                        <td>{item.updated_by_name} <div style={{ fontSize: "0.85em" }}>{item.updated_on_str}</div></td>
                                    </tr>
                                })}
                            </tbody>
                        </table> :
                        <div className="blank-div" style={{ margin: "0 -12px" }}>No Data Found.</div>}
                    <div style={{ "textAlign": "center" }}> {store && store.length > 29 && showMore ? <button className="btn btn-outline-primary" onClick={() => handleLoadMore()} id="loadMore" > Show More ... </button> : undefined}</div>
                </div>
            </div>
        </div>
    )
}

export default Grid;


