import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import ProgressArc from '../../../Components/widgets/ProgressArc';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SelectField from './fields/Select';
function IncreasedThroughput(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    let operating_days_per_year_mri = 0;
    let procedure_per_day_mri = 0;
    let procedure_per_year_mri = 0;
    let average_annual_procedures_per_tech_mri = 0;
    let average_annual_revenue_mri = 0;
    let after_procedure_per_day_mri = 0;
    let after_procedure_per_year_mri = 0;
    let after_average_annual_procedures_per_tech_mri = 0;
    let after_average_annual_revenue_mri = 0;
    let gross_saving_mri = 0;
    let gross_saving_percent_mri = 0;
    let revenue_recovered_mri = 0;


    if (parseFloat(data.step1__device_count_mri) > 0) {
        operating_days_per_year_mri = 52 * parseFloat(data.step5__operating_day_per_week_mri);
        procedure_per_day_mri = Math.floor(parseFloat(data.step7_number_of_ftes_mri) * parseFloat(data.step7_avg_procedures_per_fte_mri) * parseFloat(data.step5__operating_hour_per_day_mri));
        procedure_per_year_mri = operating_days_per_year_mri * procedure_per_day_mri;
        average_annual_procedures_per_tech_mri = procedure_per_year_mri / parseFloat(data.step7_number_of_ftes_mri);
        average_annual_revenue_mri = procedure_per_year_mri * parseFloat(data.step5__avg_procedure_revenue_mri);
        after_procedure_per_day_mri = ((1 + parseFloat(data.step7_avg_training_boost_mri) / 100) * parseFloat(data.step7_number_of_ftes_mri) * parseFloat(data.step7_avg_procedures_per_fte_mri) * parseFloat(data.step5__operating_hour_per_day_mri));
        after_procedure_per_year_mri = operating_days_per_year_mri * after_procedure_per_day_mri;
        after_average_annual_procedures_per_tech_mri = after_procedure_per_year_mri / parseFloat(data.step7_number_of_ftes_mri);
        after_average_annual_revenue_mri = after_procedure_per_year_mri * parseFloat(data.step5__avg_procedure_revenue_mri);

        revenue_recovered_mri = after_average_annual_revenue_mri - average_annual_revenue_mri;
        gross_saving_mri = revenue_recovered_mri * parseFloat(data.step1__avg_margin_mri)/100;
        gross_saving_percent_mri = parseInt((after_average_annual_revenue_mri - average_annual_revenue_mri) * 100 / after_average_annual_revenue_mri);
    }
    let operating_days_per_year_ct = 0;
    let procedure_per_day_ct = 0;
    let procedure_per_year_ct = 0;
    let average_annual_procedures_per_tech_ct = 0;
    let average_annual_revenue_ct = 0;
    let after_procedure_per_day_ct = 0;
    let after_procedure_per_year_ct = 0;
    let after_average_annual_procedures_per_tech_ct = 0;
    let after_average_annual_revenue_ct = 0;
    let gross_saving_ct = 0;
    let gross_saving_percent_ct = 0;
    let revenue_recovered_ct = 0;


    if (parseFloat(data.step1__device_count_ct) > 0) {
        operating_days_per_year_ct = 52 * parseFloat(data.step5__operating_day_per_week_ct);
        procedure_per_day_ct = Math.floor(parseFloat(data.step7_number_of_ftes_ct) * parseFloat(data.step7_avg_procedures_per_fte_ct) * parseFloat(data.step5__operating_hour_per_day_ct));
        procedure_per_year_ct = operating_days_per_year_ct * procedure_per_day_ct;
        average_annual_procedures_per_tech_ct = procedure_per_year_ct / parseFloat(data.step7_number_of_ftes_ct);
        average_annual_revenue_ct = procedure_per_year_ct * parseFloat(data.step5__avg_procedure_revenue_ct);
        after_procedure_per_day_ct = ((1 + parseFloat(data.step7_avg_training_boost_ct) / 100) * parseFloat(data.step7_number_of_ftes_ct) * parseFloat(data.step7_avg_procedures_per_fte_ct) * parseFloat(data.step5__operating_hour_per_day_ct));
        after_procedure_per_year_ct = operating_days_per_year_ct * after_procedure_per_day_ct;
        after_average_annual_procedures_per_tech_ct = after_procedure_per_year_ct / parseFloat(data.step7_number_of_ftes_ct);
        after_average_annual_revenue_ct = after_procedure_per_year_ct * parseFloat(data.step5__avg_procedure_revenue_ct);

        revenue_recovered_ct = after_average_annual_revenue_ct - average_annual_revenue_ct;
        gross_saving_ct = revenue_recovered_ct * parseFloat(data.step1__avg_margin_ct)/100;
        gross_saving_percent_ct = parseInt((after_average_annual_revenue_ct - average_annual_revenue_ct) * 100 / after_average_annual_revenue_ct);
    }
    let operating_days_per_year_xr = 0;
    let procedure_per_day_xr = 0;
    let procedure_per_year_xr = 0;
    let average_annual_procedures_per_tech_xr = 0;
    let average_annual_revenue_xr = 0;
    let after_procedure_per_day_xr = 0;
    let after_procedure_per_year_xr = 0;
    let after_average_annual_procedures_per_tech_xr = 0;
    let after_average_annual_revenue_xr = 0;
    let gross_saving_xr = 0;
    let gross_saving_percent_xr = 0;
    let revenue_recovered_xr = 0;


    if (parseFloat(data.step1__device_count_xr) > 0) {
        operating_days_per_year_xr = 52 * parseFloat(data.step5__operating_day_per_week_xr);
        procedure_per_day_xr = Math.floor(parseFloat(data.step7_number_of_ftes_xr) * parseFloat(data.step7_avg_procedures_per_fte_xr) * parseFloat(data.step5__operating_hour_per_day_xr));
        procedure_per_year_xr = operating_days_per_year_xr * procedure_per_day_xr;
        average_annual_procedures_per_tech_xr = procedure_per_year_xr / parseFloat(data.step7_number_of_ftes_xr);
        average_annual_revenue_xr = procedure_per_year_xr * parseFloat(data.step5__avg_procedure_revenue_xr);
        after_procedure_per_day_xr = ((1 + parseFloat(data.step7_avg_training_boost_xr) / 100) * parseFloat(data.step7_number_of_ftes_xr) * parseFloat(data.step7_avg_procedures_per_fte_xr) * parseFloat(data.step5__operating_hour_per_day_xr));
        after_procedure_per_year_xr = operating_days_per_year_xr * after_procedure_per_day_xr;
        after_average_annual_procedures_per_tech_xr = after_procedure_per_year_xr / parseFloat(data.step7_number_of_ftes_xr);
        after_average_annual_revenue_xr = after_procedure_per_year_xr * parseFloat(data.step5__avg_procedure_revenue_xr);

        revenue_recovered_xr = after_average_annual_revenue_xr - average_annual_revenue_xr;
        gross_saving_xr = revenue_recovered_xr * parseFloat(data.step1__avg_margin_xr)/100;
        gross_saving_percent_xr = parseInt((after_average_annual_revenue_xr - average_annual_revenue_xr) * 100 / after_average_annual_revenue_xr);
    }

    const before_cost = average_annual_revenue_mri * parseFloat(data.step1__avg_margin_mri)/100 + 
    average_annual_revenue_ct * parseFloat(data.step1__avg_margin_ct)/100 + 
    average_annual_revenue_xr * parseFloat(data.step1__avg_margin_xr)/100;
    const after_cost = after_average_annual_revenue_mri * parseFloat(data.step1__avg_margin_mri)/100 +
     after_average_annual_revenue_ct * parseFloat(data.step1__avg_margin_ct)/100 +
      after_average_annual_revenue_xr * parseFloat(data.step1__avg_margin_xr)/100;
    const gross_saving = after_cost - before_cost;
    const gross_saving_percent = before_cost>0?Math.round((after_cost - before_cost) * 100 / before_cost):0;

    const average_productivity_increase = ((data.step1__device_count_mri > 0 ? parseFloat(data.step7_avg_training_boost_mri) : 0)
        + (data.step1__device_count_ct > 0 ? parseFloat(data.step7_avg_training_boost_ct) : 0)
        + (data.step1__device_count_xr > 0 ? parseFloat(data.step7_avg_training_boost_xr) : 0)) /
        ((data.step1__device_count_mri > 0 ? 1 : 0)
            + (data.step1__device_count_ct > 0 ? 1 : 0)
            + (data.step1__device_count_xr > 0 ? 1 : 0));

    const increased_procedures_per_day = (after_procedure_per_day_mri + after_procedure_per_day_ct + after_procedure_per_day_xr)
        - (procedure_per_day_mri + procedure_per_day_ct + procedure_per_day_xr);

    const next = () => {
        props.next({
            step7__total: gross_saving,
            step7__percent: gross_saving_percent,
            step7__average_productivity_increase: average_productivity_increase,
            step7__increased_procedures_per_day: increased_procedures_per_day,
            step7__gross_saving_mri: gross_saving_mri,
            step7__gross_saving_ct: gross_saving_ct ,
            step7__gross_saving_xr: gross_saving_xr
        });
    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>

            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Increased Throughput</h4>
                </div>
                <div className='col-2' >
                    <SelectField
                        fieldColor="orange"
                        fieldName="step7__mvr"
                        value={(data && data["step7__mvr"] && props.mvrs.find(item => item.value === data["step7__mvr"])) || null}
                        options={props.mvrs}
                        label="Months to Value Realization"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step7__mvr"]}
                    />
                </div>
            </div>
            <div className='row mt-3 gx-5 '>

                <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(gross_saving) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Under Utilization Revenue Recovery</div>
                        <div className='row' >
                            <div className='col-2'>
                                <div className='icon' style={{ width: "50px", height: "50px" }}>
                                    <ProgressArc value={gross_saving_percent} radius={40} arcColor={"#ffffff"} arcBackgroundColor={parseFloat(gross_saving) > 0 ? "#4D9262" : "#333333"} textColor={"#ffffff"} />
                                </div>
                            </div>
                            <div className='col-8'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(gross_saving), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3 gx-5 '>
                <Tabs style={{ width: "100%" }} variant="fullWidth">
                    <TabList>
                        {data.step1__device_count_mri > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_mri} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>MRI <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_mri > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_mri, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_ct > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_ct} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>CT SCANNER <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_ct > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_ct, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}
                        {data.step1__device_count_xr > 0 && <Tab >
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='icon' style={{ width: "50px", height: "50px" }}>
                                        <ProgressArc value={gross_saving_percent_xr} radius={40} arcColor={"#222222"} textColor={"#222222"} />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className="tab-title" style={{ fontSize: '0.75em', textAlign: "center" }}>X-RAY <br /> REVENUE RECOVERY</div>
                                    <div style={{ fontSize: '1.5em', fontWeight: "600", textAlign: "center", color: gross_saving_xr > 0 ? "#4D9262" : "#cc0000" }}>${formatCurrency(gross_saving_xr, 0)}</div>
                                </div>
                                <div className='col-2'>
                                    &nbsp;
                                </div>
                            </div>
                        </Tab>}

                    </TabList>
                    {data.step1__device_count_mri > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>

                                <Text
                                    fieldColor="sky"
                                    fieldName="step7_avg_training_boost_mri"
                                    value={data["step7_avg_training_boost_mri"] + ""}
                                    label="Average Training Productivity Boost Per Technician"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_avg_training_boost_mri"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of Technicians (FTE)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_number_of_ftes_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_number_of_ftes_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average procedure revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Hourly Procedures Per Technician</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_avg_procedures_per_fte_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_avg_procedures_per_fte_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Days Per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Days Per Year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Procedures Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(procedure_per_day_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_procedure_per_day_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Annual Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(procedure_per_year_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_procedure_per_year_mri, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Annual Procedures Per Technician</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(average_annual_procedures_per_tech_mri, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_average_annual_procedures_per_tech_mri, -2)}</td>
                                        </tr>

                                        <tr>
                                            <td>Annual Revenue </td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(average_annual_revenue_mri, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_average_annual_revenue_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_mri, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_mri,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_mri, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}

                    {data.step1__device_count_ct > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>

                                <Text
                                    fieldColor="sky"
                                    fieldName="step7_avg_training_boost_ct"
                                    value={data["step7_avg_training_boost_ct"] + ""}
                                    label="Average Training Productivity Boost Per Technician"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_avg_training_boost_ct"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of Technicians (FTE)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_number_of_ftes_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_number_of_ftes_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average procedure revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Hourly Procedures Per Technician</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_avg_procedures_per_fte_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_avg_procedures_per_fte_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Days Per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Days Per Year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Procedures Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(procedure_per_day_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_procedure_per_day_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Annual Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(procedure_per_year_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_procedure_per_year_ct, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Annual Procedures Per Technician</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(average_annual_procedures_per_tech_ct, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_average_annual_procedures_per_tech_ct, -2)}</td>
                                        </tr>

                                        <tr>
                                            <td>Annual Revenue </td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(average_annual_revenue_ct, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_average_annual_revenue_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_ct, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_ct,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_ct, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}
                    {data.step1__device_count_xr > 0 && <TabPanel>
                        <div className='row mt-3'>
                            <div className='col-4'>

                                <Text
                                    fieldColor="sky"
                                    fieldName="step7_avg_training_boost_xr"
                                    value={data["step7_avg_training_boost_xr"] + ""}
                                    label="Average Training Productivity Boost Per Technician"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step7_avg_training_boost_xr"]}
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                    rightMarker="%"
                                />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of Technicians (FTE)</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_number_of_ftes_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_number_of_ftes_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average procedure revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step5__avg_procedure_revenue_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Hourly Procedures Per Technician</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_avg_procedures_per_fte_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step7_avg_procedures_per_fte_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Hours Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_hour_per_day_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Days Per Week</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step5__operating_day_per_week_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Operating Days Per Year</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(operating_days_per_year_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Procedures Per Day</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(procedure_per_day_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_procedure_per_day_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Annual Procedures</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(procedure_per_year_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_procedure_per_year_xr, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Annual Procedures Per Technician</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(average_annual_procedures_per_tech_xr, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(after_average_annual_procedures_per_tech_xr, -2)}</td>
                                        </tr>

                                        <tr>
                                            <td>Annual Revenue </td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(average_annual_revenue_xr, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(after_average_annual_revenue_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Recovered</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(revenue_recovered_xr, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Margin</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__avg_margin_xr,- 2)}%</td>
                                        </tr>
                                        
                                        <tr style={{ fontWeight: "600" }}>
                                            <td>Estimated Savings</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(gross_saving_xr, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>}

                </Tabs>
            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default IncreasedThroughput;