import React, { Component } from 'react';
import Select from 'react-select';

export default class MultySelectDropdown extends Component {

    constructor(props) {
        super();
        this.inputField = React.createRef();
        this.state = {};
    }

    componentDidMount() {
        this.setState({ value: this.props.value, options: this.props.options });
    }

    handleChange = (value, { action, removedValue }) => {
        let _value = this.state.value;
        switch (action) {
            case 'remove-value':
                _value = _value.filter(item => removedValue.value !== item.value);
                break;
            case 'select-option':
                _value = value;
                break;
            case 'clear':
                _value = []
                break;
            default:
                break;
        }
        this.setState({ value: _value });
        if (this.props.onChange) this.props.onChange(_value);
    };

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({ value: this.props.value, options: this.props.options })
        }
    }

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <div className="panel-subheading">
                    <Select
                        placeholder={this.props.placeholder}
                        closeMenuOnSelect={this.props.closeMenuOnSelect}
                        value={this.state.value}
                        isMulti
                        menuPlacement="auto"
                        options={this.state.options}
                        isDisabled={this.props.isDisabled || false}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        );
    }
}