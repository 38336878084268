import { get, loginpost } from "./Service";
import { establishUserType } from "./../Components/Util";

export const login = (payload, onDone) => {
    loginpost("/api/authenticate", payload, (res) => {
        onDone(res);
    });
};
export const loginMFA = (payload, onDone) => {
    console.log("authenticate-mfa");
    loginpost("/api/authenticate-mfa", payload, (res) => {
        onDone(res);
    });
};

export const pluse = (onDone) => {
    get("/api/pluse", (res) => {
        onDone(res);
    });
};
export const enableMFA = (onDone) => {
    get("/api/enable-mfa", (res) => {
        onDone(res);
    });
};
export const getInstrospect = (onDone) => {
    get("/api/userInfo", (res) => {
        if (res.user && res.auths) res.userType = establishUserType(res.auths, res.user);
        onDone(res);
    });
};

export const addActivity = (app, action, ref) => {
    get("/api/add-activity/"+app+"/"+action+"/"+ref, (res) => {
        //console.log('');
    });
};