import React, { useState, useEffect } from 'react';
import Header from '../../Components/layout/Header';
import { startLoading, stopLoading } from '../../Components/Util';
import * as service from "../../services";

function ConfigureServices(props) {

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [recipe, setRecipe] = useState({});
    const [quoteHeader, setQuoteHeader] = useState({});
    useEffect(() => {
        startLoading();
        let p1 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.quote_id) > 0) {

                service.getElaData("ela-header", { id: props.match.params.quote_id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setQuoteHeader({ ...res[0], contract_start_date: res[0].contract_start_date_string });
                        resolve("");
                    }
                });
            } else {
                resolve("");
            }
        });
        let p2 = new Promise((resolve, reject) => {
            if (parseInt(props.match.params.id) > 0) {
                service.getElaData("get_services_by_id", { id: props.match.params.id }, res => {
                    console.log(res);
                    if (res && res.length === 1) {
                        console.log(res);
                        setRecipe({ ...res[0] });
                        resolve("");
                    }
                });
            } else {
                setRecipe({ quote_id: props.match.params.quote_id });
                resolve("");
            }
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false);
            })
            .catch(error => {
                console.log(error.message);
            });
    }, [props.match.params.quote_id, props.match.params.id]);

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "service", msg: "Please choose Installation Type" },
            { field: "package", msg: "Please choose Service" }
        ];

        validation.map(item => {
            console.log(recipe);
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        if ((recipe.year1_count || 0) + (recipe.year2_count || 0) + (recipe.year3_count || 0) + (recipe.year4_count || 0) + (recipe.year4_count || 0) < 1) {
            setErrors(errors => ({ ...errors, deployment: "Please enter deployment schedule" }));
            isValid = false;
        }
        return isValid;
    };


    const handleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }));
    };
    const handleNext = () => {
        if (validate()) {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(recipe));
            payload.service_category='Enterprise';
            payload.year1_count=payload.year1_count || 0;
            payload.year2_count=payload.year2_count || 0;
            payload.year3_count=payload.year3_count || 0;
            payload.year4_count=payload.year4_count || 0;
            payload.year5_count=payload.year5_count || 0;
            service.upsertEla('ela-services', payload, res => {
                console.log(res);
                props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref');
            });
        }
    }
    loading ? startLoading() : stopLoading();
    return (
        <>
            <Header {...props} />
            <main className="auto-height">
                <div className="container-fluid auto-height">
                    <div >
                        {!loading && <div className="row" style={{ background: "#000000" }} >
                            <div className="col-12" style={{ padding: "0" }}>
                                <div className="container-fluid mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="col-11" style={{ paddingTop: "10px", color: "#ffffff" }}>Configure Ela Quote</h3>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-12" style={{ padding: "0" }} >
                                <div className="container-fluid mt-1 pt-2 filter-bar">
                                    <div className="container">
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >ELA</div>
                                                <div className="summary_data">{quoteHeader.ela_number + '/' + quoteHeader.ela_name}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">End Customer</div>
                                                <div className="summary_data">{quoteHeader.end_customer_name || '-'}</div>
                                            </div>
                                            {quoteHeader.channel !== 'DIRECT' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Reseller</div>
                                                    <div className="summary_data">{quoteHeader.reseller_name ? quoteHeader.reseller_name + '(' + quoteHeader.service + ')' : '-'}</div>
                                                </div>
                                            }
                                            {quoteHeader.channel === 'T2' &&
                                                <div className="col-3">
                                                    <div className="summary_label">Distributor</div>
                                                    <div className="summary_data">{quoteHeader.distributor_name || 'No'}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >Contract Start Date</div>
                                                <div className="summary_data">{quoteHeader.contract_start_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract End Date</div>
                                                <div className="summary_data">{quoteHeader.contract_end_date_string}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Contract Length</div>
                                                <div className="summary_data">{quoteHeader.contract_length + ' years'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Currency</div>
                                                <div className="summary_data">{quoteHeader.currency_code}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SFDC Opportunity Id</div>
                                                <div className="summary_data">{quoteHeader.opportunity_id || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">SFDC Opportunity Name</div>
                                                <div className="summary_data">{quoteHeader.opportunity_name || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Billing Country</div>
                                                <div className="summary_data">{quoteHeader.opportunity_sales_country || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Is Registered Deal?</div>
                                                <div className="summary_data">{quoteHeader.opportunity_is_registered_deal? 'Yes':'No'}</div>
                                            </div>
                                        </div>
                                        <div className="row g-12 mt-2">
                                            <div className="col-3">
                                                <div className="summary_label" >SLA</div>
                                                <div className="summary_data">{quoteHeader.sla || '-'}</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="summary_label">Payment Term</div>
                                                <div className="summary_data">{quoteHeader.payment_term || '-'}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="summary_label">Sales Director</div>
                                                <div className="summary_data">{(quoteHeader.sales_director_name || '') + (quoteHeader.sales_director_email?' [EMAIL: '+quoteHeader.sales_director_email+']':'') + (quoteHeader.sales_director_phone?' [CONTACT #: '+quoteHeader.sales_director_phone+']':'')}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-3" style={{ background: "#f8f9fa" }}>
                                <div className="container pt-1">
                                    <div className="row g-12">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="card  mt-3">
                                                {/* <h4 className="card-header">Quote Header</h4> */}
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <h4>Service Configuration</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">


                                                        <div className="col-sm-12">
                                                            <div className="row g-3">
                                                                <div className="col-sm-12 ">

                                                                    <div className="list-group">

                                                                        <div className="list-group-item p-3">
                                                                           
                                                                            <div className="col-sm-12">
                                                                                <label className="form-label"> Service <span className="required">*</span></label>
                                                                                <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                                    <label className={recipe.package === 'Enterprise Package Installation/Deployment' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="package_ent">
                                                                                        <input type="radio" className="btn-check" name="btnradio" id="package_ent" autoComplete="off" checked={recipe.package === 'Enterprise Package Installation/Deployment'}
                                                                                            onChange={(e) => { handleChange('package', e.target.checked ? 'Enterprise Package Installation/Deployment' : undefined) }}
                                                                                        />
                                                                                        <i className='mdi mdi-package-variant-closed mdi-24px'></i>
                                                                                        <span style={{ fontSize: "1.2em"  }}> Enterprise Package Installation</span>
                                                                                    </label>
                                                                                    <label className={recipe.package === 'Single Remote Site Installation' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="package_r">
                                                                                        <input type="radio" className="btn-check" name="btnradio" id="package_r" autoComplete="off" checked={recipe.package === 'Single Remote Site Installation'}
                                                                                            onChange={(e) => { handleChange('package', e.target.checked ? 'Single Remote Site Installation' : undefined) }}
                                                                                        />
                                                                                        <i className='mdi mdi-remote-desktop mdi-24px'></i>
                                                                                        <span style={{ fontSize: "1.2em"  }}> Single Remote Installation</span>
                                                                                    </label>
                                                                                    <label className={recipe.package === 'Single On-Site Installation' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="package_o">
                                                                                        <input type="radio" className="btn-check" name="btnradio" id="package_o" autoComplete="off" checked={recipe.package === 'Single On-Site Installation'}
                                                                                            onChange={(e) => { handleChange('package', e.target.checked ? 'Single On-Site Installation' : undefined) }}
                                                                                        />
                                                                                        <i className='mdi mdi-map-marker-radius mdi-24px'></i>
                                                                                        <span style={{ fontSize: "1.2em"  }}> Single On-site Installation</span>
                                                                                    </label>

                                                                                </div>
                                                                                {errors.package && <div className="validation-error">{errors.package}</div>}
                                                                            </div>
                                                                            <div className="col-sm-12 mt-3">
                                                                                <label className="form-label"> Installation Type <span className="required">*</span></label>
                                                                                <div className="btn-group col-sm-12" role="group" aria-label="Basic radio toggle button group">
                                                                                    <label className={recipe.service === 'CTD' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="service_CTD">
                                                                                        <input type="radio" className="btn-check" name="btnradio" id="service_CTD" autoComplete="off" checked={recipe.service === 'CTD'}
                                                                                            onChange={(e) => { handleChange('service', e.target.checked ? 'CTD' : undefined) }}
                                                                                        />
                                                                                        <span style={{ fontSize: "1em"}}> CTD</span>
                                                                                    </label>
                                                                                    <label className={recipe.service === 'SRA' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="service_SRA">
                                                                                        <input type="radio" className="btn-check" name="btnradio" id="service_SRA" autoComplete="off" checked={recipe.service === 'SRA'}
                                                                                            onChange={(e) => { handleChange('service', e.target.checked ? 'SRA' : undefined) }}
                                                                                        />
                                                                                        <span style={{ fontSize: "1em"  }}> SRA</span>
                                                                                    </label>
                                                                                    <label className={recipe.service === 'Platform' ? "btn btn-primary" : "btn btn-outline-secondary"} htmlFor="service_Platform">
                                                                                        <input type="radio" className="btn-check" name="btnradio" id="service_Platform" autoComplete="off" checked={recipe.service === 'Platform'}
                                                                                            onChange={(e) => { handleChange('service', e.target.checked ? 'Platform' : undefined) }}
                                                                                        />
                                                                                        <span style={{ fontSize: "1em"  }}> Platform</span>
                                                                                    </label>

                                                                                </div>
                                                                                {errors.service && <div className="validation-error">{errors.service}</div>}
                                                                            </div>
                                                                            <div className="col-sm-12 mt-3">
                                                                            
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="year1_count" className="form-label">Sites deployed during year 1 </label>
                                                                                    <input type="text" className="form-control" name="year1_count" placeholder=""
                                                                                        value={recipe.year1_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('year1_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.year1_count && <div className="validation-error">{errors.year1_count}</div>}
                                                                                </div>
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="year2_count" className="form-label">Sites deployed during year 2 </label>
                                                                                    <input type="text" className="form-control" name="year2_count" placeholder=""
                                                                                        value={recipe.year2_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('year2_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.year2_count && <div className="validation-error">{errors.year2_count}</div>}
                                                                                </div>
                                                                                <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                    <label htmlFor="year3_count" className="form-label">Sites deployed during year 3 </label>
                                                                                    <input type="text" className="form-control" name="year3_count" placeholder=""
                                                                                        value={recipe.year3_count || ''}
                                                                                        maxLength="100"
                                                                                        pattern="^\d+$"

                                                                                        onChange={(e) => { handleChange('year3_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                    {errors.year3_count && <div className="validation-error">{errors.year3_count}</div>}
                                                                                </div>
                                                                                {quoteHeader.contract_length > 3 &&
                                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                        <label htmlFor="year4_count" className="form-label">Sites deployed during year 4 </label>
                                                                                        <input type="text" className="form-control" name="year4_count" placeholder=""
                                                                                            value={recipe.year4_count || ''}
                                                                                            maxLength="100"
                                                                                            pattern="^\d+$"

                                                                                            onChange={(e) => { handleChange('year4_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                        {errors.year4_count && <div className="validation-error">{errors.year4_count}</div>}
                                                                                    </div>
                                                                                }
                                                                                {quoteHeader.contract_length > 4 &&
                                                                                    <div style={{ padding: "5px", display: "inline-block", width: 100 / quoteHeader.contract_length + '%' }}>
                                                                                        <label htmlFor="year5_count" className="form-label">Sites deployed during year 5 </label>
                                                                                        <input type="text" className="form-control" name="year5_count" placeholder=""
                                                                                            value={recipe.year5_count || ''}
                                                                                            maxLength="100"
                                                                                            pattern="^\d+$"

                                                                                            onChange={(e) => { handleChange('year5_count', (e.target.validity.valid) ? e.target.value : '') }} />
                                                                                        {errors.year4_coyear5_countunt && <div className="validation-error">{errors.year5_count}</div>}
                                                                                    </div>
                                                                                }
                                                                                {errors.deployment && <div className="validation-error">{errors.deployment}</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" style={{ padding: '10px 0' }}>
                                        <button type="button" className="btn btn-g btn-outline-primary float-end" style={{ marginLeft: '10px' }} onClick={() => handleNext()} > Next <i className="mdi mdi-arrow-right-bold" aria-hidden="true" ></i></button>
                                        <button type="button" className="btn btn-g btn-outline-secondary float-start" onClick={() => props.history.push('/configure-ela/' + props.match.params.quote_id + '/ref')} ><i className="mdi mdi-arrow-left-bold " aria-hidden="true" ></i> Back </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default ConfigureServices;


