import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import SelectField from './fields/Select';

function DeviceLifecycle(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;


    const growth_devices_yr1 = parseFloat(data.step12__number_of_devices_across_facilities) * (1 + parseFloat(data.step12__cagr) / 100);
    const growth_devices_yr2 = growth_devices_yr1 * (1 + parseFloat(data.step12__cagr) / 100);
    const growth_devices_yr3 = growth_devices_yr2 * (1 + parseFloat(data.step12__cagr) / 100);
    const growth_devices_yr4 = growth_devices_yr3 * (1 + parseFloat(data.step12__cagr) / 100);
    const growth_devices_yr5 = growth_devices_yr4 * (1 + parseFloat(data.step12__cagr) / 100);

    const growth_owned_devices_yr1 = (growth_devices_yr1  - Math.round(growth_devices_yr1*parseFloat(data.step12__per_rental_devices) / 100));
    const growth_owned_devices_yr2 = (growth_devices_yr2  - Math.round(growth_devices_yr2*parseFloat(data.step12__per_rental_devices) / 100));
    const growth_owned_devices_yr3 = (growth_devices_yr3  - Math.round(growth_devices_yr3*parseFloat(data.step12__per_rental_devices) / 100));
    const growth_owned_devices_yr4 = (growth_devices_yr4  - Math.round(growth_devices_yr4*parseFloat(data.step12__per_rental_devices) / 100));
    const growth_owned_devices_yr5 = (growth_devices_yr5  - Math.round(growth_devices_yr5*parseFloat(data.step12__per_rental_devices) / 100));


    const growth_mobile_devices_yr1 = (growth_owned_devices_yr1 * (1 - parseFloat(data.step12__per_stationary_devices) / 100));
    const growth_mobile_devices_yr2 = (growth_owned_devices_yr2 * (1 - parseFloat(data.step12__per_stationary_devices) / 100));
    const growth_mobile_devices_yr3 = (growth_owned_devices_yr3 * (1 - parseFloat(data.step12__per_stationary_devices) / 100));
    const growth_mobile_devices_yr4 = (growth_owned_devices_yr4 * (1 - parseFloat(data.step12__per_stationary_devices) / 100));
    const growth_mobile_devices_yr5 = (growth_owned_devices_yr5 * (1 - parseFloat(data.step12__per_stationary_devices) / 100));

    const new_devices_yr1 = growth_devices_yr1 - parseFloat(data.step12__number_of_devices_across_facilities);
    const new_devices_yr2 = growth_devices_yr2 - growth_devices_yr1;
    const new_devices_yr3 = growth_devices_yr3 - growth_devices_yr2;
    const new_devices_yr4 = growth_devices_yr4 - growth_devices_yr3;
    const new_devices_yr5 = growth_devices_yr5 - growth_devices_yr4;

    const decomissioned_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_decommissioned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const decomissioned_devices_yr2 = Math.ceil((parseFloat(data.step12__number_of_decommissioned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr2);
    const decomissioned_devices_yr3 = Math.ceil((parseFloat(data.step12__number_of_decommissioned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr3);
    const decomissioned_devices_yr4 = Math.ceil((parseFloat(data.step12__number_of_decommissioned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr4);
    const decomissioned_devices_yr5 = Math.ceil((parseFloat(data.step12__number_of_decommissioned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr5);

    const reassigned_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_reassigned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12.0) * growth_owned_devices_yr1);
    const reassigned_devices_yr2 = Math.ceil((parseFloat(data.step12__number_of_reassigned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12.0) * growth_owned_devices_yr2);
    const reassigned_devices_yr3 = Math.ceil((parseFloat(data.step12__number_of_reassigned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12.0) * growth_owned_devices_yr3);
    const reassigned_devices_yr4 = Math.ceil((parseFloat(data.step12__number_of_reassigned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12.0) * growth_owned_devices_yr4);
    const reassigned_devices_yr5 = Math.ceil((parseFloat(data.step12__number_of_reassigned) / parseFloat(data.step12__number_of_devices_across_facilities) * 12.0) * growth_owned_devices_yr5);

    const updated_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_updated) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_mobile_devices_yr1);
    const updated_devices_yr2 = Math.ceil((parseFloat(data.step12__number_of_updated) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_mobile_devices_yr2);
    const updated_devices_yr3 = Math.ceil((parseFloat(data.step12__number_of_updated) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_mobile_devices_yr3);
    const updated_devices_yr4 = Math.ceil((parseFloat(data.step12__number_of_updated) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_mobile_devices_yr4);
    const updated_devices_yr5 = Math.ceil((parseFloat(data.step12__number_of_updated) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_mobile_devices_yr5);

    const recalled_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_recalled) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const recalled_devices_yr2 = Math.ceil((parseFloat(data.step12__number_of_recalled) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr2);
    const recalled_devices_yr3 = Math.ceil((parseFloat(data.step12__number_of_recalled) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr3);
    const recalled_devices_yr4 = Math.ceil((parseFloat(data.step12__number_of_recalled) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr4);
    const recalled_devices_yr5 = Math.ceil((parseFloat(data.step12__number_of_recalled) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr5);

    const pm_tickets_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_tickets) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const pm_tickets_devices_yr2 = Math.ceil((parseFloat(data.step12__number_of_tickets) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr2);
    const pm_tickets_devices_yr3 = Math.ceil((parseFloat(data.step12__number_of_tickets) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr3);
    const pm_tickets_devices_yr4 = Math.ceil((parseFloat(data.step12__number_of_tickets) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr4);
    const pm_tickets_devices_yr5 = Math.ceil((parseFloat(data.step12__number_of_tickets) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr5);

    const new_device_types_devices_yr1 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr1);
    const new_device_types_devices_yr2 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr2);
    const new_device_types_devices_yr3 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr3);
    const new_device_types_devices_yr4 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr4);
    const new_device_types_devices_yr5 = Math.ceil((parseFloat(data.step12__number_of_installed) / parseFloat(data.step12__number_of_devices_across_facilities) * 12) * growth_owned_devices_yr5);

    const new_networks_yr1 =  Math.ceil(parseFloat(data.step12__number_of_new_networks) *(parseFloat(data.step12__cagr) / 100));
    const new_networks_yr2 = Math.ceil(new_networks_yr1 * (1 + parseFloat(data.step12__cagr) / 100));
    const new_networks_yr3 = Math.ceil(new_networks_yr2 * (1 + parseFloat(data.step12__cagr) / 100));
    const new_networks_yr4 = Math.ceil(new_networks_yr3 * (1 + parseFloat(data.step12__cagr) / 100));
    const new_networks_yr5 = Math.ceil(new_networks_yr4 * (1 + parseFloat(data.step12__cagr) / 100));

    const new_vlans_yr1 =  Math.ceil(parseFloat(data.step12__number_of_vlans) *(parseFloat(data.step12__cagr) / 100));
    const new_vlans_yr2 = Math.ceil(new_vlans_yr1 * (1 + parseFloat(data.step12__cagr) / 100));
    const new_vlans_yr3 = Math.ceil(new_vlans_yr2 * (1 + parseFloat(data.step12__cagr) / 100));
    const new_vlans_yr4 = Math.ceil(new_vlans_yr3 * (1 + parseFloat(data.step12__cagr) / 100));
    const new_vlans_yr5 = Math.ceil(new_vlans_yr4 * (1 + parseFloat(data.step12__cagr) / 100));


    const next = () => {
        props.next({ });

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
                <div className='col-10' >
                    <h4>Device Lifecycle Labor</h4>
                </div>
            </div>

            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                    <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Customer</h4>
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="blue"
                        fieldName="step12__number_of_beds"
                        value={data["step12__number_of_beds"] + ""}
                        label="Number of Beds"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__number_of_beds"]}
                        pattern="[0-9]*"
                    />
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="orange"
                        fieldName="step12__cagr"
                        value={data["step12__cagr"] + ""}
                        label="Compound Annual Growth Rate (CAGR) for medical devices"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__cagr"]}
                        rightMarker="%"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                    
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="blue"
                        fieldName="step12__number_of_devices_across_facilities"
                        value={data["step12__number_of_devices_across_facilities"] + ""}
                        label="How many medical devices are managed across all facilities?"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__number_of_devices_across_facilities"]}
                        pattern="[0-9]*"
                    />
                </div>
                <div className='col-12'>
                    <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Labor Salary Assumptions</h4>
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="orange"
                        fieldName="step12__salary_clinical_staff"
                        value={data["step12__salary_clinical_staff"] + ""}
                        label="Clinical Staff"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__salary_clinical_staff"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="orange"
                        fieldName="step12__salary_clerical_staff"
                        value={data["step12__salary_clerical_staff"] + ""}
                        label="Clerical Staff"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__salary_clerical_staff"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="orange"
                        fieldName="step12__salary_network_engineer"
                        value={data["step12__salary_network_engineer"] + ""}
                        label="Network Engineer"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__salary_network_engineer"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="orange"
                        fieldName="step12__salary_infosec_engineer"
                        value={data["step12__salary_infosec_engineer"] + ""}
                        label="InfoSec Engineer"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__salary_infosec_engineer"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                </div>
                <div className='col-4'>
                    <Text
                        fieldColor="orange"
                        fieldName="step12__salary_biomedical_engineer"
                        value={data["step12__salary_biomedical_engineer"] + ""}
                        label="Biomedical Engineer"
                        required={true}
                        handleChange={handleChange}
                        error={errors["step12__salary_biomedical_engineer"]}
                        leftMarker="$"
                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                    />
                </div>

            </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 1</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 2</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 3</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 4</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Year 5</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={7}><h4>Installed Base & Growth</h4></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Devices</td>
                                        <td style={{ textAlign: "right" }}>{formatCurrency(growth_devices_yr1, 0)}</td>
                                        <td style={{ textAlign: "right" }}>{formatCurrency(growth_devices_yr2, 0)}</td>
                                        <td style={{ textAlign: "right" }}>{formatCurrency(growth_devices_yr3, 0)}</td>
                                        <td style={{ textAlign: "right" }}>{formatCurrency(growth_devices_yr4, 0)}</td>
                                        <td style={{ textAlign: "right" }}>{formatCurrency(growth_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__per_rental_devices"
                                            value={data["step12__per_rental_devices"] + ""}
                                            label="What is the average percentage of the devices that are rentals?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__per_rental_devices"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        /></td>
                                        <td valign='bottom'>Owned Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_owned_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_owned_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_owned_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_owned_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_owned_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__per_stationary_devices"
                                            value={data["step12__per_stationary_devices"] + ""}
                                            label="What percentage of the devices are stationary?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__per_stationary_devices"]}
                                            rightMarker="%"
                                            pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        /></td>
                                        <td  valign='bottom'>Mobile Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_mobile_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_mobile_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_mobile_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_mobile_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(growth_mobile_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}><h4>Inventory Activities</h4></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td valign='bottom'>New Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_decommissioned"
                                            value={data["step12__number_of_decommissioned"] + ""}
                                            label="How many devices are decommissioned each month?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_decommissioned"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>Decomissioned Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(decomissioned_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(decomissioned_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(decomissioned_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(decomissioned_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(decomissioned_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_reassigned"
                                            value={data["step12__number_of_reassigned"] + ""}
                                            label="How many devices are reassigned each month?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_reassigned"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>Reassigned Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(reassigned_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(reassigned_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(reassigned_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(reassigned_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(reassigned_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_updated"
                                            value={data["step12__number_of_updated"] + ""}
                                            label="How many devices are updated each month?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_updated"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>Updated Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(updated_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(updated_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(updated_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(updated_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(updated_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_recalled"
                                            value={data["step12__number_of_recalled"] + ""}
                                            label="How many devices are processed for recall each month?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_recalled"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>Recalled Devices</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(recalled_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(recalled_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(recalled_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(recalled_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(recalled_devices_yr5, 0)}</td>
                                    </tr>

                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_tickets"
                                            value={data["step12__number_of_tickets"] + ""}
                                            label="How many preventative maintenance tickets are processed monthly?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_tickets"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>PM Tickets</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(pm_tickets_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(pm_tickets_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(pm_tickets_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(pm_tickets_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(pm_tickets_devices_yr5, 0)}</td>
                                    </tr>

                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_installed"
                                            value={data["step12__number_of_installed"] + ""}
                                            label="How many new types of devices are installed annually?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_installed"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>New Device Types</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_device_types_devices_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_device_types_devices_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_device_types_devices_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_device_types_devices_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_device_types_devices_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}><h4>Medical Network Activities</h4></td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_new_networks"
                                            value={data["step12__number_of_new_networks"] + ""}
                                            label="How many new networks are installed annually?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_new_networks"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>New Networks</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_networks_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_networks_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_networks_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_networks_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_networks_yr5, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td><Text
                                            fieldColor="blue"
                                            fieldName="step12__number_of_vlans"
                                            value={data["step12__number_of_vlans"] + ""}
                                            label="How many VLANs do you manage across all networks?"
                                            required={true}
                                            handleChange={handleChange}
                                            error={errors["step12__number_of_vlans"]}
                                            pattern="[0-9]*"
                                        /></td>
                                        <td valign='bottom'>New VLANs</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_vlans_yr1, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_vlans_yr2, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_vlans_yr3, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_vlans_yr4, 0)}</td>
                                        <td valign='bottom' style={{ textAlign: "right" }}>{formatCurrency(new_vlans_yr5, 0)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>

            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default DeviceLifecycle;