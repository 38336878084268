import React, { Component } from 'react';
import * as service from "./../services";
import { startLoading, stopLoading, replaceSpecialChars, checkFileSize, copyToClipboard } from "./Util";
import ReactTooltip from 'react-tooltip';
import { FileIcon, defaultStyles } from 'react-file-icon';

export default class Attachments extends Component {
    constructor(props) {
        super();
        this.inputFileRef = React.createRef();
        this.state = {
            maxFiles: 1,
            files: [],
            allowedMimeTypes: ['image/', 'application/vnd.ms', 'application/octet-stream', 'text/plain', 'application/pdf', 'application/ms', 'application/vnd.openxmlformats', 'application/vnd.ms-outlook', 'application/octet-stream', 'video/quicktime', 'application/x-shockwave-flash', 'text/html'],
            allowedFileExtns: ['csv', 'doc', 'docx', 'eml', 'gif', 'html', 'htm', 'ics', 'jpg', 'mp3', 'mp4', 'msg', 'oft', 'pdf', 'png', 'ppt', 'pptx', 'txt', 'xls', 'xlsx', 'zip', 'zipx', 'mov', 'swf', 'psd', 'ai', 'cdr', 'xlsb']
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.allowedFileExtns) {
            this.setState({ allowedFileExtns: this.props.allowedFileExtns });
        }
        if (this.props.maxFiles) {
            this.setState({ maxFiles: this.props.maxFiles });
        }
        if (this.props.defaultLoad) {
            this.loadFiles((files) => {
                if (this._isMounted) this.setState({ files: files || [] })
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.loadFiles((files) => {
                if (this._isMounted) this.setState({ files: files || []})
            });
        }
    }

    loadFiles(cb) {
        service.getUploadedFile({
            guid: this.props.guid,
            context: this.props.context,
            contextId: this.props.contextId || 0
        }, function (res) {
            cb(res);
        });
    }

    onFileChange = event => {
        console.log("1");
        let errors = [];
        console.log("1");
        const selectedFile = event.target.files[0];
        console.log("2");
        let isAllowedFileType = this.state.allowedFileExtns.includes(selectedFile.name.split('.').pop());
        if (!isAllowedFileType) {
            errors.push('Only the following file types can be uploaded <br>(' + this.state.allowedFileExtns.join() + ')');
        }
        console.log("3");
        let isFileSizeOk = checkFileSize(selectedFile);
        if (!isFileSizeOk) {
            errors.push('File size exceeded the maximum allowed size');
        }

        console.log("4");
        let isFileCountOk = this.state.maxFiles > this.state.files.length;
        if (!isFileCountOk) {
            errors.push('Maximum ' + this.state.maxFiles + ' files allowed');
        }
        console.log("5");
        if (errors.length > 0) {
            this.setState({ errors });
        } else {
            startLoading();
            this.setState({ errors: [] });
            let fileName = replaceSpecialChars(selectedFile.name);
            const formData = new FormData();
            formData.append('file', selectedFile);
            let { guid, contextId, context } = this.props;
            service.uploadFile(formData, fileName, guid, contextId || 0, context, (res) => {
                console.log(res);
                if (res && res.response && res.response.status && res.response.status === "ERROR") {
                    stopLoading();
                }
                this.loadFiles((data) => {
                    console.log(data);
                    this.setState({ files: data });
                    stopLoading();
                    this.inputFileRef.current.value = "";
                    if (this.props.onChangeHandler) {
                        this.props.onChangeHandler('attachment', res.attachments);
                    }
                });
            });
        }
    };

    download(_row) {
        startLoading();
        service.serveFilefromS3(_row.fileName, _row.filePath, function () {
            stopLoading();
        });
    }

    deleteFile(data) {
        startLoading();
        service.deleteUploadedFile({
            id: data.id
        }, (res) => {
            if (res.status === "ERROR") {
                stopLoading();
            } else {
                this.loadFiles((data) => {
                    this.inputFileRef.current.value = "";
                    this.setState({ files: data, errors:[] });
                    stopLoading();
                });
            }
        });
    }

    render() {
        return (
            <div style={{ marginTop: "10px" }}>
                {((this.state.files && this.state.files.length > 0) || this.props.allowUpload) && <span><i className="fa fa-paperclip" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                    <span className="form-label">{this.props.label}</span>
                    {this.props.tooltipText && <span style={{ cursor: "pointer", paddingLeft: "5px" }}>
                        <i className="fa fa-question-circle light" aria-hidden="true" title={this.props.tooltipText}></i>
                    </span>}
                </span>}

                {this.props.allowUpload && <div>
                    <div className="panel-subheading">
                        <div className="file">
                            <input type="file" ref={this.inputFileRef} onChange={this.onFileChange} multiple="multiple" />
                            <button className="btn btn-attachment" ><i className='mdi mdi-upload' style={{marginRight:"10px"}}></i>Choose file to upload ... </button>
                        </div>
                    </div>
                    <div className="col-md-12 trim">
                        {this.state.errors && this.state.errors.length > 0 &&
                            this.state.errors.map((item, index) => {
                                return <div key={index} className="validation-error" dangerouslySetInnerHTML={{ __html: item }}></div>
                            })
                        }
                    </div>
                </div>}

                {this.state.files && <div><table style={{ fontSize: "12px" }}><tbody>{this.state.files.map((item, index) => {
                    return <tr key={index}>
                        {this.props.allowUpload && <td style={{ width: "20px" }}>
                            <div style={{ cursor: "pointer", color: "red" }}><i className="mdi mdi-delete-outline" aria-hidden="true" data-tip data-for="delete-upload" onClick={() => this.deleteFile(item)}></i>
                                <ReactTooltip type="dark" id="delete-upload" place="top" effect="solid" className="sidenav-tooltip">Delete</ReactTooltip>
                            </div>
                        </td>}
                        <td style={{ cursor: "pointer", padding: "5px" }} >
                                    <div style={{width:"20px", display:"inline-block"}}><FileIcon extension={item.fileName.split('.').pop()}  {...defaultStyles[item.fileName.split('.').pop()]} /></div>
                        </td>
                        <td style={{ cursor: "pointer", padding: "5px" }} >
                            <span onClick={() => this.download(item)}>{item.fileName + " (" + item.fileSize + " KB)"}</span>
                            {this.props.showLink && <span color="badge badge-primary" style={{ marginLeft: "10px", padding: "3px", fontSize: "10px" }} title="Copy Link" onClick={e => { copyToClipboard(item.appUrl + 'api/serve-files/' + item.fileName + '/' + item.filePath); }}>Copy</span>}
                        </td>
                    </tr>;
                })}</tbody></table></div>
                }
            </div>
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}