import { post } from "./Service";
import axios from "axios";
import {saveAs} from "file-saver";
import {startLoading, stopLoading} from '../Components/Util'

export const getBvaHcData = (query, payload, onDone) => {
    post("/api/get-bvahc-data/" + query, payload, (res) => {
        onDone(res);
    });
};

export const getHcModelsDashboard = (payload, onDone) => {
    post("/api/get-bvahc-data/get-dashboard", payload, (res) => {
        onDone(res);
    });
};

export const upsertSharedHCModelUsers = (modelId, payload, onDone) => {
    post("/api/shared-model-users-hc/" + modelId, payload, (res) => {
        onDone(res);
    });
};

export const downloadHCPPTX = (model_id,  onDone) => {
    startLoading();
    axios({
        url: encodeURI("/api/get-hc-model-pptx/"+model_id),
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('token')),
            'Access-Control-Allow-Origin': '*'
        } 
      }).then((response) => {
        saveAs(response.data, "bvahc_"+model_id+".pptx");
        stopLoading();
      });
};