import React from 'react';
import Text from './fields/Text';
import { formatCurrency } from '../../../Components/Util';
import SelectField from './fields/Select';

function BreachFines(props) {
    const data = props.data;
    const errors = props.errors;
    const handleChange = props.handleChange;

    const num_of_facilities = parseFloat(data.step1__sec_hospital_count) + parseFloat(data.step1__sec_ambulatory_count) + parseFloat(data.step1__sec_datacenter_count);
    const total_cmp = parseFloat(data.step11__cmp_device_breach_fine) + parseFloat(data.step11__cmp_ephi_risk_analysis_fine) + parseFloat(data.step11__cmp_breach_notification_fine);
    const lawsuit_damages = parseFloat(data.step11__number_of_records_breached) * parseFloat(data.step11__lawsuit_damages_per_record);
    const court_expenses = parseFloat(data.step11__number_of_records_breached) * parseFloat(data.step11__court_expenses_per_record);
    const total_legal_cost = lawsuit_damages+court_expenses;
    const loss_of_business =  parseFloat(data.step1__fin_patient_gross_revenue) * (parseFloat(data.step11__revenue_loss_from_breach)/100);
    const insurance_coverage_increase = parseFloat(data.step1__fin_cyberinsurance_premium) * (parseFloat(data.step11__insurance_premium_from_increase)/100);
    const investigation = num_of_facilities * parseFloat(data.step11__investigation_cost_per_facility);
    const cybersecurity_upgrade = num_of_facilities * parseFloat(data.step11__upgrade_cost_per_facility);
    const reserve_for_future_defense = total_legal_cost * parseFloat(data.step11__total_legal_cost_defence/100);
    const pr_marketing_cost = parseFloat(data.step1__fin_patient_gross_revenue)  * (parseFloat(data.step11__patient_reveneue_pr_recovery)/100);
    const consumer_monitoring = parseFloat(data.step11__number_of_records_breached) * parseFloat(data.step11__consumer_monitoring_per_record);
    const notification_cost = parseFloat(data.step11__number_of_records_breached) * parseFloat(data.step11__notification_per_record);
    const consequential_fines = loss_of_business+insurance_coverage_increase+investigation+cybersecurity_upgrade+reserve_for_future_defense+pr_marketing_cost+consumer_monitoring+notification_cost;
    const exposure = (total_legal_cost + consequential_fines) + (total_cmp * parseFloat(data.step11__num_years_in_audit));
    const risk_reserve_before = ((total_cmp * parseFloat(data.step11__num_years_in_audit)) + (total_legal_cost + consequential_fines)) * parseFloat(data.step11__probability_before)/100;
    const risk_reserve_after = ((total_cmp * parseFloat(data.step11__num_years_in_audit)) + (total_legal_cost + consequential_fines)) * parseFloat(data.step11__probability_after)/100;
    const cost_avoidance_before = risk_reserve_before * parseFloat(data.step1__fin_interest_on_cost_avoidance)/100;
    const cost_avoidance_after = risk_reserve_after * parseFloat(data.step1__fin_interest_on_cost_avoidance)/100;

    const step11__total = cost_avoidance_before - cost_avoidance_after;
    
    
    const next = () => {
        props.next({ step11__total: step11__total });

    }
    const previous = () => {
        props.previous();
    }
    return (
        <div className='container'>
            <div className='row mt-3'>
            <div className='col-10' >
                <h4>Breach Fines & Fees</h4>
                </div>
                <div className='col-2' >
                <SelectField
                    fieldColor="orange"
                    fieldName="step11__mvr"
                    value={(data && data["step11__mvr"] && props.mvrs.find(item => item.value === data["step11__mvr"])) || null}
                    options={props.mvrs}
                    label="Months to Value Realization"
                    required={true}
                    handleChange={handleChange}
                    error={errors["step11__mvr"]}
                />
                </div>
            </div>
            <div className='col-12' >
                    <div className='row mt-3' style={{ textAlign: "center", background: parseFloat(step11__total) > 0 ? "#4D9262" : "#cc0000", color: "#ffff", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ textTransform: "uppercase", fontSize: "0.65em" }}>Breach Fines & Fees Cybersecurity Cost Avoidance</div>
                        <div className='row' >
                            
                            <div className='col-12'>
                                <div style={{ textTransform: "uppercase", fontSize: "1.85em", fontWeight: "600" }}>
                                    ${formatCurrency(
                                        parseFloat(step11__total), 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='row mt-3 gx-5 '>
                <div className='col-12'>
                <div className='row mt-3'>
                            <div className='col-4'>
                                <h4 style={{ fontSize: "1.2rem" }}>Basic Input</h4>
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__revenue_loss_from_breach"
                                    value={data["step11__revenue_loss_from_breach"] +""}
                                    label="Revenue Loss from Breach"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__revenue_loss_from_breach"]}
                                    rightMarker="%"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__number_of_records_breached"
                                    value={data["step11__number_of_records_breached"] +""}
                                    label="Number of Records Breached"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__number_of_records_breached"]}
                                    pattern="[0-9]*"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__lawsuit_damages_per_record"
                                    value={data["step11__lawsuit_damages_per_record"] +""}
                                    label="Lawsuit Damages Per Record Breached"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__lawsuit_damages_per_record"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__court_expenses_per_record"
                                    value={data["step11__court_expenses_per_record"] +""}
                                    label="Attorney/Court Expenses Per Record Breached"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__court_expenses_per_record"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__insurance_premium_from_increase"
                                    value={data["step11__insurance_premium_from_increase"] +""}
                                    label="Cyber Insurance Coverage Premium From Increase"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__insurance_premium_from_increase"]}
                                    rightMarker="%"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__investigation_cost_per_facility"
                                    value={data["step11__investigation_cost_per_facility"] +""}
                                    label="Investigation Cost Per Data Center/Facility"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__investigation_cost_per_facility"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__upgrade_cost_per_facility"
                                    value={data["step11__upgrade_cost_per_facility"] +""}
                                    label="Cyber Security Upgrade Cost Per Data Center/Facility"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__upgrade_cost_per_facility"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__total_legal_cost_defence"
                                    value={data["step11__total_legal_cost_defence"] +""}
                                    label="Percentage of Total Legal Costs Reserved for Future Defense"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__total_legal_cost_defence"]}
                                    rightMarker="%"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__patient_reveneue_pr_recovery"
                                    value={data["step11__patient_reveneue_pr_recovery"] +""}
                                    label="Percentage of Patient Revenue Dedicated to PR Recovery"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__patient_reveneue_pr_recovery"]}
                                    rightMarker="%"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__consumer_monitoring_per_record"
                                    value={data["step11__consumer_monitoring_per_record"] +""}
                                    label="Consumer Monitering Per Record Breached"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__consumer_monitoring_per_record"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__notification_per_record"
                                    value={data["step11__notification_per_record"] +""}
                                    label="Notification Mailing Cost Per Record"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__notification_per_record"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Civil Monetary Penalties (CMP)</h4>
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__cmp_device_breach_fine"
                                    value={data["step11__cmp_device_breach_fine"] +""}
                                    label="CMP: Device Breach Fine"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__cmp_device_breach_fine"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__cmp_ephi_risk_analysis_fine"
                                    value={data["step11__cmp_ephi_risk_analysis_fine"] +""}
                                    label="CMP: ePHI Risk Analysis Fine"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__cmp_ephi_risk_analysis_fine"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__cmp_breach_notification_fine"
                                    value={data["step11__cmp_breach_notification_fine"] +""}
                                    label="CMP: Breach Notification Fine"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__cmp_breach_notification_fine"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <h4 style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>Fines & Fees</h4>
                                <Text
                                    fieldColor="blue"
                                    fieldName="step11__num_years_in_audit"
                                    value={data["step11__num_years_in_audit"] +""}
                                    label="Number of Years in Audit "
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__num_years_in_audit"]}
                                    leftMarker="$"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step11__probability_before"
                                    value={data["step11__probability_before"] +""}
                                    label="Probability of Stated Fines & Fees (BEFORE)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__probability_before"]}
                                    rightMarker="%"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                    fieldColor="sky"
                                    fieldName="step11__probability_after"
                                    value={data["step11__probability_after"] +""}
                                    label="Probability of Stated Fines & Fees (AFTER)"
                                    required={true}
                                    handleChange={handleChange}
                                    error={errors["step11__probability_after"]}
                                    rightMarker="%"
                                    pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                />
                                <Text
                                fieldColor="orange"
                                fieldName="step1__fin_interest_on_cost_avoidance"
                                value={data["step1__fin_interest_on_cost_avoidance"]+""}
                                label="Interest on Cost Avoidance"
                                required={true}
                                handleChange={handleChange} 
                                error={errors["step1__fin_interest_on_cost_avoidance"]}
                                rightMarker="%"
                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                            />
                            </div>
                            <div className='col-8'>
                                <table className='table'>
                                    <thead>
                                        <tr>

                                            <th scope="col">&nbsp;</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Before</th>
                                            <th scope="col" style={{ textAlign: "right" }}>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Gross Patient Revenue</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_patient_gross_revenue, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_patient_gross_revenue, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenue Loss from Breach</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__revenue_loss_from_breach, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__revenue_loss_from_breach, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Records Breached</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__number_of_records_breached, 0)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__number_of_records_breached, 0)}</td>
                                        </tr>
                                        <tr>
                                            <td>Lawsuit Damages Per Record Breached</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__lawsuit_damages_per_record, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__lawsuit_damages_per_record, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Attorney/Court Expenses Per Record Breached</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__court_expenses_per_record, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__court_expenses_per_record, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cyber Insurance Coverage Premium</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_cyberinsurance_premium, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step1__fin_cyberinsurance_premium, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cyber Insurance Coverage Premium From Increase</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__insurance_premium_from_increase, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__insurance_premium_from_increase, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Data Centers/Facilities</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(num_of_facilities, 0)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(num_of_facilities, 0)}</td>
                                        </tr>
                                        <tr>
                                            <td>Investigation Cost Per Data Center/Facility</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__investigation_cost_per_facility, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__investigation_cost_per_facility, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cyber Security Upgrade Cost Per Data Center/Facility</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__upgrade_cost_per_facility, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__upgrade_cost_per_facility, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of Total Legal Costs Reserved for Future Defense</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__total_legal_cost_defence, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__total_legal_cost_defence, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of Patient Revenue Dedicated to PR Recovery</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__patient_reveneue_pr_recovery, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__patient_reveneue_pr_recovery, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>Consumer Monitering Per Record Breached</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__consumer_monitoring_per_record, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__consumer_monitoring_per_record, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Notification Mailing Cost Per Record</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__notification_per_record, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__notification_per_record, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}><h4>Civil Monetary Penalties (CMP)</h4></td>
                                        </tr>
                                        <tr>
                                            <td>CMP: Device Breach Fine</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__cmp_device_breach_fine, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__cmp_device_breach_fine, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>CMP: ePHI Risk Analysis Fine</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__cmp_ephi_risk_analysis_fine, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__cmp_ephi_risk_analysis_fine, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>CMP: Breach Notification Fine</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__cmp_breach_notification_fine, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(data.step11__cmp_breach_notification_fine, 2)}</td>
                                        </tr>
                                        <tr style={{fontWeight:600}}>
                                            <td>Total Civil Monetary Penalties</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_cmp, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_cmp, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}><h4>Legal Costs</h4></td>
                                        </tr>
                                        <tr>
                                            <td>Lawsuit Damages (Class Action & Derivative)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(lawsuit_damages, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(lawsuit_damages, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Attorney & Court Expenses</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(court_expenses, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(court_expenses, 2)}</td>
                                        </tr>
                                        <tr style={{fontWeight:600}}>
                                            <td>Total Legal Costs of Breach</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_legal_cost, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(total_legal_cost, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}><h4>Consequential Costs & Fees</h4></td>
                                        </tr>
                                        <tr>
                                            <td>Loss of Business</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(loss_of_business, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(loss_of_business, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cyber Insurance Coverage Increase</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(insurance_coverage_increase, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(insurance_coverage_increase, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Investigations</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(investigation, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(investigation, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cyber Security Upgrade</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(cybersecurity_upgrade, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(cybersecurity_upgrade, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Reserve for Future Defense Measures</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(reserve_for_future_defense, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(reserve_for_future_defense, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Public Relations Marketing Costs</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(pr_marketing_cost, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(pr_marketing_cost, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Consumer Monitoring for Victims</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(consumer_monitoring, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(consumer_monitoring, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Notification Mailing Cost</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(notification_cost, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(notification_cost, 2)}</td>
                                        </tr>
                                        <tr style={{fontWeight:600}}>
                                            <td>Total Consequential Fines & Fees</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(consequential_fines, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(consequential_fines, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr >
                                            <td>Number of Years in Audit </td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__num_years_in_audit, -2)}</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__num_years_in_audit, -2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Probability of Stated Fines & Fees</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__probability_before, -2)}%</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step11__probability_after, -2)}%</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Exposure Risk (100% Probability of Stated of Fines & Fees)</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(exposure, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(exposure, 2)}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                        </tr>
                                        <tr style={{fontWeight:600}}>
                                            <td>Risk Reserve Account</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(risk_reserve_before, 2)}</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(risk_reserve_after, 2)}</td>
                                        </tr>
                                        <tr style={{fontWeight:600}}>
                                            <td>Increased Rate of Return</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>{formatCurrency(data.step1__fin_interest_on_cost_avoidance, 0)}%</td>
                                        </tr>
                                        <tr style={{fontWeight:600}}>
                                            <td>Increased Return</td>
                                            <td style={{ textAlign: "right" }}>&nbsp;</td>
                                            <td style={{ textAlign: "right" }}>${formatCurrency(step11__total, 2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>               


                </div>

            </div>
            <div className='mt-3'>
                <div className="clearfix">
                    <button type="button" className="btn btn-g btn-outline-primary float-start" onClick={() => previous()} ><i className="mdi mdi-skip-previous " aria-hidden="true" ></i> Back</button>
                    <button type="button" className="btn btn-g btn-outline-primary float-end" onClick={() => next()} ><i className="mdi mdi-skip-next " aria-hidden="true" ></i> Next</button>
                </div>
            </div>
        </div>
    )
}

export default BreachFines;