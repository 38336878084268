import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading, formatCurrency } from '../../Components/Util.js';

import * as service from "../../services";


function PricingVariables(props) {
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.className = "normal-html";
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "normal-body";


    const [loading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [partnerDiscounts, setPartnerDiscounts] = useState([]);
    const [supportMultipliers, setSupportMultipliers] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [record, setRecord] = useState(undefined);
    const [recordType, setRecordType] = useState(undefined);
    const [errors, setErrors] = useState({});




    useEffect(() => {
        if (!loading) {
            console.log("loaded...");
        }
    }, [loading]);


    useEffect(() => {
        startLoading()

        let p1 = new Promise((resolve, reject) => {
            service.getElaData("sfdc_currencies", {}, res => {
                setCurrencies(res)
                resolve("");
            });
        });
        let p2 = new Promise((resolve, reject) => {
            service.getElaData("ela_partner_discounts", {}, res => {
                setPartnerDiscounts(res)
                resolve("");
            });
        });
        let p3 = new Promise((resolve, reject) => {
            service.getElaData("ela_support_multiplier", {}, res => {
                setSupportMultipliers(res)
                resolve("");
            });
        });
        Promise.all([p1, p2, p3])
            .then(values => {
                setLoading(false)
            })
            .catch(error => {
                console.log(error.message);
            });
    }, []);

    const showDetail =(dataset, item) =>{
        setRecord(item);
        setRecordType(dataset);
        setShowForm(true);
    }
    const handleChange = (prop, value) => {
        setRecord(record => ({ ...record, [prop]: value }));
    }
    const refreshPriceBookEntries =()=>{
        startLoading();
        service.refreshPriceBookEntries((data)=>{
            console.log(data);
            stopLoading();
        });
    }

    const validate = () => {
        setErrors({});
        let isValid = true;
        let validation = [];
        if(recordType==='PARTNER_DISCOUNT'){
            validation.push({ field: "discount_non_reg", msg: "Please enter Partner Discount (Deal not registered)" });
            validation.push({ field: "discount_reg", msg: "Please enter Partner Discount (Deal registered)" });
            validation.push({ field: "discount_disti_non_reg", msg: "Please enter Distributor Discount (Deal not registered)" });
            validation.push({ field: "discount_disti_reg", msg: "Please enter Distributor Discount (Deal registered)" });
        }
        if(recordType==='SUPPORT'){
            validation.push({ field: "multiplier", msg: "Please enter Uplift/Downlift Multiplier" });
        }
        if(recordType==='CURRENCY'){
            validation.push({ field: "fx_rate", msg: "Please enter FX Rate" });
        }
        validation.map(item => {
            if (record[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };

    const saveForm=()=>{
        if(validate()){

            service.upsertEla(recordType==='PARTNER_DISCOUNT'?'partner-discounts':recordType==='SUPPORT'?'ela-support':recordType==='CURRENCY'?'ela-currency':"", record, resx => {
                startLoading()

                let p1 = new Promise((resolve, reject) => {
                    service.getElaData("sfdc_currencies", {}, res => {
                        setCurrencies(res)
                        resolve("");
                    });
                });
                let p2 = new Promise((resolve, reject) => {
                    service.getElaData("ela_partner_discounts", {}, res => {
                        setPartnerDiscounts(res)
                        resolve("");
                    });
                });
                let p3 = new Promise((resolve, reject) => {
                    service.getElaData("ela_support_multiplier", {}, res => {
                        setSupportMultipliers(res)
                        resolve("");
                    });
                });
                Promise.all([p1, p2, p3])
                    .then(values => {
                        setLoading(false)
                        setShowForm(false);
                    })
                    .catch(error => {
                        console.log(error.message);
                    });
            });
        }
    }



    loading ? startLoading() : stopLoading();
    return <div className="col-12 mt-3" style={{ background: "#f8f9fa" }}>
        <div className="row">
            <div className="col-12 mt-3" style={{ background: "#f8f9fa" }}>
                <div className="container-fluid mt-1 filter-bar" >
                    <div className="row pt-2">
                        <h4 style={{ color: "#ffffff" }}>Partner Discounts</h4>
                    </div>
                    <div className="row " style={{ background: "#FFFFFF" }}>
                        <table className="table ">
                            <thead>
                                <tr style={{background:"#333333", color:"#ffffff"}}>
                                    <th scope="col">Partner Type</th>
                                    <th scope="col" style={{ textAlign: "right" }}>Partner Discount<br/>(Deal not registered)</th>
                                    <th scope="col" style={{ textAlign: "right" }}>Partner Discount<br/>(Deal registered)</th>
                                    <th scope="col" style={{ textAlign: "right" }}>Distributor Discount<br/>(Deal not registered)</th>
                                    <th scope="col" style={{ textAlign: "right" }}>Distributor Discount<br/>(Deal registered)</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partnerDiscounts && partnerDiscounts.map(item => {
                                    return <tr key={item.partner_type}>
                                        <th scope="col">{item.partner_type}</th>
                                        <td  style={{ textAlign: "right" }}>{formatCurrency(item.discount_non_reg*100,2)}%</td>
                                        <td  style={{ textAlign: "right" }}>{formatCurrency(item.discount_reg*100,2)}%</td>
                                        <td  style={{ textAlign: "right" }}>{formatCurrency(item.discount_disti_non_reg*100,2)}%</td>
                                        <td  style={{ textAlign: "right" }}>{formatCurrency(item.discount_disti_reg*100,2)}%</td>
                                        <td  style={{ textAlign: "right" }}> <i className="mdi mdi-square-edit-outline " aria-hidden="true" data-tip data-for="edit-site" style={{ cursor: "pointer" }} onClick={() => { showDetail('PARTNER_DISCOUNT', item) }}></i> </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div className="row">
            <div className="col-6 mt-3 " style={{ background: "#f8f9fa" }}>
                <div className="container-fluid mt-1 filter-bar" >
                    <div className="row pt-2">
                        <h4  style={{ color: "#ffffff" }}>FX Rates <span  style={{ fontSize: "0.65em", fontWeight:"100" }}>Sourced from SFDCs</span></h4>
                        
                    </div>
                    <div className="row " style={{ background: "#FFFFFF" }}>
                        <table className="table ">
                            <thead>
                                <tr style={{background:"#333333", color:"#ffffff"}}>
                                    <th scope="col">Currency</th>
                                    <th scope="col" style={{ textAlign: "right" }}>FX Rate</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {currencies && currencies.map(item => {
                                    return <tr key={item.currency_code}>
                                        <th scope="col">{item.currency_code}</th>
                                        <td  style={{ textAlign: "right" }}>{item.fx_rate}</td>
                                        
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-6 mt-3" style={{ background: "#f8f9fa" }}>
                <div className="container-fluid mt-1 filter-bar" >
                    <div className="row pt-2">
                        <h4 style={{ color: "#ffffff" }}>Support Uplift/Downlift</h4>
                    </div>
                    <div className="row " style={{ background: "#FFFFFF" }}>
                        <table className="table ">
                            <thead>
                                <tr style={{background:"#333333", color:"#ffffff"}}>
                                    <th scope="col">Support Type</th>
                                    <th scope="col" style={{ textAlign: "right" }}>Uplift/Downlift Multiplier</th>
                                    <th scope="col"> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {supportMultipliers && supportMultipliers.map(item => {
                                    return <tr key={item.support_type}>
                                        <th scope="col">{item.support_type}</th>
                                        <td  style={{ textAlign: "right" }}>{formatCurrency(item.multiplier*100,2)}%</td>
                                        <td  style={{ textAlign: "right" }}> <i className="mdi mdi-square-edit-outline " aria-hidden="true" data-tip data-for="edit-site" style={{ cursor: "pointer" }} onClick={() => { showDetail('SUPPORT', item) }}></i> </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid mt-1 filter-bar" >
                    <div className="row pt-2">
                        <h4 style={{ color: "#ffffff" }}>Product Descriptions and Price Book Entries</h4>
                    </div>
                    <div className="row " style={{ background: "#FFFFFF", padding:"5px" }}>
                        <button type="button" className="btn btn-g btn-primary float-end"  onClick={() => refreshPriceBookEntries()} >Sync With SFDC</button>
                    </div>
                </div>
            </div>
        </div>
        {showForm && <div className='popup'>
            <div className='popup_content' style={{ width: "900px", left: "calc( 50% - 450px)", background: "#fafafa" }}>
                <div className="popup-title" style={{ paddingBottom: "40px" }}>
                    Update {recordType==='PARTNER_DISCOUNT'?'Partner Discounts':recordType==='SUPPORT'?'Support Uplift/Downlift':recordType==='CURRENCY'?'FX Rate':""}
                </div>
                <div style={{ fontSize: "13px" }}>


                    <div className='container-fluid'>
                        <div className="row g-12 mt-2">
                            <div className="col-3">
                                <div className="form-label" >{recordType==='PARTNER_DISCOUNT'?'Partner Type':recordType==='SUPPORT'?'Support Type':recordType==='CURRENCY'?'Currency Code':""}</div>
                                <div className="form-label" style={{ fontWeight: "400" }}>{recordType==='PARTNER_DISCOUNT'?record.partner_type:recordType==='SUPPORT'?record.support_type:recordType==='CURRENCY'?record.currency_code:""}</div>
                            </div>
                            
                        </div>

                        <div className='row mt-2'>
                            <div className="col-sm-12">
                                <label htmlFor="price1" className="form-label">{recordType==='PARTNER_DISCOUNT'?'Partner Discount (Deal not registered)':recordType==='SUPPORT'?'Uplift/Downlift Multiplier':recordType==='CURRENCY'?'FX Rate':""} <span className="required">*</span></label>
                                <div className="input-group">
                                    
                                    <input type="text" className="form-control" name="price1" placeholder=""
                                        value={(recordType==='PARTNER_DISCOUNT'?record.discount_non_reg:recordType==='SUPPORT'?record.multiplier:recordType==='CURRENCY'?record.fx_rate:"") || ''}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handleChange(recordType==='PARTNER_DISCOUNT'?'discount_non_reg':recordType==='SUPPORT'?'multiplier':recordType==='CURRENCY'?'fx_rate':"", (e.target.validity.valid) ? e.target.value : '') }} />
                                        {recordType!=='CURRENCY' &&<div className="input-group-text">%</div>}
                                </div>
                                {errors[recordType==='PARTNER_DISCOUNT'?'discount_non_reg':recordType==='SUPPORT'?'multiplier':recordType==='CURRENCY'?'fx_rate':""] && <div className="validation-error">{errors[recordType==='PARTNER_DISCOUNT'?'discount_non_reg':recordType==='SUPPORT'?'multiplier':recordType==='CURRENCY'?'fx_rate':""]}</div>}
                            </div>
                            {recordType==='PARTNER_DISCOUNT' && <div className="col-sm-12 mt-2">
                                <label htmlFor="price2" className="form-label">Partner Discount (Deal registered) <span className="required">*</span></label>
                                <div className="input-group">
                                  
                                    <input type="text" className="form-control" name="price1" placeholder=""
                                        value={record.discount_reg || ''}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handleChange('discount_reg', (e.target.validity.valid) ? e.target.value : '') }} />
                                        <div className="input-group-text">%</div>
                                </div>
                                {errors.discount_reg && <div className="validation-error">{errors.discount_reg}</div>}
                            </div>}

                            {recordType==='PARTNER_DISCOUNT' && <div className="col-sm-12 mt-2">
                                <label htmlFor="price3" className="form-label">Distributor Discount (Deal not registered)	 <span className="required">*</span></label>
                                <div className="input-group">
                                   
                                    <input type="text" className="form-control" name="price3" placeholder=""
                                        value={record.discount_disti_non_reg || ''}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handleChange('discount_disti_non_reg', (e.target.validity.valid) ? e.target.value : '') }} />
                                        <div className="input-group-text">%</div>
                                </div>
                                {errors.discount_disti_non_reg && <div className="validation-error">{errors.discount_disti_non_reg}</div>}
                            </div>}
                            {recordType==='PARTNER_DISCOUNT' && <div className="col-sm-12 mt-2">
                                <label htmlFor="price4" className="form-label">Distributor Discount (Deal registered)	  <span className="required">*</span></label>
                                <div className="input-group">
                                    
                                    <input type="text" className="form-control" name="price4" placeholder=""
                                        value={record.discount_disti_reg || ''}
                                        maxLength="100"
                                        pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                        onChange={(e) => { handleChange('discount_disti_reg', (e.target.validity.valid) ? e.target.value : '') }} />
                                        <div className="input-group-text">%</div>
                                </div>
                                {errors.discount_disti_reg && <div className="validation-error">{errors.discount_disti_reg}</div>}
                            </div>}

                        </div>

                    </div>

                    <div className="popup-btn-panel">
                        <button type="button" className="btn btn-g btn-primary float-end" style={{ marginLeft: "5px" }} onClick={() => saveForm()} >Save</button>
                        <button type="button" className="btn btn-g btn-secondary float-end" style={{ marginLeft: "5px" }} onClick={() => setShowForm(false)} >Cancel</button>
                    </div>
                </div>
            </div>
        </div>}
        
    </div>
}

export default PricingVariables;