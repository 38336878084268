
import { ResponsivePie } from '@nivo/pie'



export const ROIPie = ({ data /* see data tab */ }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        
        valueFormat=" >-$,d"
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            },
            {
                id: 'squares',
                type: 'patternSquares',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 2,
                padding: 1,
                stagger: true
            }
        ]}
        fill={[
            {
                match: {
                    id: 'Fleet Management'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Outdated Operating System Replacement'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Over Utilization'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Under Utilization'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Increased Throughput'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'Uptime & Patient Satisfaction'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'Maintenance Contract Expenses'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'Parts Management'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'Clinical Engineering Labor'
                },
                id: 'squares'
            },
            {
                match: {
                    id: 'Cyber Hygiene Labor'
                },
                id: 'squares'
            },
            {
                match: {
                    id: 'Network Management Labor'
                },
                id: 'squares'
            },
            {
                match: {
                    id: 'Network Policy Creation Labor'
                },
                id: 'squares'
            }
        ]}
        // legends={[
        //     {
        //         anchor: 'bottom-right',
        //         direction: 'column',
        //         justify: false,
        //         translateX: 0,
        //         translateY: 56,
        //         itemsSpacing: 0,
        //         itemWidth: 90,
        //         itemHeight: 12,
        //         itemTextColor: '#999',
        //         itemDirection: 'left-to-right',
        //         itemOpacity: 1,
        //         symbolSize: 10,
        //         symbolShape: 'square',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemTextColor: '#000'
        //                 }
        //             }
        //         ]
        //     }
        // ]}
    />
)